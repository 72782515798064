import "core-js/modules/es.array.concat.js";
/**
 * 线索管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 续保线索池——列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getCluesPoolList: function getCluesPoolList(pageNum, pageSize, data) {
    return request.get(getUrl("/customer/clues/manage/list/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 续保线索池——线索回收
   * @param {*} id 
   */
  batchRecyclinga: function batchRecyclinga(id) {
    return request.get(getUrl("/customer/clues/manage/recycle/".concat(id), 1));
  },
  /**
   * 续保线索池——线索分配
   * @param {*} data 内容 
   */
  batchDistribution: function batchDistribution(data) {
    return request.post(getUrl("/customer/clues/manage/distribution", 1), data);
  },
  /**
  * 线索跟进任务池——列表
  * @param {*} pageNum 当前页
  * @param {*} pageSize 每页显示条数
  * @param {*} data 内容
  */
  getTaskPoolList: function getTaskPoolList(pageNum, pageSize, data) {
    return request.get(getUrl("/customer/clues/follow/list/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
  * 线索跟进任务池——查看详情
  * @param {*} id
  */
  checkDetails: function checkDetails(id) {
    return request.get(getUrl("/customer/clues/follow/detail/".concat(id), 1));
  },
  /**
  * 线索跟进任务池——新增
  * @param {*} data 内容
  */
  addTaskPool: function addTaskPool(data) {
    return request.get(getUrl("/customer/clues/follow/add", 1), data);
  },
  /**
  * 线索跟进任务池——修改车主信息
  * @param {*} data 内容
  */
  editOwnerInformation: function editOwnerInformation(data) {
    return request.get(getUrl("/customer/clues/follow/update/owner", 1), {
      params: data
    });
  },
  /**
  * 线索跟进任务池——修改车辆信息
  * @param {*} data 内容
  */
  editVehicleInformation: function editVehicleInformation(data) {
    return request.get(getUrl("/customer/clues/follow/update/vehicle", 1), {
      params: data
    });
  },
  /**
  * 线索跟进任务池——修改投保线索
  * @param {*} data 内容
  */
  editForClues: function editForClues(data) {
    return request.post(getUrl("/customer/clues/follow/update/participant", 1), data);
  },
  /**
  * 线索跟进任务池——修改上一年投保公司信息
  * @param {*} data 内容
  */
  editInsuranceLast: function editInsuranceLast(data) {
    return request.get(getUrl("/customer/clues/follow/update/risks", 1), {
      params: data
    });
  },
  /**
  * 经销商员工列表
  */
  dealerEmployees: function dealerEmployees(data) {
    return request.get(getUrl("/dealer/employee/all/list", 12), {
      params: data
    });
  }
};
export default objApi;