import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '../../mixins/dict';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import activityRequest from '../../api/insuranceActivities';
import { commonExport } from '@/utils/common';
export default {
  name: 'ActivityVerification',
  components: {
    Pagination: Pagination,
    DatePicker: DatePicker
  },
  mixins: [dict, initHeight],
  computed: {
    tableHeight: function tableHeight() {
      return document.body.clientHeight - 215 - 132 + 'px';
    }
  },
  data: function data() {
    return {
      activityTime: [],
      activityTime2: [],
      activeName: 'first',
      searchForm1: {
        activityName: ''
      },
      searchForm2: {
        activityName: ''
      },
      total1: 0,
      total2: 0,
      listQuery1: {
        pageNum: 1,
        pageSize: 20
      },
      listQuery2: {
        pageNum: 1,
        pageSize: 20
      },
      tableData1: [],
      tableData2: [],
      multipleSelection1: [],
      multipleSelection2: [],
      loading: false
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化
      this.resetSearch();
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    exportFn1: function exportFn1() {
      if (this.activityTime) {
        this.searchForm1.activityStartTime = this.activityTime[0] ? this.activityTime[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm1.activityEndTime = this.activityTime[1] ? this.activityTime[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var searchForm = {
        activityName: this.searchForm1.activityName,
        activityStartTime: this.searchForm1.activityStartTime,
        activityEndTime: this.searchForm1.activityEndTime,
        isCheckSales: 0
      };
      var exporParams = {
        total: this.total1,
        fileName: '活动待核销列表',
        exportUrl: '/ps-ftms-service/activitys/check/sales',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable1);
    },
    exportFn2: function exportFn2() {
      if (this.activityTime2) {
        this.searchForm2.activityStartTime = this.activityTime2[0] ? this.activityTime2[0].split(' ')[0] + ' 00:00:00' : null;
        this.searchForm2.activityEndTime = this.activityTime2[1] ? this.activityTime2[1].split(' ')[0] + ' 23:59:59' : null;
      }
      var searchForm = {
        activityName: this.searchForm2.activityName,
        activityStartTime: this.searchForm2.activityStartTime,
        activityEndTime: this.searchForm2.activityEndTime,
        isCheckSales: 1
      };
      var exporParams = {
        total: this.total2,
        fileName: '活动已核销列表',
        exportUrl: '/ps-ftms-service/activitys/check/sales',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable2);
    },
    tabHandleClick: function tabHandleClick(tab, event) {
      this.loadData();
    },
    // 日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 查看活动核销详情
    viewDetail: function viewDetail(item) {
      var router_path = this.$route.path;
      this.$router.push({
        path: "/verification/detail",
        query: {
          activityId: item.activityId,
          check: this.activeName,
          // 待核销/已核销
          back: router_path
        }
      });
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      if (this.activeName === 'first') {
        this.searchForm1 = {
          activityName: ''
        };
        this.activityTime = [];
      } else {
        this.searchForm2 = {
          activityName: ''
        };
        this.activityTime2 = [];
      }
      // this.loadData();
    },
    searchData: function searchData() {
      //搜索
      if (this.activeName === 'first') {
        this.listQuery1 = {
          pageNum: 1,
          pageSize: 20
        };
      } else {
        this.listQuery2 = {
          pageNum: 1,
          pageSize: 20
        };
      }
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      //获取列表数据
      this.loading = true;
      var params = {};
      if (this.activeName === 'first') {
        if (this.activityTime) {
          this.searchForm1.activityStartTime = this.activityTime[0] ? this.activityTime[0].split(' ')[0] + ' 00:00:00' : null;
          this.searchForm1.activityEndTime = this.activityTime[1] ? this.activityTime[1].split(' ')[0] + ' 23:59:59' : null;
        }
        params.pageNum = this.listQuery1.pageNum;
        params.pageSize = this.listQuery1.pageSize;
        params.activityName = this.searchForm1.activityName;
        params.activityStartTime = this.searchForm1.activityStartTime;
        params.activityEndTime = this.searchForm1.activityEndTime;
        params.isCheckSales = 0;
      } else {
        if (this.activityTime2) {
          this.searchForm2.activityStartTime = this.activityTime2[0] ? this.activityTime2[0].split(' ')[0] + ' 00:00:00' : null;
          this.searchForm2.activityEndTime = this.activityTime2[1] ? this.activityTime2[1].split(' ')[0] + ' 23:59:59' : null;
        }
        params.pageNum = this.listQuery2.pageNum;
        params.pageSize = this.listQuery2.pageSize;
        params.activityName = this.searchForm2.activityName;
        params.activityStartTime = this.searchForm2.activityStartTime;
        params.activityEndTime = this.searchForm2.activityEndTime;
        params.isCheckSales = 1;
      }
      activityRequest.getCheckSales(params).then(function (res) {
        _this.loading = false;
        if (!_.isEmpty(res.data)) {
          if (_this.activeName === 'first') {
            _this.tableData1 = res.data.list;
            _this.total1 = res.data.page.recordsTotal;
          } else {
            _this.tableData2 = res.data.list;
            _this.total2 = res.data.page.recordsTotal;
          }
          _this.$nextTick(function () {
            _this.$refs.multipleTable1.doLayout();
            _this.$refs.multipleTable2.doLayout();
          });
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    handleSelectionChange1: function handleSelectionChange1(val) {
      //列表行选择事件
      this.multipleSelection1 = val;
    },
    handleSelectionChange2: function handleSelectionChange2(val) {
      //列表行选择事件
      this.multipleSelection2 = val;
    }
  }
};