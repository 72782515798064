var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { height: _vm.scrollerHeight, overflow: "auto" } },
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 12, lg: 6 } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card", attrs: { shadow: "never" } },
                      [
                        _c("div", { staticClass: "chart-wrapper" }, [
                          _c("div", { staticClass: "header" }, [
                            _vm._v("总出单量"),
                          ]),
                          _c(
                            "div",
                            { staticStyle: { padding: "10px" } },
                            [
                              _c("div", { staticClass: "quantity" }, [
                                _vm._v("0"),
                              ]),
                              _c("issue-line-chart", {
                                attrs: {
                                  "chart-data": _vm.val1,
                                  height: "100px",
                                  "series-name": "出单量",
                                },
                              }),
                              _c("div", { staticClass: "footer" }, [
                                _c("div", [_vm._v("月环比")]),
                                _c("div", [_vm._v("周环比")]),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 12, lg: 6 } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card", attrs: { shadow: "never" } },
                      [
                        _c("div", { staticClass: "chart-wrapper" }, [
                          _c("div", { staticClass: "header" }, [
                            _vm._v("总保费规模"),
                          ]),
                          _c(
                            "div",
                            { staticStyle: { padding: "10px" } },
                            [
                              _c("div", { staticClass: "quantity" }, [
                                _vm._v("0"),
                              ]),
                              _c("premium-bar-chart", {
                                attrs: {
                                  "chart-data": _vm.val1,
                                  height: "100px",
                                },
                              }),
                              _c("div", { staticClass: "footer" }, [
                                _c("div", [_vm._v("月环比")]),
                                _c("div", [_vm._v("周环比")]),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 12, lg: 6 } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card", attrs: { shadow: "never" } },
                      [
                        _c("div", { staticClass: "header" }, [
                          _vm._v("总手续费规模"),
                        ]),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c("div", { staticClass: "quantity" }, [
                              _vm._v("0"),
                            ]),
                            _c("issue-line-chart", {
                              attrs: {
                                "chart-data": _vm.val2,
                                height: "100px",
                                "series-name": "手续费规模",
                              },
                            }),
                            _c("div", { staticClass: "footer" }, [
                              _c("div", [_vm._v("月环比")]),
                              _c("div", [_vm._v("周环比")]),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 12, lg: 6 } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card", attrs: { shadow: "never" } },
                      [
                        _c(
                          "el-carousel",
                          {
                            attrs: {
                              height: "217px",
                              "indicator-position": "outside",
                            },
                          },
                          _vm._l(_vm.val3, function (item, index) {
                            return _c("el-carousel-item", { key: index }, [
                              _c("div", { staticClass: "header" }, [
                                _vm._v(_vm._s(item.key)),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "quantity",
                                  staticStyle: { padding: "5px 0 10px 5px" },
                                },
                                [_vm._v("0")]
                              ),
                              _c("div", { staticStyle: { padding: "10px" } }, [
                                _c("div", { staticStyle: { height: "80px" } }),
                                _c("div", { staticClass: "footer" }, [
                                  _c("div", [_vm._v("月环比")]),
                                  _c("div", [_vm._v("周环比")]),
                                ]),
                              ]),
                            ])
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 24, lg: 14 } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card", attrs: { shadow: "never" } },
                      [
                        _c("div", { staticClass: "header" }, [
                          _vm._v("保费规模/出单量趋势"),
                        ]),
                        _c(
                          "el-carousel",
                          {
                            attrs: {
                              height: "317px",
                              "indicator-position": "outside",
                            },
                          },
                          _vm._l(_vm.val4, function (item, index) {
                            return _c(
                              "el-carousel-item",
                              { key: index },
                              [
                                _c("line-charts", {
                                  attrs: {
                                    "chart-data": item,
                                    height: "300px",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { xs: 24, sm: 24, lg: 10 } },
                  [
                    _c(
                      "el-card",
                      { staticClass: "box-card", attrs: { shadow: "never" } },
                      [
                        _c("div", { staticClass: "header" }, [
                          _vm._v("保险公司保费规模/出单量占比"),
                        ]),
                        _c(
                          "el-carousel",
                          {
                            attrs: {
                              height: "317px",
                              "indicator-position": "outside",
                            },
                          },
                          _vm._l(_vm.val5, function (item, index) {
                            return _c(
                              "el-carousel-item",
                              { key: index },
                              [
                                _c("pie-charts", {
                                  attrs: {
                                    "chart-data": item,
                                    height: "300px",
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticStyle: { height: "170px" } }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }