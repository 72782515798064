import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Rules from '@/utils/rules';
import { dataFormat, idCardValidate, getCurrentDate, getCityItem, getAfterDate, getNextYear, getBeforeOfDate, getPlateColorType, getVehicleType, character, getVechileTypeByClass, limitInput, getBeforeOfMonth, getSupplyers, getAnalysisIdCard, getCurrentOrganization, getUserOrgInfo } from '@/utils/common';
import FormLabel from "@/components/FormSubLabel";
import dict from "../../mixins/dict";
export default {
  name: 'policyholderPersonal',
  mixins: [dict],
  components: {
    FormLabel: FormLabel
  },
  props: {
    form: {
      type: Object,
      default: function _default() {}
    },
    insuranceArea: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      activeOne: '1',
      sourceId: '',
      cityList: [],
      disabledLessThan: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      ownerDisabledStart: this.ownerMaxDate(),
      ownerDisabledEnd: this.ownerMinDate(),
      personalCertificateTypeList: [],
      unPersonalCertificateTypeList: [],
      idCardNoWidth: 0,
      orgType: [],
      personType: [],
      cardTypeOptions: [],
      VI_CERTIFICATE_TYPE: []
    };
  },
  created: function created() {
    var _this = this;
    this.cityList = JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [];
    this.VI_CERTIFICATE_TYPE = JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [];
    if (this.VI_CERTIFICATE_TYPE) {
      var _iterator = _createForOfIteratorHelper(this.VI_CERTIFICATE_TYPE),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var i = _step.value;
          if (i.dictCode == 'E02' || i.dictCode == 'E01') {
            this.unPersonalCertificateTypeList.push(i);
          } else {
            this.personalCertificateTypeList.push(i);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
    //统一社会信用代码(6)/组织机构代码证(7)
    this.VI_CERTIFICATE_TYPE.forEach(function (item) {
      return item.dictCode == 'E01' || item.dictCode == 'E02' ? _this.orgType.push(item) : _this.personType.push(item);
    });
    this.cardTypeOptions = this.personType;
  },
  mounted: function mounted() {
    this.calcWidth();
  },
  watch: {
    insuranceArea: {
      handler: function handler(newValue, oldValue) {
        // this.headerCollapse = (newValue == '310100' && this.showOperator);
        this.calcWidth();
      }
    }
  },
  methods: {
    calcWidth: function calcWidth() {
      //计算地址栏，和证件号控件宽度
      var width = document.body.clientWidth;
      width = width - (this.sidebarOpened ? 210 : 54) - 30 - 20;
      var w = parseInt(width / 3);
      this.idCardNoWidth = w + 110 + 320;
    },
    checkChange: function checkChange(val) {
      this.form.manager.isNotSelf = val;
    },
    mobileChange: function mobileChange(data) {
      //校验联系电话
      if (!data.operatorMobile) return false;
      var mobile = Rules['MOBILE_RULE'].value;
      // let phone = Rules['LANDLINE_RULE'].value;
      var flag = mobile.test(data.operatorMobile);
      if (!flag) {
        this.$message({
          type: 'error',
          message: '经办人手机号码不满足校验规则，请修改！',
          offset: this.sourceId ? 50 : 20,
          customClass: 'zZindex'
        });
        data.operatorMobile = '';
        this.$forceUpdate();
        return false;
      } else {
        return true;
      }
    },
    emailChange: function emailChange(data) {
      //校验邮箱
      if (!data.operatorEmail) return false;
      var email = Rules['EMAIL'].value;
      var flag = email.test(data.operatorEmail);
      if (!flag) {
        this.$message({
          type: 'error',
          message: '经办人邮箱不满足校验规则，请修改！',
          offset: this.sourceId ? 50 : 20,
          customClass: 'zZindex'
        });
        data.operatorEmail = '';
        this.$forceUpdate();
        return false;
      } else {
        return true;
      }
    },
    managerInputCardNo: function managerInputCardNo(val) {
      if (val) {
        if (this.form.manager.operatorCertificate == 'P01') {
          this.form.manager.operatorCertificateNo = character(_.toUpper(val));
        } else {
          val = val.replace(/[^A-Za-z0-9\（\）\(\)]/g, '');
          this.form.manager.operatorCertificateNo = _.toUpper(val);
        }
      }
    },
    getCertificateTypeName: function getCertificateTypeName(val) {
      //设置证件类型名称
      var item = _.find(this.personalCertificateTypeList, function (i) {
        return i.dictCode == val;
      });
      this.form.policyholder.certificateTypeDisplay = item.dictName;
      this.$forceUpdate();
    },
    // 车主证件有效起期
    ownerMaxDate: function ownerMaxDate() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.data && self.data.ownerCertEndDate) {
            return time.getTime() > new Date(self.data.ownerCertEndDate).getTime();
          }
          return false;
        }
      };
    },
    // 车主证件有效止期
    ownerMinDate: function ownerMinDate() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.data && self.data.ownerCertStartDate) {
            return time.getTime() < new Date(self.data.ownerCertStartDate).getTime();
          }
          return false;
        }
      };
    },
    changeValue: function changeValue(val) {
      //刷新控件
      this.$forceUpdate();
    },
    inputCardNo: function inputCardNo(val) {
      if (val) {
        if (this.form.policyholder.certificateType == 'P01') {
          this.form.policyholder.certificateNo = character(_.toUpper(val));
        } else {
          val = val.replace(/[^A-Za-z0-9\（\）\(\)]/g, '');
          this.form.policyholder.certificateNo = _.toUpper(val);
        }
      }
    },
    certificateNoChange: function certificateNoChange(data) {
      //根据身份证获取出生日期和性别,性质：个人，证件类型：身份证
      var _data = _.cloneDeep(this.form.policyholder);
      if (_data.certificateType == 'P01' && _data.certificateNo) {
        var certificateNo = Rules['ID_CARD'].value;
        var flag = certificateNo.test(_data.certificateNo);
        var isValidate = idCardValidate(_data.certificateNo);
        if (!flag || !isValidate) {
          this.$message({
            type: 'error',
            message: '身份证号码不满足校验规则，请修改！',
            offset: this.sourceId ? 50 : 20,
            customClass: 'zZindex'
          });
          // data.certificateNo = '';
          this.$forceUpdate();
          return false;
        }
        var birthDay = getAnalysisIdCard(_data.certificateNo, 1);
        var sex = getAnalysisIdCard(_data.certificateNo, 2);
        _data.birthday = birthDay; //出生日期
        _data.sex = sex; //性别
        data.birthday = _data.birthday ? _data.birthday : null; //出生日期
        data.sex = _data.sex; //性别
        this.$forceUpdate();
      }
      if (data.isNotSelf && data.mobileHolderCertificate == 'P01' && data.mobileHolderCertificateNo && this.insuranceArea == '310100') {
        var noRules = Rules['ID_CARD'].value;
        var _flag = noRules.test(data.mobileHolderCertificateNo);
        var _isValidate = idCardValidate(data.mobileHolderCertificateNo);
        if (!_flag || !_isValidate) {
          this.$message({
            type: 'error',
            message: '手机持有人的身份证号码不满足校验规则，请修改！',
            offset: this.sourceId ? 50 : 20,
            customClass: 'zZindex'
          });
          this.$forceUpdate();
          return false;
        }
        this.$forceUpdate();
      }
      return true;
    },
    // 长期切换
    dataValueChange: function dataValueChange(val, isOwner) {
      if (isOwner) {
        this.form.policyholder.ownerCertEndDate = val ? '9999-12-31 23:59:59' : '';
      } else {
        this.form.policyholder.certEndDate = val ? '9999-12-31 23:59:59' : '';
      }
    },
    handleChange: function handleChange(val) {
      if (!val) return;
      if (_.isArray(val) && val.length > 0) {
        if (this.$refs.addrssCascader.$refs.panel.checkedValue && this.$refs.addrssCascader.$refs.panel.checkedValue != val[2]) {
          // 清空选中的节点
          this.$refs.addrssCascader.$refs.panel.clearCheckedNodes();
          // 设置为空可以让节点不高亮显示
          this.$refs.addrssCascader.$refs.panel.activePath = [];
          this.form.policyholder.regionCode = val;
          this.$forceUpdate();
        }

        //获取地区名称
        var regionName = '';
        var province = _.find(this.cityList, function (item) {
          return item.value == val[0];
        });
        if (province) {
          regionName = province.label;
          var city = _.find(province.children, function (item) {
            return item.value == val[1];
          });
          if (city) {
            regionName = regionName === city.label ? regionName : regionName + ' ' + city.label;
            var area = _.find(city.children, function (item) {
              return item.value == val[2];
            });
            if (area) {
              regionName = regionName + ' ' + area.label;
              this.form.policyholder.regionCode = area.value;
            }
          }
        }
        //设置地区名称
        this.form.policyholder.regionName = regionName;
        this.form.policyholder.fullAddress = val;
        this.$set(this.form.policyholder, 'address', this.form.policyholder.regionName.replace(/\s+/g, ''));
      }
      this.$forceUpdate();
    }
  }
};