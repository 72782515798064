import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customersRequest from '@/api/customers';
import Rules from '@/utils/rules';
import dictRequest from '@/api/dictionary';
import { getSupplyers, getUserOrgInfo } from "@/utils/common";
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    customerId: {
      type: String,
      default: ''
    },
    DialogData: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      form: {},
      listData: [],
      cascaderAddr: [],
      listLoading: false,
      insuranceList: [],
      renewalTypeList: [],
      customerTypeList: [],
      customerSysList: [],
      checkbzLastYearEndDate: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
    this.getInsuranceData();
    this.getRenewalTypeList();
    this.getOrgRenewalConfig();
    this.getCustomerSysList();
  },
  methods: {
    //获取客户类别
    getCustomerSysList: function getCustomerSysList() {
      var _this = this;
      dictRequest.getDictItemsByCode("CUSTOMER_SYS_TYPE").then(function (res) {
        if (res.data) {
          _this.customerSysList = res.data;
        }
        ;
      });
    },
    // 获取客户类型
    getOrgRenewalConfig: function getOrgRenewalConfig() {
      var _this2 = this;
      var data = {
        bizType: 'BROKER',
        orgCode: ''
      };
      var _orgInfo = getUserOrgInfo();
      if (_orgInfo && _orgInfo.organizationId) {
        data.orgCode = _orgInfo.orgCode;
      }
      ;
      customersRequest.getByOrg(data).then(function (res) {
        if (res.code === 200) {
          _this2.customerTypeList = res.data.distinguishConversionList;
        }
        ;
      });
    },
    //获取客户类别
    getRenewalTypeList: function getRenewalTypeList() {
      var _this3 = this;
      dictRequest.getDictItemsByCode("RENEWAL_TYPE").then(function (res) {
        if (res.data) {
          _this3.renewalTypeList = res.data;
        }
      });
    },
    //获取保司列表
    getInsuranceData: function getInsuranceData() {
      this.insuranceList = getSupplyers();
    },
    insCorpChange: function insCorpChange(val) {
      var obj = this.insuranceList.find(function (item) {
        return item.code === val;
      });
      this.form.bsLastYearInsCorpName = obj.shortName;
    },
    initData: function initData() {//初始化数据
      // console.log(this.DialogData)
      //获取弹窗所需展示数据
      // this.form = {
      // ownerName: this.customersInfo.ownerName,
      // ownerMobile: this.customersInfo.ownerMobile,
      // ownerLandline: this.customersInfo.ownerLandline,
      // email: this.customersInfo.email,
      // ownerAddress: this.customersInfo.ownerAddress,
      // ownerProvinceCode: this.customersInfo.ownerProvinceCode,
      // ownerCityCode: this.customersInfo.ownerCityCode,
      // ownerDistrictCode: this.customersInfo.ownerDistrictCode,
      // bsLastYearInsCorpCode: this.DialogData.bsLastYearInsCorpCode,
      // bsLastYearInsCorpName: this.DialogData.bsLastYearInsCorpName,
      // bsLastYearEndDate: this.DialogData.bsLastYearEndDate,
      // bzLastYearInsCorpCode: this.DialogData.bzLastYearInsCorpCode,
      // bzLastYearInsCorpName: this.DialogData.bzLastYearInsCorpName,
      // bzLastYearEndDate: this.DialogData.bzLastYearEndDate,
      // renewalType: this.customersInfo.renewalType,
      // ownerOtherMobile: this.customersInfo.ownerOtherMobile,
      // }
      //展示省/市/区
      // this.cascaderAddr = [];
      // this.cascaderAddr.push(this.customersInfo.ownerProvinceCode);
      // this.cascaderAddr.push(this.customersInfo.ownerCityCode);
      // this.cascaderAddr.push(this.customersInfo.ownerDistrictCode);
    },
    handleChange: function handleChange(val) {
      //省/市/区选择
      this.$nextTick(function () {
        var cascaderAddr = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;
        this.form.ownerProvinceName = cascaderAddr[0];
        this.form.ownerCityName = cascaderAddr[1];
        this.form.ownerDistrictName = cascaderAddr[2];
        this.form.ownerProvinceCode = val[0];
        this.form.ownerCityCode = val[1];
        this.form.ownerDistrictCode = val[2];
      });
    },
    // 校验
    validate: function validate() {
      var flag = false;
      if (this.form.ownerName && !Rules["NAME"].value.test(this.form.ownerName)) {
        this.$message.warning('联系人格式有误');
        flag = true;
        return flag;
      }
      if (this.form.ownerMobile && !Rules["MOBILE_RULE"].value.test(this.form.ownerMobile)) {
        this.$message.warning('联系电话格式有误');
        flag = true;
        return flag;
      }
      if (this.form.ownerOtherMobile && !Rules["MOBILE_RULE"].value.test(this.form.ownerOtherMobile)) {
        this.$message.warning('其他电话格式有误');
        flag = true;
        return flag;
      }
      if (this.form.LANDLINE_RULE && !Rules["LANDLINE_RULE"].value.test(this.form.LANDLINE_RULE)) {
        this.$message.warning('座机号格式有误');
        flag = true;
        return flag;
      }
      if (this.form.email && !Rules["EMAIL"].value.test(this.form.email)) {
        this.$message.warning('电子邮箱格式有误');
        flag = true;
        return flag;
      }
      return flag;
    },
    save: function save() {
      var _this4 = this;
      //保存数据
      if (this.validate()) return;
      var customerId = this.customerId;
      var data = {};
      var _licenceType = this.DialogData.licenceType ? this.DialogData.licenceType.substring(this.DialogData.licenceType.length - 2) : '';
      if (this.checkbzLastYearEndDate) {
        data = {
          plateNo: this.DialogData.plateNo,
          vin: this.DialogData.vin,
          engineNo: this.DialogData.engineNo,
          plateType: _licenceType,
          bsLastYearInsCorpCode: this.DialogData.companyCode,
          bsLastYearInsCorpName: this.DialogData.companyName,
          bsLastYearEndDate: this.DialogData.endDate,
          bzLastYearInsCorpCode: this.DialogData.companyCode,
          bzLastYearInsCorpName: this.DialogData.companyName,
          bzLastYearEndDate: this.DialogData.endDate
        };
      } else {
        data = {
          plateNo: this.DialogData.plateNo,
          vin: this.DialogData.vin,
          engineNo: this.DialogData.engineNo,
          plateType: _licenceType,
          bsLastYearInsCorpCode: this.DialogData.companyCode,
          bsLastYearInsCorpName: this.DialogData.companyName,
          bsLastYearEndDate: this.DialogData.endDate
        };
      }
      customersRequest.update(customerId, data).then(function (res) {
        _this4.$message.success(res.msg);
        _this4.closePage();
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    customerSysTypeChange: function customerSysTypeChange(val) {
      if (!val) this.form.customerSysType = null;
    }
  }
};