var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c("div", { staticStyle: { padding: "10px" } }, [
                          _c(
                            "div",
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "30px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.NonAutoPolicyDetail
                                        .productClassName || "-"
                                    )
                                  ),
                                ]
                              ),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "font-size": "15px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "注销状态：" +
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail
                                          .cancelStatusDisplay || "-"
                                      )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                "font-size": "14px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "line-height": "28px",
                                  },
                                },
                                [
                                  _c("div", [_vm._v("注销申请编号：")]),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail.cancelNo
                                            ? _vm.NonAutoPolicyDetail.cancelNo
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _vm.policyInfo.productClassCode == "DBC"
                                    ? _c("svg-icon", {
                                        attrs: {
                                          "icon-class": _vm.policyInfo
                                            .supplierCode
                                            ? _vm.policyInfo.supplierCode.split(
                                                "_"
                                              )[1] &&
                                              _vm.policyInfo.supplierCode
                                                .split("_")[1]
                                                .toLowerCase()
                                            : "-",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.policyInfo.productClassCode == "DBC"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getInsCorpName(
                                              _vm.policyInfo.supplierCode.split(
                                                "_"
                                              )[1] &&
                                                _vm.policyInfo.supplierCode
                                                  .split("_")[1]
                                                  .toLowerCase()
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.policyInfo.productClassCode == "DBC"
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      "保单号：" +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail.policyNo
                                            ? _vm.NonAutoPolicyDetail.policyNo
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "投保单号：" +
                                        _vm._s(
                                          _vm.policyInfo.applyPolicyNo
                                            ? _vm.policyInfo.applyPolicyNo
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "注销机构：" +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail.orgName
                                            ? _vm.NonAutoPolicyDetail.orgName
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "业务员：" +
                                        _vm._s(
                                          _vm.policyInfo.salemanName
                                            ? _vm.policyInfo.salemanName
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "操作人：" +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail.creatorName
                                            ? _vm.NonAutoPolicyDetail
                                                .creatorName
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "注销申请时间：" +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail
                                            .cancelApplyTime
                                            ? _vm.NonAutoPolicyDetail
                                                .cancelApplyTime
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "承保时间：" +
                                        _vm._s(
                                          _vm.NonAutoPolicyDetail.policySaleTime
                                            ? _vm.NonAutoPolicyDetail
                                                .policySaleTime
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeOne,
                          callback: function ($$v) {
                            _vm.activeOne = $$v
                          },
                          expression: "activeOne",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "车辆信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("车牌号：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.policyInfo.targetPlateNo ||
                                              "新车"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("车架号：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.policyInfo.targetVin || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("厂家品牌/车系：")]
                                      ),
                                      _c("div", [
                                        _vm.policyInfo.productClassCode ==
                                        "BZHG"
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.policyInfo
                                                      .targetVhcBrandName || "-"
                                                  ) +
                                                  "/" +
                                                  _vm._s(
                                                    _vm.policyInfo
                                                      .targetVhcSeriesName ||
                                                      "-"
                                                  ) +
                                                  "/" +
                                                  _vm._s(
                                                    _vm.policyInfo
                                                      .targetVhcModelName || "-"
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.policyInfo
                                                      .targetVhcBrandName || "-"
                                                  ) +
                                                  "/" +
                                                  _vm._s(
                                                    _vm.policyInfo
                                                      .targetVhcSeriesName
                                                  ) +
                                                  " "
                                              ),
                                              _vm.policyInfo
                                                .targetVhcSeriesChineseName
                                                ? _c("span", [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .targetVhcSeriesChineseName ||
                                                            "-"
                                                        ) +
                                                        ")"
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm._l(_vm.attributeList, function (item) {
                                  return _c(
                                    "el-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.policyInfo.productClassCode !=
                                              "DBC" &&
                                            item.attributeName ==
                                              "购车发票日期",
                                          expression:
                                            "policyInfo.productClassCode != 'DBC' && item.attributeName == '购车发票日期'",
                                        },
                                      ],
                                      key: item.attributeName,
                                      attrs: {
                                        xs: 12,
                                        sm: 12,
                                        md: 8,
                                        lg: 8,
                                        xl: 6,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item_label" },
                                          [
                                            _vm._v(
                                              _vm._s(item.attributeName + "：")
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          item.attributeName == "使用性质"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.policyInfo
                                                      .targetUseAttributeDisplay ||
                                                      "-"
                                                  )
                                                ),
                                              ])
                                            : item.attributeName == "能源类型"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.policyInfo
                                                      .targetFuelTypeDisplay ||
                                                      "-"
                                                  )
                                                ),
                                              ])
                                            : item.attributeName == "车辆类型"
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.policyInfo
                                                      .targetVehicleTypeDisplay ||
                                                      "-"
                                                  )
                                                ),
                                              ])
                                            : item.attributeName ==
                                              "购车发票日期"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (item.attributeValue &&
                                                        item.attributeValue.split(
                                                          " "
                                                        )[0]) ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : item.attributeName ==
                                              "新车销售日期"
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      (item.attributeValue &&
                                                        item.attributeValue.split(
                                                          " "
                                                        )[0]) ||
                                                        "-"
                                                    )
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.attributeValue || "-"
                                                  )
                                                ),
                                              ]),
                                        ]),
                                      ]),
                                    ]
                                  )
                                }),
                                _vm.policyInfo.productClassCode == "TCWY"
                                  ? _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("使用性质：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.policyInfo
                                                      .targetUseAttributeDisplay ||
                                                      "-"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeTwo,
                          callback: function ($$v) {
                            _vm.activeTwo = $$v
                          },
                          expression: "activeTwo",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "客户信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("客户名称：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.policyInfo.targetOwnerName ||
                                              "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("证件类型：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.policyInfo
                                              .targetOwnerCertificateTypeDisplay ||
                                              "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("证件号码：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.policyInfo
                                              .targetOwnerCertificateNo || "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("联系电话：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.policyInfo.targetOwnerMobile ||
                                              "-"
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("电子邮箱：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.policyInfo.targetOwnerEmail ||
                                                "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 8,
                                      lg: 8,
                                      xl: 6,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("所在省市：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.policyInfo
                                                .targetOwnerProviceName || "-"
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.policyInfo
                                                .targetOwnerCityName || "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 12,
                                      sm: 12,
                                      md: 12,
                                      lg: 12,
                                      xl: 12,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "card_item" }, [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("详细地址：")]
                                      ),
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.policyInfo
                                                .targetOwnerAddress || "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeThree,
                          callback: function ($$v) {
                            _vm.activeThree = $$v
                          },
                          expression: "activeThree",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    attrs: { "form-label": "服务信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm.NonAutoPolicyDetail.productClassCode != "SB"
                              ? _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("服务产品编码：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .productCode || "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("服务产品名称：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NonAutoPolicyDetail
                                                    .productName || "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.policyInfo.productClassCode == "DBC"
                                      ? _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "累计最多赔偿天数(天)："
                                                    ),
                                                  ]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .targetCumulateMaxCompensationDay
                                                        ? _vm.policyInfo
                                                            .targetCumulateMaxCompensationDay
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.policyInfo.productClassCode == "DBC"
                                      ? _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "每次事故最多赔偿天数(天)："
                                                    ),
                                                  ]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .targetAccidentMaxCompensationDay
                                                        ? _vm.policyInfo
                                                            .targetAccidentMaxCompensationDay
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.policyInfo.productClassCode == "DBC"
                                      ? _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("最大赔付金额(元)：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .targetMaxCompensationAmount
                                                        ? _vm.policyInfo.targetMaxCompensationAmount.toFixed(
                                                            2
                                                          )
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("服务保障期限(月)：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .productTimeLimit || "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("服务生效起期：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.policyInfo.startTime
                                                    ? _vm.policyInfo.startTime.slice(
                                                        0,
                                                        10
                                                      )
                                                    : "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("服务生效止期：")]
                                            ),
                                            _vm.policyInfo &&
                                            _vm.policyInfo.productClassCode ==
                                              "YB"
                                              ? _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo &&
                                                        _vm.policyInfo
                                                          .targetGuaranteeRange ==
                                                          "双擎动力蓄电池"
                                                        ? "终身"
                                                        : _vm.policyInfo.endTime
                                                        ? _vm.policyInfo.endTime.slice(
                                                            0,
                                                            10
                                                          )
                                                        : "-"
                                                    )
                                                  ),
                                                ])
                                              : _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo.endTime
                                                        ? _vm.policyInfo.endTime.slice(
                                                            0,
                                                            10
                                                          )
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card_item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card_item_label",
                                              },
                                              [_vm._v("销售方式：")]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .salesMethodDisplay || "-"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        staticClass: "flexWarp",
                                        attrs: { gutter: 0 },
                                      },
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("服务产品编码：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .productCode || "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("服务产品名称：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .productName || "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("销售方式：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .salesMethodDisplay
                                                        ? _vm.policyInfo
                                                            .salesMethodDisplay
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "card_item" },
                                            [
                                              _c(
                                                "el-divider",
                                                {
                                                  attrs: {
                                                    "content-position": "left",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#333",
                                                        "font-size": "14px",
                                                        "font-weight": "600",
                                                      },
                                                    },
                                                    [_vm._v("三保方案")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "原厂质保公里数(万)："
                                                    ),
                                                  ]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .targetOriginalWarrantyMileage
                                                        ? _vm.policyInfo
                                                            .targetOriginalWarrantyMileage
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("原厂质保期限(年)：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .originalWarrantyPeriod
                                                        ? _vm.policyInfo
                                                            .originalWarrantyPeriod
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("原厂保修起期：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .originalStartTime
                                                        ? _vm.policyInfo.originalStartTime.slice(
                                                            0,
                                                            10
                                                          ) + " 0时"
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("原厂保修止期：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .originalEndTime
                                                        ? _vm.policyInfo.originalEndTime.slice(
                                                            0,
                                                            10
                                                          ) + " 24时"
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "card_item" },
                                            [
                                              _c(
                                                "el-divider",
                                                {
                                                  attrs: {
                                                    "content-position": "left",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#333",
                                                        "font-size": "14px",
                                                        "font-weight": "600",
                                                      },
                                                    },
                                                    [_vm._v("延保方案")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("延保保障范围：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .targetGuaranteeRange
                                                        ? _vm.policyInfo
                                                            .targetGuaranteeRange
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("延保方案：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo.ternScheme
                                                        ? _vm.policyInfo
                                                            .ternScheme
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("延保起期：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo.startTime
                                                        ? _vm.policyInfo.startTime.slice(
                                                            0,
                                                            10
                                                          ) + " 0时"
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("延保止期：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.NonAutoPolicyDetail
                                                        .endTime
                                                        ? _vm.NonAutoPolicyDetail.endTime.slice(
                                                            0,
                                                            10
                                                          ) + " 24时"
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("已行驶里程：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .targetMileage
                                                        ? _vm.policyInfo
                                                            .targetMileage
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("延长公里数(万)：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.NonAutoPolicyDetail
                                                        .targetExtendMileage ||
                                                        _vm.NonAutoPolicyDetail
                                                          .targetExtendMileage ===
                                                          0
                                                        ? _vm
                                                            .NonAutoPolicyDetail
                                                            .targetExtendMileage
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "card_item" },
                                            [
                                              _c(
                                                "el-divider",
                                                {
                                                  attrs: {
                                                    "content-position": "left",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#333",
                                                        "font-size": "14px",
                                                        "font-weight": "600",
                                                      },
                                                    },
                                                    [_vm._v("代步车方案")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("代步车年限(年)：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .targetScooterYearLimit
                                                        ? _vm.policyInfo
                                                            .targetScooterYearLimit
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("代步车服务起期：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .dbcStartTime
                                                        ? _vm.policyInfo.dbcStartTime.slice(
                                                            0,
                                                            10
                                                          ) + " 0时"
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("代步车服务止期：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo.dbcEndTime
                                                        ? _vm.policyInfo.dbcEndTime.slice(
                                                            0,
                                                            10
                                                          ) + " 24时"
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "card_item" },
                                            [
                                              _c(
                                                "el-divider",
                                                {
                                                  attrs: {
                                                    "content-position": "left",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#333",
                                                        "font-size": "14px",
                                                        "font-weight": "600",
                                                      },
                                                    },
                                                    [_vm._v("车险置换方案")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("补偿限额(万)：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .limitCompensation
                                                        ? _vm.policyInfo
                                                            .limitCompensation
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("置换年限(年)：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .targetScooterYearLimit
                                                        ? _vm.policyInfo
                                                            .targetScooterYearLimit
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("置换服务起期：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo.zhStartTime
                                                        ? _vm.policyInfo.zhStartTime.slice(
                                                            0,
                                                            10
                                                          ) + " 0时"
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "card_item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card_item_label",
                                                  },
                                                  [_vm._v("置换服务止期：")]
                                                ),
                                                _c("div", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.policyInfo.zhEndTime
                                                        ? _vm.policyInfo.zhEndTime.slice(
                                                            0,
                                                            10
                                                          ) + " 24时"
                                                        : "-"
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-collapse",
                      {
                        staticStyle: { "margin-top": "10px" },
                        model: {
                          value: _vm.activeFour,
                          callback: function ($$v) {
                            _vm.activeFour = $$v
                          },
                          expression: "activeFour",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          {
                            staticClass: "collapse-item-style",
                            attrs: { name: "1" },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "title",
                                  staticStyle: { "line-height": "25px" },
                                },
                                [
                                  _c("FormLabel", {
                                    staticStyle: { "font-size": "14px" },
                                    attrs: { "form-label": "注销信息" },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "el-row",
                              [
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("申请时间：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.form.cancelApplyTime || "-")
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("申请原因：")]
                                    ),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(_vm.form.cancelDesc || "-")
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm.NonAutoPolicyDetail.redInvoiceNo
                                  ? _c("el-col", { attrs: { span: 24 } }, [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item_label" },
                                          [_vm._v("红色通知单号：")]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.NonAutoPolicyDetail
                                                .redInvoiceNo || "-"
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c("div", { staticClass: "card_item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "card_item_label" },
                                      [_vm._v("厂家审核：")]
                                    ),
                                    _c("div", [
                                      _c("span", [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form.auditStatus === "CAS02"
                                                ? "通过"
                                                : _vm.form.auditStatus ===
                                                  "CAS03"
                                                ? "驳回"
                                                : _vm.form.auditStatusDisplay
                                            )
                                          ),
                                        ]),
                                        _vm.form.auditStatus === "CAS03"
                                          ? _c("span", [
                                              _vm._v(
                                                "：" +
                                                  _vm._s(
                                                    _vm.form.auditDesc || "-"
                                                  )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _vm.form.auditStatus === "CAS01"
                                        ? _c("span", [
                                            _vm._v(" (预计五个工作日审批完成)"),
                                          ])
                                        : _vm._e(),
                                      _vm.form.auditStatus && _vm.form.auditTime
                                        ? _c("span", [
                                            _vm._v(
                                              " (审核时间：" +
                                                _vm._s(_vm.form.auditTime) +
                                                ")"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]),
                                _vm.form.auditStatus === "CAS02"
                                  ? _c("el-col", { attrs: { span: 24 } }, [
                                      _c("div", { staticClass: "card_item" }, [
                                        _c(
                                          "div",
                                          { staticClass: "card_item_label" },
                                          [_vm._v("保司审核：")]
                                        ),
                                        _c("div", [
                                          _c("span", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.form
                                                    .supplierAuditStatus ===
                                                    "CAS02"
                                                    ? "通过"
                                                    : _vm.form
                                                        .supplierAuditStatus ===
                                                      "CAS03"
                                                    ? "驳回"
                                                    : _vm.form
                                                        .supplierAuditStatusDisplay
                                                )
                                              ),
                                            ]),
                                            _vm.form.supplierAuditStatus ===
                                              "CAS02" ||
                                            _vm.form.supplierAuditStatus ===
                                              "CAS03"
                                              ? _c("span", [
                                                  _vm._v(
                                                    "：" +
                                                      _vm._s(
                                                        _vm.form
                                                          .supplierAuditDesc ||
                                                          "-"
                                                      )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]),
                                          _vm.form.auditStatus === "CAS02" &&
                                          !_vm.form.supplierAuditTime
                                            ? _c("span", [
                                                _vm._v(
                                                  "待审批 (预计五个工作日审批完成)"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.form.supplierAuditStatus &&
                                          _vm.form.supplierAuditTime
                                            ? _c("span", [
                                                _vm._v(
                                                  " (审核时间：" +
                                                    _vm._s(
                                                      _vm.form.supplierAuditTime
                                                    ) +
                                                    ")"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("el-col", { attrs: { span: 24 } }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "margin-bottom": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "card_item_label" },
                                        [_vm._v("注销情况说明：")]
                                      ),
                                      _vm._l(
                                        _vm.fileList,
                                        function (fitem, findex) {
                                          return _c(
                                            "div",
                                            {
                                              key: findex,
                                              staticClass: "fileBox",
                                            },
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                },
                                                attrs: {
                                                  src: fitem.fileUrl,
                                                  "preview-src-list": [
                                                    fitem.fileUrl,
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-top": "1px solid #d7d7d7",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "footer-btn-list",
                                    staticStyle: {
                                      "margin-top": "10px",
                                      "font-size": "16px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "50px" },
                                      },
                                      [
                                        _vm._v(
                                          "服务销售价(元)：" +
                                            _vm._s(
                                              _vm.policyInfo.targetSalesPrice ||
                                                "-"
                                            )
                                        ),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        "DLR采购价-含税(元)：" +
                                          _vm._s(
                                            _vm.policyInfo
                                              .productPurchasePrice || "-"
                                          )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("h2", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    "退款金额(元)：" +
                                      _vm._s(
                                        _vm.NonAutoPolicyDetail.cancelAmount
                                          ? _vm.NonAutoPolicyDetail.cancelAmount
                                          : "-"
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm.isView && _vm.policyCancelLogList.length
                      ? _c(
                          "el-card",
                          {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { shadow: "never" },
                          },
                          [
                            _vm.isView && _vm.policyCancelLogList.length
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "background-color": "#f2f2f2",
                                      padding: "20px",
                                      "border-radius": "10px",
                                      "margin-top": "15px",
                                    },
                                  },
                                  [
                                    _c("b", [_vm._v("注销处理记录：")]),
                                    _vm._l(
                                      _vm.policyCancelLogList,
                                      function (item, index) {
                                        return _c("div", { key: index }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                                "margin-top": "20px",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(item.operationTime)
                                                ),
                                              ]),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(item.operationAction)
                                                ),
                                              ]),
                                              item.operationResult === "COR03"
                                                ? _c("div", [
                                                    _vm._v(
                                                      "不通过理由：" +
                                                        _vm._s(item.reason)
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.back } },
                    [_vm._v("关闭")]
                  ),
                  _vm.bizType == "OEM" && _vm.form.auditStatus == "CAS01"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.checkClicked },
                        },
                        [_vm._v("审核")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAuditDialog,
            "close-on-click-modal": false,
            width: "800px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAuditDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("注销审核")]
              ),
            ]
          ),
          _c("AuditDialog", {
            attrs: {
              policyCancelId: _vm.policyCancelId,
              refresh: _vm.showAuditDialog,
            },
            on: { closePage: _vm.closeAuditDialog },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }