var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mobile_holder",
      style: {
        "padding-left": _vm.showOperator ? "10px" : "0px",
        "margin-bottom": _vm.showOperator ? "10px" : "0px",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "operator",
          staticStyle: { width: "100%" },
          attrs: {
            model: _vm.operatorData,
            rules: _vm.formRules,
            "label-width": "130px",
            size: "mini",
          },
        },
        [
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showOperator,
                  expression: "showOperator",
                },
              ],
              attrs: { gutter: 0 },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    padding: "8px",
                  },
                  on: { click: _vm.expandOperator },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "13px",
                        "font-weight": "bold",
                        width: "100%",
                        display: "flex",
                        "flex-direction": "row",
                        "justify-content": "space-between",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("div", [_vm._v("经办人")]),
                      _vm.opType == "insure"
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-right": "10px",
                                position: "relative",
                                "z-index": "9",
                              },
                              on: {
                                mouseenter: function ($event) {
                                  _vm.iconOwnerShow = false
                                },
                                mouseleave: function ($event) {
                                  _vm.iconOwnerShow = true
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    plain: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.identificationOfOperatorClicked(
                                        "operator"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("svg-icon", {
                                    staticStyle: {
                                      "font-size": "12px",
                                      "margin-right": "2px",
                                    },
                                    attrs: {
                                      "class-name": "ident-icon_owner",
                                      "icon-class": _vm.iconOwnerShow
                                        ? "identificationPerson"
                                        : "identificationPersonHover",
                                    },
                                  }),
                                  _vm._v(" 证件识别 "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _c("div", [
                    _c("i", {
                      class:
                        "" +
                        (_vm.headerCollapse
                          ? "el-icon-arrow-down"
                          : "el-icon-arrow-right"),
                    }),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showOperator && _vm.headerCollapse,
                  expression: "showOperator && headerCollapse",
                },
              ],
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "operatorName",
                          attrs: { label: "经办人姓名", prop: "operatorName" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "special_col_one",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "double-input",
                                staticStyle: {
                                  "margin-right": "10px",
                                  width: "200px",
                                },
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入经办人姓名",
                                  clearable: false,
                                  disabled: _vm.isDisabled,
                                },
                                model: {
                                  value: _vm.operatorData.operatorName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.operatorData,
                                      "operatorName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "operatorData.operatorName",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "operatorType",
                          staticClass: "special_row_content",
                          attrs: { label: "经办人类别", prop: "operatorType" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "special_col_one",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "double-input",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.isDisabled,
                                    placeholder: "请选择经办人类别",
                                    clearable: false,
                                  },
                                  model: {
                                    value: _vm.operatorData.operatorType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.operatorData,
                                        "operatorType",
                                        $$v
                                      )
                                    },
                                    expression: "operatorData.operatorType",
                                  },
                                },
                                _vm._l(
                                  _vm.dictSource["OPERRATOR_TYPE"],
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "operatorCertificateNo",
                          attrs: {
                            label: "经办人证件类型",
                            prop: "operatorCertificateNo",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "special_col_one",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "double-input",
                                  staticStyle: { width: "200px" },
                                  attrs: {
                                    filterable: "",
                                    disabled: _vm.isDisabled,
                                    placeholder: "请选择经办人证件类型",
                                    clearable: false,
                                  },
                                  on: { change: _vm.changeValue },
                                  model: {
                                    value: _vm.operatorData.operatorCertificate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.operatorData,
                                        "operatorCertificate",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "operatorData.operatorCertificate",
                                  },
                                },
                                _vm._l(
                                  _vm.cardTypeOptions,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _c("el-input", {
                                staticClass: "input-with-select",
                                style: {
                                  "max-width": _vm.idCardNoWidth + "px",
                                  "min-width": "300px",
                                },
                                attrs: {
                                  maxlength: "20",
                                  disabled: _vm.isDisabled,
                                  placeholder: "请输入经办人证件号码",
                                  clearable: "",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.certificateNoChange(
                                      _vm.operatorData
                                    )
                                  },
                                  input: _vm.inputCardNo,
                                },
                                model: {
                                  value: _vm.operatorData.operatorCertificateNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.operatorData,
                                      "operatorCertificateNo",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "operatorData.operatorCertificateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 0 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          ref: "operatorMobile",
                          attrs: {
                            label: "经办人手机号",
                            prop: "operatorMobile",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "special_col_one",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c("el-input", {
                                staticClass: "double-input",
                                staticStyle: {
                                  "margin-right": "10px",
                                  width: "200px",
                                },
                                attrs: {
                                  type: "text",
                                  onkeyup:
                                    "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                  maxlength: "13",
                                  placeholder: "请输入经办人手机号",
                                  clearable: false,
                                  disabled: _vm.isDisabled,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.mobileChange(_vm.operatorData)
                                  },
                                },
                                model: {
                                  value: _vm.operatorData.operatorMobile,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.operatorData,
                                      "operatorMobile",
                                      $$v
                                    )
                                  },
                                  expression: "operatorData.operatorMobile",
                                },
                              }),
                              _vm.insuranceArea == "310100"
                                ? _c(
                                    "el-checkbox",
                                    {
                                      attrs: { disabled: _vm.isDisabled },
                                      on: { change: _vm.checkChange },
                                      model: {
                                        value: _vm.operatorData.isNotSelf,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.operatorData,
                                            "isNotSelf",
                                            $$v
                                          )
                                        },
                                        expression: "operatorData.isNotSelf",
                                      },
                                    },
                                    [_vm._v("非本人手机号")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "double-input",
                          staticStyle: { width: "200px" },
                          attrs: {
                            label: "经办人邮箱",
                            "label-width": "130px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "special_col_one",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  "margin-right": "10px",
                                  "min-width": "200px",
                                },
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入经办人邮箱",
                                  clearable: false,
                                  disabled: _vm.isDisabled,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.emailChange(_vm.operatorData)
                                  },
                                },
                                model: {
                                  value: _vm.operatorData.operatorEmail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.operatorData,
                                      "operatorEmail",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "operatorData.operatorEmail",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.insuranceArea == "310100" && _vm.operatorData.isNotSelf
                ? _c("el-divider")
                : _vm._e(),
              _vm.insuranceArea == "310100" && _vm.operatorData.isNotSelf
                ? _c(
                    "el-row",
                    { attrs: { gutter: 0 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              ref: "mobileHolderName",
                              attrs: {
                                label: "持有人姓名",
                                prop: "mobileHolderName",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "special_col_one",
                                  staticStyle: { display: "flex" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "double-input",
                                    staticStyle: {
                                      width: "200px",
                                      "margin-right": "10px",
                                    },
                                    attrs: {
                                      maxlength: "40",
                                      placeholder: "请输入持有人姓名",
                                      clearable: false,
                                      disabled: _vm.isDisabled,
                                    },
                                    model: {
                                      value: _vm.operatorData.mobileHolderName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.operatorData,
                                          "mobileHolderName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "operatorData.mobileHolderName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              ref: "mobileHolderCertificateNo",
                              attrs: {
                                label: "持有人证件类型",
                                prop: "mobileHolderCertificateNo",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "special_col_one",
                                  staticStyle: { display: "flex" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "double-input",
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        filterable: "",
                                        disabled: _vm.isDisabled,
                                        placeholder: "请选择持有人证件类型",
                                        clearable: false,
                                      },
                                      on: { change: _vm.changeValue },
                                      model: {
                                        value:
                                          _vm.operatorData
                                            .mobileHolderCertificate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.operatorData,
                                            "mobileHolderCertificate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "operatorData.mobileHolderCertificate",
                                      },
                                    },
                                    _vm._l(
                                      _vm.cardTypeOptions,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.dictName,
                                            value: item.dictCode,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _c("el-input", {
                                    staticClass: "input-with-select",
                                    style: {
                                      "max-width": _vm.idCardNoWidth + "px",
                                      "min-width": "300px",
                                    },
                                    attrs: {
                                      maxlength: "20",
                                      disabled: _vm.isDisabled,
                                      placeholder: "请输入持有人证件号码",
                                      clearable: false,
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.certificateNoChange(
                                          _vm.operatorData
                                        )
                                      },
                                      input: _vm.inputHolderCardNo,
                                    },
                                    model: {
                                      value:
                                        _vm.operatorData
                                          .mobileHolderCertificateNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.operatorData,
                                          "mobileHolderCertificateNo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "operatorData.mobileHolderCertificateNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }