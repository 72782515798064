import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import requestApi from '@/api/interactPlatform';
import InternalConfig from './dialog/internalConfig';
import dictRequest from "@/api/dictionary";
import { commonExport } from '@/utils/common';
export default {
  name: 'InternalSubscripConfig',
  components: {
    Pagination: Pagination,
    InternalConfig: InternalConfig
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      loading: false,
      showDialog: false,
      opType: '',
      inputConfigId: null,
      serviceList: [],
      allDataConfigList: []
    };
  },
  mounted: function mounted() {
    this.loadData();
    this.getDict();
    this.getAllDuipdataconfigList();
  },
  methods: {
    exportExcels: function exportExcels() {
      //导出
      var searchForm = _objectSpread(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery), {}, {
        count: false
      });
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '内部数据源配置',
        exportUrl: '/tp-data-process-service/duip/internal/config/search',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    getAllDuipdataconfigList: function getAllDuipdataconfigList() {
      var _this = this;
      requestApi.getAllDuipdataconfigList().then(function (res) {
        if (res.code == 200) {
          _this.allDataConfigList = res.data.map(function (item) {
            return {
              dictName: item.dataConfigName,
              dictCode: item.dataConfigCode
            };
          });
        }
      });
    },
    getDict: function getDict() {
      var _this2 = this;
      dictRequest.getDictItemsByCode("SYSTEM_MICRO_SERVICE").then(function (res) {
        if (res.code == 200) {
          _this2.serviceList = res.data.map(function (item) {
            return {
              dictName: item.itemName,
              dictCode: item.itemCode
            };
          });
        }
      });
    },
    deleteConfig: function deleteConfig(item) {
      var _this3 = this;
      //删除
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        requestApi.deleteDuipInternaConfig(item.inputConfigId).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    breakData: function breakData() {
      // 刷新
      // this.resetSearch();
      this.loadData();
    },
    loadData: function loadData() {
      var _this4 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      //获取列表数据
      this.showDialog = false;
      if (isDialogNoRefresh) return;
      // this.loading = true;
      requestApi.getDuipInternaConfiglist(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        _this4.loading = false;
        if (res.code === 200) {
          _this4.tableData = res.data.list;
          _this4.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this4.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    //编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.inputConfigId = item.inputConfigId;
    },
    //新增
    add: function add(type) {
      this.opType = type;
      this.showDialog = true;
    }
  }
};