import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import userCenterApi from '@/api/userCenter';
import UserTypeDialog from './dialog/userTypeDialog';
import { commonExport, getCurrentDate, getBeforeDate } from '@/utils/common';
export default {
  name: 'UserType',
  components: {
    Pagination: Pagination,
    UserTypeDialog: UserTypeDialog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      drawer: false,
      searchForm: {
        createdTimeStart: "".concat(getBeforeDate(6), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      vehicleForm: {},
      vehicleFormLoading: false,
      netUserVehicleId: null,
      typeList: [],
      enableList: [{
        label: '是',
        value: true
      }, {
        label: '否',
        value: false
      }],
      startEndTime: [getBeforeDate(6), getCurrentDate()],
      opType: '',
      currentId: '',
      showUserTypeDialog: false
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 导出
    exportExcels: function exportExcels() {
      var exporParams = {
        total: this.total,
        fileName: '用户类型',
        exportUrl: '/tp-core-service/netUserType/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        createdTimeStart: "".concat(getBeforeDate(6), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.startEndTime = [getBeforeDate(6), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 新增
    add: function add(type) {
      this.opType = type;
      this.showUserTypeDialog = true;
    },
    // 编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.currentId = item.netUserTypeId.toString();
      this.showUserTypeDialog = true;
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showUserTypeDialog = false;
      if (isDialogNoRefresh) return;
      this.drawer = false;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      userCenterApi.getNetUserTypeList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this2 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.netUserTypeId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'netUserTypeId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        userCenterApi.deleteNetUserType(ids).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        });
      });
    },
    // 是否启用
    enableChange: function enableChange(val) {
      var _this3 = this;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(val.enabled ? '禁用' : '启用', "\u8BE5\u6570\u636E\u5417\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        userCenterApi.changeNetUserTypeEnabled(val.netUserTypeId).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      }).catch(function () {});
    }
  }
};