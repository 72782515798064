import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import pagination from '@/components/Pagination';
import DatePicker from '@/components/DatePicker';
import leadManagementApi from '@/api/leadManagement';
import CluesDistribution from './dialog/cluesDistribution';
import archivesManagement from "@/api/archivesManagement";
import dictRequest from '@/api/dictionary';
import requestOrg from '@/api/orgChildren';
import { character, getUserOrgInfoDealerOem, commonExport, getBeforeDate, getCurrentDate, getSupplyers } from '@/utils/common';
export default {
  name: 'InsuranceRenewalClue',
  mixins: [initHeight],
  components: {
    pagination: pagination,
    CluesDistribution: CluesDistribution,
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      createdStartEndTime: [getBeforeDate(6), getCurrentDate(0)],
      renewalTypeList: [],
      drawer: false,
      searchForm: {
        distributionStatus: 'UNDISTRIBUTED',
        ownerName: null,
        plateNo: null,
        vin: null,
        ownerBy: null,
        modelName: null,
        sellDate: null,
        commencementDateInsurance: null,
        expiryDateInsurance: null,
        daysInsuranceDue: null,
        daysInsuranceDueMin: null,
        daysInsuranceDueMax: null,
        startCreatedDate: "".concat(getBeforeDate(6), " 00:00:00"),
        endCreatedDate: "".concat(getCurrentDate(0), " 23:59:59")
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      vehicleOptions: [],
      tableData: [],
      showDialog: false,
      customerIdArr: [],
      customerId: '',
      opType: '',
      rulesList: [],
      startEndTime: [],
      multipleSelection: [],
      loading: false,
      bizType: localStorage.getItem('bizType') || '',
      salesman: [],
      supplyers: []
    };
  },
  created: function created() {
    //保司
    this.supplyers = getSupplyers();
    this.getVehicleModelList();
    this.loadData();
    this.getRenewalTypeList();
    if (this.bizType == 'DEALER') {
      this.getUsers();
    }
  },
  methods: {
    sortByDate: function sortByDate(val) {
      if (val) {
        if (val.order) {
          var fieldBy = val.prop;
          var orderBy = val.order == 'ascending' ? 'asc' : 'desc';
          this.searchForm.fieldBy = fieldBy;
          this.searchForm.orderBy = orderBy;
        } else {
          this.searchForm.fieldBy = null;
          this.searchForm.orderBy = null;
        }
        this.loadData();
      }
    },
    getUsers: function getUsers() {
      var _this = this;
      //获取续保专员
      var orgObj = getUserOrgInfoDealerOem('DEALER');
      if (orgObj) {
        var request = requestOrg.getOrgPerson(orgObj.salesOrgCode);
        request.then(function (result) {
          _this.salesman = result.data || [];
        });
      }
    },
    getRenewalTypeList: function getRenewalTypeList() {
      var _this2 = this;
      //获取续客户类别
      dictRequest.getDictItemsByCode("RENEWAL_TYPE").then(function (res) {
        if (res.data) {
          _this2.renewalTypeList = res.data;
        }
      });
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    handleTabClick: function handleTabClick(tab, event) {
      // tab切换事件
      // this.startEndTime = [];
      // this.searchForm = {};
      this.searchForm.distributionStatus = tab.name;
      this.listQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.loadData();
    },
    loadData: function loadData() {
      var _this3 = this;
      // 获取列表数据
      if (this.searchForm && this.searchForm.expiryDateInsurance) {
        this.searchForm.expiryDateInsurance = this.searchForm.expiryDateInsurance.split(' ')[0] + ' 23:59:59';
      }
      if (this.startEndTime) {
        this.searchForm.commencementDateInsurance = this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.expiryDateInsurance = this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      if (this.createdStartEndTime) {
        this.searchForm.startCreatedDate = this.createdStartEndTime[0] && this.createdStartEndTime[0].split(' ')[0] + ' 00:00:00' || null;
        this.searchForm.endCreatedDate = this.createdStartEndTime[1] && this.createdStartEndTime[1].split(' ')[0] + ' 23:59:59' || null;
      }
      this.drawer = false;
      this.showDialog = false;
      this.customerIdArr = [];
      this.loading = true;
      leadManagementApi.getCluesPoolList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this3.loading = false;
        if (res.code === 200 && res.data) {
          _this3.tableData = res.data.customerCluesManageList.list;
          _this3.rulesList = res.data.marketingRuleClueList;
          _this3.total = res.data.customerCluesManageList.page.recordsTotal;
        }
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    // 获取所有车型列表
    getVehicleModelList: function getVehicleModelList() {
      var _this4 = this;
      archivesManagement.getVehicleModel().then(function (res) {
        if (res.code == 200) {
          _this4.vehicleOptions = res.data;
        } else {
          _this4.$message({
            type: "error",
            message: res.msg
          });
        }
      }).catch(function (err) {
        _this4.$message({
          type: "error",
          message: err.msg
        });
      });
    },
    inputvin: function inputvin(val) {
      //监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    //日期选择事件
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    resetSearch: function resetSearch() {
      // 重置搜索表单
      this.startEndTime = [];
      this.createdStartEndTime = [getBeforeDate(6), getCurrentDate(0)];
      this.searchForm = {
        distributionStatus: this.searchForm.distributionStatus,
        ownerName: null,
        plateNo: null,
        vin: null,
        ownerBy: null,
        modelName: null,
        sellDate: null,
        commencementDateInsurance: null,
        expiryDateInsurance: null,
        daysInsuranceDue: null,
        daysInsuranceDueMin: null,
        daysInsuranceDueMax: null,
        startCreatedDate: "".concat(getBeforeDate(6), " 00:00:00"),
        endCreatedDate: "".concat(getCurrentDate(0), " 23:59:59")
      };
    },
    searchData: function searchData() {
      // 搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      // 复选框数据
      this.multipleSelection = val;
    },
    toView: function toView(row) {
      // 查看
      this.$router.push({
        path: '/cluesDetail',
        query: {
          customerId: row.customerId,
          plateNo: row.plateNo,
          vin: row.vin
        }
      });
    },
    exportFn: function exportFn() {
      // 导出
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '续保线索池导出',
        exportUrl: '/vi-core-service/customer/clues/manage/list/exp',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    distribution: function distribution(item) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      // 分配
      console.log(this.multipleSelection);
      this.opType = type;
      this.customerIdArr = [];
      //判断是否多选
      if (type) {
        if (this.multipleSelection.length === 0) {
          this.$message.error('请选择要分配的数据');
        } else {
          this.showDialog = true;
          var list = [];
          var _iterator = _createForOfIteratorHelper(this.multipleSelection),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              console.log(i);
              list.push({
                customerId: i.customerId,
                customerOrgCode: i.orgCode
              });
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          this.customerIdArr = list;
        }
      } else {
        this.showDialog = true;
        this.customerIdArr = [{
          customerId: item.customerId,
          customerOrgCode: item.orgCode
        }];
      }
    },
    recycling: function recycling(row) {
      var _this5 = this;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      // 回收
      if (type) {
        if (!this.multipleSelection.length) {
          this.$message.error('请勾选要回收的数据');
        } else {
          this.$confirm("您确定回收选中的数据吗?", '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: "确定",
            type: 'warning'
          }).then(function () {
            _this5.customerIdArr = _.map(_this5.multipleSelection, 'customerId');
            _this5.batchRecyclingaPublic(_this5.customerIdArr);
          }).catch(function () {});
        }
      } else {
        this.$confirm("您确定回收该数据吗?", '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          type: 'warning'
        }).then(function () {
          _this5.batchRecyclingaPublic(row.customerId);
        }).catch(function () {});
      }
    },
    batchRecyclingaPublic: function batchRecyclingaPublic(id) {
      var _this6 = this;
      // 回收公共方法
      leadManagementApi.batchRecyclinga(id).then(function (res) {
        if (res.code === 200) {
          _this6.$message.success(res.msg);
          _this6.loadData();
        }
      });
    }
  }
};