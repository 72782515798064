//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import OemSelect from '@/components/OemSelect';
import customersRequest from '@/api/customers';
import dict from '@/mixins/dict';
import { getCurrentDate, getAfterDate, getUserOrgInfoDealerOem, getLastMonth } from '@/utils/common';
export default {
  name: "SalesmanTaskKanban",
  components: {
    OemSelect: OemSelect
  },
  mixins: [dict, initHeight],
  data: function data() {
    return {
      searchForm: {
        timeSelect: "thirtyDays",
        bizType: localStorage.getItem("bizType") || '',
        orgCode: '',
        startDate: "".concat(getAfterDate(-29), " 00:00:00"),
        endDate: "".concat(getCurrentDate(), " 23:59:59")
      },
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      tableData: [],
      loading: false,
      sumList: [],
      isDataTime: true,
      startEndTime: [getAfterDate(-29), getCurrentDate()],
      showQuoteDialog: false,
      showPolicyDialog: false,
      currentItem: {},
      isOnline: null
    };
  },
  created: function created() {
    var _orgInfo = getUserOrgInfoDealerOem();
    if (_orgInfo) {
      this.searchForm.orgCode = _orgInfo.salesOrgCode;
      // this.searchForm.bizType = _orgInfo.bizType;
      this.searchForm.areaType = _orgInfo.salesOrgType;
      this.orgData.salesOrgCode = _orgInfo.salesOrgCode;
      this.orgData.salesOrgName = _orgInfo.salesOrgName;
    }
    this.loadData();
  },
  methods: {
    handleTime: function handleTime(val) {
      switch (val) {
        case "now":
          this.startEndTime = [getCurrentDate(), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "yesterday":
          this.startEndTime = [getAfterDate(-1), getAfterDate(-1)];
          this.isDataTime = true;
          break;
        case "sevenDays":
          this.startEndTime = [getAfterDate(-6), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "thirtyDays":
          this.startEndTime = [getAfterDate(-29), getCurrentDate()];
          this.isDataTime = true;
          break;
        case "lastMonth":
          this.startEndTime = getLastMonth();
          this.isDataTime = true;
          break;
        case "custom":
          this.isDataTime = false;
          this.$refs.input.focus();
          break;
      }
      this.searchForm.startDate = "".concat(this.startEndTime[0], " 00:00:00");
      this.searchForm.endDate = "".concat(this.startEndTime[1], " 23:59:59");
      if (this.isDataTime) {
        this.loadData();
      }
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
      this.loadData();
    },
    nodeClick: function nodeClick(val) {
      this.searchForm.orgCode = val.salesOrgCode;
      this.searchForm.areaType = val.salesOrgType;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      customersRequest.getSalesmanStatistics(this.searchForm).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.sumList = res.data.head;
          _this.tableData = res.data.body;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    }
  }
};