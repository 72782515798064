var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "500px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticStyle: { "margin-bottom": "-0px" },
                attrs: {
                  model: _vm.form,
                  "label-width": "110px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "定损金额：", prop: "fixedAmount" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入定损金额",
                            clearable: "",
                          },
                          model: {
                            value: _vm.form.fixedAmount,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fixedAmount", $$v)
                            },
                            expression: "form.fixedAmount",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "定损项目：", prop: "fixedItem" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入定损项目",
                            clearable: "",
                            type: "textarea",
                            rows: 3,
                          },
                          model: {
                            value: _vm.form.fixedItem,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fixedItem", $$v)
                            },
                            expression: "form.fixedItem",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo",
                        attrs: {
                          action: "",
                          "on-preview": _vm.handlePreview,
                          "on-remove": _vm.handleRemove,
                          "http-request": _vm.uploadFile,
                          "file-list": _vm.fileList,
                          "list-type": "picture",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [_vm._v("上传定损清单照片")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }