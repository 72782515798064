var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-type" }, [
    _vm._m(0),
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "form",
            staticStyle: { "margin-top": "20px" },
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              "label-width": "100px",
              size: "mini",
            },
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "前置日期：", prop: "leadTimeMonth" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入前置日期",
                            clearable: "",
                          },
                          on: {
                            input: function ($event) {
                              _vm.form.leadTimeMonth = _vm.onInput(
                                _vm.form.leadTimeMonth,
                                0,
                                12,
                                2
                              )
                            },
                          },
                          model: {
                            value: _vm.form.leadTimeMonth,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "leadTimeMonth", $$v)
                            },
                            expression: "form.leadTimeMonth",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "red",
                              display: "flex",
                              "justify-content": "flex-end",
                            },
                          },
                          [_vm._v("[单位：月]")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 12 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "后置日期：", prop: "postTimeMonth" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入后置日期",
                            clearable: "",
                          },
                          on: {
                            input: function ($event) {
                              _vm.form.postTimeMonth = _vm.onInput(
                                _vm.form.postTimeMonth,
                                0,
                                12,
                                2
                              )
                            },
                          },
                          model: {
                            value: _vm.form.postTimeMonth,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "postTimeMonth", $$v)
                            },
                            expression: "form.postTimeMonth",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "red",
                              display: "flex",
                              "justify-content": "flex-end",
                            },
                          },
                          [_vm._v("[单位：月]")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 24 } }, [
                  _c(
                    "div",
                    { staticClass: "searchBtnStyle" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-check",
                            size: "mini",
                            type: "primary",
                            disabled:
                              _vm.currentObj &&
                              _vm.currentObj.defaulted &&
                              !!_vm.curRenewalConfigParams.orgCode,
                          },
                          on: { click: _vm.save },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "space-between",
          "align-items": "center",
          margin: "10px 0",
        },
      },
      [_c("b", [_vm._v("任务跟进配置")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }