import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import AccountDialog from './dialog/accountDialog';
import OemSelect from '@/components/OemSelect';
import requestApi from '@/api/smsManage';
import dictRequest from '@/api/dictionary';
import { getUserOrgInfoDealerOem, commonExport } from '@/utils/common';
export default {
  name: 'SmsAccountConfig',
  components: {
    Pagination: Pagination,
    AccountDialog: AccountDialog,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      multipleSelection: [],
      opType: '',
      loading: false,
      showDialog: false,
      bizType: localStorage.getItem("bizType") || '',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      currentId: '',
      operatorList: []
    };
  },
  created: function created() {
    var _this = this;
    this.loadData();
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
    //获取运营商
    dictRequest.getDictItemsByCode("SMSCHANNEL").then(function (res) {
      if (res.data) {
        _this.operatorList = res.data;
      }
    });
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    handleNodeClick: function handleNodeClick(data, node, target) {
      // 机构树点击事件
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    batchDelete: function batchDelete(item) {
      var _this2 = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.mnoConfigId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'mnoConfigId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        requestApi.deleteMnoConfig(ids).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        });
      });
    },
    //编辑
    edit: function edit(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.currentId = item.mnoConfigId.toString();
    },
    //新增
    add: function add(type) {
      this.opType = type;
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        orgName: this.bizType === 'OEM' ? '' : this.searchForm.orgName,
        orgCode: this.bizType === 'OEM' ? '' : this.searchForm.orgCode
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    loadData: function loadData() {
      var _this3 = this;
      //获取列表数据
      this.loading = true;
      this.showDialog = false;
      requestApi.getMnoConfigList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this3.loading = false;
          _this3.tableData = res.data.list;
          _this3.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    exportExcels: function exportExcels() {
      //导出
      var exporParams = {
        total: this.total,
        fileName: '短信账号维护',
        exportUrl: '/tp-message-service/mno/config/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    }
  }
};