import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//

import VI_VEHICLE_KIND from '@/assets/json/VI_VEHICLE_KIND';
import VI_USE_ATTRIBUTE from '@/assets/json/VI_USE_ATTRIBUTE';
import VI_CERTIFICATE_TYPE from '@/assets/json/VI_CERTIFICATE_TYPE';
import VI_PLATE_TYPE from '@/assets/json/VI_PLATE_TYPE';
import VI_PLATE_COLOR from '@/assets/json/VI_PLATE_COLOR';
import VI_ENGRGY_TYPE from '@/assets/json/VI_ENGRGY_TYPE';
import VI_USE_ATTRIBUT_DETAIL from '@/assets/json/VI_USE_ATTRIBUT_DETAIL';
import VI_JQX_CLASS from '@/assets/json/VI_JQX_CLASS';
import VI_CUST_NATUER from '@/assets/json/VI_CUST_NATUER';
import VI_FREE_TAX_REASON from '@/assets/json/VI_FREE_TAX_REASON';
import VI_CUT_TAX_REASON from '@/assets/json/VI_CUT_TAX_REASON';
import VI_NATIONAL_SOURCE from '@/assets/json/VI_NATIONAL_SOURCE';
import userRequest from "@/api/user";
import suppliersRes from "@/api/insCorpMaintain";
import axios from 'axios';
import { initCityTree } from '@/utils/common';
export default {
  name: 'App',
  created: function created() {},
  mounted: function mounted() {
    document.title = process.env.VUE_APP_TITLE;
    if (process.env.VUE_APP_TJ_APPID) localStorage.setItem('TJ_APPID', process.env.VUE_APP_TJ_APPID);
    this.getOfflineData();
    setInterval(function () {
      var token = localStorage.getItem('token') || "";
      if (!token) return;
      userRequest.refreshToken().then(function (result) {
        if (result.data) {
          localStorage.setItem('token', result.data.accessToken);
        }
      });
    }, 1000 * 60 * 5);
  },
  methods: {
    // 批量获取字典数据
    getOfflineData: function getOfflineData() {
      var token = localStorage.getItem('token') || "";
      if (token) {
        axios.all([suppliersRes.getAllSuppliers('INSURANCE'),
        //保司列表
        suppliersRes.getAllCityAreaTree() //省市区列表
        ]).then(axios.spread(function (PROP_SUPPLYER, CITY_AREA_TREE) {
          if (PROP_SUPPLYER && !_.isEmpty(PROP_SUPPLYER.data)) {
            localStorage.removeItem('VI_PROP_SUPPLYER');
            localStorage.setItem('VI_PROP_SUPPLYER', JSON.stringify(PROP_SUPPLYER.data));
          }
          if (CITY_AREA_TREE && !_.isEmpty(CITY_AREA_TREE.data)) {
            initCityTree(CITY_AREA_TREE.data);
          }
        })).catch(function (err) {});
      }
      if (!_.isEmpty(VI_VEHICLE_KIND)) localStorage.setItem('VI_VEHICLE_KIND', JSON.stringify(VI_VEHICLE_KIND));
      if (!_.isEmpty(VI_USE_ATTRIBUTE)) localStorage.setItem('VI_USE_ATTRIBUTE', JSON.stringify(VI_USE_ATTRIBUTE));
      if (!_.isEmpty(VI_CERTIFICATE_TYPE)) localStorage.setItem('VI_CERTIFICATE_TYPE', JSON.stringify(VI_CERTIFICATE_TYPE));
      if (!_.isEmpty(VI_PLATE_TYPE)) localStorage.setItem('VI_PLATE_TYPE', JSON.stringify(VI_PLATE_TYPE));
      if (!_.isEmpty(VI_PLATE_COLOR)) localStorage.setItem('VI_PLATE_COLOR', JSON.stringify(VI_PLATE_COLOR));
      if (!_.isEmpty(VI_ENGRGY_TYPE)) localStorage.setItem('VI_ENGRGY_TYPE', JSON.stringify(VI_ENGRGY_TYPE));
      if (!_.isEmpty(VI_USE_ATTRIBUT_DETAIL)) localStorage.setItem('VI_USE_ATTRIBUT_DETAIL', JSON.stringify(VI_USE_ATTRIBUT_DETAIL));
      if (!_.isEmpty(VI_JQX_CLASS)) localStorage.setItem('VI_JQX_CLASS', JSON.stringify(VI_JQX_CLASS));
      if (!_.isEmpty(VI_CUST_NATUER)) localStorage.setItem('VI_CUST_NATUER', JSON.stringify(VI_CUST_NATUER));
      if (!_.isEmpty(VI_FREE_TAX_REASON)) localStorage.setItem('VI_FREE_TAX_REASON', JSON.stringify(VI_FREE_TAX_REASON));
      if (!_.isEmpty(VI_CUT_TAX_REASON)) localStorage.setItem('VI_CUT_TAX_REASON', JSON.stringify(VI_CUT_TAX_REASON));
      if (!_.isEmpty(VI_NATIONAL_SOURCE)) localStorage.setItem('VI_NATIONAL_SOURCE', JSON.stringify(VI_NATIONAL_SOURCE));
    }
  }
};