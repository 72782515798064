var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _vm.bizType === "OEM"
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属机构：",
                                    prop: "orgCode",
                                  },
                                },
                                [
                                  _c("OemSelect", {
                                    attrs: {
                                      searchForm: _vm.orgForm,
                                      isContainOem: false,
                                    },
                                    on: { nodeClick: _vm.handleNodeClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bizType === "DEALER"
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所属机构：",
                                    prop: "orgCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.orgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "orgName", $$v)
                                      },
                                      expression: "form.orgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "分组名称：", prop: "groupName" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入分组名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.groupName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "groupName", $$v)
                                  },
                                  expression: "form.groupName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "车牌号：", prop: "plateNoList" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "500",
                                  type: "textarea",
                                  autosize: { minRows: 4, maxRows: 4 },
                                  resize: "none",
                                  placeholder:
                                    "不能超过50个车牌号码（多个以逗号分隔）",
                                },
                                model: {
                                  value: _vm.form.plateNoList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "plateNoList", $$v)
                                  },
                                  expression: "form.plateNoList",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-form-item", { attrs: { label: "注：" } }, [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("清单中的车牌号将不再进店提醒"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }