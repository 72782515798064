import _slicedToArray from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/slicedToArray.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/interactPlatform';
import OemSelect from '@/components/OemSelect';
import ssoConfig from '@/api/ssoConfig';
import dict from '@/mixins/dict';
import { getUserOrgInfoDealerOem } from "@/utils/common";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    outputConfigId: {
      type: String,
      default: ''
    }
  },
  components: {
    OemSelect: OemSelect
  },
  mixins: [dict],
  data: function data() {
    return {
      defaultSalesOemCode: [],
      salesOemName: '',
      form: {
        bizType: null,
        dataConfigCode: '',
        // orgCustomCode: '',
        // orgName: '',
        // outputPlatformAppid: '',
        outputPlatformName: '',
        // outputPlatformSecret: '',
        outputPlatformUrl: '',
        outputSendStrategy: '',
        outputConfigState: false,
        subOrgCanUse: false,
        successFlag: 'CODE200',
        //成功依据
        directConnect: false,
        //是否直连
        dataOrgRange: 'ALL' //订阅范围
      },

      successFlagList: [{
        label: '响应码200',
        value: 'CODE200'
      }, {
        label: '响应码200同时返回SUCCESS',
        value: 'SUCCESS'
      }, {
        label: '响应码200同时返回msg值为SUCCESS',
        value: 'MSGSUCCESS'
      }],
      rules: {
        bizType: {
          required: true,
          message: '请选择业务机构',
          trigger: "change"
        },
        dataConfigCode: {
          required: true,
          message: '请选择订阅数据',
          trigger: "change"
        },
        // orgCustomCode: {
        //     required: true,
        //     message: '请选择所属机构',
        //     trigger: "change"
        // },
        subOrgCanUse: {
          required: true,
          message: '请选择是否订阅下级机构',
          trigger: "change"
        },
        outputConfigState: {
          required: true,
          message: '请选择是否启用',
          trigger: "change"
        },
        directConnect: {
          required: true,
          message: '请选择是否直连',
          trigger: "change"
        },
        outputPlatformName: {
          required: true,
          message: '请输入订阅配置名称',
          trigger: "blur"
        },
        outputPlatformUrl: {
          required: true,
          message: '请输入订阅地址',
          trigger: "blur"
        }
      },
      subscriptionList: [],
      pushStrategyList: [],
      AllList: [],
      orgForm: {
        salesOrgCode: [],
        salesOrgName: []
      },
      bizType: localStorage.getItem("bizType") || null,
      orgInfo: {}
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.getDuipdataconfigList();
        this.getPushstragegy();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
    this.getDuipdataconfigList();
    this.getPushstragegy();
  },
  methods: {
    getOrgCode: function getOrgCode(val) {
      this.getAllList();
    },
    getAllList: function getAllList() {
      var _this = this;
      ssoConfig.getAllList().then(function (res) {
        if (res.code == 200) {
          _this.AllList = res.data ? res.data : [];
        }
      });
    },
    getPushstragegy: function getPushstragegy() {
      var _this2 = this;
      requestApi.getPushstragegy().then(function (res) {
        if (res.code == 200) {
          _this2.pushStrategyList = res.data;
        }
      });
    },
    getDuipdataconfigList: function getDuipdataconfigList() {
      var _this3 = this;
      //获取订阅数据下拉列表
      requestApi.getDuipdataconfigList(1, 100000).then(function (res) {
        if (res.code == 200) {
          _this3.subscriptionList = res.data.list ? res.data.list : [];
        }
      });
    },
    initData: function initData() {
      var _this4 = this;
      this.orgForm = {
        salesOrgCode: [],
        salesOrgName: []
      };
      this.form = {
        bizType: null,
        dataConfigCode: '',
        // orgCustomCode: '',
        // orgName: '',
        // outputPlatformAppid: '',
        // outputPlatformSecret: '',
        outputPlatformName: '',
        outputPlatformUrl: '',
        outputSendStrategy: '',
        outputConfigState: false,
        subOrgCanUse: false,
        successFlag: 'CODE200',
        //成功依据
        directConnect: false,
        dataOrgRange: 'ALL'
      };
      if (this.opType === "edit" || this.opType === 'copy') {
        requestApi.getDuipoutputconfigInfo(this.outputConfigId).then(function (res) {
          if (res.code === 200) {
            _this4.form = res.data;
            _this4.orgForm = {
              salesOrgCode: res.data.orgCodeList,
              salesOrgName: res.data.orgNameList
            };
            _this4.nodeClick(_this4.orgForm);
            if (res.data.orgNameList.length > 1) {
              _this4.salesOemName = res.data.orgNameList[0] + '等' + res.data.orgNameList.length + '家';
            } else {
              _this4.salesOemName = res.data.orgNameList[0];
            }
            ;
            _this4.defaultSalesOemCode = res.data.orgCodeList ? res.data.orgCodeList : [];
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    clearBizType: function clearBizType() {
      this.form.bizType = null;
      this.orgForm = {
        salesOrgCode: [],
        salesOrgName: []
      };
    },
    nodeClick: function nodeClick(val) {
      var list = new Map();
      if (val.salesOrgCode.length && val.salesOrgName.length) {
        val.salesOrgCode.forEach(function (item, index) {
          list.set(item, val.salesOrgName[index]);
        });
      }
      var obj = Object.create(null);
      var _iterator = _createForOfIteratorHelper(list),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _step$value = _slicedToArray(_step.value, 2),
            k = _step$value[0],
            v = _step$value[1];
          obj[k] = v;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.orgInfo = obj;
    },
    // 保存数据
    save: function save() {
      var _this5 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var data = {};
          if (_this5.opType == 'edit') {
            data = {
              outputConfigId: _this5.outputConfigId,
              bizType: _this5.form.dataOrgRange == 'ALL' ? null : _this5.form.bizType,
              dataConfigCode: _this5.form.dataConfigCode,
              // orgCustomCode: this.form.orgCustomCode,
              // orgName: this.form.orgName,
              outputConfigState: _this5.form.outputConfigState,
              // outputPlatformAppid: this.form.outputPlatformAppid,
              // outputPlatformSecret: this.form.outputPlatformSecret,
              outputPlatformName: _this5.form.outputPlatformName,
              outputPlatformUrl: _this5.form.outputPlatformUrl,
              outputSendStrategy: _this5.form.outputSendStrategy,
              subOrgCanUse: _this5.form.subOrgCanUse,
              successFlag: _this5.form.successFlag,
              //成功依据
              directConnect: _this5.form.directConnect,
              dataOrgRange: _this5.form.dataOrgRange,
              orgInfo: _this5.orgInfo
            };
          }
          _this5.form.orgInfo = _this5.orgInfo;
          _this5.form.bizType = _this5.form.dataOrgRange == 'ALL' ? null : _this5.form.bizType;
          var req = _this5.opType === 'add' || _this5.opType === 'copy' ? requestApi.duipoutputconfigAddData(_this5.form) : requestApi.duipoutputconfigUpdateData(data);
          req.then(function (res) {
            if (res.code === 200) {
              _this5.$message({
                message: res.msg,
                type: 'success'
              });
              _this5.closePage();
            }
            ;
          });
        }
        ;
      });
    },
    businessChange: function businessChange(val) {
      console.log(val, 'val');
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};