import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import claimManageApi from '@/api/claimManage';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import { getSupplyers, commonExport, getBeforeDate, getCurrentDate, character } from '@/utils/common';
export default {
  name: 'ReportClaim',
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  data: function data() {
    return {
      searchForm: {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      drawer: false,
      loading: false,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      tableData: [],
      createdStartEndTime: [getBeforeDate(30), getCurrentDate()],
      reportStatusList: [{
        value: true,
        label: '是'
      }, {
        value: false,
        label: '否'
      }],
      supplyers: getSupplyers() || [],
      multipleSelection: [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      }
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
    },
    // 去除车架号中的i、o、q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    // 自动将车牌号码转为大写
    inputPlateNo: function inputPlateNo(val) {
      this.searchForm.plateNo = _.toUpper(val);
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '车主报案线索',
        exportUrl: '/vi-core-service/claims/report/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        createdTimeStart: "".concat(getBeforeDate(30), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.orgForm = {
        salesOrgName: '',
        salesOrgCode: ''
      };
      this.createdStartEndTime = [getBeforeDate(30), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      claimManageApi.getClaimsReportList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this2 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.reportId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'reportId');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        claimManageApi.deleteClaimsReport(ids).then(function (res) {
          if (res.code === 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
          ;
        });
      });
    },
    // 查看
    view: function view(row) {
      this.$router.push({
        path: '/reportClaim/detail',
        query: {
          reportId: row.reportId,
          tabName: "\u8F66\u4E3B\u62A5\u6848\u7EBF\u7D22\u8BE6\u60C5-".concat(row.bizReportNo)
        }
      });
    }
  }
};