//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import insCorpRequest from '../../../api/insCorpMaintain';
import storageRequest from '@/api/storage';
import agreementManagement from '@/api/agreementManagement';
import { getCurrentDate, getSupplyers, saveFile } from "@/utils/common";
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: {}
    },
    agreementItem: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      fileList: [],
      searchForm: {
        agreementFileList: [],
        agreementType: ''
      },
      test: '11',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      insuranceList: [],
      listLoading: false,
      rules: {
        newBsRate: [{
          required: true,
          message: "请输入商业险费率",
          trigger: 'blur'
        }],
        newBzRate: [{
          required: true,
          message: '请输入交强险费率',
          trigger: 'blur'
        }],
        renewBsRate: [{
          required: true,
          message: '请输入商业险费率',
          trigger: 'blur'
        }],
        renewBzRate: [{
          required: true,
          message: '请输入交强险费率',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  components: {},
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    newBzRateChange: function newBzRateChange() {
      if (this.searchForm.newBzRate > this.agreementItem.bzMaxRate) {
        this.$message({
          message: " \u4EA4\u5F3A\u9669\u6700\u9AD8\u8D39\u7387\u662F".concat(this.agreementItem.bzMaxRate, "%,\u65B0\u4FDD\u4EA4\u5F3A\u9669\u8D39\u7387\u4E0D\u80FD\u5927\u4E8E\u4EA4\u5F3A\u9669\u6700\u9AD8\u8D39\u7387 "),
          type: 'warning'
        });
        return;
      }
    },
    newBsRateChange: function newBsRateChange() {
      if (this.searchForm.newBsRate > this.agreementItem.bsMaxRate) {
        this.$message({
          message: " \u5546\u4E1A\u9669\u8D39\u7387\u6700\u9AD8\u8D39\u7387\u662F".concat(this.agreementItem.bsMaxRate, "%,\u65B0\u4FDD\u5546\u4E1A\u9669\u8D39\u7387\u4E0D\u80FD\u5927\u4E8E\u5546\u4E1A\u9669\u8D39\u7387 "),
          type: 'warning'
        });
        return;
      }
    },
    renewBzRateChange: function renewBzRateChange() {
      if (this.searchForm.renewBzRate > this.agreementItem.bzMaxRate) {
        this.$message({
          message: " \u4EA4\u5F3A\u9669\u6700\u9AD8\u8D39\u7387\u662F".concat(this.agreementItem.bzMaxRate, "%,\u7EED\u4FDD\u4EA4\u5F3A\u9669\u8D39\u7387\u4E0D\u80FD\u5927\u4E8E\u4EA4\u5F3A\u9669\u6700\u9AD8\u8D39\u7387 "),
          type: 'warning'
        });
        return;
      }
    },
    renewBsRateChange: function renewBsRateChange() {
      if (this.searchForm.renewBsRate > this.agreementItem.bsMaxRate) {
        this.$message({
          message: " \u5546\u4E1A\u9669\u6700\u9AD8\u8D39\u7387\u662F".concat(this.agreementItem.bsMaxRate, "%,\u7EED\u4FDD\u5546\u4E1A\u9669\u8D39\u7387\u4E0D\u80FD\u5927\u4E8E\u5546\u4E1A\u9669\u6700\u9AD8\u8D39\u7387 "),
          type: 'warning'
        });
        return;
      }
    },
    initData: function initData() {
      var _this = this;
      agreementManagement.getRateData(this.agreementItem.agreementId).then(function (res) {
        if (res.code === 200) {
          _this.searchForm = res.data;
        }
      });
    },
    save: function save() {
      var _this2 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.listLoading = true;
          agreementManagement.updateRateData(_this2.agreementItem.agreementId, _this2.searchForm).then(function (res) {
            _this2.listLoading = false;
            if (res.code === 200) {
              _this2.$message({
                message: res.msg,
                type: 'success'
              });
              _this2.closePage();
            }
          }).catch(function (error) {
            _this2.listLoading = false;
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    loadData: function loadData() {//加载数据
    }
  }
};