var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-m-cls" },
    [
      _c(
        "Dialog",
        {
          attrs: {
            title: _vm.opType == "edit" ? "编辑" : "新增",
            width: "1000px",
            "form-loading": _vm.formLoading,
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "div",
            {
              ref: "header",
              staticClass: "header-cls",
              staticStyle: { "max-height": "400px", overflow: "auto" },
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _c(
                "el-card",
                { attrs: { shadow: "never" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "detail",
                      staticStyle: { "margin-bottom": "-20px" },
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "110px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "字段名称：",
                                    prop: "fieldCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "allow-create": "",
                                        filterable: "",
                                        placeholder: "请选择字段名称",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.form.fieldCode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "fieldCode", $$v)
                                        },
                                        expression: "form.fieldCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.policyFieidEnum,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.itemName,
                                            value: item.itemCode,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "所在页码：",
                                    prop: "pageIndex",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入所在页码",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.pageIndex,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "pageIndex", $$v)
                                      },
                                      expression: "form.pageIndex",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "识别方式：",
                                    prop: "methodCode",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择识别方式",
                                        clearable: "",
                                      },
                                      on: { change: _vm.changeMethod },
                                      model: {
                                        value: _vm.form.methodCode,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "methodCode", $$v)
                                        },
                                        expression: "form.methodCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.methodEnum,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.text,
                                            value: item.code,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "表格序号：" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入表格序号",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.tableIndex,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "tableIndex", $$v)
                                      },
                                      expression: "form.tableIndex",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "表格最小行数：" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入表格最小行数",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.tableMinRows,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "tableMinRows", $$v)
                                      },
                                      expression: "form.tableMinRows",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.form.methodCode == "TEXT" ||
                          _vm.form.methodCode == "TABLE"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "文本：",
                                            prop: "text",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入文本",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.text,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "text", $$v)
                                              },
                                              expression: "form.text",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "minI：",
                                            prop: "minI",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入minI",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.minI,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "minI", $$v)
                                              },
                                              expression: "form.minI",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "maxI：",
                                            prop: "maxI",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入maxI",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.maxI,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "maxI", $$v)
                                              },
                                              expression: "form.maxI",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "minJ：",
                                            prop: "minJ",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入minJ",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.minJ,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "minJ", $$v)
                                              },
                                              expression: "form.minJ",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "maxJ：",
                                            prop: "maxJ",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入maxJ",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.maxJ,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "maxJ", $$v)
                                              },
                                              expression: "form.maxJ",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.methodCode == "TABLE" ||
                          _vm.form.methodCode == "TEXT" ||
                          _vm.form.methodCode == "RISKLIST"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "定位方式：",
                                            prop: "getCellType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                placeholder: "请选择定位方式",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.form.getCellType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "getCellType",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.getCellType",
                                              },
                                            },
                                            _vm._l(
                                              _vm.positionTypeEnum,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.text,
                                                    value: item.code,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "行号：",
                                            prop: "cellRowIndex",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入行号",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.cellRowIndex,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "cellRowIndex",
                                                  $$v
                                                )
                                              },
                                              expression: "form.cellRowIndex",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.form.methodCode == "RISKLIST"
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "列号：",
                                                prop: "riskTypeIndex",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入列号",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.form.riskTypeIndex,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "riskTypeIndex",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.riskTypeIndex",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "列号：",
                                                prop: "cellColIndex",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入列号",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.form.cellColIndex,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "cellColIndex",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.cellColIndex",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.methodCode == "RECT"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "left：",
                                            prop: "rectangleLeft",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入left",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.rectangleLeft,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "rectangleLeft",
                                                  $$v
                                                )
                                              },
                                              expression: "form.rectangleLeft",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "top：",
                                            prop: "rectangleTop",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入top",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.rectangleTop,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "rectangleTop",
                                                  $$v
                                                )
                                              },
                                              expression: "form.rectangleTop",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "width：",
                                            prop: "rectangleWidth",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入width",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.rectangleWidth,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "rectangleWidth",
                                                  $$v
                                                )
                                              },
                                              expression: "form.rectangleWidth",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "height：",
                                            prop: "rectangleHeight",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入height",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.rectangleHeight,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "rectangleHeight",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.rectangleHeight",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.methodCode == "RISKLIST"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "列表形式：",
                                            prop: "riskTypeList",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入列表形式",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.riskTypeList,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "riskTypeList",
                                                  $$v
                                                )
                                              },
                                              expression: "form.riskTypeList",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "结束标志：",
                                            prop: "riskTypeEndTag",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder: "请输入结束标志",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.form.riskTypeEndTag,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "riskTypeEndTag",
                                                  $$v
                                                )
                                              },
                                              expression: "form.riskTypeEndTag",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "判断方式：",
                                    prop: "supportType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择判断方式",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.form.supportType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "supportType", $$v)
                                        },
                                        expression: "form.supportType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.supportTypeEnum,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.text,
                                            value: item.code,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "目标文本：",
                                    prop: "readTarget",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入目标文本",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.readTarget,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "readTarget", $$v)
                                      },
                                      expression: "form.readTarget",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "取值文本：",
                                    prop: "readValue",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入取值文本",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.readValue,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "readValue", $$v)
                                      },
                                      expression: "form.readValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "字符串截取起：",
                                    prop: "start",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      rows: 1,
                                      type: "textarea",
                                      placeholder: "请输入字符串截取起",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.start,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "start", $$v)
                                      },
                                      expression: "form.start",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "字符串截取止：",
                                    prop: "end",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      rows: 1,
                                      type: "textarea",
                                      placeholder: "请输入字符串截取止",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.end,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "end", $$v)
                                      },
                                      expression: "form.end",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "正则表达式：",
                                    prop: "regex",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      rows: 1,
                                      type: "textarea",
                                      placeholder: "请输入正则表达式",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.regex,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "regex", $$v)
                                      },
                                      expression: "form.regex",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "替换字符：",
                                    prop: "replacement",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      rows: 1,
                                      type: "textarea",
                                      placeholder: "请输入替换字符",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.replacement,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "replacement", $$v)
                                      },
                                      expression: "form.replacement",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "数据转换：",
                                    prop: "convertDataType",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入数据转换",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.convertDataType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "convertDataType",
                                          $$v
                                        )
                                      },
                                      expression: "form.convertDataType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用于匹配：" } },
                                [
                                  _c("el-switch", {
                                    model: {
                                      value: _vm.form.supportTag,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "supportTag", $$v)
                                      },
                                      expression: "form.supportTag",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "启用：" } },
                                [
                                  _c("el-switch", {
                                    model: {
                                      value: _vm.form.enable,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "enable", $$v)
                                      },
                                      expression: "form.enable",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "8px",
              },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-close", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.closePage(false)
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }