import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
var _name$components$mixi;
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from "@/components/FormSubLabel";
import InsuredPersonal from "@/components/Personal/insuredPersonal";
import PolicyholderPersonal from "@/components/Personal/policyholderPersonal";
import PageContainer from "@/components/PageContainer";
import OrgSelect from '@/components/OrgSelect';
import dict from "@/mixins/dict";
import slipRequest from "@/api/insuranceslip";
import carService from '@/api/carService';
import Rules from '@/utils/rules';
import servicePackageApi from "@/api/servicePackage";
import { dataFormat, idCardValidate, getNextYear, getBeforeOfDate, character, getAnalysisIdCard } from '@/utils/common';
export default (_name$components$mixi = {
  name: 'ServiceOrderDetail',
  components: {
    FormLabel: FormLabel,
    PageContainer: PageContainer,
    OrgSelect: OrgSelect,
    InsuredPersonal: InsuredPersonal,
    PolicyholderPersonal: PolicyholderPersonal
  },
  mixins: [dict],
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化
      console.log(to);
    }
  },
  data: function data() {
    return {
      insuranceData: [{
        code: 'JDCSSX',
        name: '机动车损失保险',
        value: ''
      }, {
        code: 'SZ',
        name: '第三者责任保险',
        value: '',
        list: [{
          value: 'SZA',
          label: "100万"
        }, {
          value: 'SZB',
          label: "200万"
        }, {
          value: 'SZC',
          label: "300万"
        }, {
          value: 'SZD',
          label: "400万"
        }]
      }, {
        code: 'SJX',
        name: '机动车车上人员责任保险(司机)',
        value: '',
        list: [{
          value: 'SJXA',
          label: "1万"
        }, {
          value: 'SJXB',
          label: "2万"
        }, {
          value: 'SJXC',
          label: "3万"
        }, {
          value: 'SJXD',
          label: "4万"
        }, {
          value: 'SJXE',
          label: "5万"
        }]
      }, {
        code: 'CKX',
        name: '机动车车上人员责任保险(乘客)',
        value: '',
        list: [{
          value: 'CKXA',
          label: "1万"
        }, {
          value: 'CKXB',
          label: "2万"
        }, {
          value: 'CKXC',
          label: "3万"
        }, {
          value: 'CKXD',
          label: "4万"
        }, {
          value: 'CKXE',
          label: "5万"
        }]
      }],
      sourceId: null,
      source: null,
      loadingVehicle: false,
      users: [],
      opType: '',
      activeOne: '1',
      activeTwo: '1',
      activeThree: '1',
      activeFour: '1',
      activeFive: '1',
      activeSix: '1',
      activeSeven: '1',
      activeEight: '1',
      defaultCheckedKeys: [],
      policyCityItems: [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      form: {},
      insuranceForm: {},
      coreProposalVehicleVO: {},
      couponList: [],
      InsurantObj: {},
      ApplicantObj: {},
      formRules: {},
      cityList: [],
      typeList: [],
      modellist: [],
      USE_ATTRIBUTE: [],
      VI_CERTIFICATE_TYPE: [],
      personalCertificateTypeList: [],
      unPersonalCertificateTypeList: [],
      disabledLessThan: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      cardTypeOptions: [],
      ownerDisabledStart: this.ownerMaxDate(),
      ownerDisabledEnd: this.ownerMinDate(),
      CXTableData: [],
      JSXTableData: [],
      ZZFWTableData: [],
      ZPTableData: [],
      CXInsCorpName: '',
      disabledBzPickerOptions: this.processMinDate('bz'),
      disabledBsPickerOptions: this.processMinDate('bs'),
      endDateBzPicker: this.processDate("0"),
      endDateBsPicker: this.processDate("1"),
      defaultBzTimeOptions: {
        start: "00:00",
        step: "00:15",
        end: "23:45"
      },
      defaultBsTimeOptions: {
        start: "00:00",
        step: "00:15",
        end: "23:45"
      },
      defaultTimeOptions: {
        start: "00:00",
        step: "00:15",
        end: "23:45"
      },
      TAX_REASON: [],
      bsRiskKind: {},
      bzRiskKind: {},
      nonRiskKind: {},
      detailData: {}
    };
  },
  created: function created() {
    var _this = this;
    this.opType = this.$route.query && this.$route.query.type;
    if (this.$route.query) {
      this.form.vin = this.$route.query.addVin;
    }
    carService.brandOptions().then(function (res) {
      if (res.code === 200) {
        _this.typeList = res.data;
      }
    });
    this.cityList = JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [];
    this.FREE_TAX_REASON = JSON.parse(localStorage.getItem("VI_FREE_TAX_REASON")) || [];
    this.CUT_TAX_REASON = JSON.parse(localStorage.getItem("VI_CUT_TAX_REASON")) || [];
    this.USE_ATTRIBUTE = JSON.parse(localStorage.getItem("VI_USE_ATTRIBUTE")) || [];
    this.VI_CERTIFICATE_TYPE = JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [];
    if (this.VI_CERTIFICATE_TYPE) {
      var _iterator = _createForOfIteratorHelper(this.VI_CERTIFICATE_TYPE),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var i = _step.value;
          if (i.dictCode == 'E02' || i.dictCode == 'E01') {
            this.unPersonalCertificateTypeList.push(i);
          } else {
            this.personalCertificateTypeList.push(i);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
    if (this.form.taxType) {
      var val = this.form.taxType;
      if (val == this.dictSource['TAX_TYPE'][1].dictCode) {
        //减税
        this.TAX_REASON = this.CUT_TAX_REASON;
      } else if (val == this.dictSource['TAX_TYPE'][2].dictCode) {
        //免税
        this.TAX_REASON = this.FREE_TAX_REASON;
      }
    }
    this.insuranceProductsTableData = JSON.parse(localStorage.getItem('insuranceProductsTableData')) || [];
    this.drivingPproductsTableData = JSON.parse(localStorage.getItem('drivingPproductsTableData')) || [];
    this.ProgramServiceProductsTableData = JSON.parse(localStorage.getItem('ProgramServiceProductsTableData')) || [];
    if (this.ProgramServiceProductsTableData && this.ProgramServiceProductsTableData.length != 0) {
      var item = _.find(this.ProgramServiceProductsTableData, function (i) {
        return i.ProgramServiceId == _this.$route.query.ProgramId;
      });
      this.CXInsCorpName = item.insCorpName || '';
      this.ZPTableData = item.ProgramGiveawayServiceProducts || [];
      this.ZZFWTableData = item.ProgramValueAddedServiceProducts || [];
      var JYXItem = _.find(this.drivingPproductsTableData, function (i) {
        return i.risProductCode == item.ProgramDrivingInsuranceMethods;
      });
      if (JYXItem.riskAccidentPackageSaveParams[0].riskList) {
        var _iterator2 = _createForOfIteratorHelper(JYXItem.riskAccidentPackageSaveParams[0].riskList),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var JYX = _step2.value;
            JYX.insuranceName = JYXItem.riskAccidentPackageSaveParams[0].insuranceName;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
      this.JSXTableData = JYXItem.riskAccidentPackageSaveParams[0].riskList || [];
      var CXItem = _.find(this.insuranceProductsTableData, function (i) {
        return i.insuranceProductsCode == item.ProgramInsuranceTypeMethods;
      });
      this.CXTableData = CXItem.insuranceProducts || [];
      // let ProgramDrivingInsuranceMethodsId = ProgramDrivingInsuranceMethods
    }
  }
}, _defineProperty(_name$components$mixi, "watch", {
  $route: function $route(to, from) {
    if (to.query.servPackOrderId) {
      this.getServPackOrderDetail(to.query.servPackOrderId);
    }
  }
}), _defineProperty(_name$components$mixi, "mounted", function mounted() {
  console.log('每次都触发');
  if (this.$route.query) {
    var servPackOrderId = this.$route.query.servPackOrderId;
    console.log(servPackOrderId);
    this.getServPackOrderDetail(servPackOrderId);
  }
}), _defineProperty(_name$components$mixi, "methods", {
  getServPackOrderDetail: function getServPackOrderDetail(id) {
    var _this2 = this;
    servicePackageApi.getServPackOrderDetail(Number(id)).then(function (res) {
      if (res.code == 200) {
        _this2.detailData = res.data;
        var couponList = res.data && res.data.couponList ? res.data.couponList : [];
        var insurance = res.data && res.data.insurance ? res.data.insurance : {};
        var coreProposalInsuredVOList = insurance && insurance.coreProposalInsuredVOList ? insurance.coreProposalInsuredVOList : [];
        console.log(insurance);
        // this.form.salesOrgName = insurance.salesOrgName;
        // this.form.salemanName = insurance.salemanName;
        if (coreProposalInsuredVOList) {
          var _iterator3 = _createForOfIteratorHelper(coreProposalInsuredVOList),
            _step3;
          try {
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              var i = _step3.value;
              // 被保人/
              if (i.relationshipFlag == 'Insurant') {
                _this2.InsurantObj = i;
              }
              // "投保人"
              if (i.relationshipFlag == 'Applicant') {
                _this2.ApplicantObj = i;
              }
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
        }
        _this2.couponList = couponList;
        _this2.insuranceForm = insurance;
        _this2.coreProposalVehicleVO = insurance && insurance.coreProposalVehicleVO ? insurance.coreProposalVehicleVO : {};
        _this2.bsRiskKind = insurance.coreProposalProductVOList ? insurance.coreProposalProductVOList.find(function (val) {
          return val.riskType === '1';
        }) : {};
        _this2.bzRiskKind = insurance.coreProposalProductVOList ? insurance.coreProposalProductVOList.find(function (val) {
          return val.riskType === '0';
        }) : {};
        _this2.nonRiskKind = insurance.coreProposalProductVOList ? insurance.coreProposalProductVOList.find(function (val) {
          return val.riskType === '2';
        }) : {};
      }
    });
  },
  dictChange: function dictChange(val) {
    if (val == '04') {
      this.isFormRules = true;
    } else {
      this.isFormRules = false;
    }
  },
  paymentType: function paymentType(val) {
    //选择纳税类型
    this.isFormRules = false;
    this.form.taxType = val;
    if (val == this.dictSource['TAX_TYPE'][1].dictCode) {
      //减税
      this.TAX_REASON = this.CUT_TAX_REASON;
    }
    if (val == this.dictSource['TAX_TYPE'][2].dictCode) {
      //免税
      this.TAX_REASON = this.FREE_TAX_REASON;
    }
  },
  noPlateNoChange: function noPlateNoChange() {},
  datePickerChange: function datePickerChange(form, init, bzChange) {
    //保险期间日期设置
    var formData = _.cloneDeep(form);
    var currentDay = new Date();
    var currentDayStr = dataFormat(currentDay);
    //起保时间默认往后推1小时
    var hh = currentDay.getHours() + 1;
    // let mm = currentDay.getMinutes();
    hh = hh < 10 ? "0" + hh : hh >= 24 ? "24" : hh;
    // mm = mm < 10 ? '0' + mm : mm;
    if (formData.bzEffectiveStartTime && !init) {
      var curDay = new Date(formData.bzEffectiveStartTime);
      var bzTime = dataFormat(curDay);
      //交强险终保日期
      formData.bzEffectiveEndTime = getNextYear(formData.bzEffectiveStartTime);
      //交强险起保日期为当天，起保时间和终保时间默认相同
      if (bzTime.split(" ")[0] == currentDayStr.split(" ")[0]) {
        this.defaultBzTimeOptions.start = hh + ":00";
        formData.bzEndTime = formData.bzStartTime = hh + ":00"; // + mm;
        if (this.form.insCityCode == '110100') {
          formData.bzEndTime = formData.bzStartTime = "00:00"; // + mm;
        }
      } else {
        this.defaultBzTimeOptions.start = "00:00";
        formData.bzEndTime = formData.bzStartTime = "00:00";
      }
    }
    if (bzChange) {
      formData.bsEffectiveStartTime = formData.bzEffectiveStartTime;
    }
    if (formData.bsEffectiveStartTime) {
      var _curDay = new Date(formData.bsEffectiveStartTime);
      var bsTime = dataFormat(_curDay);
      //商业险终保日期
      formData.bsEndTime = "00:00";
      if (bsTime.split(" ")[0] == currentDayStr.split(" ")[0]) {
        //商业险起保时间为非0点时，终保日期默认+1
        this.defaultBsTimeOptions.start = hh + ":00";
        formData.bsEffectiveEndTime = getNextYear(formData.bsEffectiveStartTime, 1);
        formData.bsStartTime = hh + ":00"; // + mm;
      } else {
        //商业险起保时间为0点时，终保日期和起保日期相同
        formData.bsEffectiveEndTime = getNextYear(formData.bsEffectiveStartTime);
        this.defaultBsTimeOptions.start = "00:00";
        formData.bsStartTime = "00:00";
      }
    }
    this.originBzEffectiveEndTime = formData.bzEffectiveEndTime;
    this.originBsEffectiveEndTime = formData.bsEffectiveEndTime;
    this.form = formData;
  },
  timePickerChange: function timePickerChange(form) {
    //保险期间时间设置
    var formData = _.cloneDeep(form);
    if (formData.bzEffectiveStartTime) {
      //交强险终保时间和起保时间相同
      formData.bzEndTime = formData.bzStartTime;
      if (this.form.insCityCode == '110100') {
        formData.bzEndTime = formData.bzStartTime = "00:00"; // + mm;
      }
    }

    if (formData.bsEffectiveStartTime) {
      //商业险终保时间设置
      var curDay = new Date(formData.bsEffectiveStartTime);
      //商业险终保时间默认为0点
      formData.bsEndTime = "00:00";
      if (formData.bsStartTime != "00:00") {
        //商业险起保时间非0点时，终保日期默认+1
        formData.bsEffectiveEndTime = getNextYear(formData.bsEffectiveStartTime, 1);
      } else {
        //商业险起保时间为0点时，终保日期和起保日期相同
        formData.bsEffectiveEndTime = getNextYear(formData.bsEffectiveStartTime);
      }
    }
    formData.bzEffectiveStartTime = formData.bzEffectiveStartTime.split(' ')[0] + ' ' + formData.bzStartTime;
    formData.bzEffectiveEndTime = formData.bzEffectiveEndTime.split(' ')[0] + ' ' + formData.bzEndTime;
    formData.bsEffectiveStartTime = formData.bsEffectiveStartTime.split(' ')[0] + ' ' + formData.bsStartTime;
    formData.bsEffectiveEndTime = formData.bsEffectiveEndTime.split(' ')[0] + ' ' + formData.bsEndTime;
    this.form = formData;
  },
  processMinDate: function processMinDate(type) {
    //处理北京地区交强险起保日期
    var self = this;
    return {
      disabledDate: function disabledDate(time) {
        if (self.form.insCityCode == '110100' && type == 'bz') {
          return time.getTime() < Date.now();
        }
        return time.getTime() < Date.now() - 8.64e7;
      }
    };
  },
  processDate: function processDate(type) {
    //处理交强险和商业险最大可选日期；
    var self = this;
    return {
      disabledDate: function disabledDate(time) {
        if (type == "0") {
          return time.getTime() > new Date(self.bzEffectiveStartTime).getTime();
        } else {
          return time.getTime() > new Date(self.originBsEffectiveEndTime).getTime();
        }
      }
    };
  },
  insuranceName: function insuranceName(val) {
    var name = '';
    var _iterator4 = _createForOfIteratorHelper(this.insuranceData),
      _step4;
    try {
      for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
        var i = _step4.value;
        if (i.code == val) {
          name = i.name;
        }
      }
    } catch (err) {
      _iterator4.e(err);
    } finally {
      _iterator4.f();
    }
    return name;
  },
  insuranceValue: function insuranceValue(val, PVal) {
    var priceVal = '';
    var _iterator5 = _createForOfIteratorHelper(this.insuranceData),
      _step5;
    try {
      for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
        var i = _step5.value;
        if (i.code == val) {
          var _iterator6 = _createForOfIteratorHelper(i.list),
            _step6;
          try {
            for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
              var j = _step6.value;
              if (j.value == PVal) {
                priceVal = j.label;
              }
            }
          } catch (err) {
            _iterator6.e(err);
          } finally {
            _iterator6.f();
          }
        }
      }
    } catch (err) {
      _iterator5.e(err);
    } finally {
      _iterator5.f();
    }
    return priceVal;
  },
  clearCache: function clearCache() {
    this.form.owner.certificateType = '';
    // console.log('清楚缓存')
  },
  // 被保人切换
  InsuredChange: function InsuredChange(val, isOwner) {
    console.log(val);
    console.log(isOwner);
  },
  // 长期切换
  dataValueChange: function dataValueChange(val, isOwner) {
    if (isOwner) {
      this.form.owner.ownerCertEndDate = val ? '9999-12-31 23:59:59' : '';
    } else {
      this.form.owner.certEndDate = val ? '9999-12-31 23:59:59' : '';
    }
  },
  // 车主证件有效起期
  ownerMaxDate: function ownerMaxDate() {
    var self = this;
    return {
      disabledDate: function disabledDate(time) {
        if (self.data && self.data.ownerCertEndDate) {
          return time.getTime() > new Date(self.data.ownerCertEndDate).getTime();
        }
        return false;
      }
    };
  },
  // 车主证件有效止期
  ownerMinDate: function ownerMinDate() {
    var self = this;
    return {
      disabledDate: function disabledDate(time) {
        if (self.data && self.data.ownerCertStartDate) {
          return time.getTime() < new Date(self.data.ownerCertStartDate).getTime();
        }
        return false;
      }
    };
  },
  handleChange: function handleChange(val) {
    if (!val) return;
    if (_.isArray(val) && val.length > 0) {
      if (this.$refs.addrssCascader.$refs.panel.checkedValue && this.$refs.addrssCascader.$refs.panel.checkedValue != val[2]) {
        // 清空选中的节点
        this.$refs.addrssCascader.$refs.panel.clearCheckedNodes();
        // 设置为空可以让节点不高亮显示
        this.$refs.addrssCascader.$refs.panel.activePath = [];
        this.form.owner.regionCode = val;
        this.$forceUpdate();
      }

      //获取地区名称
      var regionName = '';
      var province = _.find(this.cityList, function (item) {
        return item.value == val[0];
      });
      if (province) {
        regionName = province.label;
        var city = _.find(province.children, function (item) {
          return item.value == val[1];
        });
        if (city) {
          regionName = regionName === city.label ? regionName : regionName + ' ' + city.label;
          var area = _.find(city.children, function (item) {
            return item.value == val[2];
          });
          if (area) {
            regionName = regionName + ' ' + area.label;
            this.form.owner.regionCode = area.value;
          }
        }
      }
      //设置地区名称
      this.form.owner.regionName = regionName;
      this.form.owner.fullAddress = val;
      this.$set(this.form.owner, 'address', this.form.owner.regionName.replace(/\s+/g, ''));
    }
    this.$forceUpdate();
  },
  changeValue: function changeValue(val) {
    //刷新控件
    this.$forceUpdate();
  },
  inputCardNo: function inputCardNo(val) {
    if (val) {
      if (this.form.owner.certificateType == 'P01') {
        this.form.owner.certificateNo = character(_.toUpper(val));
      } else {
        val = val.replace(/[^A-Za-z0-9\（\）\(\)]/g, '');
        this.form.owner.certificateNo = _.toUpper(val);
      }
    }
  },
  certificateNoChange: function certificateNoChange(data) {
    //根据身份证获取出生日期和性别,性质：个人，证件类型：身份证
    var _data = _.cloneDeep(this.form.owner);
    if (_data.certificateType == 'P01' && _data.certificateNo) {
      var certificateNo = Rules['ID_CARD'].value;
      var flag = certificateNo.test(_data.certificateNo);
      var isValidate = idCardValidate(_data.certificateNo);
      if (!flag || !isValidate) {
        this.$message({
          type: 'error',
          message: '身份证号码不满足校验规则，请修改！',
          offset: this.sourceId ? 50 : 20,
          customClass: 'zZindex'
        });
        // data.certificateNo = '';
        this.$forceUpdate();
        return false;
      }
      var birthDay = getAnalysisIdCard(_data.certificateNo, 1);
      var sex = getAnalysisIdCard(_data.certificateNo, 2);
      _data.birthday = birthDay; //出生日期
      _data.sex = sex; //性别
      data.birthday = _data.birthday ? _data.birthday : null; //出生日期
      data.sex = _data.sex; //性别
      this.$forceUpdate();
    }
    if (data.isNotSelf && data.mobileHolderCertificate == 'P01' && data.mobileHolderCertificateNo && this.insuranceArea == '310100') {
      var noRules = Rules['ID_CARD'].value;
      var _flag = noRules.test(data.mobileHolderCertificateNo);
      var _isValidate = idCardValidate(data.mobileHolderCertificateNo);
      if (!_flag || !_isValidate) {
        this.$message({
          type: 'error',
          message: '手机持有人的身份证号码不满足校验规则，请修改！',
          offset: this.sourceId ? 50 : 20,
          customClass: 'zZindex'
        });
        this.$forceUpdate();
        return false;
      }
      this.$forceUpdate();
    }
    return true;
  },
  filterProduct: function filterProduct(val, code) {
    var _this3 = this;
    //通过品牌、型号、性质过滤产品
    if (code === "brand") {
      this.modellist = [];
      var brand = _.find(this.typeList, function (item) {
        return item.brandName === val;
      });
      var id = brand.vehicleBrandId;
      if (id) {
        carService.allTheModels(id).then(function (res) {
          if (res.code === 200) {
            _this3.modellist = res.data;
          }
        });
      }
    }
  },
  inputvin: function inputvin(val) {
    //监听输入事件
    if (val && this.opType != 'underwriting') {
      val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      var quoteVehicle = _.cloneDeep(this.form);
      quoteVehicle.vin = character(_.toUpper(val));
      this.form = quoteVehicle;
      this.$forceUpdate();
    }
  },
  nodeClick: function nodeClick(data) {
    this.form.ownerOrgCode = data.salesOrgCode;
    this.form.ownerOrgName = data.salesOrgName;
  },
  getUsers: function getUsers(val) {
    //列表行选择事件
    this.form.salemanName = '';
    this.form.insCityCode = '';
    this.users = val;
  },
  salemanNameChange: function salemanNameChange(val) {
    var _this4 = this;
    //获取渠道信息
    slipRequest.getChannels(this.form.ownerOrgCode).then(function (res) {
      if (res.code == RESPONSE_SUCCESS) {
        var resData = _.cloneDeep(res.data ? res.data : []);
        _this4.policyCityItems = resData;
      }
    }).catch(function (error) {
      _this4.initLoading = false;
    });
    var item = _.find(this.users, function (i) {
      return i.userId == val;
    });
    this.form.salemanNameDisplay = item.userName;
  },
  getInsCityName: function getInsCityName(val) {
    //设置出单机构城市
    var item = _.find(this.policyCityItems, function (i) {
      return i.insCityCode == val;
    });
    this.form.insCityName = item.insCityName;
  },
  getCertificateTypeName: function getCertificateTypeName(val) {
    //设置证件类型名称
    var item = _.find(this.personalCertificateTypeList, function (i) {
      return i.dictCode == val;
    });
    this.form.owner.certificateTypeDisplay = item.dictName;
    this.$forceUpdate();
  },
  submitQuote: function submitQuote(val) {
    console.log('提交', val);
    console.log(this.form);
    var Num = "";
    for (var i = 0; i < 6; i++) {
      Num += Math.floor(Math.random() * 10);
    }
    console.log(Num);
    console.log(this.ProgramServiceProductsTableData);
    this.form.ProgramName = this.ProgramServiceProductsTableData && this.ProgramServiceProductsTableData[0].ProgramName;
    this.form.orderNo = 'FWB' + Num;
    var rquestData = [];
    if (localStorage.getItem('serviceOrderList')) {
      rquestData = JSON.parse(localStorage.getItem('serviceOrderList'));
      rquestData.push(this.form);
    } else {
      rquestData.push(this.form);
    }
    localStorage.setItem('serviceOrderList', JSON.stringify(rquestData));
    this.form = {
      ProgramName: '',
      orderNo: '',
      owner: {
        name: '',
        type: 'personal',
        ownerCertStartDate: '',
        ownerCertEndDate: '',
        phone: '',
        email: '',
        regionCode: '',
        regionName: '',
        fullAddress: '',
        address: ''
      },
      ownerOrgCode: '',
      ownerOrgName: '',
      salemanName: '',
      insCityCode: '',
      isNewCar: true,
      vin: '',
      BrandName: '',
      ModelName: '',
      vehiclePrice: '',
      useAttribute: '',
      registerDate: '',
      issueDate: '',
      isBz: true,
      bzEffectiveStartTime: '',
      bzEffectiveEndTime: '',
      isBs: true,
      bsEffectiveStartTime: '',
      bsEffectiveEndTime: '',
      taxType: ''
    };
    this.$store.dispatch('tagsView/delView', this.$route);
    this.$router.go(-1);
  },
  goBack: function goBack() {
    this.$store.dispatch('tagsView/delView', this.$route);
    this.$router.go(-1);
  },
  formatOption: function formatOption(amount) {
    //格式化保额
    if (amount) {
      var divideValue = _.divide(amount, 10000);
      var text = amount;
      if (divideValue >= 1) {
        text = divideValue + '万';
      }
      return text;
    }
  },
  getEffectiveStartTime: function getEffectiveStartTime(time) {
    //起保日期即时起保
    if (!time) return "-";
    if (time.indexOf("00:00") != -1 || time.indexOf("00:00:00") != -1) {
      return time.slice(0, 13) + "时";
    }
    return time.slice(0, 13) + "时" + time.slice(14, 16) + '分';
  },
  getEffectiveEndTime: function getEffectiveEndTime(time) {
    //终保日期默认为前一天的24时
    if (!time) return "";
    if (time.indexOf("00:00") != -1 || time.indexOf("00:00:00") != -1) {
      var timeStr = time.substring(0, 10);
      var hour = time.substring(11, 13);
      var min = time.substring(14, 16);
      var date = getBeforeOfDate(timeStr, 1);
      if (hour != '00' || min != '00') {
        date = getBeforeOfDate(timeStr, 0);
      }
      var endTime = hour != '00' ? date + ' ' + hour + '时' : date + " 24时";
      return endTime;
    }
    return time.slice(0, 13) + "时" + time.slice(14, 16) + '分';
  },
  viewDetail: function viewDetail(item) {
    // 查看投保单详情
    var router_path = this.$route.path;
    this.$router.push({
      path: "/proposal/detail",
      query: {
        quoteId: item.quoteId,
        back: router_path,
        plateNo: item.coreProposalVehicleVO.plateNo,
        vin: item.coreProposalVehicleVO.vin,
        fromStatus: 'servicePack'
      }
    });
  },
  viewContract: function viewContract(item) {
    //查看合同
    if (item.servPackContractFileUrl) {
      window.open(item.servPackContractFileUrl, '_blank');
    }
  },
  viewPolicy: function viewPolicy(item) {
    //查看保单
    this.$router.push({
      path: "/policy/detail",
      query: {
        plateNo: this.insuranceForm.coreProposalVehicleVO.plateNo,
        vin: this.insuranceForm.coreProposalVehicleVO.vin,
        policyNo: item.policyNo
      }
    });
  },
  getQuantity: function getQuantity(item) {
    //拼接增值服务次数
    var text = '';
    if (!_.isEmpty(item.proposalProductDutyServicetermsUpdateVOList)) {
      item.proposalProductDutyServicetermsUpdateVOList.forEach(function (item) {
        text = text + item.serviceText + ':' + item.serviceTimes + ',';
      });
    } else if (!_.isEmpty(item.policyDutyServicetermses)) {
      item.policyDutyServicetermses.forEach(function (item) {
        text = text + item.serviceText + ':' + item.serviceTimes + ',';
      });
    }
    if (text && text.lastIndexOf(',') != -1) {
      text = text.substr(0, text.length - 1);
    }
    return text;
  }
}), _name$components$mixi);