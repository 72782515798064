import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _objApi;
/**
 * 保单模板接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = (_objApi = {
  /**
   * 获取列表
   */
  getList: function getList(data) {
    return request.get(getUrl("/policytemplate/search", 17), {
      params: data
    });
  },
  /**
   * 创建模板
   */
  createTemplate: function createTemplate(data) {
    return request.post(getUrl("/policytemplate/create", 17), data);
  },
  /**
   * 查看应用
   */
  getDetailByTemplateId: function getDetailByTemplateId(id) {
    return request.get(getUrl("/template/get?id=".concat(id), 17));
  },
  /**
   * 更新模板
   */
  updataTemplate: function updataTemplate(data) {
    return request.post(getUrl("/policytemplate/update", 17), data);
  },
  /**
   * 删除应用
   */
  deleteTemplate: function deleteTemplate(id) {
    return request.post(getUrl("/policytemplate/delete?id=".concat(id), 17));
  },
  /**
  * 通过id获取详情
  */
  getDetailById: function getDetailById(id) {
    return request.get(getUrl("/policytemplate/get?id=".concat(id), 17));
  },
  /**
   * 获取险别枚举
   */
  getRiskEnum: function getRiskEnum() {
    return request.get(getUrl("/policyenum/listRiskKindEnum", 17));
  },
  /**
   * 获取配置列表
   */
  getConfigList: function getConfigList(data) {
    return request.get(getUrl("/policytemplateconfig/search", 17), {
      params: data
    });
  }
}, _defineProperty(_objApi, "getConfigList", function getConfigList(data) {
  return request.get(getUrl("/policytemplateconfig/search", 17), {
    params: data
  });
}), _defineProperty(_objApi, "getTemplatedata", function getTemplatedata(data) {
  return request.get(getUrl("/policytemplatedata/list", 17), {
    params: data
  });
}), _defineProperty(_objApi, "createTemplateConfig", function createTemplateConfig(data) {
  return request.post(getUrl("/policytemplateconfig/create", 17), data);
}), _defineProperty(_objApi, "updataTemplateConfig", function updataTemplateConfig(data) {
  return request.post(getUrl("/policytemplateconfig/update", 17), data);
}), _defineProperty(_objApi, "deleteTemplateConfig", function deleteTemplateConfig(id) {
  return request.post(getUrl("/policytemplateconfig/delete?id=".concat(id), 17));
}), _defineProperty(_objApi, "getSupportTypeEnum", function getSupportTypeEnum() {
  return request.get(getUrl("/policyenum/listSupportTypeEnum", 17));
}), _defineProperty(_objApi, "getRecoMethodEnum", function getRecoMethodEnum() {
  return request.get(getUrl("/policyenum/listRecoMethodEnum", 17));
}), _defineProperty(_objApi, "getPositionTypeEnum", function getPositionTypeEnum() {
  return request.get(getUrl("/policyenum/listCellPositionTypeEnum", 17));
}), _defineProperty(_objApi, "getConfigDetailById", function getConfigDetailById(id) {
  return request.get(getUrl("/policytemplateconfig/get?id=".concat(id), 17));
}), _defineProperty(_objApi, "copy", function copy(id) {
  return request.post(getUrl("/policytemplate/copy?templateId=".concat(id), 17));
}), _objApi);
export default objApi;