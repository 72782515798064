/**
 * 投保要素接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} data 参数
   */
  getList: function getList(data) {
    return request.get(getUrl("/inscorp/channel/verify/search", 1), {
      params: data
    });
  },
  /**
   * 删除保司验证
   * @param {*} ids 参数
   */
  deleteInsCorpVerification: function deleteInsCorpVerification(ids) {
    return request.post(getUrl("/inscorp/channel/verify/batchDeleteList", 1), ids);
  },
  /**
   * 新增保司验证
   * @param {*} data 参数
   */
  createInsCorpVerification: function createInsCorpVerification(data) {
    return request.post(getUrl("/inscorp/channel/verify/create", 1), data);
  },
  /**
  * 获取详情
  * @param {*} id 参数
  */
  getDetailById: function getDetailById(id) {
    return request.get(getUrl("/inscorp/channel/verify/get?id=".concat(id), 1));
  },
  /**
  * 更新保司验证
  * @param {*} id 参数
  * @param {*} data 参数
  */
  updataInsCorpVerification: function updataInsCorpVerification(id, data) {
    return request.post(getUrl("/inscorp/channel/verify/update?monitorId=".concat(id), 1), data);
  },
  /**
  * 发送验证码
  * @param {*} data 参数
  */
  sendDynamicCode: function sendDynamicCode(data) {
    return request.post(getUrl("/insure/sendLoginDynamicCode", 1), data);
  },
  /**
  * 保司验证保存验证码
  * @param {*} data 参数
  */
  saveDynamicCode: function saveDynamicCode(data) {
    return request.post(getUrl("/inscorp/channel/verify/recoveryDynamicCode", 1), data);
  },
  /**
  * 报价页面保司验证保存验证码
  * @param {*} data 参数
  */
  quoteAndSaveDynamicCode: function quoteAndSaveDynamicCode(data) {
    return request.post(getUrl("/insure/sendLoginDynamicCode", 1), data);
  }
};
export default objApi;