export default {
  data: function data() {
    return {
      dictSource: {
        "RENEWALS_STATUS": [{
          dictCode: "ALL",
          dictName: '全部'
        }, {
          dictCode: "CAN_RENEWAL",
          dictName: '可续保'
        }, {
          dictCode: "INTERRUPT",
          dictName: '已脱保'
        }, {
          dictCode: "UN_EXPIRE",
          dictName: '未到期'
        }, {
          dictCode: "UNKNOWN",
          dictName: '未知'
        }],
        "A_SRATUS": {
          pass: "通过",
          reject: "驳回"
        },
        "ORDER_STATUS": [{
          dictCode: "01",
          dictName: '待付款'
        }, {
          dictCode: "06",
          dictName: '已完成'
        }, {
          dictCode: "03",
          dictName: '已取消'
        }],
        "TIME_SELECT": [{
          dictCode: "now",
          dictName: '今天'
        }, {
          dictCode: "yesterday",
          dictName: '昨天'
        }, {
          dictCode: "sevenDays",
          dictName: '近一周'
        }, {
          dictCode: "currentMonth",
          dictName: '本月'
        }, {
          dictCode: "thirtyDays",
          dictName: '近一月'
        }, {
          dictCode: "lastMonth",
          dictName: '上月'
        }, {
          dictCode: "custom",
          dictName: '自定义'
        }],
        "PRODUCT_STATUS": [{
          dictCode: "PSE01",
          dictName: '生效中'
        }, {
          dictCode: "PSE02",
          dictName: '待生效'
        }, {
          dictCode: "PSE03",
          dictName: '已过期'
        }],
        "RENEWAL_STATUS": [{
          dictCode: "NEW_INSURANCE",
          dictName: '新保'
        }, {
          dictCode: "RENEWAL",
          dictName: '续保'
        }],
        "CAR_STATUS": [{
          dictCode: "NEW_CAR",
          dictName: '新车'
        }, {
          dictCode: "LITTLE_OLD_CAR",
          dictName: '次新车'
        }, {
          dictCode: "OLD_CAR",
          dictName: '旧车'
        }],
        "TRANSFER_CAR": [{
          dictCode: "0",
          dictName: '是'
        }, {
          dictCode: "1",
          dictName: '否'
        }],
        "BRAND": [{
          dictCode: "0",
          dictName: '其他'
        }],
        "ORDER": [{
          dictCode: "01",
          dictName: '交商同出'
        }, {
          dictCode: "0",
          dictName: '单出商业险'
        }, {
          dictCode: "1",
          dictName: '单出交强险'
        }, {
          dictCode: "2",
          dictName: '非车险'
        }, {
          dictCode: "3",
          dictName: '交商+非车险'
        }],
        "EMPLOYEESTATE": [{
          dictCode: 'REGULAR',
          dictName: '正式员工'
        }, {
          dictCode: 'DIMISSION',
          dictName: '已离职'
        }],
        "ORGTYPELIST": [{
          dictCode: 'COMPANY',
          dictName: '公司'
        }, {
          dictCode: 'SHOP',
          dictName: '网点'
        }],
        'NATION_ITEM': ['汉族', '满族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '侗族', '瑶族', '白族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '傈僳族', '佤族', '畲族', '高山族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族', '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族', '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族', '朝鲜族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族'],
        'FEATURE_TYPE': [{
          code: 'OnlineInsurance',
          description: '在线承保'
        }, {
          code: 'RenewalSearch',
          description: '续保查询'
        }, {
          code: 'PolicySync',
          description: '保单同步'
        }, {
          code: 'CiitcQuery',
          description: '在保查询'
        }],
        'INSURANCE_TYPE': [{
          dictCode: '0',
          dictName: '交强险'
        }, {
          dictCode: '1',
          dictName: '商业险'
        }, {
          dictCode: '2',
          dictName: '非车险'
        }],
        'INSURANCE_LIMIT': [{
          dictCode: '元',
          dictName: '元'
        }, {
          dictCode: '%',
          dictName: '%'
        }, {
          dictCode: '元/天',
          dictName: '元/天'
        }, {
          dictCode: '元/座',
          dictName: '元/座'
        }],
        'INSURED_TYPE': [{
          dictCode: 'Applicant',
          dictName: '投保人'
        }, {
          dictCode: 'Insurant',
          dictName: '被保人'
        }],
        'TAX_TYPE': [{
          dictCode: '01',
          dictName: '正常缴纳'
        }, {
          dictCode: '04',
          dictName: '减税'
        }, {
          dictCode: '02',
          dictName: '免税'
        }, {
          dictCode: '03',
          dictName: '已完税'
        }, {
          dictCode: '05',
          dictName: '补税'
        }],
        'TAX_TYPE_BJ': [{
          dictCode: '06',
          dictName: '已申报未入库'
        }, {
          dictCode: '07',
          dictName: '不在征收范围'
        }, {
          dictCode: '08',
          dictName: '不征'
        }],
        //全部,暂存,报价修改,重复投保,报价失败,待提核,人工核保中（不可修改报价方案）,
        //核保修改,待缴费（不可修改报价方案）,核保失败,已承保（不可修改报价方案）
        'INSURED_STATUS_DROPDOWN': [{
          dictCode: null,
          dictName: '全部'
        }, {
          dictCode: '99',
          dictName: '暂存'
        }, {
          dictCode: '02',
          dictName: '报价修改'
        }, {
          dictCode: '03',
          dictName: '报价失败'
        }, {
          dictCode: '04',
          dictName: '待核保'
        }, {
          dictCode: '06',
          dictName: '核保修改'
        }, {
          dictCode: '07',
          dictName: '人工核保中'
        }, {
          dictCode: '08',
          dictName: '核保失败'
        }, {
          dictCode: '09',
          dictName: '待缴费'
        }, {
          dictCode: '11',
          dictName: '已承保'
        }, {
          dictCode: '10',
          dictName: '缴费中'
        }, {
          dictCode: '12',
          dictName: '待承保'
        }, {
          dictCode: '13',
          dictName: '重复投保'
        }],
        'INSURED_STATUS_OTHER': [{
          dictCode: '01',
          dictName: '报价中'
        }, {
          dictCode: '05',
          dictName: '核保中'
        }, {
          dictCode: '10',
          dictName: '缴费中'
        }],
        'API_CONFIG_TYPE': [{
          dictCode: 'INSURED',
          dictName: '承保接口'
        },
        // {
        //     dictCode: 'ENDORSEMENT',
        //     dictName: '批改接口'
        // },
        {
          dictCode: 'CLAIMS',
          dictName: '理赔接口'
        }],
        'DEDUCTION_DUE_TYPE': [{
          dictCode: '01',
          dictName: '拖拉机'
        }, {
          dictCode: '02',
          dictName: '军队,武警专用车'
        }, {
          dictCode: '03',
          dictName: '警车'
        }, {
          dictCode: '04',
          dictName: '具备减免税证明'
        }, {
          dictCode: '05',
          dictName: '外国使领馆,国际组织及其人员'
        }, {
          dictCode: '06',
          dictName: '农村摩托车,三轮汽车或低速载货汽车'
        }, {
          dictCode: '07',
          dictName: '新能源车辆'
        }, {
          dictCode: '08',
          dictName: '其他'
        }],
        'PAYMENT_STATUS': [{
          dictCode: '01',
          dictName: '待审核'
        }, {
          dictCode: '02',
          dictName: '待缴费'
        }, {
          dictCode: '03',
          dictName: '缴费中'
        }, {
          dictCode: '04',
          dictName: '已承保'
        }, {
          dictCode: '05',
          dictName: '承保失败'
        }],
        'CUST_ATTRIBUTE': [{
          dictCode: '01',
          dictName: '个人'
        }, {
          dictCode: '02',
          dictName: '机关'
        }, {
          dictCode: '03',
          dictName: '团体'
        }],
        'CERTIFICATE_TYPE': [{
          dictCode: '01',
          dictName: '销售发票'
        }, {
          dictCode: '02',
          dictName: '法院调解书'
        }, {
          dictCode: '03',
          dictName: '法院裁定书'
        }, {
          dictCode: '04',
          dictName: '法院判决书'
        }, {
          dictCode: '05',
          dictName: '仲裁裁决书'
        }, {
          dictCode: '06',
          dictName: '相关文书（继承,赠予,协议抵债）'
        }, {
          dictCode: '07',
          dictName: '批准文件'
        }, {
          dictCode: '08',
          dictName: '调拨证明'
        }, {
          dictCode: '09',
          dictName: '修理发票'
        }, {
          dictCode: '10',
          dictName: '车辆合格证'
        }, {
          dictCode: '11',
          dictName: '货物进口证明书'
        }],
        'OPERRATOR_TYPE': [{
          dictCode: '1',
          dictName: '车辆所有人'
        }, {
          dictCode: '2',
          dictName: '车辆管理人'
        }, {
          dictCode: '3',
          dictName: '保险人允许的合法驾驶员'
        }, {
          dictCode: '9',
          dictName: '其它'
        }],
        'PLATENO_PREFIX': ['粤', '京', '冀', '沪', '津', '晋', '蒙', '辽', '吉', '黑', '苏', '浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '桂', '琼', '渝', '川', '贵', '云', '藏', '陕', '甘', '青', '宁', '使', '领'],
        'PLATE_PREFIX': [{
          "code": "冀A",
          "city": "石家庄",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀B",
          "city": "唐山",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀C",
          "city": "秦皇岛",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀D",
          "city": "邯郸",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀E",
          "city": "邢台",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀F",
          "city": "保定",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀G",
          "city": "张家口",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀H",
          "city": "承德",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀J",
          "city": "沧州",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀R",
          "city": "廊坊",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀S",
          "city": "沧州",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "冀T",
          "city": "衡水",
          "province": "河北",
          "Pcode": "HB"
        }, {
          "code": "辽A",
          "city": "沈阳",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽B",
          "city": "大连",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽C",
          "city": "鞍山",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽D",
          "city": "抚顺",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽E",
          "city": "本溪",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽F",
          "city": "丹东",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽G",
          "city": "锦州",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽H",
          "city": "营口",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽J",
          "city": "阜新",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽K",
          "city": "辽阳",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽L",
          "city": "盘锦",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽M",
          "city": "铁岭",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽N",
          "city": "朝阳",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽P",
          "city": "葫芦岛",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "辽V",
          "city": "沈阳",
          "province": "辽宁",
          "Pcode": "LN"
        }, {
          "code": "皖A",
          "city": "合肥",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖B",
          "city": "芜湖",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖C",
          "city": "蚌埠",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖D",
          "city": "淮南",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖E",
          "city": "马鞍山",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖F",
          "city": "淮北",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖G",
          "city": "铜陵",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖H",
          "city": "安庆",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖J",
          "city": "黄山",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖K",
          "city": "阜阳",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖L",
          "city": "宿州",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖M",
          "city": "滁州",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖N",
          "city": "六安",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖P",
          "city": "宣城",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖Q",
          "city": "巢湖",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖R",
          "city": "池州",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "皖S",
          "city": "亳州",
          "province": "安徽",
          "Pcode": "AH"
        }, {
          "code": "苏A",
          "city": "南京",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏B",
          "city": "无锡",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏C",
          "city": "徐州",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏D",
          "city": "常州",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏E",
          "city": "苏州",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏F",
          "city": "南通",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏G",
          "city": "连云港",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏H",
          "city": "淮安",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏J",
          "city": "盐城",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏K",
          "city": "扬州",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏L",
          "city": "镇江",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏M",
          "city": "泰州",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "苏N",
          "city": "宿迁",
          "province": "江苏",
          "Pcode": "JS"
        }, {
          "code": "鄂A",
          "city": "武汉",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂B",
          "city": "黄石",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂C",
          "city": "十堰",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂D",
          "city": "荆州",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂E",
          "city": "宜昌",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂F",
          "city": "襄阳",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂G",
          "city": "鄂州",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂H",
          "city": "荆门 ",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂J",
          "city": "黄冈",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂K",
          "city": "孝感",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂L",
          "city": "咸宁",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂M",
          "city": "仙桃",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂N",
          "city": "潜江",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂P",
          "city": "神农架林区",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂Q",
          "city": "恩施土家族苗族自治州",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂R",
          "city": "天门",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "鄂S",
          "city": "随州",
          "province": "湖北",
          "Pcode": "HB"
        }, {
          "code": "晋A",
          "city": "太原",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋B",
          "city": "大同",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋C",
          "city": "阳泉",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋D",
          "city": "长治",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋E",
          "city": "晋城",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋F",
          "city": "朔州",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋H",
          "city": "忻州",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋J",
          "city": "吕梁",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋K",
          "city": "晋中",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋L",
          "city": "临汾",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "晋M",
          "city": "运城",
          "province": "山西",
          "Pcode": "SX"
        }, {
          "code": "吉A",
          "city": "长春",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "吉B",
          "city": "吉林",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "吉C",
          "city": "四平",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "吉D",
          "city": "辽源",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "吉E",
          "city": "通化",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "吉F",
          "city": "白山",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "吉G",
          "city": "白城",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "吉H",
          "city": "延边朝鲜族自治州",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "吉J",
          "city": "松原",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "吉K",
          "city": "长白山",
          "province": "吉林",
          "Pcode": "JL"
        }, {
          "code": "粤A",
          "city": "广州",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤B",
          "city": "深圳",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤C",
          "city": "珠海",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤D",
          "city": "汕头",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤E",
          "city": "佛山",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤F",
          "city": "韶关",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤G",
          "city": "湛江",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤H",
          "city": "肇庆",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤J",
          "city": "江门",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤K",
          "city": "茂名",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤L",
          "city": "惠州",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤M",
          "city": "梅州",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤N",
          "city": "汕尾",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤P",
          "city": "河源",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤Q",
          "city": "阳江",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤R",
          "city": "清远",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤S",
          "city": "东莞",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤T",
          "city": "中山",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤U",
          "city": "潮州",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤V",
          "city": "揭阳",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤W",
          "city": "云浮",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤X",
          "city": "顺德区",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤Y",
          "city": "南海区",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "粤Z",
          "city": "港澳进入内地车辆",
          "province": "广东",
          "Pcode": "GD"
        }, {
          "code": "宁A",
          "city": "银川",
          "province": "宁夏",
          "Pcode": "NX"
        }, {
          "code": "宁B",
          "city": "石嘴山",
          "province": "宁夏",
          "Pcode": "NX"
        }, {
          "code": "宁C",
          "city": "吴忠",
          "province": "宁夏",
          "Pcode": "NX"
        }, {
          "code": "宁D",
          "city": "固原",
          "province": "宁夏",
          "Pcode": "NX"
        }, {
          "code": "宁E",
          "city": "中卫",
          "province": "宁夏",
          "Pcode": "NX"
        }, {
          "code": "京A",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京B",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京C",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京D",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京E",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京F",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京G",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京H",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京J",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京K",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京L",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京M",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "京Y",
          "city": "北京",
          "province": "北京",
          "Pcode": "BJ"
        }, {
          "code": "豫A",
          "city": "郑州",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫B",
          "city": "开封",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫C",
          "city": "洛阳",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫D",
          "city": "平顶山",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫E",
          "city": "安阳",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫F",
          "city": "鹤壁",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫G",
          "city": "新乡",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫H",
          "city": "焦作",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫J",
          "city": "濮阳",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫K",
          "city": "许昌",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫L",
          "city": "漯河",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫M",
          "city": "三门峡",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫N",
          "city": "商丘",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫P",
          "city": "周口",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫Q",
          "city": "驻马店",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫R",
          "city": "南阳",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫S",
          "city": "信阳",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "豫U",
          "city": "济源",
          "province": "河南",
          "Pcode": "HN"
        }, {
          "code": "黑A",
          "city": "哈尔滨",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑B",
          "city": "齐齐哈尔",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑C",
          "city": "牡丹江",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑D",
          "city": "佳木斯",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑E",
          "city": "大庆",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑F",
          "city": "伊春",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑G",
          "city": "鸡西",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑H",
          "city": "鹤岗",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑J",
          "city": "双鸭山",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑K",
          "city": "七台河",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑L",
          "city": "松花江",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑M",
          "city": "绥化",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑N",
          "city": "黑河",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑P",
          "city": "大兴安岭地区",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "黑R",
          "city": "农垦系统",
          "province": "黑龙江",
          "Pcode": "HLJ"
        }, {
          "code": "鲁A",
          "city": "济南",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁B",
          "city": "青岛",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁C",
          "city": "淄博",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁D",
          "city": "枣庄",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁E",
          "city": "东营",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁F",
          "city": "烟台",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁G",
          "city": "潍坊",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁H",
          "city": "济宁",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁J",
          "city": "泰安",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁K",
          "city": "威海",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁L",
          "city": "日照",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁M",
          "city": "滨州",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁N",
          "city": "德州",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁P",
          "city": "聊城",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁Q",
          "city": "临沂",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁R",
          "city": "菏泽",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁S",
          "city": "莱芜",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁U",
          "city": "青岛",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁V",
          "city": "潍坊",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "鲁Y",
          "city": "烟台",
          "province": "山东",
          "Pcode": "SD"
        }, {
          "code": "浙A",
          "city": "杭州",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙B",
          "city": "宁波",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙C",
          "city": "温州",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙D",
          "city": "绍兴",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙E",
          "city": "湖州",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙F",
          "city": "嘉兴",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙G",
          "city": "金华",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙H",
          "city": "衢州",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙J",
          "city": "台州",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙K",
          "city": "丽水",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "浙L",
          "city": "舟山",
          "province": "浙江",
          "Pcode": "ZJ"
        }, {
          "code": "桂A",
          "city": "南宁",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂B",
          "city": "柳州",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂C",
          "city": "桂林",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂D",
          "city": "梧州",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂E",
          "city": "北海",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂F",
          "city": "崇左",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂G",
          "city": "来宾",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂H",
          "city": "桂林",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂J",
          "city": "贺州",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂K",
          "city": "玉林",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂L",
          "city": "百色",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂M",
          "city": "河池",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂N",
          "city": "钦州",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂P",
          "city": "防城港",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "桂R",
          "city": "贵港",
          "province": "广西",
          "Pcode": "GX"
        }, {
          "code": "蒙A",
          "city": "呼和浩特",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙B",
          "city": "包头",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙C",
          "city": "乌海",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙D",
          "city": "赤峰",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙E",
          "city": "呼伦贝尔",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙F",
          "city": "兴安盟",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙G",
          "city": "通辽",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙H",
          "city": "锡林郭勒盟",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙J",
          "city": "乌兰察布",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙K",
          "city": "鄂尔多斯",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙L",
          "city": "巴彦淖尔",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "蒙M",
          "city": "阿拉善盟",
          "province": "内蒙古",
          "Pcode": "NMG"
        }, {
          "code": "闽A",
          "city": "福州",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "闽B",
          "city": "莆田",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "闽C",
          "city": "泉州",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "闽D",
          "city": "厦门",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "闽E",
          "city": "漳州",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "闽F",
          "city": "龙岩",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "闽G",
          "city": "三明",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "闽H",
          "city": "南平",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "闽J",
          "city": "宁德",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "闽K",
          "city": "福州",
          "province": "福建",
          "Pcode": "FJ"
        }, {
          "code": "川A",
          "city": "成都",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川B",
          "city": "绵阳",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川C",
          "city": "自贡",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川D",
          "city": "攀枝花",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川E",
          "city": "泸州",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川F",
          "city": "德阳",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川H",
          "city": "广元",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川J",
          "city": "遂宁",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川K",
          "city": "内江",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川L",
          "city": "乐山",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川M",
          "city": "资阳",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川Q",
          "city": "宜宾",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川R",
          "city": "南充",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川S",
          "city": "达州",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川T",
          "city": "雅安",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川U",
          "city": "阿坝藏族羌族自治州",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川V",
          "city": "甘孜藏族自治州",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川W",
          "city": "凉山彝族自治州",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川X",
          "city": "广安",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川Y",
          "city": "巴中",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "川Z",
          "city": "眉山",
          "province": "四川",
          "Pcode": "SC"
        }, {
          "code": "渝A",
          "city": "重庆",
          "province": "重庆",
          "Pcode": "ZQ"
        }, {
          "code": "渝B",
          "city": "重庆",
          "province": "重庆",
          "Pcode": "ZQ"
        }, {
          "code": "渝C",
          "city": "永川区",
          "province": "重庆",
          "Pcode": "ZQ"
        }, {
          "code": "渝F",
          "city": "万州区",
          "province": "重庆",
          "Pcode": "ZQ"
        }, {
          "code": "渝G",
          "city": "涪陵区",
          "province": "重庆",
          "Pcode": "ZQ"
        }, {
          "code": "渝H",
          "city": "黔江区",
          "province": "重庆",
          "Pcode": "ZQ"
        }, {
          "code": "津A",
          "city": "天津",
          "province": "天津",
          "Pcode": "TJ"
        }, {
          "code": "津B",
          "city": "天津",
          "province": "天津",
          "Pcode": "TJ"
        }, {
          "code": "津C",
          "city": "天津",
          "province": "天津",
          "Pcode": "TJ"
        }, {
          "code": "津D",
          "city": "天津",
          "province": "天津",
          "Pcode": "TJ"
        }, {
          "code": "津E",
          "city": "天津",
          "province": "天津",
          "Pcode": "TJ"
        }, {
          "code": "津F",
          "city": "天津",
          "province": "天津",
          "Pcode": "TJ"
        }, {
          "code": "津G",
          "city": "天津",
          "province": "天津",
          "Pcode": "TJ"
        }, {
          "code": "津H",
          "city": "天津",
          "province": "天津",
          "Pcode": "TJ"
        }, {
          "code": "云A",
          "city": "昆明",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云A",
          "city": "东川",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云C",
          "city": "昭通",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云D",
          "city": "曲靖",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云E",
          "city": "楚雄彝族自治州",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云F",
          "city": "玉溪",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云G",
          "city": "红河哈尼族彝族自治州",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云H",
          "city": "文山壮族苗族自治州",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云J",
          "city": "思茅",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云J",
          "city": "普洱",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云K",
          "city": "西双版纳傣族自治州",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云L",
          "city": "大理白族自治州",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云M",
          "city": "保山",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云N",
          "city": "德宏傣族景颇族自治州",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云P",
          "city": "丽江",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云Q",
          "city": "怒江傈僳族自治州",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云R",
          "city": "迪庆藏族自治州",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "云S",
          "city": "临沧",
          "province": "云南",
          "Pcode": "YN"
        }, {
          "code": "湘A",
          "city": "长沙",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘B",
          "city": "株洲",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘C",
          "city": "湘潭",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘D",
          "city": "衡阳",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘E",
          "city": "邵阳",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘F",
          "city": "岳阳",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘G",
          "city": "张家界",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘H",
          "city": "益阳",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘J",
          "city": "常德",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘K",
          "city": "娄底",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘L",
          "city": "郴州",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘M",
          "city": "永州",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘N",
          "city": "怀化",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "湘U",
          "city": "湘西土家族苗族自治州",
          "province": "湖南",
          "Pcode": "HN"
        }, {
          "code": "新A",
          "city": "乌鲁木齐",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新B",
          "city": "昌吉回族自治州",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新C",
          "city": "石河子",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新D",
          "city": "奎屯",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新E",
          "city": "博尔塔拉蒙古自治州",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新F",
          "city": "伊犁哈萨克自治州",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新G",
          "city": "塔城",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新H",
          "city": "阿勒泰",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新J",
          "city": "克拉玛依",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新K",
          "city": "吐鲁番",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新L",
          "city": "哈密",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新M",
          "city": "巴音郭楞蒙古自治州",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新N",
          "city": "阿克苏",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新P",
          "city": "克孜勒苏柯尔克孜自治州",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新Q",
          "city": "喀什",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "新R",
          "city": "和田",
          "province": "新疆",
          "Pcode": "XJ"
        }, {
          "code": "赣A",
          "city": "南昌",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣B",
          "city": "赣州",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣C",
          "city": "宜春",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣D",
          "city": "吉安",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣E",
          "city": "上饶",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣F",
          "city": "抚州",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣G",
          "city": "九江",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣H",
          "city": "景德镇",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣J",
          "city": "萍乡",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣K",
          "city": "新余",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣L",
          "city": "鹰潭",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "赣M",
          "city": "南昌",
          "province": "江西",
          "Pcode": "JX"
        }, {
          "code": "甘A",
          "city": "兰州",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘B",
          "city": "嘉峪关",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘C",
          "city": "金昌",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘D",
          "city": "白银",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘E",
          "city": "天水",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘F",
          "city": "酒泉",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘G",
          "city": "张掖",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘H",
          "city": "武威",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘J",
          "city": "定西",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘K",
          "city": "陇南",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘L",
          "city": "平凉",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘M",
          "city": "庆阳",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘N",
          "city": "临夏回族自治州",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "甘P",
          "city": "甘南藏族自治州",
          "province": "甘肃",
          "Pcode": "GS"
        }, {
          "code": "陕A",
          "city": "西安",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕B",
          "city": "铜川",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕C",
          "city": "宝鸡",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕D",
          "city": "咸阳",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕E",
          "city": "渭南",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕F",
          "city": "汉中",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕G",
          "city": "安康",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕H",
          "city": "商洛",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕J",
          "city": "延安",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕K",
          "city": "榆林",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "陕V",
          "city": "杨凌高新农业示范区",
          "province": "陕西",
          "Pcode": "SX"
        }, {
          "code": "贵A",
          "city": "贵阳",
          "province": "贵州",
          "Pcode": "GZ"
        }, {
          "code": "贵B",
          "city": "六盘水",
          "province": "贵州",
          "Pcode": "GZ"
        }, {
          "code": "贵C",
          "city": "遵义",
          "province": "贵州",
          "Pcode": "GZ"
        }, {
          "code": "贵D",
          "city": "铜仁",
          "province": "贵州",
          "Pcode": "GZ"
        }, {
          "code": "贵E",
          "city": "黔西南布依族苗族自治州",
          "province": "贵州",
          "Pcode": "GZ"
        }, {
          "code": "贵F",
          "city": "毕节",
          "province": "贵州",
          "Pcode": "GZ"
        }, {
          "code": "贵G",
          "city": "安顺",
          "province": "贵州",
          "Pcode": "GZ"
        }, {
          "code": "贵H",
          "city": "黔东南苗族侗族自治州",
          "province": "贵州",
          "Pcode": "GZ"
        }, {
          "code": "贵J",
          "city": "黔南布依族苗族自治州",
          "province": "贵州",
          "Pcode": "GZ"
        }, {
          "code": "青A",
          "city": "西宁",
          "province": "青海",
          "Pcode": "QH"
        }, {
          "code": "青B",
          "city": "海东",
          "province": "青海",
          "Pcode": "QH"
        }, {
          "code": "青C",
          "city": "海北藏族自治州",
          "province": "青海",
          "Pcode": "QH"
        }, {
          "code": "青D",
          "city": "黄南藏族自治州",
          "province": "青海",
          "Pcode": "QH"
        }, {
          "code": "青E",
          "city": "海南藏族自治州",
          "province": "青海",
          "Pcode": "QH"
        }, {
          "code": "青F",
          "city": "果洛藏族自治州",
          "province": "青海",
          "Pcode": "QH"
        }, {
          "code": "青G",
          "city": "玉树藏族自治州",
          "province": "青海",
          "Pcode": "QH"
        }, {
          "code": "青H",
          "city": "海西蒙古族藏族自治州",
          "province": "青海",
          "Pcode": "QH"
        }, {
          "code": "藏A",
          "city": "拉萨",
          "province": "西藏",
          "Pcode": "XC"
        }, {
          "code": "藏B",
          "city": "昌都",
          "province": "西藏",
          "Pcode": "XC"
        }, {
          "code": "藏C",
          "city": "山南",
          "province": "西藏",
          "Pcode": "XC"
        }, {
          "code": "藏D",
          "city": "日喀则",
          "province": "西藏",
          "Pcode": "XC"
        }, {
          "code": "藏E",
          "city": "那曲",
          "province": "西藏",
          "Pcode": "XC"
        }, {
          "code": "藏F",
          "city": "阿里地区",
          "province": "西藏",
          "Pcode": "XC"
        }, {
          "code": "藏G",
          "city": "林芝",
          "province": "西藏",
          "Pcode": "XC"
        }, {
          "code": "藏H",
          "city": "天全县车辆管理所",
          "province": "西藏",
          "Pcode": "XC"
        }, {
          "code": "藏J",
          "city": "格尔木车辆管理所",
          "province": "西藏",
          "Pcode": "XC"
        }, {
          "code": "琼A",
          "city": "海口",
          "province": "海南",
          "Pcode": "HN"
        }, {
          "code": "琼B",
          "city": "三亚",
          "province": "海南",
          "Pcode": "HN"
        }, {
          "code": "琼C",
          "city": "直辖县级",
          "province": "海南",
          "Pcode": "HN"
        }, {
          "code": "琼D",
          "city": "直辖县级",
          "province": "海南",
          "Pcode": "HN"
        }, {
          "code": "琼E",
          "city": "儋州",
          "province": "海南",
          "Pcode": "HN"
        }, {
          "code": "琼F",
          "city": "儋州",
          "province": "海南",
          "Pcode": "HN"
        }, {
          "code": "沪A",
          "city": "上海",
          "province": "上海",
          "Pcode": "SH"
        }, {
          "code": "沪B",
          "city": "上海",
          "province": "上海",
          "Pcode": "SH"
        }, {
          "code": "沪C",
          "city": "上海",
          "province": "上海",
          "Pcode": "SH"
        }, {
          "code": "沪D",
          "city": "上海",
          "province": "上海",
          "Pcode": "SH"
        }, {
          "code": "沪R",
          "city": "上海",
          "province": "上海",
          "Pcode": "SH"
        }],
        "BIZ_TYPE": [{
          dictCode: "OEM",
          dictName: '主机厂端'
        }, {
          dictCode: "DEALER",
          dictName: '经销商端'
        }],
        'EXPORT_STATUS_DROPDOWN': [{
          dictCode: 'SUCCESS',
          dictName: '成功'
        }, {
          dictCode: 'FAILURE',
          dictName: '失败'
        }, {
          dictCode: 'INPROGRESS',
          dictName: '进行中'
        }, {
          dictCode: 'CANCEL',
          dictName: '取消'
        }],
        'MIME_TYPES': [{
          dictCode: "ai",
          dictName: "application/postscript"
        }, {
          dictCode: "aif",
          dictName: "audio/x-aiff"
        }, {
          dictCode: "aifc",
          dictName: "audio/x-aiff"
        }, {
          dictCode: "aiff",
          dictName: "audio/x-aiff"
        }, {
          dictCode: "asc",
          dictName: "text/plain"
        }, {
          dictCode: "atom",
          dictName: "application/atom+xml"
        }, {
          dictCode: "au",
          dictName: "audio/basic"
        }, {
          dictCode: "avi",
          dictName: "video/x-msvideo"
        }, {
          dictCode: "bcpio",
          dictName: "application/x-bcpio"
        }, {
          dictCode: "bin",
          dictName: "application/octet-stream"
        }, {
          dictCode: "bmp",
          dictName: "image/bmp"
        }, {
          dictCode: "cdf",
          dictName: "application/x-netcdf"
        }, {
          dictCode: "cgm",
          dictName: "image/cgm"
        }, {
          dictCode: "class",
          dictName: "application/octet-stream"
        }, {
          dictCode: "cpio",
          dictName: "application/x-cpio"
        }, {
          dictCode: "cpt",
          dictName: "application/mac-compactpro"
        }, {
          dictCode: "csh",
          dictName: "application/x-csh"
        }, {
          dictCode: "css",
          dictName: "text/css"
        }, {
          dictCode: "dcr",
          dictName: "application/x-director"
        }, {
          dictCode: "dif",
          dictName: "video/x-dv"
        }, {
          dictCode: "dir",
          dictName: "application/x-director"
        }, {
          dictCode: "djv",
          dictName: "image/vnd.djvu"
        }, {
          dictCode: "djvu",
          dictName: "image/vnd.djvu"
        }, {
          dictCode: "dll",
          dictName: "application/octet-stream"
        }, {
          dictCode: "dmg",
          dictName: "application/octet-stream"
        }, {
          dictCode: "dms",
          dictName: "application/octet-stream"
        }, {
          dictCode: "doc",
          dictName: "application/msword"
        }, {
          dictCode: "docx",
          dictName: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        }, {
          dictCode: "dotx",
          dictName: "application/vnd.openxmlformats-officedocument.wordprocessingml.template"
        }, {
          dictCode: "docm",
          dictName: "application/vnd.ms-word.document.macroEnabled.12"
        }, {
          dictCode: "dotm",
          dictName: "application/vnd.ms-word.template.macroEnabled.12"
        }, {
          dictCode: "dtd",
          dictName: "application/xml-dtd"
        }, {
          dictCode: "dv",
          dictName: "video/x-dv"
        }, {
          dictCode: "dvi",
          dictName: "application/x-dvi"
        }, {
          dictCode: "dxr",
          dictName: "application/x-director"
        }, {
          dictCode: "eps",
          dictName: "application/postscript"
        }, {
          dictCode: "etx",
          dictName: "text/x-setext"
        }, {
          dictCode: "exe",
          dictName: "application/octet-stream"
        }, {
          dictCode: "ez",
          dictName: "application/andrew-inset"
        }, {
          dictCode: "gif",
          dictName: "image/gif"
        }, {
          dictCode: "gram",
          dictName: "application/srgs"
        }, {
          dictCode: "grxml",
          dictName: "application/srgs+xml"
        }, {
          dictCode: "gtar",
          dictName: "application/x-gtar"
        }, {
          dictCode: "hdf",
          dictName: "application/x-hdf"
        }, {
          dictCode: "hqx",
          dictName: "application/mac-binhex40"
        }, {
          dictCode: "htm",
          dictName: "text/html"
        }, {
          dictCode: "html",
          dictName: "text/html"
        }, {
          dictCode: "ice",
          dictName: "x-conference/x-cooltalk"
        }, {
          dictCode: "ico",
          dictName: "image/x-icon"
        }, {
          dictCode: "ics",
          dictName: "text/calendar"
        }, {
          dictCode: "ief",
          dictName: "image/ief"
        }, {
          dictCode: "ifb",
          dictName: "text/calendar"
        }, {
          dictCode: "iges",
          dictName: "model/iges"
        }, {
          dictCode: "igs",
          dictName: "model/iges"
        }, {
          dictCode: "jnlp",
          dictName: "application/x-java-jnlp-file"
        }, {
          dictCode: "jp2",
          dictName: "image/jp2"
        }, {
          dictCode: "jpe",
          dictName: "image/jpeg"
        }, {
          dictCode: "jpeg",
          dictName: "image/jpeg"
        }, {
          dictCode: "jpg",
          dictName: "image/jpeg"
        }, {
          dictCode: "js",
          dictName: "application/x-javascript"
        }, {
          dictCode: "kar",
          dictName: "audio/midi"
        }, {
          dictCode: "latex",
          dictName: "application/x-latex"
        }, {
          dictCode: "lha",
          dictName: "application/octet-stream"
        }, {
          dictCode: "lzh",
          dictName: "application/octet-stream"
        }, {
          dictCode: "m3u",
          dictName: "audio/x-mpegurl"
        }, {
          dictCode: "m4a",
          dictName: "audio/mp4a-latm"
        }, {
          dictCode: "m4b",
          dictName: "audio/mp4a-latm"
        }, {
          dictCode: "m4p",
          dictName: "audio/mp4a-latm"
        }, {
          dictCode: "m4u",
          dictName: "video/vnd.mpegurl"
        }, {
          dictCode: "m4v",
          dictName: "video/x-m4v"
        }, {
          dictCode: "mac",
          dictName: "image/x-macpaint"
        }, {
          dictCode: "man",
          dictName: "application/x-troff-man"
        }, {
          dictCode: "mathml",
          dictName: "application/mathml+xml"
        }, {
          dictCode: "me",
          dictName: "application/x-troff-me"
        }, {
          dictCode: "mesh",
          dictName: "model/mesh"
        }, {
          dictCode: "mid",
          dictName: "audio/midi"
        }, {
          dictCode: "midi",
          dictName: "audio/midi"
        }, {
          dictCode: "mif",
          dictName: "application/vnd.mif"
        }, {
          dictCode: "mov",
          dictName: "video/quicktime"
        }, {
          dictCode: "movie",
          dictName: "video/x-sgi-movie"
        }, {
          dictCode: "mp2",
          dictName: "audio/mpeg"
        }, {
          dictCode: "mp3",
          dictName: "audio/mpeg"
        }, {
          dictCode: "mp4",
          dictName: "video/mp4"
        }, {
          dictCode: "mpe",
          dictName: "video/mpeg"
        }, {
          dictCode: "mpeg",
          dictName: "video/mpeg"
        }, {
          dictCode: "mpg",
          dictName: "video/mpeg"
        }, {
          dictCode: "mpga",
          dictName: "audio/mpeg"
        }, {
          dictCode: "ms",
          dictName: "application/x-troff-ms"
        }, {
          dictCode: "msh",
          dictName: "model/mesh"
        }, {
          dictCode: "mxu",
          dictName: "video/vnd.mpegurl"
        }, {
          dictCode: "nc",
          dictName: "application/x-netcdf"
        }, {
          dictCode: "oda",
          dictName: "application/oda"
        }, {
          dictCode: "ogg",
          dictName: "application/ogg"
        }, {
          dictCode: "pbm",
          dictName: "image/x-portable-bitmap"
        }, {
          dictCode: "pct",
          dictName: "image/pict"
        }, {
          dictCode: "pdb",
          dictName: "chemical/x-pdb"
        }, {
          dictCode: "pdf",
          dictName: "application/pdf"
        }, {
          dictCode: "pgm",
          dictName: "image/x-portable-graymap"
        }, {
          dictCode: "pgn",
          dictName: "application/x-chess-pgn"
        }, {
          dictCode: "pic",
          dictName: "image/pict"
        }, {
          dictCode: "pict",
          dictName: "image/pict"
        }, {
          dictCode: "png",
          dictName: "image/png"
        }, {
          dictCode: "pnm",
          dictName: "image/x-portable-anymap"
        }, {
          dictCode: "pnt",
          dictName: "image/x-macpaint"
        }, {
          dictCode: "pntg",
          dictName: "image/x-macpaint"
        }, {
          dictCode: "ppm",
          dictName: "image/x-portable-pixmap"
        }, {
          dictCode: "ppt",
          dictName: "application/vnd.ms-powerpoint"
        }, {
          dictCode: "pptx",
          dictName: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        }, {
          dictCode: "potx",
          dictName: "application/vnd.openxmlformats-officedocument.presentationml.template"
        }, {
          dictCode: "ppsx",
          dictName: "application/vnd.openxmlformats-officedocument.presentationml.slideshow"
        }, {
          dictCode: "ppam",
          dictName: "application/vnd.ms-powerpoint.addin.macroEnabled.12"
        }, {
          dictCode: "pptm",
          dictName: "application/vnd.ms-powerpoint.presentation.macroEnabled.12"
        }, {
          dictCode: "potm",
          dictName: "application/vnd.ms-powerpoint.template.macroEnabled.12"
        }, {
          dictCode: "ppsm",
          dictName: "application/vnd.ms-powerpoint.slideshow.macroEnabled.12"
        }, {
          dictCode: "ps",
          dictName: "application/postscript"
        }, {
          dictCode: "qt",
          dictName: "video/quicktime"
        }, {
          dictCode: "qti",
          dictName: "image/x-quicktime"
        }, {
          dictCode: "qtif",
          dictName: "image/x-quicktime"
        }, {
          dictCode: "ra",
          dictName: "audio/x-pn-realaudio"
        }, {
          dictCode: "ram",
          dictName: "audio/x-pn-realaudio"
        }, {
          dictCode: "ras",
          dictName: "image/x-cmu-raster"
        }, {
          dictCode: "rdf",
          dictName: "application/rdf+xml"
        }, {
          dictCode: "rgb",
          dictName: "image/x-rgb"
        }, {
          dictCode: "rm",
          dictName: "application/vnd.rn-realmedia"
        }, {
          dictCode: "roff",
          dictName: "application/x-troff"
        }, {
          dictCode: "rtf",
          dictName: "text/rtf"
        }, {
          dictCode: "rtx",
          dictName: "text/richtext"
        }, {
          dictCode: "sgm",
          dictName: "text/sgml"
        }, {
          dictCode: "sgml",
          dictName: "text/sgml"
        }, {
          dictCode: "sh",
          dictName: "application/x-sh"
        }, {
          dictCode: "shar",
          dictName: "application/x-shar"
        }, {
          dictCode: "silo",
          dictName: "model/mesh"
        }, {
          dictCode: "sit",
          dictName: "application/x-stuffit"
        }, {
          dictCode: "skd",
          dictName: "application/x-koan"
        }, {
          dictCode: "skm",
          dictName: "application/x-koan"
        }, {
          dictCode: "skp",
          dictName: "application/x-koan"
        }, {
          dictCode: "skt",
          dictName: "application/x-koan"
        }, {
          dictCode: "smi",
          dictName: "application/smil"
        }, {
          dictCode: "smil",
          dictName: "application/smil"
        }, {
          dictCode: "snd",
          dictName: "audio/basic"
        }, {
          dictCode: "so",
          dictName: "application/octet-stream"
        }, {
          dictCode: "spl",
          dictName: "application/x-futuresplash"
        }, {
          dictCode: "src",
          dictName: "application/x-wais-source"
        }, {
          dictCode: "sv4cpio",
          dictName: "application/x-sv4cpio"
        }, {
          dictCode: "sv4crc",
          dictName: "application/x-sv4crc"
        }, {
          dictCode: "svg",
          dictName: "image/svg+xml"
        }, {
          dictCode: "swf",
          dictName: "application/x-shockwave-flash"
        }, {
          dictCode: "t",
          dictName: "application/x-troff"
        }, {
          dictCode: "tar",
          dictName: "application/x-tar"
        }, {
          dictCode: "tcl",
          dictName: "application/x-tcl"
        }, {
          dictCode: "tex",
          dictName: "application/x-tex"
        }, {
          dictCode: "texi",
          dictName: "application/x-texinfo"
        }, {
          dictCode: "texinfo",
          dictName: "application/x-texinfo"
        }, {
          dictCode: "tif",
          dictName: "image/tiff"
        }, {
          dictCode: "tiff",
          dictName: "image/tiff"
        }, {
          dictCode: "tr",
          dictName: "application/x-troff"
        }, {
          dictCode: "tsv",
          dictName: "text/tab-separated-values"
        }, {
          dictCode: "txt",
          dictName: "text/plain"
        }, {
          dictCode: "ustar",
          dictName: "application/x-ustar"
        }, {
          dictCode: "vcd",
          dictName: "application/x-cdlink"
        }, {
          dictCode: "vrml",
          dictName: "model/vrml"
        }, {
          dictCode: "vxml",
          dictName: "application/voicexml+xml"
        }, {
          dictCode: "wav",
          dictName: "audio/x-wav"
        }, {
          dictCode: "wbmp",
          dictName: "image/vnd.wap.wbmp"
        }, {
          dictCode: "wbmxl",
          dictName: "application/vnd.wap.wbxml"
        }, {
          dictCode: "wml",
          dictName: "text/vnd.wap.wml"
        }, {
          dictCode: "wmlc",
          dictName: "application/vnd.wap.wmlc"
        }, {
          dictCode: "wmls",
          dictName: "text/vnd.wap.wmlscript"
        }, {
          dictCode: "wmlsc",
          dictName: "application/vnd.wap.wmlscriptc"
        }, {
          dictCode: "wrl",
          dictName: "model/vrml"
        }, {
          dictCode: "xbm",
          dictName: "image/x-xbitmap"
        }, {
          dictCode: "xht",
          dictName: "application/xhtml+xml"
        }, {
          dictCode: "xhtml",
          dictName: "application/xhtml+xml"
        }, {
          dictCode: "xls",
          dictName: "application/vnd.ms-excel"
        }, {
          dictCode: "xml",
          dictName: "application/xml"
        }, {
          dictCode: "xpm",
          dictName: "image/x-xpixmap"
        }, {
          dictCode: "xsl",
          dictName: "application/xml"
        }, {
          dictCode: "xlsx",
          dictName: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }, {
          dictCode: "xltx",
          dictName: "application/vnd.openxmlformats-officedocument.spreadsheetml.template"
        }, {
          dictCode: "xlsm",
          dictName: "application/vnd.ms-excel.sheet.macroEnabled.12"
        }, {
          dictCode: "xltm",
          dictName: "application/vnd.ms-excel.template.macroEnabled.12"
        }, {
          dictCode: "xlam",
          dictName: "application/vnd.ms-excel.addin.macroEnabled.12"
        }, {
          dictCode: "xlsb",
          dictName: "application/vnd.ms-excel.sheet.binary.macroEnabled.12"
        }, {
          dictCode: "xslt",
          dictName: "application/xslt+xml"
        }, {
          dictCode: "xul",
          dictName: "application/vnd.mozilla.xul+xml"
        }, {
          dictCode: "xwd",
          dictName: "image/x-xwindowdump"
        }, {
          dictCode: "xyz",
          dictName: "chemical/x-xyz"
        }, {
          dictCode: "zip",
          dictName: "application/zip"
        }]
      }
    };
  }
};