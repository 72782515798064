var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mapBackgroundImg",
      staticStyle: { color: "white" },
      attrs: { id: "map-container" },
    },
    [
      _c("div", { staticClass: "containerHeaderBox" }, [
        _c(
          "div",
          { staticClass: "headerLeft" },
          [
            _c(
              "el-radio-group",
              {
                staticClass: "radio_group",
                attrs: { fill: "#00579c" },
                on: { change: _vm.handleRadio },
                model: {
                  value: _vm.tabPosition,
                  callback: function ($$v) {
                    _vm.tabPosition = $$v
                  },
                  expression: "tabPosition",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "day" } }, [
                  _vm._v("今天"),
                ]),
                _c(
                  "el-radio-button",
                  { staticStyle: { margin: "0px" }, attrs: { label: "week" } },
                  [_vm._v("本周")]
                ),
                _c("el-radio-button", { attrs: { label: "month" } }, [
                  _vm._v("本月"),
                ]),
                _c(
                  "el-radio-button",
                  { staticStyle: { margin: "0px" }, attrs: { label: "year" } },
                  [_vm._v("今年")]
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticStyle: {
                  margin: "0 3px",
                  color: "#f9f9f9",
                  opacity: "0.5",
                },
              },
              [_vm._v("|")]
            ),
            _c(
              "el-radio-group",
              {
                staticClass: "radio_group",
                attrs: { fill: "#00579c" },
                on: { change: _vm.riskTypeC },
                model: {
                  value: _vm.riskType,
                  callback: function ($$v) {
                    _vm.riskType = $$v
                  },
                  expression: "riskType",
                },
              },
              [
                _c(
                  "el-radio-button",
                  {
                    staticStyle: { "margin-left": "2px" },
                    attrs: { label: "" },
                  },
                  [_vm._v("交&商")]
                ),
                _c(
                  "el-radio-button",
                  { staticStyle: { margin: "0 2px" }, attrs: { label: "0" } },
                  [_vm._v("交强险")]
                ),
                _c("el-radio-button", { attrs: { label: "1" } }, [
                  _vm._v("商业险"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "headerTitle" }, [_vm._v(_vm._s(_vm.title))]),
        _c(
          "div",
          { staticClass: "headerRight" },
          [
            _c(
              "span",
              { staticStyle: { "margin-right": "15px" } },
              [
                _vm._v("当前位置： "),
                _c(
                  "el-link",
                  {
                    staticStyle: {
                      "font-weight": "normal",
                      color: "#f7f7f7",
                      "margin-bottom": "2px",
                    },
                    attrs: { underline: false },
                    on: {
                      click: function ($event) {
                        return _vm.switchMap(_vm.defaultCity)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.defaultCity))]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.currentCity,
                        expression: "currentCity",
                      },
                    ],
                  },
                  [_vm._v(" | ")]
                ),
                _c(
                  "el-link",
                  {
                    staticStyle: {
                      "font-weight": "normal",
                      color: "#f7f7f7",
                      "margin-bottom": "2px",
                    },
                    attrs: { underline: false },
                    on: {
                      click: function ($event) {
                        return _vm.switchMap(_vm.currentCity)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.currentCity))]
                ),
              ],
              1
            ),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: "" + (!_vm.isFullscreen ? "全屏" : "退出全屏"),
                  effect: "dark",
                  placement: "bottom",
                },
              },
              [
                _c("svg-icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isFullscreen,
                      expression: "!isFullscreen",
                    },
                  ],
                  attrs: {
                    "icon-class": _vm.isFullscreen
                      ? "exit-fullscreen"
                      : "fullscreen",
                  },
                  on: { click: _vm.switchScreen },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticStyle: { position: "relative" },
          style: {
            "overflow-y": "auto",
            padding: "10px",
            height: _vm.containerH,
            "overflow-y": "auto",
          },
        },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                width: "100%",
                position: "absolute",
                top: "0",
                left: "0%",
                "z-index": "1",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "40%", margin: "20px auto" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            opacity: "0.8",
                            color: "#9bb6df",
                          },
                        },
                        [_vm._v("出单总量")]
                      ),
                      _c("div", { staticClass: "QuantityBox" }, [
                        _c(
                          "div",
                          {
                            ref: "totalQuantitysNum",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.totalQuantity
                                    ? _vm.totalQuantity.toLocaleString()
                                    : 0
                                ) +
                                " "
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "addQuantity animated",
                                class: _vm.animateTime
                                  ? "fadeInUp"
                                  : "fadeOutUp",
                                style: { left: _vm.totalQuantitysleft },
                              },
                              [_vm._v("+" + _vm._s(_vm.oldQuantity))]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            opacity: "0.8",
                            color: "#9bb6df",
                          },
                        },
                        [_vm._v("总保费(元)")]
                      ),
                      _c("div", { staticClass: "QuantityBox" }, [
                        _c(
                          "div",
                          {
                            ref: "totalPremiumsNum",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.totalPremium
                                    ? _vm.totalPremium.toLocaleString("zh", {
                                        style: "currency",
                                        currency: "CNY",
                                        minimumFractionDigits: 2,
                                      })
                                    : "0.00"
                                ) +
                                " "
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "addQuantity animated",
                                class: _vm.animateTime2
                                  ? "fadeInUp"
                                  : "fadeOutUp",
                                style: { left: _vm.totalPremiumsNumleft },
                              },
                              [_vm._v("+" + _vm._s(_vm.oldPremium))]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-row",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-col",
                { staticStyle: { "min-width": "300px" }, attrs: { span: 5 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        height: "300px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [
                            _c("span", { staticClass: "headerLine" }),
                            _vm._v("总出单量走势"),
                          ]
                        ),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c("line-smooth-chart", {
                              attrs: {
                                xAxis: _vm.xlineData,
                                series: _vm.seriesLineData,
                                height: "260px",
                                "series-name": "出单量",
                                "filter-type": _vm.tabPosition,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        "margin-top": "30px",
                        height: "300px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [
                            _c("span", { staticClass: "headerLine" }),
                            _vm._v("总保费走势"),
                          ]
                        ),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c("line-smooth-chart", {
                              attrs: {
                                xAxis: _vm.xlineData,
                                series: _vm.seriesLineData1,
                                height: "260px",
                                "series-name": "出单量",
                                "filter-type": _vm.tabPosition,
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "grid-content bg-purple-dark",
                      staticStyle: { color: "white," },
                    },
                    [
                      _c("ChinaMapChart", {
                        ref: "chinaMap",
                        attrs: {
                          id: _vm.mapId,
                          "chart-data": _vm.mapData,
                          width: _vm.mapW,
                          height: _vm.mapH,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    "min-width": "300px",
                    position: "absolute",
                    right: "0px",
                    top: "0",
                  },
                  attrs: { span: 5 },
                },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        height: "300px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [
                            _c("span", { staticClass: "headerLine" }),
                            _vm._v(" 出单保费TOP5"),
                          ]
                        ),
                        !_vm._.isEmpty(_vm.premiumData)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 5px",
                                  "font-size": "12px",
                                  "overflow-y": "auto",
                                  "max-height": "260px",
                                },
                              },
                              _vm._l(_vm.premiumData, function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        padding: "5px 0",
                                        "border-bottom": "1px dashed #294664",
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.salesOrgName)),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.premium
                                              ? item.premium.toLocaleString(
                                                  "zh",
                                                  {
                                                    style: "currency",
                                                    currency: "CNY",
                                                    minimumFractionDigits: 2,
                                                  }
                                                )
                                              : "0.00"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "5px",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(" 暂无网点出单数据！ ")]
                            ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        "margin-top": "30px",
                        height: "300px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [
                            _c("span", { staticClass: "headerLine" }),
                            _vm._v("出单量TOP5"),
                          ]
                        ),
                        !_vm._.isEmpty(_vm.quantityData)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 5px",
                                  "font-size": "12px",
                                  "overflow-y": "auto",
                                  "max-height": "260px",
                                },
                              },
                              _vm._l(_vm.quantityData, function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        padding: "5px 0",
                                        "border-bottom": "1px dashed #294664",
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.salesOrgName)),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            item.quantity
                                              ? item.quantity.toLocaleString()
                                              : "0"
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 5px",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(" 暂无网点出单数据！ ")]
                            ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "box-card",
              staticStyle: {
                background: "#163456",
                border: "1px solid #173551",
                padding: "5px",
                "margin-top": "30px",
                "border-radius": "4px",
              },
            },
            [
              _vm._m(0),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-left": "10px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("pie-charts", {
                        attrs: {
                          legendColor: "#fff",
                          "chart-data": _vm.quantityRateData,
                          height: "260px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-left": "10px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("pie-charts", {
                        attrs: {
                          legendColor: "#fff",
                          "chart-data": _vm.premiumRateData,
                          height: "260px",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          "font-weight": "700",
          padding: "5px",
          "line-height": "30px",
          color: "white",
          "font-size": "14px",
          display: "flex",
          "align-items": "center",
        },
      },
      [_c("span", { staticClass: "headerLine" }), _vm._v("保司出单业务")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }