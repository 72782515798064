//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {
        applyIds: '',
        auditStatus: 'AUDIT_SUCCEEDED',
        rejectCause: ''
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  created: function created() {},
  methods: {
    initData: function initData() {
      //初始化数据
      this.form = {
        applyIds: '',
        auditStatus: 'AUDIT_SUCCEEDED',
        rejectCause: ''
      };
    },
    // 保存数据
    save: function save() {
      if (this.form.auditStatus == 'PENDING_AUDIT') {
        if (!this.form.rejectCause) {
          this.$message.warning('请输入驳回原因');
          return;
        }
        ;
      }
      ;
      // this.formLoading = true;
      // business.update(this.form).then(res => {
      //     this.formLoading = false;
      //     if (res.code === 200) {
      //         this.$message.success(res.msg)
      this.closePage(false);
      //     }
      // }).catch(err => {
      //     this.formLoading = false;
      // });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$emit('closePage', boolean);
    },
    loadData: function loadData() {
      //加载数据
    }
  }
};