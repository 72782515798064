import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestOrg from '@/api/orgChildren'; //获取机构
import resignation from '@/api/resignation'; //获取离职
import storageRequest from '@/api/storage'; //上传文件
import secondEntryApi from "@/api/secondEntry"; //二次入司接口api
import { downFile } from "@/utils/common";
export default {
  // mixins: [dict],
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: {}
    },
    insCorpItem: {
      type: Object,
      default: {}
    },
    selectOptions: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      hidden: true,
      activeName: '',
      options: [],
      orgData: [],
      fileList: [],
      form: {
        fullName: "",
        applyDate: "",
        userName: '',
        applyReason: ''
      },
      highlightCurrent: true,
      formData: '',
      listLoading: false,
      defaultProps: {
        children: 'children',
        label: 'fullName'
      },
      rules: {
        fullName: [{
          required: true,
          message: '请选择所属机构',
          trigger: ["change"]
        }],
        userId: [{
          required: true,
          message: '请选择员工姓名',
          trigger: 'blur'
        }],
        applyReason: [{
          required: true,
          message: '请输入申请原因',
          trigger: 'blur'
        }],
        applyDate: [{
          required: true,
          message: '请选择申请日期',
          trigger: 'blur'
        }]
      },
      fileType: [] //文件列表
    };
  },

  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        console.log(newValue);
      }
    }
  },
  mounted: function mounted() {
    this.getOrgTree();
  },
  methods: {
    handle: function handle(row) {
      if (row.id !== '') {
        downFile(row.fileUrl, row.fileName);
      }
    },
    getUserId: function getUserId(val) {
      //获取员工id
      var nameObj = _.find(this.selectOptions, function (o) {
        return o.userId == val;
      });
      this.form.employeeAccount = nameObj.account;
      this.form.employeeId = nameObj.employeeId;
      this.form.employeeName = nameObj.userName;
      this.form.employeeOrgCode = nameObj.dealerCode;
      this.form.employeeOrgName = nameObj.dealerName;
      this.form.userId = nameObj.userId;
    },
    dataFilterS: function dataFilterS(val) {
      //机构树查询
      this.$refs.trees.filter(val);
    },
    filterNodeS: function filterNodeS(value, data) {
      //机构树查询
      if (!value) return true;
      return data.fullName.indexOf(value) !== -1;
    },
    getOrgTree: function getOrgTree() {
      var _this = this;
      //获取机构数
      var parentId = 0; //0为管理员
      requestOrg.getTree(parentId).then(function (res) {
        if (res.code === 200) {
          _this.orgData = [res.data];
        }
      });
    },
    handleNodeClickS: function handleNodeClickS(data, node, target) {
      var _this2 = this;
      //节点点击事件
      this.form.fullName = data.fullName;
      this.form.orgCode = data.orgCode;
      this.$refs.orgSelect1.blur();
      this.$forceUpdate();
      // let orgCode = {
      //     orgCode: data.orgCode
      // }
      this.form.userName = '';
      resignation.getDetail(data.orgCode).then(function (res) {
        if (res.code === 200) {
          _this2.options = res.data;
        }
      });
    },
    ondelete: function ondelete(index) {
      //删除文件
      this.fileList.splice(index, 1);
    },
    uploadFile: function uploadFile(param) {
      var _this3 = this;
      //上传文件
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this3.$message({
            message: res.msg,
            type: 'success'
          });
          var obj = {
            employeeAgainEntryId: _this3.form.employeeId,
            //员工id
            fileName: param.file.name,
            //	文件名
            fileId: res.data.fileStorageId,
            //文件存储id
            name: param.file.name,
            //	文件名
            fileStorageId: res.data.fileStorageId,
            //文件存储id
            infoId: '',
            //
            status: '上传成功',
            infoType: 'QT' //文件类型,可用值:HT,ZYZ,LZFJ,YHK,SFZ,QT
          };

          _this3.fileList.push(obj);
        }
      });
    },
    save: function save() {
      var _this4 = this;
      //保存数据
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this4.form.employeeInfoParams = _this4.fileList;
          secondEntryApi.employeeAgainApply(_this4.form.employeeId, _this4.form).then(function (res) {
            if (res.code === 200) {
              _this4.closePage();
              _this4.form = {};
              _this4.fileList = [];
            }
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
      this.$refs.detail.resetFields();
    }
  }
};