import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import Pagination from '@/components/Pagination';
import request from '@/api/dataCenter';
import dictRequest from '@/api/dictionary';
import { getSupplyers, character, commonExport, getCurrentDate, getBeforeDate } from '@/utils/common';
export default {
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight],
  data: function data() {
    return {
      drawer: false,
      searchForm: {
        createdTimeStart: "".concat(getBeforeDate(6), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      sourceTypeList: [{
        itemCode: 'INS_CORP',
        itemName: '保司查询'
      }, {
        itemCode: 'POLICY_IDENTIFY',
        itemName: '保单识别'
      }],
      loading: false,
      supplyers: [],
      multipleSelection: [],
      riskTypeList: [],
      createdStartEndTime: [getBeforeDate(6), getCurrentDate()]
    };
  },
  created: function created() {
    var _this = this;
    // 获取险种类型
    dictRequest.getDictItemsByCode("RISK_TYPE").then(function (res) {
      if (res.data) {
        _this.riskTypeList = res.data;
      }
    });
    // 保司
    this.supplyers = getSupplyers();
    this.loadData();
  },
  methods: {
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        createdTimeStart: "".concat(getBeforeDate(6), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.createdStartEndTime = [getBeforeDate(6), getCurrentDate()];
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      this.searchData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.drawer = false;
      var postData = {};
      postData = Object.assign(postData, this.searchForm, this.listQuery);
      request.searchInsurance(postData).then(function (res) {
        _this2.loading = false;
        _this2.tableData = res.data.list;
        _this2.total = res.data.page.recordsTotal;
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '在保公库',
        exportUrl: '/vi-core-service/insurance/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 列表行选择事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 删除
    batchDelete: function batchDelete(item) {
      var _this3 = this;
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.id);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'id');
        }
        ;
      }
      ;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        request.batchDeleteInsurance(ids).then(function (res) {
          if (res.code === 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
          ;
        });
      });
    },
    // 输入的车架号中的字母自动转大写，并去除i，o，q
    checkValue: function checkValue(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    // 日期值变化监听
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    }
  }
};