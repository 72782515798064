import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import areaStackGradient from './components/areaStackGradient.vue';
import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import pieDoughnut from './components/pieDoughnut';
import mapStatisticsRequest from '@/api/mapStatistics.js';
import OrgSelect from '@/components/OrgSelect';
import OemSelect from '@/components/OemSelect';
import dictionary from '@/api/dictionary';
import { getCurrentDate, getBeforeDate, getLastMonth, getUserOrgInfo, getUserOrgInfoDealerOem } from '@/utils/common';
import DatePicker from '@/components/DatePicker';
import dayjs from 'dayjs';
export default {
  name: "BusinessStatisticsBoard",
  mixins: [initHeight, dict],
  components: {
    areaStackGradient: areaStackGradient,
    OrgSelect: OrgSelect,
    pieDoughnut: pieDoughnut,
    OemSelect: OemSelect,
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          var newDate = new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000 * 1;
          //若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        }
      },
      orgData: {},
      loading: false,
      tableDataObj: null,
      tableData: [],
      searchForm: {
        startDate: '',
        endDate: '',
        // startDate: getBeforeDate(30),
        // endDate: getCurrentDate(),
        sourceList: ['ADD', 'IMPORT', 'INSURED'],
        riskTypeList: ['BZ', 'BS', 'ACCIDENT']
      },
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      startEndTime: [],
      filterType: 'week',
      quantityLineData: [],
      premiumLineData: [],
      quantitySeriesLineData: {
        name: '出单',
        data: []
      },
      premiumSeriesLineData: {
        name: '保费',
        data: []
      },
      containerH: '100%',
      tabDate: 'InsuredTime',
      tabFilter: 'PolicyQuantity',
      premium: 0.00,
      quantity: 0.00,
      toDayPremium: 0.00,
      toDayQuantity: 0.00,
      days: 30,
      bizType: localStorage.getItem('bizType') || '',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      PolicySourceList: [],
      //保单来源
      timeout2: null,
      vehicleStampList: []
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;
    this.dictSource['CAR_STATUS'].forEach(function (item) {
      return _this.vehicleStampList.push(item.dictCode);
    });
    this.vehicleStampList.push(null);
    this.getPolicySourceByCode();
    var self = this;
    self.containerH = document.body.clientHeight - 139 + 'px';
    var _orgInfo = getUserOrgInfo();
    if (_orgInfo && _orgInfo.organizationId) {
      this.searchForm.orgCode = _orgInfo.orgCode;
      this.orgData.salesOrgName = _orgInfo.orgCode;
      this.orgData.salesOrgName = _orgInfo.orgName;
    }
    if (this.bizType === "DEALER") {
      this.initData();
    }
    this.selectFilterType(this.filterType);
    this.loadData();
  },
  methods: {
    // 车辆标识
    vehicleStampChange: function vehicleStampChange(val) {
      if (val.length > 0) {
        this.searchForm.selectVehicleStamp = true;
      } else {
        this.searchForm.selectVehicleStamp = false;
      }
    },
    // 获取保单来源枚举
    getPolicySourceByCode: function getPolicySourceByCode() {
      var _this2 = this;
      dictionary.getDictItemsByCode('SOURCE').then(function (res) {
        if (res.code === 200) {
          _this2.PolicySourceList = res.data ? res.data : [];
        } else {
          _this2.PolicySourceList = [];
        }
      });
    },
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    //切换机构
    nodeClick: function nodeClick(val) {
      console.log(val);
      if (this.bizType === "BROKER") {
        this.searchForm.orgCode = val.salesOrgCode;
      }
      if (this.bizType === "OEM") {
        this.searchForm.orgCode = val.salesOrgCode;
        this.searchForm.areaType = val.salesOrgType;
      }
      // this.loadData();
    },
    searchData: function searchData() {
      this.loadData();
    },
    //切换保单来源
    PolicySourceChange: function PolicySourceChange(val) {
      if (!val || val.length === 0) {
        this.$message.warning('最少选择一个保单来源');
        return;
      }
      // this.loadData();
    },
    //切换险种
    riskTypeC: function riskTypeC(val) {
      if (!val || val.length === 0) {
        this.$message.warning('最少选择一个险种');
        return;
      }
      // this.loadData();
    },
    // 今日出单量和保费
    getQueryStatsTrendChartByTimeToday: function getQueryStatsTrendChartByTimeToday() {
      var _this3 = this;
      var data = {
        areaType: this.searchForm.areaType,
        //区域类型
        orgCode: this.searchForm.orgCode,
        //组织机构编码
        sourceList: this.searchForm.sourceList && this.searchForm.sourceList.length !== 0 ? this.searchForm.sourceList : null,
        //保单来源
        riskTypeList: this.searchForm.riskTypeList && this.searchForm.riskTypeList.length !== 0 ? this.searchForm.riskTypeList : null,
        //统计险种列表
        statsDateDimension: 'HOUR',
        //时间维度类型
        startDate: getCurrentDate() + ' 00:00:00',
        endDate: getCurrentDate() + ' 23:59:59',
        vehicleStampList: this.searchForm.vehicleStampList,
        selectVehicleStamp: this.searchForm.selectVehicleStamp
      };
      mapStatisticsRequest.getQueryOrganStatsTrendChart(data).then(function (res) {
        if (res.code === 200) {
          var resList = res.data ? res.data : [];
          var _iterator = _createForOfIteratorHelper(resList),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              if (i.yaxisName.indexOf('出单') !== -1) {
                _this3.toDayQuantity = i.totalSeries || 0;
              }
              if (i.yaxisName.indexOf('保费') !== -1) {
                _this3.toDayPremium = i.totalSeries || 0;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
      });
    },
    // 今日出单量和保费
    getQueryOrganStatsTrendChartByTime: function getQueryOrganStatsTrendChartByTime() {
      var _this4 = this;
      var data = {
        areaType: this.searchForm.areaType,
        //区域类型
        orgCode: this.searchForm.orgCode,
        //组织机构编码
        sourceList: this.searchForm.sourceList && this.searchForm.sourceList.length !== 0 ? this.searchForm.sourceList : null,
        //保单来源
        riskTypeList: this.searchForm.riskTypeList && this.searchForm.riskTypeList.length !== 0 ? this.searchForm.riskTypeList : null,
        //统计险种列表
        statsDateDimension: 'DAY',
        //时间维度类型
        startDate: this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null,
        endDate: this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null,
        vehicleStampList: this.searchForm.vehicleStampList,
        selectVehicleStamp: this.searchForm.selectVehicleStamp
      };
      mapStatisticsRequest.getQueryOrganStatsTrendChart(data).then(function (res) {
        if (res.code === 200) {
          var resList = res.data ? res.data : [];
          var _iterator2 = _createForOfIteratorHelper(resList),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var i = _step2.value;
              if (i.yaxisName.indexOf('出单') !== -1) {
                _this4.quantity = i.totalSeries || 0;
                _this4.quantityLineData = i.xaxis || [];
                //今天或昨天，默认设置为5段
                var _day = _this4.quantityLineData[0];
                if (_this4.days == 0 || _this4.days == 1) {
                  _this4.quantityLineData = [' ', ' ', _day, ' ', ' '];
                }
                _this4.quantitySeriesLineData.data = i.series || [];
                //今天或昨天，默认设置为5段
                var _data = _this4.quantitySeriesLineData.data[0];
                if (_this4.days == 0 || _this4.days == 1) {
                  _this4.quantitySeriesLineData.data = [' ', ' ', _data, ' ', ' '];
                }
              }
              if (i.yaxisName.indexOf('保费') !== -1) {
                _this4.premium = i.totalSeries || 0;
                _this4.premiumLineData = i.xaxis || [];
                //今天或昨天，默认设置为5段
                var _day2 = _this4.premiumLineData[0];
                if (_this4.days == 0 || _this4.days == 1) {
                  _this4.premiumLineData = [' ', ' ', _day2, ' ', ' '];
                }
                _this4.premiumSeriesLineData.data = i.series || [];
                //今天或昨天，默认设置为5段
                var _data2 = _this4.premiumSeriesLineData.data[0];
                if (_this4.days == 0 || _this4.days == 1) {
                  _this4.premiumSeriesLineData.data = [' ', ' ', _data2, ' ', ' '];
                }
              }
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      });
    },
    // 保司排行
    getQueryOrganStatsInsCorpRanking: function getQueryOrganStatsInsCorpRanking() {
      var _this5 = this;
      var data = {
        areaType: this.searchForm.areaType,
        //区域类型
        orgCode: this.searchForm.orgCode,
        //组织机构编码
        sourceList: this.searchForm.sourceList && this.searchForm.sourceList.length !== 0 ? this.searchForm.sourceList : null,
        //保单来源
        riskTypeList: this.searchForm.riskTypeList && this.searchForm.riskTypeList.length !== 0 ? this.searchForm.riskTypeList : null,
        //统计险种列表
        statsDateDimension: 'DAY',
        //时间维度类型
        startDate: this.startEndTime[0] && this.startEndTime[0].split(' ')[0] + ' 00:00:00' || null,
        endDate: this.startEndTime[1] && this.startEndTime[1].split(' ')[0] + ' 23:59:59' || null,
        vehicleStampList: this.searchForm.vehicleStampList,
        selectVehicleStamp: this.searchForm.selectVehicleStamp
      };
      mapStatisticsRequest.getQueryOrganStatsInsCorpRanking(100, data).then(function (res) {
        if (res.code === 200) {
          var _tableObj = res.data ? res.data : null;
          _this5.tableDataObj = _tableObj;
          _this5.changeTabFilter(_this5.tabFilter);
        }
      });
    },
    loadData: function loadData() {
      var _this6 = this;
      // return
      // console.log('环境', process.env.VUE_APP_FLAG)
      // if (process.env.VUE_APP_FLAG && process.env.VUE_APP_FLAG === 'prd' || process.env.VUE_APP_FLAG === 'yfprd') return
      // 防抖
      if (this.timeout2) {
        clearTimeout(this.timeout2);
      }
      this.timeout2 = setTimeout(function () {
        _this6.loadData1();
      }, 500);
    },
    //获取业务统计数据
    loadData1: function loadData1() {
      this.tableData = [];
      this.tableDataObj = null;
      if (!_.isEmpty(this.vehicleStampList)) {
        _.remove(this.vehicleStampList, function (o) {
          return !o;
        });
        this.searchForm.vehicleStampList = this.vehicleStampList.toString();
      } else {
        this.searchForm.vehicleStampList = null;
      }
      // 通过时间筛选
      this.getQueryOrganStatsTrendChartByTime();
      // 今日出单量和保费
      this.getQueryStatsTrendChartByTimeToday();
      // 按保司筛选
      this.getQueryOrganStatsInsCorpRanking();
    },
    getPickersDate: function getPickersDate(name, val) {
      if (val && val.length > 1) {
        this[name] = val;
      } else {
        this[name] = [];
      }
    },
    //日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm.startDate = null;
        this.searchForm.endDate = null;
      }
      // this.loadData();
    },
    //选择查询时段的类型
    changeTabDate: function changeTabDate(val) {},
    //选择查询时段的类型
    changeTabFilter: function changeTabFilter(val) {
      if (val) {
        if (this.tableDataObj) {
          if (val == 'PolicyQuantity') {
            this.tableData = _.orderBy(this.tableDataObj['countRanking'], ['countRanking'], ['desc']);
          } else {
            this.tableData = _.orderBy(this.tableDataObj['premiumRanking'], ['premiumRanking'], ['desc']);
          }
          if (this.tableData) {
            this.tableData.forEach(function (item) {
              try {
                item.icon = require("@/assets/images/company_logo/".concat(_.toLower(item.insCorpCode), ".png"));
              } catch (error) {
                item.icon = require("@/assets/images/company_logo/default.png");
              }
            });
          }
        } else {
          this.tableData = [];
        }
      } else {
        this.tableData = [];
      }
    },
    //选择查询时段的类型
    selectFilterType: function selectFilterType(val) {
      // let days = 0;
      this.searchForm.endDate = getBeforeDate(1);
      switch (val) {
        case 'day':
          this.days = 0;
          break;
        case 'lastDay':
          this.days = 1;
          this.searchForm.endDate = getBeforeDate(1);
          break;
        case 'week':
          this.days = 7;
          break;
        case 'month':
        case 'custom':
          this.days = 30;
          break;
        case 'year':
          this.days = 365;
          break;
        case 'lastMonth':
          this.days = 'special';
          break;
        case 'currentMonth':
          this.days = 'currentMonth';
          break;
      }
      ;
      if (this.days === 'currentMonth') {
        this.searchForm.startDate = dayjs().startOf('M').format("YYYY-MM-DD") + " 00:00:00";
        this.searchForm.endDate = dayjs().endOf('M').format("YYYY-MM-DD") + " 23:59:59";
        this.startEndTime = [this.searchForm.startDate, this.searchForm.endDate];
      } else if (this.days === 'special') {
        this.startEndTime = getLastMonth();
        this.searchForm.startDate = getLastMonth()[0];
        this.searchForm.endDate = getLastMonth()[1];
      } else {
        this.searchForm.startDate = getBeforeDate(this.days);
        this.startEndTime = [this.searchForm.startDate, this.searchForm.endDate];
      }
      // this.loadData();
    }
  }
};