import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: function data() {
    return {
      listData: [],
      // loading: true,
      loading: false
    };
  },
  created: function created() {
    this.loadData();
  },
  methods: {
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    viewDetail: function viewDetail(item) {
      //查看详情页
      this.$router.push("/customers/detail");
    },
    loadData: function loadData() {//初始化数据列表
    }
  }
};