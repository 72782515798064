import { render, staticRenderFns } from "./messageTable.vue?vue&type=template&id=03504d14&scoped=true&"
import script from "./messageTable.vue?vue&type=script&lang=js&"
export * from "./messageTable.vue?vue&type=script&lang=js&"
import style0 from "./messageTable.vue?vue&type=style&index=0&id=03504d14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03504d14",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03504d14')) {
      api.createRecord('03504d14', component.options)
    } else {
      api.reload('03504d14', component.options)
    }
    module.hot.accept("./messageTable.vue?vue&type=template&id=03504d14&scoped=true&", function () {
      api.rerender('03504d14', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/messagePlatform/messageTable.vue"
export default component.exports