//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SeachQuery',
  data: function data() {
    return {
      searchForm: {}
    };
  },
  methods: {
    searchData: function searchData() {
      this.$emit('searchData', this.searchForm);
    },
    resetSearch: function resetSearch() {
      this.$emit('resetSearch', {});
    },
    breakData: function breakData() {
      this.$emit('breakData');
    },
    exportTable: function exportTable() {
      this.$emit('exportTable');
    },
    showSearch: function showSearch() {
      this.$emit('exportTable');
    }
  }
};