import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import activityCenterRequest from '@/api/activityCenter';
import dictiRequest from "@/api/dictionary";
import { limitInput } from '@/utils/common';
export default {
  name: "ActivityRules",
  components: {
    Pagination: Pagination
  },
  mixins: [initHeight, dict],
  computed: {},
  watch: {
    showDialog: function showDialog(val) {
      var _this = this;
      if (val) {
        if (this.opType == 'add') {
          this.form = {
            enable: true,
            percent: true,
            required: false
          };
        } else {
          this.getDetailById();
        }
        this.$nextTick(function () {
          return _this.$refs['detail'].clearValidate();
        });
      }
    }
  },
  data: function data() {
    return {
      formLoading: false,
      searchForm: {},
      form: {},
      formRules: {
        couponRuleFactorName: {
          required: true,
          message: '请输入规则名称',
          trigger: 'blur'
        },
        couponRuleFactorCode: {
          required: true,
          message: '请输入规则编码',
          trigger: 'blur'
        },
        couponRuleFactorType: {
          required: true,
          message: '请选择界面控件类型',
          trigger: 'change'
        },
        couponRuleFactorOrder: {
          required: true,
          message: '请输入序号',
          trigger: 'blur'
        },
        couponRuleFactorDictCode: {
          required: true,
          message: '请输入界面控件内容',
          trigger: 'blur'
        }
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      opType: "",
      couponRuleFactorTypeList: [],
      couponRuleFactorId: null
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
    this.getDict();
  },
  methods: {
    onInput: function onInput(num, limit) {
      this.$forceUpdate();
      //对输入内容做精度校验
      return limitInput(num, limit);
    },
    handleSwitch: function handleSwitch(val, item) {
      var _this2 = this;
      item.enable = !val;
      this.$confirm("\u60A8\u786E\u5B9A\u8981".concat(val ? '启用' : '禁用', "\u8BE5\u89C4\u5219\uFF1F"), {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        activityCenterRequest.updateActivityRule(_objectSpread(_objectSpread({}, item), {}, {
          couponRuleFactorId: item.couponRuleFactorId,
          enable: val
        })).then(function (res) {
          if (res.code == 200) {
            _this2.$message.success(res.msg);
            _this2.loadData();
          }
        });
      }).catch(function () {});
    },
    getDetailById: function getDetailById() {
      var _this3 = this;
      this.formLoading = true;
      activityCenterRequest.getDetailByCouponRuleFactorId(this.couponRuleFactorId).then(function (res) {
        if (res.code == 200) {
          _this3.form = res.data;
        }
        _this3.formLoading = false;
      });
    },
    getDict: function getDict() {
      var _this4 = this;
      dictiRequest.getDictItemsByCode("COUPON_RULE_FACTOR_TYPE").then(function (res) {
        if (res.code == 200) {
          _this4.couponRuleFactorTypeList = res.data.map(function (item) {
            return {
              dictName: item.itemName,
              dictCode: item.itemCode
            };
          });
        }
      });
    },
    close: function close() {
      this.showDialog = false;
    },
    saveProduct: function saveProduct() {
      var _this5 = this;
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          _this5.formLoading = true;
          var req = _this5.opType == 'add' ? activityCenterRequest.createActivityRule(_this5.form) : activityCenterRequest.updateActivityRule(_objectSpread({
            couponRuleFactorId: _this5.couponRuleFactorId
          }, _this5.form));
          req.then(function (res) {
            if (res.code == 200) {
              _this5.$message.success(res.msg);
              _this5.loadData();
              _this5.showDialog = false;
            }
            _this5.formLoading = false;
          });
        }
      });
    },
    deleteRule: function deleteRule(item) {
      var _this6 = this;
      //删除
      this.$confirm("\u60A8\u786E\u5B9A\u8981\u5C06\u8BE5\u89C4\u5219\u5220\u9664\uFF1F ", {
        distinguishCancelAndClose: true,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        activityCenterRequest.deleteActivityRule(item.couponRuleFactorId).then(function (res) {
          if (res.code === 200) {
            _this6.$message({
              message: res.msg,
              type: 'success'
            });
            _this6.loadData();
          }
        });
      }).catch(function () {});
    },
    editRule: function editRule(type, item) {
      //编辑
      this.opType = type;
      this.showDialog = true;
      this.couponRuleFactorId = item.couponRuleFactorId;
    },
    createRule: function createRule(type) {
      //新增
      this.opType = type;
      this.showDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this7 = this;
      //获取列表数据
      this.loading = true;
      this.showImportDialog = false;
      this.showDialog = false;
      activityCenterRequest.getActivityRuleList(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery)).then(function (res) {
        if (res.code === 200) {
          _this7.tableData = res.data.list;
          _this7.loading = false;
          _this7.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this7.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    }
  }
};