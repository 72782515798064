import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '../../mixins/initHeight';
import dict from '../../mixins/dict';
import Pagination from '@/components/Pagination';
import sysSetting from '@/api/sysSetting';
import insuranceManagement from '@/api/insuranceManagement';
import OemSelect from '@/components/OemSelect';
import InsuranceManaAdd from './insuranceManaAdd';
import { commonExport } from '@/utils/common';
import { getSupplyers, character, getUserOrgInfoDealerOem } from "@/utils/common";
export default {
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    InsuranceManaAdd: InsuranceManaAdd
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      agreementItem: {},
      insuranceList: [],
      multipleSelection: [],
      reviewStatus: [{
        value: 'PASS',
        label: '通过'
      }, {
        value: 'NO_PASS',
        label: '不通过'
      }, {
        value: 'TO_AUDIT',
        label: '待审核'
      }],
      drawer: true,
      searchForm: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      showDialog: false,
      opType: '',
      highlightCurrent: true,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
    this.loadData();
    this.getInsuranceData();
  },
  methods: {
    initData: function initData() {
      //初始
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.salesOrgCode = orgObj.salesOrgCode;
      this.searchForm.salesOrgName = orgObj.salesOrgName;
    },
    breakData: function breakData() {
      // 刷新
      // this.resetSearch();
      this.loadData();
    },
    exportFn: function exportFn() {
      // 导出
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '批单管理',
        exportUrl: '/vi-core-service/correct/policy/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    checkPdRiskNo: function checkPdRiskNo(val) {
      //自动将业务单号转为大写(批单号)
      this.searchForm.correctNo = character(_.toUpper(val));
    },
    nodeClick: function nodeClick(val) {
      //切换机构
      this.searchForm.orgCode = val.salesOrgCode;
    },
    inputvin: function inputvin(val) {
      //输入的车架号中的字母自动转大写，并去除i，o，q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.searchForm.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    inputPlateNo: function inputPlateNo(val) {
      //输入的车牌号中的字母自动转大写
      if (!this.isNoPlateNo) {
        this.searchForm.plateNo = _.toUpper(val);
      }
    },
    //获取保司列表
    getInsuranceData: function getInsuranceData() {
      this.insuranceList = getSupplyers();
    },
    insCorpChange: function insCorpChange(val) {
      //保险公司选择
      if (!val) return;
      var obj = {};
      obj = _.find(this.insuranceList, function (item) {
        return item.code == val;
      });
      this.searchForm.insCorpName = obj.shortName;
    },
    addAgreement: function addAgreement(type) {
      //新增
      this.opType = type;
      this.$router.push({
        path: '/insuranceManagement/detail',
        query: {
          type: type
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //列表行选择事件
      this.multipleSelection = val;
    },
    removeItems: function removeItems(item) {
      var _this = this;
      //删除
      var ids = [];
      if (!_.isEmpty(item)) {
        ids.push(item.correctId);
      } else {
        if (!_.isEmpty(this.multipleSelection)) {
          ids = _.map(this.multipleSelection, 'correctId');
        }
      }
      if (_.isEmpty(ids)) return;
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        insuranceManagement.deleteData(ids).then(function (res) {
          if (res.code === 200) {
            _this.$message({
              message: res.msg,
              type: 'success'
            });
            _this.loadData();
          }
        });
      });
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '全部',
        salesOrgType: ''
      };
      this.listQuery.pageNum = 1;
      if (this.bizType === "DEALER") {
        this.initData();
      }
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    loadData: function loadData() {
      var _this2 = this;
      //获取列表数据
      this.loading = true;
      this.drawer = false;
      insuranceManagement.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this2.loading = false;
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};