import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import initHeight from '../../mixins/initHeight';
import stirtcut from './components/shirtcut.vue';
import request from '@/api/workbench';
import messageRequest from '@/api/message';
import dayjs from 'dayjs';
import { getCurrentDate, getBeforeDate, getLastMonth, getLastWeekData, getNextDate, getLastDate } from '@/utils/common';
export default {
  mixins: [initHeight],
  components: {
    stirtcut: stirtcut
  },
  data: function data() {
    return {
      showMsgDialog: false,
      tabPosition: "day",
      listData: [{
        menuList: [{
          name: "我的客户",
          icon: "peoples",
          path: "/customers/my"
        }, {
          name: "在线投保",
          icon: "form",
          path: "/proposalManage"
        }, {
          name: "保单管理",
          icon: "form",
          path: "/policyManage"
        }, {
          name: "保单录入",
          icon: "form",
          path: "/policy/add"
        }],
        menuListData: [
          // {
          //     name: "进店客户",
          //     icon: "form",
          //     path: "/entryStorRecord"
          // },
          // {
          //     name: "客户资源",
          //     icon: "peoples",
          //     path: "/customers/index"
          // },
          // {
          //     name: "对账/结账",
          //     icon: "money",
          //     path: "/brokerage"
          // },
          // {
          //     name: "机构管理",
          //     icon: "tree-table",
          //     path: "/users/organization",

          // }
        ]
      }],
      dateTime: {},
      dataIssuance: {},
      dataQuotation: {},
      showShortcut: true,
      messageTableData: [],
      notifyTableData: [],
      dialogTitle: '',
      dialogContent: '',
      dialogTime: '',
      messageReadLogId: null,
      loadingMsg: true,
      loadingNotice: true
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['msg'])), {}, {
    // 计算高度 
    scrollerHeight: function scrollerHeight() {
      return document.body.clientHeight - 90 + 'px';
    }
  }),
  mounted: function mounted() {
    this.getNotifyDataList();
    this.getMessageList();
    this.handleRadio("day");
    var roleData = JSON.parse(localStorage.getItem('roleData'));
    if (roleData) {
      var menuList = roleData;
      if (!_.isEmpty(menuList)) {
        var wk = _.find(menuList, function (menu) {
          return menu.code == "Workbench";
        });
        this.showShortcut = wk;
      }
    }
  },
  methods: {
    getDetail: function getDetail(item) {
      if (item.messageReadLogId && item.messageTitle && item.messageContentTranslated) {
        this.messageReadLogId = item.messageReadLogId;
        this.dialogTitle = item.messageTitle || '';
        this.dialogContent = item.messageContentTranslated || '';
        this.dialogTime = item.messageSendDatetime || '';
        this.showMsgDialog = true;
      }
    },
    // 关闭并标记为已读
    // closeMsgDialog() {
    //     this.showMsgDialog = false;
    //     messageRequest.viewMessageReceived(this.messageReadLogId);
    // },
    // 获取通知列表
    getNotifyDataList: function getNotifyDataList() {
      var _this = this;
      var params = {
        messageReadState: '',
        messageBusinessGroup: 'NOTICE',
        pageNum: 1,
        pageSize: 10
      };
      this.loadingNotice = true;
      messageRequest.newAnnouncementMsg(params).then(function (res) {
        _this.loadingNotice = false;
        if (res.code === 200 && res.data) {
          _this.notifyTableData = res.data.list || [];
        }
      }).catch(function (err) {
        _this.loadingNotice = false;
      });
    },
    // 获取消息列表
    getMessageList: function getMessageList() {
      var _this2 = this;
      var params = {
        messageReadState: ''
      };
      this.loadingMsg = true;
      messageRequest.getMessageReceivedList(1, 10, params).then(function (res) {
        _this2.loadingMsg = false;
        if (res.code === 200 && res.data) {
          _this2.messageTableData = res.data.list || [];
        }
      }).catch(function (err) {
        _this2.loadingMsg = false;
      });
    },
    // 公告查看全部
    notifyViewDetail: function notifyViewDetail() {
      this.$router.push('/message/view');
    },
    // 消息查看全部
    msgViewDetail: function msgViewDetail() {
      this.visible = false;
      this.$router.push('/messageReceived');
    },
    handleRadio: function handleRadio(val) {
      switch (val) {
        case "day":
          //今天
          this.dateTime.startDate = getCurrentDate() + " 00:00:00";
          this.dateTime.endDate = getCurrentDate() + " 23:59:59";
          this.dateTime.startPreDate = getBeforeDate(1) + " 00:00:00";
          this.dateTime.endPreDate = getBeforeDate(1) + " 23:59:59";
          break;
        case "yesterday":
          //昨天
          this.dateTime.startDate = getBeforeDate(1) + " 00:00:00";
          this.dateTime.endDate = getBeforeDate(1) + " 23:59:59";
          this.dateTime.startPreDate = getBeforeDate(2) + " 00:00:00";
          this.dateTime.endPreDate = getBeforeDate(2) + " 23:59:59";
          break;
        case "week":
          //本周
          this.dateTime.startDate = dayjs().day(1).format('YYYY-MM-DD') + " 00:00:00";
          this.dateTime.endDate = dayjs().day(7).format('YYYY-MM-DD') + " 23:59:59";
          this.dateTime.startPreDate = getLastWeekData()[0] + " 00:00:00";
          this.dateTime.endPreDate = getLastWeekData()[1] + " 23:59:59";
          break;
        case "lastWeek":
          //上周
          this.dateTime.startDate = getLastWeekData()[0] + " 00:00:00";
          this.dateTime.endDate = getLastWeekData()[1] + " 23:59:59";
          this.dateTime.startPreDate = getNextDate(getLastWeekData()[0], -7) + " 00:00:00";
          this.dateTime.endPreDate = getNextDate(getLastWeekData()[1], -7) + " 23:59:59";
          break;
        case "month":
          //本月                    
          this.dateTime.startDate = dayjs().date(1).format('YYYY-MM-DD') + " 00:00:00";
          this.dateTime.endDate = dayjs().date(31).format('YYYY-MM-DD') + " 23:59:59";
          this.dateTime.startPreDate = getLastMonth()[0] + " 00:00:00";
          this.dateTime.endPreDate = getLastMonth()[1] + " 23:59:59";
          break;
        case "lastMonth":
          //上月
          this.dateTime.startDate = getLastMonth()[0] + " 00:00:00";
          this.dateTime.endDate = getLastMonth()[1] + " 23:59:59";
          this.dateTime.startPreDate = getLastDate(getLastMonth()[0])[0] + " 00:00:00";
          this.dateTime.endPreDate = getLastDate(getLastMonth()[1])[1] + " 23:59:59";
          break;
      }
      this.loadBoardData(this.currentTab);
    },
    // 切换时间段
    loadBoardData: function loadBoardData() {
      var _this3 = this;
      request.getIssuanceStatistics(this.dateTime).then(function (res) {
        if (res.code === 200) {
          _this3.dataIssuance = res.data || {};
        }
      });
      request.getIssuanceQuotation(this.dateTime).then(function (res) {
        if (res.code === 200) {
          _this3.dataQuotation = res.data || {};
        }
      });
    }
  }
};