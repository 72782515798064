import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Dialog from '@/components/Dialog';
import { limitInput, getBeforeOfDate, calcBetweenMonths } from '@/utils/common';
export default {
  components: {
    Dialog: Dialog
  },
  props: {
    label: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: '800px'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    dutyList: {
      type: Array,
      required: true
    },
    checkDutyList: {
      type: Array,
      required: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    showBz: {
      type: Boolean,
      default: false
    },
    seats: {
      type: String,
      default: null
    },
    isNewAddItem: {
      type: Boolean,
      default: false
    },
    useAttribute: {
      type: String,
      default: null
    },
    vehicleType: {
      type: String,
      default: null
    },
    noMainDuty: {
      type: Array,
      required: false
    },
    mainDuty: {
      type: Array,
      required: false
    },
    isMain: {
      type: Boolean,
      default: false
    },
    custAttribute: {
      type: String,
      default: null
    },
    registerDate: {
      type: String,
      default: null
    },
    newEnergy: {
      type: Boolean,
      default: false
    },
    purchasePrice: {
      type: Number,
      default: 0
    },
    effectiveStartTime: {
      type: String,
      default: ''
    },
    setectInsuranceCompanyList: {
      type: Array,
      required: false
    },
    fuelType: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      pickerOptions: this.processMinDate(),
      checkedList: [],
      showDeviceDialog: false,
      showChargingDialog: false,
      form: {
        xzsbItems: [{
          deviceName: '',
          buyDate: '',
          madeChina: true,
          quantity: '',
          unitPrice: ''
        }],
        cdzItems: [{
          "amount": '0',
          "ssxAmount": '2000',
          "zrxAmount": '10000',
          "chargerAddress": "",
          "chargerAddressType": "CAT01",
          "chargerCode": "",
          "chargerKind": "CK01",
          "chargerModel": "",
          "chargerName": "设备1",
          "chargingPostYearLimit": '1'
        }]
      },
      rules: {
        xzsbItems: {},
        cdzItems: {}
      },
      formLoading: false,
      totalUnitPrice: 0,
      isXZSBSS: true,
      isXZCDZ: true,
      formData: [],
      currentXZSBItems: [{
        deviceName: '',
        buyDate: '',
        madeChina: true,
        quantity: '',
        unitPrice: ''
      }],
      currentXZCDZItems: [{
        "amount": '0',
        "ssxAmount": '2000',
        "zrxAmount": '10000',
        "chargerAddress": "",
        "chargerAddressType": "CAT01",
        "chargerCode": "",
        "chargerKind": "CK01",
        "chargerModel": "",
        "chargerName": "设备1",
        "chargingPostYearLimit": '1'
      }],
      isIndeterminate: false,
      checkAll: false,
      sidebarOpened: true,
      formWidth: 200,
      CHARGING_KIND: [{
        dictCode: "CK01",
        dictName: '地面充电桩'
      }, {
        dictCode: "CK02",
        dictName: '挂壁式充电桩'
      }, {
        dictCode: "CK03",
        dictName: '其他充电桩'
      }],
      CHARGING_ADDRESS_TYPE: [{
        dictCode: "CAT01",
        dictName: '地面停车场'
      }, {
        dictCode: "CAT02",
        dictName: '地面停车楼'
      }, {
        dictCode: "CAT03",
        dictName: '地下停车场'
      }],
      CHARGING_A09_AMOUNT: [{
        dictCode: "2000",
        dictName: '2000'
      }, {
        dictCode: "5000",
        dictName: '5000'
      }, {
        dictCode: "20000",
        dictName: '2万'
      }, {
        dictCode: "50000",
        dictName: '5万'
      }],
      CHARGING_B07_AMOUNT: [{
        dictCode: "10000",
        dictName: '1万'
      }, {
        dictCode: "20000",
        dictName: '2万'
      }, {
        dictCode: "30000",
        dictName: '3万'
      }, {
        dictCode: "50000",
        dictName: '5万'
      }, {
        dictCode: "100000",
        dictName: '10万'
      }, {
        dictCode: "200000",
        dictName: '20万'
      }, {
        dictCode: "300000",
        dictName: '30万'
      }, {
        dictCode: "500000",
        dictName: '50万'
      }],
      csxDefaultCoverage: 0,
      selectedCompany: [],
      canViewShareAmount: false
    };
  },
  computed: {},
  watch: {
    checkDutyList: function checkDutyList(newValue) {
      this.checkedList = [];
      this.viewDetail();
    },
    checkedList: function checkedList(newValue) {
      this.exportValue();
    },
    newEnergy: function newEnergy(newValue) {
      this.formData = _.sortBy(this.dutyList, function (o) {
        return o.riskkindCode;
      });
      this.removeRisk();
      this.resetRisks(this.formData);
      this.resetRisks(this.checkedList);
    },
    fuelType: function fuelType(newValue) {
      this.formData = _.sortBy(this.dutyList, function (o) {
        return o.riskkindCode;
      });
      this.removeRisk();
      this.resetRisks(this.formData);
      this.resetRisks(this.checkedList);
    },
    dutyList: {
      handler: function handler(newValue) {
        var _this = this;
        if (newValue) {
          setTimeout(function () {
            _this.viewDetail();
          });
        }
      },
      deep: true
    },
    useAttribute: function useAttribute(newValue) {
      this.removeRisk();
    },
    vehicleType: function vehicleType(newValue) {
      this.removeRisk();
    },
    custAttribute: function custAttribute(newValue) {
      this.removeRisk();
    },
    formData: {
      handler: function handler(newValue, oldValue) {
        if (!_.isEmpty(oldValue)) {
          if (newValue.length == oldValue.length) {
            //险别列表长度相同
            var _temp = _.find(newValue, function (item) {
              return item.check == false;
            });
            if (!_temp) {
              this.checkAll = true;
              this.isIndeterminate = false;
              //存在报过价的险别
              if (!_.isEmpty(this.checkDutyList)) {
                if (this.checkDutyList.length < this.checkedList.length) {
                  this.checkAll = true;
                  this.isIndeterminate = false;
                } else {
                  var _isMain = this.isMain;
                  var checkDutys = _.filter(this.checkDutyList, function (o) {
                    if (o.riskkindId) {
                      return _.find(newValue, function (item) {
                        return item.riskkindId == o.riskkindId;
                      });
                    } else {
                      if (_isMain) {
                        //主险
                        return o.riskkindCode == 'A' || o.riskkindCode == 'B' || o.riskkindCode == 'C' || o.riskkindCode == 'D';
                      } else {
                        return o.riskkindCode != 'A' && o.riskkindCode != 'B' && o.riskkindCode != 'C' && o.riskkindCode != 'D';
                      }
                    }
                  });
                  this.checkAll = checkDutys.length > 0 && checkDutys.length == newValue.length;
                  this.isIndeterminate = checkDutys.length != newValue.length;
                }
              }
            } else {
              //是否存在已选中的险别
              var _temp3 = _.find(newValue, function (item) {
                return item.check == true;
              });
              if (_temp3) {
                this.isIndeterminate = true;
              } else {
                this.isIndeterminate = false;
              }
            }
          } else {
            //险别列表长度不同时
            var _temp1 = _.find(oldValue, function (item) {
              return item.check == false;
            });
            var _temp2 = _.find(newValue, function (item) {
              return item.check == false;
            });
            //切换前后都有未选中的险别时
            if (!_temp1 && !_temp2) {
              this.checkAll = true;
              this.isIndeterminate = false;
            } else {
              //是否存在未选中的险别
              var _temp4 = _.find(newValue, function (item) {
                return item.check == false;
              });
              if (_temp4) {
                this.isIndeterminate = true;
              } else {
                this.isIndeterminate = false;
              }
            }
          }
        } else {
          this.isIndeterminate = false;
        }
        this.checkAll = this.isIndeterminate ? false : this.checkAll;
      },
      deep: true
    },
    effectiveStartTime: function effectiveStartTime() {
      this.resetCsxDefaultAmount();
    },
    registerDate: function registerDate() {
      this.resetCsxDefaultAmount();
    },
    purchasePrice: function purchasePrice() {
      this.resetCsxDefaultAmount();
    },
    setectInsuranceCompanyList: {
      handler: function handler(newValue, oldValue) {
        if (newValue) {
          var _val = _.filter(newValue, function (item) {
            return item.check;
          });
          this.selectedCompany = _.uniqBy(_val, 'insCorpCode');
          // 平安全国，人保(北京、深圳、重庆地区)展示共享保额 ['310100'] 追加：安徽，福建，湖南，广东，海南，江西，四川，宁夏
          // 平安全国，人保(目前深圳、上海地区)展示共享保额 ['310100']
          var _com = _.filter(this.selectedCompany, function (item) {
            return _.toLower(item.insCorpCode) == 'picc' && (['310100'].includes(item.insCityCode) || item.insCityCode.indexOf('34') == 0 || item.insCityCode.indexOf('35') == 0 || item.insCityCode.indexOf('43') == 0 || item.insCityCode.indexOf('44') == 0 || item.insCityCode.indexOf('36') == 0 || item.insCityCode.indexOf('46') == 0 || item.insCityCode.indexOf('51') == 0) || item.insCityCode.indexOf('64') == 0 || _.toLower(item.insCorpCode) == 'paic';
          });
          this.canViewShareAmount = !_.isEmpty(_com);
          // 是否展示共享保额
          if (!this.canViewShareAmount) {
            // 没有共享保额的地区，默认为false
            var _iterator = _createForOfIteratorHelper(this.formData),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var formItem = _step.value;
                // 医保外医疗费用责任险(三者险） B04
                // 医保外用药责任险（司机） C03
                // 医保外用药责任险（乘客） D03
                if (formItem.riskkindCode == 'B04' || formItem.riskkindCode == 'C03' || formItem.riskkindCode == 'D03') {
                  formItem.shareAmount = false;
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
          }
          ;
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    this.resetCsxDefaultAmount();
    if (!_.isEmpty(this.dutyList)) {
      this.formData = _.sortBy(this.dutyList, function (o) {
        return o.riskkindCode;
      });
      this.removeRisk();
    } else {
      this.formData = [];
    }
  },
  mounted: function mounted() {},
  methods: {
    // 格式化车身调整范围
    getCoverage: function getCoverage(type) {
      var amount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var coverage = 0;
      switch (type) {
        case 'max':
          coverage = parseFloat(amount) * 1.3;
          break;
        case 'min':
          coverage = parseFloat(amount) * 0.7;
          break;
        default:
          break;
      }
      return '￥' + coverage.toLocaleString();
    },
    // 计算车损折旧
    resetCsxDefaultAmount: function resetCsxDefaultAmount() {
      /**
       * 默认保额：折旧价格（不是原车价格）公式：原车价格*{1-（生效日期与注册日期之间的月数 *0.6%）}
       * 在此基础上，可上下浮动30%。
       */
      if (this.registerDate && this.effectiveStartTime) {
        var months = calcBetweenMonths(this.registerDate, this.effectiveStartTime);
        var rate = 1 - months * 0.006;
        rate = rate < 0.2 ? 0.2 : rate;
        this.csxDefaultCoverage = _.isNaN(months) ? '' : parseFloat(this.purchasePrice * rate).toFixed(0);
      } else {
        this.csxDefaultCoverage = this.purchasePrice;
      }
    },
    // 是否选择是否共享保额
    changeRadios: function changeRadios(item) {
      this.$forceUpdate();
      this.exportValue();
    },
    // 动态计算控件宽度
    calcWidth: function calcWidth() {
      var width = document.body.clientWidth;
      width = width - (this.sidebarOpened ? 210 : 54) - 30 - 20;
      var w = parseInt(width / 2);
      this.formWidth = w;
    },
    // 全选/取消全选按钮事件
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.formData.forEach(function (item) {
        return item.check = val;
      });
      this.checkedList = val ? this.formData : [];
      this.isIndeterminate = false;
      this.setDefaultValue();
      this.$forceUpdate();
    },
    // 新增设备的购买日期不能大于车辆注册日期
    processMinDate: function processMinDate() {
      var self = this;
      return {
        disabledDate: function disabledDate(time) {
          if (self.registerDate) {
            var beforeDate = getBeforeOfDate(self.registerDate, 1);
            return time.getTime() > Date.now() || time.getTime() < new Date(beforeDate).getTime();
          }
          return time.getTime() > Date.now();
        }
      };
    },
    // 遍历可以输入的下拉框，限制只能输入数字
    filterData: function filterData(val, riskkindCode, type) {
      var searchSelect = this.$refs["searchSelect_".concat(riskkindCode)];
      var limit = 10;
      if (searchSelect && searchSelect.length > 0) {
        searchSelect.forEach(function (element) {
          // 得到输入的文字
          var str = element.$data.selectedLabel;
          // 保留数字
          str = str.replace(/[^\d]/g, '');
          if (type == 'rate') {
            // 如果是百分比，则输入的最大值是100
            str = str > 100 ? 100 : str;
            limit = 3;
          }
          // 控制的js
          if (str.length > limit) {
            element.$data.selectedLabel = str.substr(0, limit);
          } else {
            element.$data.selectedLabel = str;
          }
        });
      }
    },
    // 对输入内容做精度校验
    onInput: function onInput(num, min, max, limit) {
      this.$forceUpdate();
      if (num.indexOf(',') != -1) {
        num = num.toString().replace(/,/g, '');
      }
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    },
    // 切换险别
    removeRisk: function removeRisk() {
      _.remove(this.formData, function (currentObject) {
        return currentObject.riskkindCode === 'B01' || currentObject.riskkindCode === 'B02';
      });

      // 使用性质为营运，且行驶证车辆类型H，G和B开头为条件时显示（限营运货车（含挂车））
      if (this.useAttribute && this.useAttribute == '1' && this.vehicleType) {
        var type = this.vehicleType;
        var hasCodes = _.filter(['H', 'G', 'B'], function (o) {
          return type.indexOf(o) != -1;
        });
        if (!_.isEmpty(hasCodes)) {
          if (!_.isEmpty(this.dutyList)) {
            this.formData = _.sortBy(this.dutyList, function (o) {
              return o.riskkindCode;
            });
          } else {
            this.formData = [];
          }
        }
        _.remove(this.formData, function (currentObject) {
          return currentObject.riskkindCode === 'B02';
        });
      }

      // 使用性质为非营运，且行驶证车辆类型K开头为条件时显示：附加法定节假日限额翻倍险
      if (this.useAttribute && this.useAttribute == '2' && this.custAttribute == '0' && this.vehicleType) {
        var _type = this.vehicleType;
        var _hasCodes = _.filter(['K'], function (o) {
          return _type.indexOf(o) != -1;
        });
        if (!_.isEmpty(_hasCodes)) {
          if (!_.isEmpty(this.dutyList)) {
            this.formData = _.sortBy(this.dutyList, function (o) {
              return o.riskkindCode;
            });
          } else {
            this.formData = [];
          }
        }
        _.remove(this.formData, function (currentObject) {
          return currentObject.riskkindCode === 'B01';
        });
      }
      this.resetRisks(this.formData);
      // 删除已选中但是不显示的记录
      this.removeCheckedIfNoShow();
      this.$forceUpdate();
    },
    // 获取到两个数组的交集，排除checkedList存在但formData不存在的
    removeCheckedIfNoShow: function removeCheckedIfNoShow() {
      var intersectionList = _.intersection(this.checkedList, this.formData);
      this.checkedList = intersectionList;
    },
    // 阻止触发下拉框点击事件冒泡；
    preventSuffix: function preventSuffix() {},
    // 刷新视图
    inputValue: function inputValue() {
      this.$forceUpdate();
    },
    // 删除新增设备险
    deleteXZItem: function deleteXZItem(index) {
      this.form.xzsbItems.splice(index, 1);
      if (this.form.xzsbItems.length == 0) {
        this.checkedList.forEach(function (item) {
          if (item.riskkindCode == 'A02') {
            item.insuredAmount = '';
            item.quoteDutyDevices = [];
          }
        });
        this.$forceUpdate();
        this.addXZItem();
      }
    },
    // 新增一条新增设备险
    addXZItem: function addXZItem() {
      var xzsbItem = {
        deviceName: '',
        buyDate: '',
        madeChina: true,
        quantity: '',
        unitPrice: ''
      };
      this.form.xzsbItems.push(xzsbItem);
    },
    // 新增设备险险别验证
    submit: function submit() {
      var _this2 = this;
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          if (_this2.form.xzsbItems) {
            var temp = _.cloneDeep(_this2.form.xzsbItems);
            // 遍历所选险别列表，给新增设备险赋值
            _this2.checkedList.forEach(function (item) {
              if (item.riskkindCode == 'A02') {
                item.insuredAmount = 0;
                item.quoteDutyDevices = temp;
                item.quoteDutyDevices.forEach(function (eitem) {
                  eitem.buyDate = eitem.buyDate.split(' ')[0] + " 00:00:00";
                  item.insuredAmount = parseFloat(item.insuredAmount) + parseFloat(eitem.unitPrice) * parseFloat(eitem.quantity);
                  item.insuredAmount = item.insuredAmount.toFixed(2);
                });
              }
            });
            // 校验新增设备险的保额
            var riskItem = _.find(_this2.checkedList, function (item) {
              return item.riskkindCode == 'A02';
            });
            if (riskItem && riskItem.insuredAmount > 99999999) {
              _this2.$message({
                type: 'error',
                message: '您录入的新增设备险总保额过大，请核实！如仍有疑问，请联系系统管理人员。'
              });
              return false;
            }
            _this2.$forceUpdate();
            _this2.currentXZSBItems = _.cloneDeep(_this2.form.xzsbItems);
            _this2.exportValue();
          }
          _this2.showDeviceDialog = false;
        }
      });
    },
    // 关闭
    cancel: function cancel() {
      this.showDeviceDialog = false;
      var self = this;
      setTimeout(function () {
        self.form.xzsbItems = _.cloneDeep(self.currentXZSBItems);
      }, 500);
    },
    // 添加新增设备
    addXZSBItem: function addXZSBItem(item) {
      this.showDeviceDialog = true;
      if (item.quoteDutyDevices && item.quoteDutyDevices.length > 0) {
        this.form.xzsbItems = _.cloneDeep(item.quoteDutyDevices);
      }
    },
    // 删除新增充电桩
    deleteCDZItem: function deleteCDZItem(index) {
      this.form.cdzItems.splice(index, 1);
      if (this.form.cdzItems.length == 0) {
        this.checkedList.forEach(function (item) {
          if (item.riskkindCode == 'A09' || item.riskkindCode == 'B07') {
            item.insuredAmount = '';
            item.quantity = '';
            item.quoteRiskkindChargers = [];
          }
        });
        this.$forceUpdate();
        this.addCDZItem();
      }
    },
    // 新增一条充电桩
    addCDZItem: function addCDZItem() {
      var index = this.form.cdzItems.length;
      var cdzItem = {
        "amount": '0',
        "ssxAmount": '2000',
        "zrxAmount": '10000',
        "chargerAddress": "",
        "chargerAddressType": "CAT01",
        "chargerCode": "",
        "chargerKind": "CK01",
        "chargerModel": "",
        "chargerName": "设备" + (index + 1),
        "chargingPostYearLimit": '1'
      };
      this.form.cdzItems.push(cdzItem);
    },
    // 汇总充电桩保额
    getCDZAmount: function getCDZAmount(item) {
      var amount = 0;
      var _quoteRiskkindChargers = _.cloneDeep(item.quoteRiskkindChargers);
      if (!_.isEmpty(_quoteRiskkindChargers)) {
        _quoteRiskkindChargers.forEach(function (eitem) {
          amount = amount + parseInt(item.riskkindCode == 'A09' ? eitem.ssxAmount : eitem.zrxAmount);
          eitem.amount = item.riskkindCode == 'A09' ? eitem.ssxAmount : eitem.zrxAmount;
        });
        item.quoteRiskkindChargers = _quoteRiskkindChargers;
        item.insuredAmount = amount;
        item.unitAmount = amount;
      }
      return this.formatOption(amount);
    },
    // 校验B07、A09的设备编码
    cdzB07OrA09CodingValidate: function cdzB07OrA09CodingValidate() {
      var flag = false;
      var ary = [];
      ary = this.form.cdzItems.map(function (val) {
        return val.chargerCode;
      }).sort();
      for (var i = 0; i < ary.length; i++) {
        for (var j = i + 1; j < ary.length; j++) {
          if (ary[i] == ary[j]) {
            this.$message.warning('设备编码不能相同, 请修改！');
            flag = true;
            return flag;
          }
        }
      }
      return flag;
    },
    // 表单验证
    submitCharging: function submitCharging(code) {
      var _this3 = this;
      this.$refs['form'].validate(function (valid, obj) {
        if (valid) {
          if (_this3.form.cdzItems) {
            if (_this3.cdzB07OrA09CodingValidate()) return;
            var temp = _.cloneDeep(_this3.form.cdzItems);
            // 遍历所选险别列表，给新增充电桩赋值
            _this3.checkedList.forEach(function (item) {
              if (item.riskkindCode == 'A09' || item.riskkindCode == 'B07') {
                item.quantity = temp && temp.length || 0;
                item.quoteRiskkindChargers = temp;
              }
            });
            _this3.currentXZCDZItems = _.cloneDeep(_this3.form.cdzItems);
            _this3.exportValue();
          }
          _this3.showChargingDialog = false;
        }
      });
    },
    // 关闭弹窗
    cancelCDZ: function cancelCDZ(code) {
      // this.$refs['form'].resetFields();
      this.showChargingDialog = false;
      var self = this;
      setTimeout(function () {
        self.form.cdzItems = _.cloneDeep(self.currentXZCDZItems);
      }, 500);
    },
    // 打开新增充电桩弹窗
    openCDZItem: function openCDZItem(item) {
      this.showChargingDialog = true;
      if (item.quoteRiskkindChargers && item.quoteRiskkindChargers.length > 0) {
        this.form.cdzItems = _.cloneDeep(item.quoteRiskkindChargers);
      }
    },
    // 刷新
    changeValue: function changeValue(item) {
      this.$forceUpdate();
      if (item.insuredAmountText) {
        // 保留数字
        item.insuredAmountText = item.insuredAmountText.replace(/[^\d]/g, '');
        item.insuredAmount = item.insuredAmountText;
      }
      if (item.permiumRate) {
        // 保留数字
        item.permiumRate = item.permiumRate.replace(/[^\d]/g, '');
      }
      this.exportValue();
    },
    // 重置险别
    resetRisks: function resetRisks(formData) {
      if (this.newEnergy) {
        _.remove(formData, function (o) {
          return o.energyType == 'NOT_NEW_ENERGY';
        });
        // 能源类型是燃料电池时，不能投保充电桩险别                
        if (['2'].includes(this.fuelType)) {
          _.remove(formData, function (o) {
            return o.riskkindCode == 'B07' || o.riskkindCode == 'A09';
          });
        }
      } else {
        _.remove(formData, function (o) {
          return o.energyType == 'NEW_ENERGY';
        });
      }
    },
    // 绑定已选中的险别
    viewDetail: function viewDetail() {
      var _this4 = this;
      if (!this.showBz) {
        _.remove(this.dutyList, function (currentObject) {
          return currentObject.riskkindCode === 'JQX';
        });
      }
      var dutyDatas = this.dutyList;
      dutyDatas.forEach(function (item) {
        if (item.riskkindCode == 'A') {
          item.csxAmount = '';
        }
      });
      if (!_.isEmpty(dutyDatas)) {
        this.formData = _.sortBy(dutyDatas, function (o) {
          return o.riskkindCode;
        });
        this.resetRisks(this.formData);
        this.removeRisk();
        dutyDatas.forEach(function (dutyData) {
          if (_.isArray(dutyData.unitAmountOptions)) {
            var options = [];
            dutyData.unitAmountOptions.forEach(function (value) {
              options.push({
                'text': _this4.formatOption(value),
                'value': value
              });
            });
            dutyData.options = options;
          }
        });
        var dutyList = [];
        if (!_.isEmpty(this.checkDutyList) && _.isEmpty(this.checkedList)) {
          dutyList = _.cloneDeep(this.checkDutyList);
          this.checkedList = [];
          dutyList.forEach(function (item) {
            _this4.addCheckedItem(dutyDatas, item);
          });
        } else {
          dutyList = _.cloneDeep(this.checkedList);
          this.checkedList = [];
          dutyList.forEach(function (item) {
            _this4.addCheckedItem(dutyDatas, item);
          });
        }
      } else {
        this.formData = [];
      }
      this.resetRisks(this.formData);
      this.$forceUpdate();
    },
    // 优先通过id匹配
    addCheckedItem: function addCheckedItem(dutyDatas, item) {
      var _this5 = this;
      var allItem = null;
      if (item) {
        if (item.riskkindId) {
          allItem = _.find(dutyDatas, function (dutyData) {
            return dutyData.riskkindId == item.riskkindId;
          });
        } else {
          allItem = _.find(dutyDatas, function (dutyData) {
            return dutyData.riskkindCode == item.riskkindCode;
          });
        }
      }
      if (!_.isEmpty(allItem)) {
        this.$nextTick(function () {
          if (allItem.hasInsuranceAmount) {
            allItem.insuredAmountText = _this5.formatOption(item.insuredAmount);
            if (allItem.amountUnit == '元') {
              allItem.insuredAmount = item.insuredAmount == 0 ? '0' : item.insuredAmount;
              allItem.insuredAmountText = item.insuredAmount == 0 ? '0' : _this5.formatOption(item.insuredAmount);
              if (allItem.riskkindCode == 'A') {
                if (!_.isEmpty(_this5.checkDutyList)) {
                  var _csxRisk = _.find(_this5.checkDutyList, function (item) {
                    return item.riskkindCode == 'A';
                  });
                  allItem.csxAmount = _csxRisk ? _csxRisk.csxAmount : '';
                  allItem.insuredAmount = _csxRisk ? _csxRisk.absoluteDeductibleAmount : 0;
                  allItem.insuredAmountText = allItem.insuredAmount == 0 ? '0' : _this5.formatOption(allItem.insuredAmount);
                } else {
                  allItem.csxAmount = '';
                }
              }
            }
            if (allItem.amountUnit == '%') {
              allItem.permiumRate = item.permiumRate;
            }
            if (allItem.amountUnit == '元/天' || allItem.amountUnit == '元/座') {
              allItem.unitAmount = item.unitAmount ? item.unitAmount : item.insuredAmount;
              allItem.insuredAmount = item.insuredAmount;
              allItem.quantity = item.quantity;
            }
            if (allItem.riskkindCode == 'A02') {
              allItem.insuredAmount = item.insuredAmount;
              _this5.isXZSBSS = false;
            }
            if (allItem.riskkindCode == 'A09' || allItem.riskkindCode == 'B07') {
              allItem.unitAmount = item.unitAmount ? item.unitAmount : item.insuredAmount;
              allItem.insuredAmount = item.insuredAmount;
              allItem.quantity = item.quantity;
              _this5.isXZCDZ = false;
            }
            if (allItem.riskkindCode == 'D03' || allItem.riskkindCode == 'B04' || allItem.riskkindCode == 'C03') {
              allItem.shareAmount = item.shareAmount || false;
              allItem.unitAmount = item.unitAmount || '0';
              allItem.insuredAmount = item.insuredAmount || '0';
            }
          }
          if (_.isArray(item.quoteDutyDevices)) {
            allItem.quoteDutyDevices = item.quoteDutyDevices;
          }
          if (_.isArray(item.quoteRiskkindChargers)) {
            allItem.quoteRiskkindChargers = item.quoteRiskkindChargers;
          }
          allItem.check = true;
          _this5.checkedList.push(allItem);
        });
      }
    },
    // 单选
    handleItemChanged: function handleItemChanged(value) {
      var _this6 = this;
      console.log(value);
      this.$forceUpdate();
      this.dutyList.forEach(function (item) {
        item.check = false;
        if (_.isArray(item.unitAmountOptions)) {
          var options = [];
          item.unitAmountOptions.forEach(function (value) {
            options.push({
              'text': _this6.formatOption(value),
              'value': value
            });
          });
          item.options = options;
        }
      });
      if (!_.isEmpty(this.dutyList)) {
        this.formData = _.sortBy(this.dutyList, function (o) {
          return o.riskkindCode;
        });
        this.resetRisks(this.formData);
        this.removeRisk();
      } else {
        this.formData = [];
      }
      if (this.isMain) {
        var _mainDuty = this.mainDuty;
        var _noMainDuty = this.noMainDuty;
        if (!_.isEmpty(_mainDuty)) {
          this.checkedList = _.concat(_mainDuty, _noMainDuty);
        } else {
          this.checkedList = [];
        }
        if (_.isEmpty(this.noMainDuty) && _.isEmpty(this.mainDuty)) {
          _.remove(this.checkDutyList, function (eitem) {
            return eitem.riskkindCode !== 'A' && eitem.riskkindCode !== 'B' && eitem.riskkindCode !== 'C' && eitem.riskkindCode !== 'D';
          });
          this.$forceUpdate();
        }
        var _checkedList = this.checkedList;
        _.remove(this.checkDutyList, function (eitem) {
          return _.find(_checkedList, function (item) {
            return item.riskkindId != eitem.riskkindId || item.riskkindCode != eitem.riskkindCode;
          });
        });
        this.$forceUpdate();
      } else {
        this.checkedList = value;
      }
      // 绑定默认值
      this.setDefaultValue();
    },
    // 绑定默认值
    setDefaultValue: function setDefaultValue() {
      var _this7 = this;
      this.isXZSBSS = true;
      this.checkedList.forEach(function (item) {
        item.check = true;
        if (item.riskkindCode == 'A02') {
          _this7.isXZSBSS = false;
        }
        if (item.riskkindCode == 'A09' || item.riskkindCode == 'B07') {
          _this7.isXZCDZ = false;
        }
        if (item.hasInsuranceAmount) {
          if (item.amountUnit == '元' && !item.insuredAmountText) {
            if (!_.isEmpty(item.unitAmountOptions) && item.riskkindCode == 'B') {
              item.insuredAmount = 1000000;
              item.insuredAmountText = _this7.formatOption(item.insuredAmount);
            } else {
              if (!_.isEmpty(item.unitAmountOptions)) {
                item.insuredAmount = item.unitAmountOptions[0];
                item.insuredAmountText = _this7.formatOption(item.insuredAmount);
                if (item.riskkindCode == 'A') {
                  item.csxAmount = '';
                }
              } else {
                item.insuredAmount = item.insuredAmount ? item.insuredAmount : '';
                item.insuredAmountText = item.insuredAmountText ? item.insuredAmountText : '';
              }
            }
          }
          if (item.amountUnit == '元/天' && !item.unitAmount) {
            item.unitAmount = '';
            item.quantity = 1;
          }
          if (item.amountUnit == '%' && !item.permiumRate) {
            item.permiumRate = item.unitAmountOptions[0];
          }
          if (item.amountUnit == '元/座' && !item.insuredAmountText) {
            item.insuredAmount = item.unitAmountOptions[0];
            item.insuredAmountText = _this7.formatOption(item.insuredAmount);
          }
        }
      });
    },
    // 返回所选险别列表
    exportValue: function exportValue() {
      var _this8 = this;
      this.checkedList.forEach(function (item) {
        if (['D', 'D02', 'D03'].includes(item.riskkindCode)) {
          item.quantity = _this8.seats ? _this8.seats : '1';
        }
      });
      // 附加法定节假日限额翻倍险的保额和三者险保额一致
      if (!_.isEmpty(this.mainDuty)) {
        var szxAmount = 0;
        var sjAmount = 0;
        var ckAmount = 0;
        this.mainDuty.forEach(function (element) {
          if (element.riskkindCode == "B") szxAmount = element.insuredAmount || null;
          if (element.riskkindCode == "C") sjAmount = element.insuredAmount || null;
          if (element.riskkindCode == "D") ckAmount = element.insuredAmount || null;
        });
        if (!_.isEmpty(this.checkedList)) {
          this.checkedList.forEach(function (element) {
            if (element.riskkindCode == "B02") {
              element.insuredAmount = szxAmount;
              element.insuredAmountText = _this8.formatOption(szxAmount);
            }
            // 三者，司机，乘客附加险医保外用勾选上“共享保额”后，保额同对应的主险保额
            if (element.shareAmount) {
              if (element.riskkindCode == "B04") {
                element.insuredAmount = szxAmount;
                element.insuredAmountText = _this8.formatOption(szxAmount);
              } else if (element.riskkindCode == "C03") {
                element.insuredAmount = sjAmount;
                element.insuredAmountText = _this8.formatOption(sjAmount);
              } else if (element.riskkindCode == "D03") {
                element.insuredAmount = ckAmount;
                element.insuredAmountText = _this8.formatOption(ckAmount);
              }
            }
          });
        }
      }
      this.$emit('exportValue', this.checkedList);
    },
    // 格式化保额
    formatOption: function formatOption(amount) {
      if (amount) {
        var divideValue = _.divide(amount, 10000);
        var text = amount;
        if (divideValue >= 1) {
          text = divideValue + '万';
        }
        return text;
      }
    }
  }
};