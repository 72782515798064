import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import Rules from '@/utils/rules';
import userCenterApi from '@/api/userCenter';
import { character, idCardValidate, limitInput } from "@/utils/common";
export default {
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel
  },
  data: function data() {
    return {
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      regionData: JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [],
      USE_ATTRIBUTE: JSON.parse(localStorage.getItem("VI_USE_ATTRIBUTE")) || [],
      VEHICLE_KINDS: JSON.parse(localStorage.getItem("VI_VEHICLE_KIND")) || [],
      PLATE_TYPE: JSON.parse(localStorage.getItem("VI_PLATE_TYPE")) || [],
      opType: null,
      formLoading: false,
      activeNames: ['1', '2', '3'],
      form: {
        cascaderAddr: []
      },
      formRules: {
        ownerName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          pattern: Rules["NAME"].value,
          message: "姓名格式有误",
          trigger: ["blur", "change"]
        }],
        ownerCertificateType: {
          required: true,
          message: '请选择证件类型',
          trigger: 'change'
        },
        ownerCertificateNo: [{
          required: true,
          message: '请输入证件号码',
          trigger: 'blur'
        }],
        ownerPhone: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "手机号码格式有误",
          trigger: ["blur", "change"]
        }],
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        }, {
          pattern: Rules["EMAIL"].value,
          message: "邮箱格式有误",
          trigger: ["blur", "change"]
        }],
        cascaderAddr: {
          required: true,
          message: '请选择所在省市区',
          trigger: ['blur', 'change']
        },
        address: {
          required: true,
          message: '请输入详细地址',
          trigger: ["blur", "change"]
        },
        plateNo: [{
          required: false,
          message: '请输入车牌号',
          trigger: 'blur'
        }, {
          pattern: Rules["PLATENO_RULE"].value,
          message: "车牌号格式有误",
          trigger: ["blur", "change"]
        }],
        vin: [{
          required: true,
          message: '请输入车架号',
          trigger: 'blur'
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }],
        engineNo: [{
          required: true,
          message: '请输入发动机号',
          trigger: 'blur'
        }, {
          pattern: Rules["ENGINENO_RULE"].value,
          message: "发动机号格式有误",
          trigger: ["blur", "change"]
        }],
        registerDate: {
          required: true,
          message: '请选择注册日期',
          trigger: 'change'
        },
        useAttribute: {
          required: true,
          message: '请选择使用性质',
          trigger: 'change'
        },
        modelName: {
          required: true,
          message: '请输入品牌型号',
          trigger: 'blur'
        },
        vehicleType: {
          required: true,
          message: '请选择车辆类型',
          trigger: 'change'
        },
        plateType: {
          required: true,
          message: '请选择号牌种类',
          trigger: 'change'
        },
        fuelType: {
          required: true,
          message: '请选择能源类型',
          trigger: 'change'
        },
        wholeWeight: {
          required: true,
          message: '请输入整备质量(吨)',
          trigger: 'blur'
        }
      },
      VI_CERTIFICATE_TYPE: [],
      checked: true,
      ENGRGY_TYPES: [],
      tableData: []
    };
  },
  created: function created() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      this.opType = this.$route.query.opType || null;
      this.VI_CERTIFICATE_TYPE = JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [];
      var temp = [{
        label: '个人',
        options: []
      }, {
        label: '企业/机关',
        options: []
      }];
      this.VI_CERTIFICATE_TYPE.forEach(function (item) {
        if (item.dictName === "组织机构代码证" || item.dictName === "统一社会信用代码") {
          temp[1].options.push({
            dictCode: item.dictCode,
            dictName: item.dictName
          });
        } else {
          temp[0].options.push({
            dictCode: item.dictCode,
            dictName: item.dictName
          });
        }
        ;
      });
      this.VI_CERTIFICATE_TYPE = temp;
      var engrgy = JSON.parse(localStorage.getItem("VI_ENGRGY_TYPE")) || [];
      this.ENGRGY_TYPES = _.filter(engrgy, function (o) {
        return ["0", "1", "2", "3", "4"].includes(o.dictCode);
      });
    },
    // 证件类型切换
    selectCertificate: function selectCertificate() {
      this.form.ownerCertificateNo = "";
    },
    // 判断证件类型
    certificateNoChange: function certificateNoChange() {
      if (this.form.ownerCertificateType == 'P01' && this.form.ownerCertificateNo) {
        var certificateNo = Rules['ID_CARD'].value;
        var flag = certificateNo.test(this.form.ownerCertificateNo);
        var isValidate = idCardValidate(this.form.ownerCertificateNo);
        if (!flag || !isValidate) {
          this.$message({
            type: 'error',
            message: '证件号码不满足校验规则，请修改！'
          });
          return false;
        }
        ;
      }
      ;
      return true;
    },
    inputCardNo: function inputCardNo(val) {
      if (val) {
        if (this.form.ownerCertificateType == 'P01') {
          this.form.ownerCertificateNo = character(_.toUpper(val));
        } else {
          val = val.replace(/[^A-Za-z0-9\（\）\(\)]/g, '');
          this.form.ownerCertificateNo = _.toUpper(val);
        }
      }
    },
    // 所在省市区选择
    handleChange: function handleChange(val) {
      var regionName = '';
      // 省
      this.form.provinceCode = val && val[0];
      // 市
      this.form.cityCode = val && val[1];
      // 区
      this.form.districtCode = val && val[2];
      //获取地区名称
      var province = _.find(this.regionData, function (item) {
        return item.value == val[0];
      });
      if (province) {
        // 省
        regionName = province.label;
        this.form.provinceName = province.label;
        var city = _.find(province.children, function (item) {
          return item.value == val[1];
        });
        if (city) {
          // 市
          regionName = regionName === city.label ? regionName : regionName + city.label;
          this.form.cityName = city.label;
          var area = _.find(city.children, function (item) {
            return item.value == val[2];
          });
          if (area) {
            // 区
            regionName = regionName + area.label;
            this.form.districtName = area.label;
          }
        }
        ;
      }
      ;
      this.form.address = regionName;
    },
    // 自动将车牌号码转为大写
    inputPlateNo: function inputPlateNo(val) {
      this.form.plateNo = _.toUpper(val);
    },
    // 车牌号数据切换
    noPlateNoChange: function noPlateNoChange(val) {
      if (val) {
        this.form.plateNo = '';
        this.formRules.plateNo[0].required = false;
      } else {
        this.formRules.plateNo[0].required = true;
      }
      ;
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.form.vin = character(_.toUpper(val));
      }
      ;
    },
    // 发动机号自动转大写
    inputEngineNo: function inputEngineNo(val) {
      val = val.replace(/[^A-Za-z0-9-]/g, '');
      this.form.engineNo = _.toUpper(val);
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      ;
      if (max && num > max) {
        num = max;
      }
      ;
      return limitInput(num, limit);
    },
    // 保存
    save: function save() {
      var _this = this;
      // 证件号码判断
      if (!this.certificateNoChange()) return;
      this.$refs.form.validate(function (valid, obj) {
        if (valid) {
          // this.formLoading = true;
          // let req =
          //     this.opType === 'add' ?
          //     userCenterApi.新增(this.form) :
          //     userCenterApi.编辑(this.form);
          // req.then(res => {
          //     this.formLoading = false;
          //     if (res.code === 200) {
          //         this.$message.success(res.msg);
          //         this.closePage();
          //     };
          // }).catch(err => {
          //     this.formLoading = false;
          // });
        } else {
          _this.scrollView(obj);
        }
        ;
      });
    },
    // 滚动到固定地方
    scrollView: function scrollView(object) {
      var _this2 = this;
      Object.keys(object).forEach(function (v, index) {
        if (index === 0) {
          if (_this2.$refs[v]) {
            _this2.$refs[v].$el.scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: "center",
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: "smooth"
            });
          }
          ;
        }
        ;
      });
    },
    // 关闭
    closePage: function closePage() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    }
  }
};