import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import request from '@/api/message';
import MessageNotificationDialog from './dialog/messageNotificationDialog';
export default {
  name: "MessageNotification",
  components: {
    Pagination: Pagination,
    MessageNotificationDialog: MessageNotificationDialog
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      total: 0,
      loading: false,
      tableData: [],
      classifyList: [],
      rangeList: [],
      showAddDialog: false,
      currentType: '',
      msgIdArr: [],
      currentId: ''
    };
  },
  created: function created() {
    this.getClassifyList();
    this.getRangeList();
    this.loadData();
  },
  methods: {
    // 获取分类数据
    getClassifyList: function getClassifyList() {
      var _this = this;
      request.getClassifyList().then(function (res) {
        if (res.data) {
          _this.classifyList = res.data;
        }
        ;
      });
    },
    // 获取范围数据
    getRangeList: function getRangeList() {
      var _this2 = this;
      request.getRangeList().then(function (res) {
        if (res.data) {
          _this2.rangeList = res.data;
        }
        ;
      });
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
    },
    // 搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this3 = this;
      this.loading = true;
      this.showAddDialog = false;
      this.msgIdArr = [];
      request.getMessageList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        _this3.loading = false;
        if (res.code === 200) {
          _this3.tableData = res.data.list;
          _this3.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this3.loading = false;
      });
    },
    // 新增编辑消息
    addAndEditMsg: function addAndEditMsg(type) {
      var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.currentType = type;
      if (type == 'edit') {
        this.currentId = item.messageTemplateId.toString();
      }
      this.showAddDialog = true;
    },
    // 勾选复选框
    handleSelectionChange: function handleSelectionChange(val) {
      this.msgIdArr = val.map(function (val) {
        return val.messageTemplateId;
      });
    },
    // 是否启用
    updateItem: function updateItem(item) {
      var _this4 = this;
      if (item.messageTemplateId) {
        this.loading = true;
        var postData = {};
        postData = {
          messageTemplateId: item.messageTemplateId
        };
        request.isEnableMessage(postData).then(function (res) {
          if (res.code == 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          } else {}
        }).catch(function (err) {
          _this4.loading = false;
        });
      }
    },
    // 删除
    remove: function remove(row) {
      var _this5 = this;
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (type) {
        if (!this.msgIdArr.length) {
          this.$message.warning('请勾选要删除的数据');
        } else {
          this.$confirm("您确定删除选中的数据吗?", '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: "确定",
            type: 'warning'
          }).then(function () {
            _this5.removePublic(_this5.msgIdArr);
          }).catch(function () {});
        }
        ;
      } else {
        this.$confirm("您确定删除该数据吗?", '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: "确定",
          type: 'warning'
        }).then(function () {
          _this5.removePublic([row.messageTemplateId]);
        }).catch(function () {});
      }
      ;
    },
    // 删除方法
    removePublic: function removePublic(idArr) {
      var _this6 = this;
      request.deleteRangeList(idArr).then(function (res) {
        if (res.code === 200) {
          _this6.$message.success(res.msg);
          _this6.loadData();
        }
        ;
      });
    }
  }
};