import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OemSelect from '@/components/OemSelect';
import storageRequest from '@/api/storage';
import vehiclesApi from '@/api/vehiclesIntoShop';
import { getUserOrgInfoDealerOem, downFile } from '@/utils/common';
export default {
  components: {
    OemSelect: OemSelect
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    cameraDeviceId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        orgCode: '',
        orgName: '',
        imageIdList: []
      },
      rules: {
        cameraCode: {
          required: true,
          message: '请输入机器编码',
          trigger: "blur"
        },
        orgCode: {
          required: true,
          message: '请选择所属机构',
          trigger: "change"
        }
      },
      bizType: localStorage.getItem("bizType") || '',
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      fileList: [],
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  filters: {
    filterType: function filterType(val) {
      if (val === 0) return '0 B';
      var k = 1024;
      var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var i = Math.floor(Math.log(val) / Math.log(k));
      return (val / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        orgCode: '',
        orgName: '',
        imageIdList: [],
        imgBox: []
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      if (this.opType === "add") {
        if (this.bizType === "DEALER") {
          var orgObj = getUserOrgInfoDealerOem(this.bizType);
          this.form.orgCode = orgObj.salesOrgCode;
          this.form.orgName = orgObj.salesOrgName;
        }
        ;
      } else {
        vehiclesApi.getCameraDeviceInfo(this.cameraDeviceId).then(function (res) {
          if (res.code === 200) {
            _this.form = res.data;
            _this.form.imgBox = [];
            _this.orgForm = {
              salesOrgCode: _this.form.orgCode,
              salesOrgName: _this.form.orgName
            };
            if (_this.form.imageIdList) _this.form.imageIdList.forEach(function (item, index) {
              _this.form.imgBox.push({
                fileStorageId: item,
                fileUrl: _this.form.imageUrlList[index]
              });
            });
          }
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data, node, target) {
      this.form.orgCode = data.salesOrgCode;
      this.form.orgName = data.salesOrgName;
    },
    // 上传图片
    uploadFile: function uploadFile(param) {
      var _this2 = this;
      var formData = new FormData();
      formData.append('file', param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200 && res.data) {
          _this2.$message.success(res.msg);
          _this2.form.imageIdList.push(res.data.fileStorageId);
          _this2.downloadFile(res.data);
        }
        ;
      });
    },
    downloadFile: function downloadFile(obj) {
      if (!obj.fileStorageId) return;
      this.viewUrl = obj.fileUrl;
      obj.url = this.viewUrl;
      this.form.imgBox.push(obj);
    },
    // 文件删除
    handleDelete: function handleDelete(item, index) {
      var j = this.form.imageIdList.findIndex(function (val) {
        return item == val;
      });
      this.form.imageIdList.splice(j, 1);
      var i = this.form.imgBox.findIndex(function (val) {
        return item == val.fileStorageId;
      });
      this.form.imgBox.splice(i, 1);
    },
    // 点击，使用id下载文件
    handle: function handle(row) {
      if (row.fileId) {
        downFile(row.fileUrl, row.fileName);
      }
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          var req = _this3.opType === 'add' ? vehiclesApi.addCameraDevice(_this3.form) : vehiclesApi.editCameraDevice(_this3.cameraDeviceId, _this3.form);
          req.then(function (res) {
            _this3.formLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage();
            }
            ;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};