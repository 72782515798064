/**
 * 区域维护
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  // 获取区域树形结构
  // getList: function() {
  //     return request.get(getUrl(`/area/search/list`, 13))
  //  },
  // 获取区域树形结构(主机厂端)
  getList: function getList(code) {
    return request.get(getUrl("/area/search/list?oemCode=".concat(code), 13));
  },
  /**
  * 添加省级区域
  * @param {*} data 内容
  */
  addProvince: function addProvince(data) {
    return request.post(getUrl("/area/add/province", 13), data);
  },
  /**
   * 添加市级区域
   * @param {*} data 内容
   */
  addCity: function addCity(data) {
    return request.post(getUrl("/area/add/city", 13), data);
  },
  /**
  * 修改省级区域
  * @param {*} data 内容
  */
  updateProvince: function updateProvince(data) {
    return request.post(getUrl("/area/update/province", 13), data);
  },
  /**
   * 修改市级区域
   * @param {*} data 内容
   */
  updateCity: function updateCity(data) {
    return request.post(getUrl("/area/update/city", 13), data);
  },
  /**
   * 删除省级区域
   * @param {*} id
   */
  deleteProvince: function deleteProvince(id) {
    return request.delete(getUrl("/area/delete/province/".concat(id), 13));
  },
  /**
   * 删除市级区域
   * @param {*} id
   */
  deleteCity: function deleteCity(id) {
    return request.delete(getUrl("/area/delete/city/".concat(id), 13));
  },
  /**
   * 省级列表
   */
  getProvinceList: function getProvinceList(code) {
    return request.get(getUrl("/area/province/list?oemCode=".concat(code), 13));
  },
  /**
   * 市级列表
   * @param {*} data 内容
   */
  getCityList: function getCityList(data) {
    return request.get(getUrl("/area/city/list", 13), {
      params: data
    });
  },
  /**
   * 市级区域上级列表
   * @param {*} data 内容
   */
  getCitySuperior: function getCitySuperior(data) {
    return request.get(getUrl("/area/city/superior/list", 13), {
      params: data
    });
  }
};
export default objApi;