var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticStyle: { height: "calc(100vh - 85px)" },
    },
    [
      _c("iframe", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.bdTokenUrl,
            expression: "bdTokenUrl",
          },
        ],
        style: { width: _vm.iframeWidth, height: "calc(100vh - 84px)" },
        attrs: {
          sandbox: "allow-forms allow-same-origin allow-scripts",
          src: _vm.bdTokenUrl,
          frameborder: "0",
          id: "bdIframe",
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading && !_vm.bdTokenUrl,
              expression: "!loading&&!bdTokenUrl",
            },
          ],
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
            height: "calc(100vh - 85px)",
          },
        },
        [
          _c("img", {
            staticStyle: { width: "30%", height: "30%" },
            attrs: { src: require("@/assets/images/404.png") },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }