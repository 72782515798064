var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "赠品名称：",
                                prop: "cardVoucherName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入赠品名称",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.cardVoucherName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cardVoucherName", $$v)
                                  },
                                  expression: "form.cardVoucherName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "赠品编码：",
                                prop: "cardVoucherCode",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入赠品编码",
                                  disabled: _vm.opType === "edit",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.cardVoucherCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "cardVoucherCode", $$v)
                                  },
                                  expression: "form.cardVoucherCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "赠品类别：",
                                prop: "cardVoucherType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择赠品类别",
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.form.cardVoucherType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "cardVoucherType", $$v)
                                    },
                                    expression: "form.cardVoucherType",
                                  },
                                },
                                _vm._l(
                                  _vm.cardVoucherTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "有效期(天)：",
                                prop: "validityDay",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "footer-btn-list" },
                                [
                                  _c("el-input", {
                                    staticStyle: {
                                      width: "100%",
                                      "margin-right": "10px",
                                    },
                                    attrs: {
                                      maxlength: "40",
                                      placeholder: "请输入有效期(天)",
                                      clearable: "",
                                      disabled: _vm.longTerm,
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.form.validityDay = _vm.onInput(
                                          _vm.form.validityDay,
                                          0,
                                          999999,
                                          0
                                        )
                                      },
                                      change: _vm.validityDayChange,
                                    },
                                    model: {
                                      value: _vm.form.validityDay,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "validityDay", $$v)
                                      },
                                      expression: "form.validityDay",
                                    },
                                  }),
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: { change: _vm.changeLongTerm },
                                      model: {
                                        value: _vm.longTerm,
                                        callback: function ($$v) {
                                          _vm.longTerm = $$v
                                        },
                                        expression: "longTerm",
                                      },
                                    },
                                    [_vm._v("长期")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "价值(元)：", prop: "value" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入价值(元)",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.value = _vm.onInput(
                                      _vm.form.value,
                                      0,
                                      100000,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "value", $$v)
                                  },
                                  expression: "form.value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "赠品成本(元)：",
                                prop: "verificationCost",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入赠品成本(元)",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.verificationCost = _vm.onInput(
                                      _vm.form.verificationCost,
                                      0,
                                      100000,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.verificationCost,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "verificationCost", $$v)
                                  },
                                  expression: "form.verificationCost",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "最大发放数量：",
                                prop: "maxGiving",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入最大发放数量",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.maxGiving = _vm.onInput(
                                      _vm.form.maxGiving,
                                      0,
                                      100,
                                      0
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.maxGiving,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "maxGiving", $$v)
                                  },
                                  expression: "form.maxGiving",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否启用：", prop: "enable" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.enable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "enable", $$v)
                                    },
                                    expression: "form.enable",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "权益说明：", prop: "equities" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "100",
                                  type: "textarea",
                                  placeholder: "请输入权益说明",
                                  autosize: { minRows: 4, maxRows: 4 },
                                  resize: "none",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.equities,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "equities", $$v)
                                  },
                                  expression: "form.equities",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }