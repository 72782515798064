import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import giftApi from '@/api/giftManagement';
import Rules from '@/utils/rules';
import OemSelect from '@/components/OemSelect';
import dictRequest from '@/api/dictionary';
import producteRequest from '@/api/product';
import { character, idCardValidate, limitInput, getUserOrgInfo } from "@/utils/common";
export default {
  components: {
    OemSelect: OemSelect
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        bizType: 'BROKER',
        orgCode: '',
        vin: '',
        policyNo: '',
        ownerName: '',
        ownerCertificateType: 'P01',
        ownerCertificate: '',
        phone: '',
        cardVoucherIssuedList: [],
        policyList: [],
        businessType: 'CX',
        productType: null,
        orderNo: ''
      },
      rules: {
        orgCode: {
          required: true,
          message: '请选择业务机构',
          trigger: ["blur", "change"]
        },
        businessType: {
          required: true,
          message: '请选择业务类别',
          trigger: "change"
        },
        productType: {
          required: true,
          message: '请选择服务类别',
          trigger: "change"
        },
        vin: [{
          required: true,
          message: '请输入车架号',
          trigger: 'blur'
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }],
        orderNo: {
          required: true,
          message: '请输入合同编号',
          trigger: ["blur", "change"]
        },
        ownerName: [{
          required: false,
          message: '请输入车主名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 40,
          pattern: Rules["NAME"].value,
          message: "车主名格式有误",
          trigger: ["blur", "change"]
        }],
        ownerCertificateType: {
          required: false,
          message: '请选择车主证件类型',
          trigger: "change"
        },
        ownerCertificate: {
          required: false,
          message: '请输入车主证件号',
          trigger: ["blur", "change"]
        },
        phone: [{
          required: false,
          message: '请输入联系电话',
          trigger: 'blur'
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "联系电话格式有误",
          trigger: ["blur", "change"]
        }]
      },
      formLoading: false,
      checkList: [],
      vouchersList: [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      VI_CERTIFICATE_TYPE: JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [],
      productTypeList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    var temp = [{
      label: '个人',
      options: []
    }, {
      label: '企业/机关',
      options: []
    }];
    this.VI_CERTIFICATE_TYPE.forEach(function (item) {
      if (item.dictName === "组织机构代码证" || item.dictName === "统一社会信用代码") {
        temp[1].options.push({
          dictCode: item.dictCode,
          dictName: item.dictName
        });
      } else {
        temp[0].options.push({
          dictCode: item.dictCode,
          dictName: item.dictName
        });
      }
    });
    this.VI_CERTIFICATE_TYPE = temp;
    this.initData();
  },
  methods: {
    businessTypeChange: function businessTypeChange(val) {
      if (val == 'CX') {
        this.form.productType = null;
        this.form.orderNo = '';
        this.form.vin = '';
      } else if (val == 'ZZ') {
        this.form.vin = '';
        this.form.policyNo = '';
      }
      ;
      this.$refs['detail'].clearValidate(['productType', 'orderNo', 'vin']);
    },
    // 获取服务类别列表
    getProductTypeList: function getProductTypeList() {
      var _this = this;
      dictRequest.getDictItemsByCode("NON_VEHICLE_PRODUCT_TYPE").then(function (res) {
        if (res.code === 200) {
          _this.productTypeList = res.data || [];
        }
        ;
      }).catch(function (err) {});
    },
    nodeClick: function nodeClick(data) {
      this.form.orgCode = data.salesOrgCode;
      this.form.orgName = data.salesOrgName;
    },
    getVouchersList: function getVouchersList() {
      var _this2 = this;
      giftApi.getCardVoucherCodeList().then(function (res) {
        if (res.code === 200) {
          _this2.vouchersList = res.data;
          _this2.vouchersList.forEach(function (val) {
            _this2.$set(val, 'isCheck', false);
            _this2.$set(val, 'issueNumber', 1);
          });
        }
        ;
      });
    },
    // 对输入内容做精度校验
    onInput: function onInput(num, min, max, limit) {
      if (min && num < min) {
        num = min;
      }
      ;
      if (max && num > max) {
        num = max;
      }
      ;
      return limitInput(num, limit);
    },
    inputCardNo: function inputCardNo(val) {
      if (val) {
        if (this.form.ownerCertificateType == 'P01') {
          this.form.ownerCertificate = character(_.toUpper(val));
        } else {
          val = val.replace(/[^A-Za-z0-9\（\）\(\)]/g, '');
          this.form.ownerCertificate = _.toUpper(val);
        }
      }
    },
    certificateNoChange: function certificateNoChange() {
      var flag = true;
      if (this.form.ownerCertificateType == 'P01' && this.form.ownerCertificate) {
        var ownerCertificate = Rules['ID_CARD'].value;
        var _flag = ownerCertificate.test(this.form.ownerCertificate);
        var isValidate = idCardValidate(this.form.ownerCertificate);
        if (!_flag || !isValidate) {
          this.$message.error('身份证号码不满足校验规则，请修改！');
          _flag = false;
          return _flag;
        }
        ;
      }
      ;
      return flag;
    },
    // 输入的保单号/合同编号中的字母自动转大写
    checkPolicy: function checkPolicy(val, type) {
      this.form[type] = character(_.toUpper(val));
    },
    // 输入的车架号中的字母自动转大写，并去除i，o，q
    checkVin: function checkVin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      ;
      this.form.vin = character(_.toUpper(val));
    },
    vincheck: function vincheck() {
      var vinNumber = /^\d{17}$/;
      var vinLetter = /^[A-Z]{17}$/;
      if (vinNumber.test(this.form.vin) || vinLetter.test(this.form.vin)) {
        this.$message({
          type: 'error',
          message: '车架号不能纯数字或纯字母，请修改！'
        });
        this.$forceUpdate();
        return false;
      }
      ;
      return true;
    },
    // 车险查询客户信息
    queryCustomerInfo: function queryCustomerInfo() {
      var _this3 = this;
      this.formLoading = true;
      var data = {
        orgCode: this.form.orgCode || null,
        vin: this.form.vin || null,
        policyNo: this.form.policyNo || null
      };
      giftApi.getPolicyInformation(data).then(function (res) {
        _this3.formLoading = false;
        if (res.code === 200 && res.data) {
          _this3.$message.success(res.msg);
          _this3.form.ownerName = res.data.ownerName;
          _this3.form.ownerCertificate = res.data.ownerCertificate;
          _this3.form.ownerCertificateType = res.data.ownerCertificateType || 'P01';
          _this3.form.phone = res.data.phone;
          _this3.form.vin = res.data.vin;
          _this3.form.policyNo = res.data.policyNo;
        } else {
          var msg = '';
          if (data.vin && data.policyNo) {
            msg = "未查到客户信息，请手工录入资料。";
          } else if (data.vin && !data.policyNo) {
            msg = "未查到客户信息，还可尝试录入保单号检索或手工录入资料。";
          } else if (!data.vin && data.policyNo) {
            msg = "未查到客户信息，还可尝试录入车架号检索或手工录入资料。";
          }
          ;
          _this3.$message({
            type: "warning",
            message: msg
          });
        }
        ;
      }).catch(function (err) {
        _this3.formLoading = false;
      });
    },
    // 增值服务查询客户信息
    zzQueryCustomerInfo: function zzQueryCustomerInfo() {
      var _this4 = this;
      this.formLoading = true;
      producteRequest.queryAddServiceInfo(this.form.vin, this.form.productType).then(function (res) {
        _this4.formLoading = false;
        if (res.code === 200 && res.data) {
          _this4.$message.success(res.msg);
          _this4.form.orderNo = res.data.productOrderNo;
          _this4.form.ownerName = res.data.ownerName;
          _this4.form.ownerCertificate = res.data.ownerCertificateNo;
          _this4.form.ownerCertificateType = res.data.ownerCertificateType || 'P01';
          _this4.form.phone = res.data.ownerMobile;
        } else {
          _this4.$message.warning("未查到客户信息，请手工录入资料。");
        }
        ;
      }).catch(function (err) {
        _this4.formLoading = false;
      });
    },
    // 初始化数据
    initData: function initData() {
      this.getProductTypeList();
      this.getVouchersList();
      this.form = {
        bizType: 'BROKER',
        orgCode: '',
        vin: '',
        policyNo: '',
        ownerName: '',
        ownerCertificateType: 'P01',
        ownerCertificate: '',
        phone: '',
        cardVoucherIssuedList: [],
        policyList: [],
        businessType: 'CX',
        productType: null,
        orderNo: ''
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      this.initUserInfo();
      if (this.opType === "edit") {
        // giftApi.详情(this.currentId).then(res => {
        //     if (res.code === 200) {
        //         this.form = res.data;
        //     };
        // });
      }
      ;
      this.$refs.detail.resetFields();
    },
    initUserInfo: function initUserInfo() {
      var _orgInfo = getUserOrgInfo();
      if (_orgInfo && _orgInfo.organizationId && _orgInfo.orgTypeName) {
        this.form.orgName = _orgInfo.fullName || '';
        this.form.orgCode = _orgInfo.orgCode || '';
        this.orgForm = {
          salesOrgCode: this.form.orgCode,
          salesOrgName: this.form.orgName
        };
      }
    },
    vouchersValidate: function vouchersValidate() {
      var _this5 = this;
      var flag = false;
      if (this.vouchersList.every(function (val) {
        return !val.isCheck;
      })) {
        this.$message.warning('请勾选赠品选择！');
        flag = true;
        return flag;
      }
      ;
      this.vouchersList.some(function (val) {
        if (val.isCheck && !Number(val.issueNumber)) {
          _this5.$message.warning("".concat(val.cardVoucherName, "\u6570\u91CF\u4E0D\u80FD\u4E3A\u7A7A\u4E14\u4E0D\u80FD\u4E3A0"));
          flag = true;
          return flag;
        }
        ;
      });
      return flag;
    },
    // 保存数据
    save: function save() {
      var _this6 = this;
      if (this.form.businessType == 'CX' && !this.vincheck()) return;
      // 车主信息证件号判断
      if (!this.certificateNoChange()) return;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this6.vouchersValidate()) return;
          _this6.formLoading = true;
          _this6.form.cardVoucherIssuedList = _this6.vouchersList.filter(function (val) {
            return val.isCheck;
          });
          _this6.form.policyList = _this6.form.policyNo ? [_this6.form.policyNo] : [];
          // let req =
          //     this.opType === 'add' ?
          //     giftApi.新增(this.form) :
          //     giftApi.编辑(this.currentId, this.form);
          giftApi.addGiftIssue(_this6.form).then(function (res) {
            _this6.formLoading = false;
            if (res.code === 200) {
              _this6.$message.success(res.msg);
              _this6.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this6.formLoading = false;
          });
        }
        ;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};