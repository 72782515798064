import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.regexp.test.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customersRequest from '@/api/customers';
import dictRequest from '@/api/dictionary';
import Rules from '@/utils/rules';
import OemSelect from '@/components/OemSelect';
import CarBrands from '@/components/CarBrands/index';
import { getSupplyers, character, getUserOrgInfoDealerOem, idCardValidate, limitInput } from "@/utils/common";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    from: {
      type: String,
      default: ''
    }
  },
  components: {
    OemSelect: OemSelect,
    CarBrands: CarBrands
  },
  data: function data() {
    return {
      carTypeVal: [],
      VI_CERTIFICATE_TYPE: JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [],
      USE_ATTRIBUTE: JSON.parse(localStorage.getItem("VI_USE_ATTRIBUTE")) || [],
      VEHICLE_KINDS: JSON.parse(localStorage.getItem("VI_VEHICLE_KIND")) || [],
      PLATE_TYPE: JSON.parse(localStorage.getItem("VI_PLATE_TYPE")) || [],
      ENGRGY_TYPES: [],
      cascaderAddr: [],
      options: JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [],
      insuranceList: [],
      checked: true,
      form: {
        ownerOrgCode: '',
        ownerOrgName: '',
        areaType: '',
        modelName: ''
      },
      bizType: localStorage.getItem("bizType") || '',
      formLoading: false,
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
      formRules: {
        ownerName: [{
          required: true,
          message: '请输入车主名',
          trigger: 'blur'
        }, {
          min: 2,
          max: 50,
          pattern: Rules["NAME"].value,
          message: "车主名格式有误",
          trigger: ["blur", "change"]
        }],
        ownerMobile: [{
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "联系电话格式有误",
          trigger: ["blur", "change"]
        }],
        ownerOtherMobile: [{
          required: false,
          message: '',
          trigger: 'blur'
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "其他电话格式有误",
          trigger: ["blur", "change"]
        }],
        ownerCertificateType: {
          required: true,
          message: '请选择证件类型',
          trigger: 'change'
        },
        ownerOrgCode: {
          required: true,
          message: '请选择组织机构',
          trigger: ["blur", "change"]
        },
        ownerCertificateNo: [{
          required: true,
          message: '请输入证件号',
          trigger: 'blur'
        }],
        ownerLandline: [{
          required: false,
          message: '',
          trigger: 'blur'
        }, {
          pattern: Rules["LANDLINE_RULE"].value,
          message: "座机号格式有误",
          trigger: ["blur", "change"]
        }],
        email: [{
          required: false,
          message: '',
          trigger: 'blur'
        }, {
          pattern: Rules["EMAIL"].value,
          message: "电子邮箱格式有误",
          trigger: ["blur", "change"]
        }],
        plateNo: [{
          required: false,
          message: '请输入车牌号',
          trigger: 'blur'
        }, {
          pattern: Rules["PLATENO_RULE"].value,
          message: "车牌号格式有误",
          trigger: ["blur", "change"]
        }],
        vin: [{
          required: true,
          message: '请输入车架号',
          trigger: 'blur'
        }, {
          pattern: Rules["VIN_RULE"].value,
          message: "车架号格式有误",
          trigger: ["blur", "change"]
        }],
        engineNo: [{
          required: false,
          message: '',
          trigger: 'blur'
        }, {
          pattern: Rules["ENGINENO_RULE"].value,
          message: "发动机号格式有误",
          trigger: ["blur", "change"]
        }]
      },
      renewalTypeList: [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      disabledLessThan: {
        disabledDate: function disabledDate(time) {
          return time.getTime() > Date.now();
        }
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.addForm.resetFields();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
    this.getRenewalTypeList();
    this.getInsuranceData();
    this.classification();
  },
  methods: {
    // 监听数据变化
    CarHandleChange: function CarHandleChange(val) {
      this.form.cjVhcBrandNo = val[0]; // 厂家品牌编号
      this.form.cjVhcSeriesNo = val[1]; //   厂家车系编号
      this.carTypeVal = val;
      // cjVhcModelNo: val[2], //  厂家车型编号
    },
    nodeClick: function nodeClick(data) {
      this.form.ownerOrgCode = data.salesOrgCode;
      this.form.ownerOrgName = data.salesOrgName;
      this.form.areaType = data.salesOrgType;
    },
    //获取续客户类别
    getRenewalTypeList: function getRenewalTypeList() {
      var _this = this;
      dictRequest.getDictItemsByCode("RENEWAL_TYPE").then(function (res) {
        if (res.data) {
          _this.renewalTypeList = res.data;
        }
      });
    },
    // 监听输入事件
    inputvin: function inputvin(val) {
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        this.form.vin = character(_.toUpper(val));
        this.$forceUpdate();
      }
    },
    // 发动机号自动转大写
    inputEngineNo: function inputEngineNo(val) {
      val = val.replace(/[^A-Za-z0-9-]/g, '');
      this.form.engineNo = _.toUpper(val);
    },
    // 车牌号事件
    noPlateNoChange: function noPlateNoChange(val) {
      if (val) {
        this.form.plateNo = '';
        this.formRules.plateNo[0].required = false;
      } else {
        this.formRules.plateNo[0].required = true;
      }
      this.$forceUpdate();
    },
    // 证件信息处理
    classification: function classification() {
      var temp = [{
        label: '个人',
        options: []
      }, {
        label: '企业/机关',
        options: []
      }];
      this.VI_CERTIFICATE_TYPE.forEach(function (item) {
        if (item.dictName === "组织机构代码证" || item.dictName === "统一社会信用代码") {
          temp[1].options.push({
            dictCode: item.dictCode,
            dictName: item.dictName
          });
        } else {
          temp[0].options.push({
            dictCode: item.dictCode,
            dictName: item.dictName
          });
        }
      });
      this.VI_CERTIFICATE_TYPE = temp;
    },
    // 获取保司列表
    getInsuranceData: function getInsuranceData() {
      this.insuranceList = getSupplyers();
    },
    // 初始化数据
    initData: function initData() {
      this.form = {
        ownerOrgCode: '',
        ownerOrgName: '',
        bizType: '',
        areaType: '',
        modelName: ''
      };
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      };
      this.cascaderAddr = [];
      var engrgy = JSON.parse(localStorage.getItem("VI_ENGRGY_TYPE")) || [];
      this.ENGRGY_TYPES = _.filter(engrgy, function (o) {
        return ["0", "1", "2", "3", "4"].includes(o.dictCode);
      });
      if (this.bizType === "DEALER") {
        var orgObj = getUserOrgInfoDealerOem(this.bizType);
        // this.form = Object.assign(this.form, _getUserInfo)
        this.form.ownerOrgCode = orgObj.salesOrgCode;
        this.form.ownerOrgName = orgObj.salesOrgName;
        this.form.bizType = orgObj.salesOrgType;
        this.form.areaType = orgObj.areaType;
      }
    },
    // 省/市/区选择
    handleChange: function handleChange(val) {
      this.$nextTick(function () {
        var cascaderAddr = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;
        this.form.ownerProvinceName = cascaderAddr[0];
        this.form.ownerCityName = cascaderAddr[1];
        this.form.ownerDistrictName = cascaderAddr[2];
        this.form.ownerProvinceCode = val[0];
        this.form.ownerCityCode = val[1];
        this.form.ownerDistrictCode = val[2];
      });
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs['addForm'].validate(function (valid, obj) {
        if (valid) {
          if (!_this2.form.vin && !_this2.form.plateNo) {
            _this2.$message.warning('车牌号和车架号需录入其中一个！');
            return;
          }
          if (!_this2.certificateNoChange()) return;
          _this2.form.ownerOrgCode = _this2.form.ownerOrgCode || _this2.userInfo.data.orgCode;
          _this2.form.ownerOrgName = _this2.form.ownerOrgName || _this2.userInfo.data.orgName;
          _this2.form.bizType = _this2.form.bizType || _this2.userInfo.data.orgType;
          if (_this2.from == 'my') {
            _this2.form.ownerBy = _this2.userInfo.userId;
          }
          if (_this2.form.bzLastYearInsCorpName) {
            var bzLastYearInsCorp = _.find(_this2.insuranceList, function (item) {
              return item.shortName == _this2.form.bzLastYearInsCorpName;
            }) || {};
            _this2.form.bzLastYearInsCorpCode = bzLastYearInsCorp.code;
          }
          if (_this2.form.bsLastYearInsCorpName) {
            var bsLastYearInsCorp = _.find(_this2.insuranceList, function (item) {
              return item.shortName == _this2.form.bsLastYearInsCorpName;
            }) || {};
            _this2.form.bsLastYearInsCorpCode = bsLastYearInsCorp.code;
          }
          _this2.formLoading = true;
          customersRequest.addData(_this2.form).then(function (res) {
            _this2.formLoading = false;
            _this2.$message.success(res.msg);
            _this2.closePage(false);
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
      });
    },
    closePage: function closePage(val) {
      //关闭弹窗
      this.$emit('closePage', val);
    },
    // 证件号处理事件
    inputCardNo: function inputCardNo(val) {
      if (val) {
        if (this.form.ownerCertificateType == 'P01') {
          this.form.ownerCertificateNo = character(_.toUpper(val));
        } else {
          val = val.replace(/[^A-Za-z0-9\（\）\(\)]/g, '');
          this.form.ownerCertificateNo = _.toUpper(val);
        }
      }
    },
    // 证件号校验事件
    certificateNoChange: function certificateNoChange() {
      var flag = true;
      if (this.form.ownerCertificateType == 'P01' && this.form.ownerCertificateNo) {
        var ownerCertificateNo = Rules['ID_CARD'].value;
        var _flag = ownerCertificateNo.test(this.form.ownerCertificateNo);
        var isValidate = idCardValidate(this.form.ownerCertificateNo);
        if (!_flag || !isValidate) {
          this.$message.error('身份证号码不满足校验规则，请修改！');
          _flag = false;
          return _flag;
        }
        ;
      }
      ;
      return flag;
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      this.$forceUpdate();
      if (limit > 0) {
        if (min && parseFloat(num) < parseFloat(min)) {
          num = min;
        }
        if (max && parseFloat(num) > parseFloat(max)) {
          num = max;
        }
      } else {
        if (min && num < min) {
          num = min;
        }
        if (max && num > max) {
          num = max;
        }
      }
      return limitInput(num, limit);
    }
  }
};