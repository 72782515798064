var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("el-card", { staticStyle: { flex: "1" } }, [
        _c(
          "div",
          {
            staticStyle: { "text-align": "center", "margin-top": "100px" },
            attrs: { id: "dashboard-container" },
          },
          [
            _c("p", [
              _c(
                "span",
                { staticStyle: { "font-size": "30px", "font-weight": "600" } },
                [_vm._v("欢迎使用")]
              ),
            ]),
            _c("p", [
              _c(
                "span",
                { staticStyle: { "font-size": "20px", "font-weight": "500" } },
                [_vm._v(_vm._s(_vm.title))]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }