var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: _vm.width,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        fullscreen: _vm.fullscreen,
        top: "8vh",
        modal: _vm.modal,
        "append-to-body": _vm.appendToBody,
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { ref: "imageWrapper" }, [
        _c(
          "div",
          { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
          [
            _c("FormLabel", { attrs: { "form-label": _vm.title } }),
            _c("el-divider"),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.formLoading,
                expression: "formLoading",
              },
            ],
          },
          [
            _c(
              "el-card",
              {
                style: {
                  paddingRight: "0px",
                  maxHeight: _vm.formHeight,
                  overflowY: "auto",
                  overflowX: "hidden",
                },
                attrs: { shadow: "never" },
              },
              [_vm._t("content")],
              2
            ),
            _c(
              "div",
              {
                staticClass: "footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [_vm._t("footer")],
              2
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }