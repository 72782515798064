import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customersRequest from '@/api/customers';
import { limitInput } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    configId: {
      type: Number,
      default: null
    },
    currentItem: {
      ttype: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        activeName: '',
        configId: '',
        maxDay: '',
        minDay: ''
      },
      rules: {
        activeName: {
          required: true,
          message: '请输入流程名称',
          trigger: "blur"
        },
        minDay: {
          required: true,
          message: '请输入最小值',
          trigger: "blur"
        },
        maxDay: {
          required: true,
          message: '请输入最大值',
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      console.log(this.configId, 'this.configId');
      this.form = {
        activeName: '',
        configId: this.configId,
        maxDay: '',
        minDay: ''
      };
      if (this.opType === "add") {} else {
        this.form.activeName = this.currentItem.activeName;
        this.form.maxDay = this.currentItem.maxDay;
        this.form.minDay = this.currentItem.minDay;
      }
      ;
      this.$refs.detail.resetFields();
    },
    onInput: function onInput(num, min, max, limit) {
      this.$forceUpdate();
      //对输入内容做精度校验
      if (min && num < min) {
        num = min;
      }
      if (max && num > max) {
        num = max;
      }
      return limitInput(num, limit);
    },
    validate: function validate() {
      var flag = false;
      if (Number(this.form.minDay) >= Number(this.form.maxDay)) {
        this.$message.warning('时间节点最小值不能大于最大值');
        flag = true;
        return flag;
      }
      ;
      return flag;
    },
    // 保存数据
    save: function save() {
      var _this = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          if (_this.validate()) return;
          _this.formLoading = true;
          var req = _this.opType === 'add' ? customersRequest.createActivity(_this.form) : customersRequest.updateActivity(_this.currentItem.activeId, _this.form);
          req.then(function (res) {
            _this.formLoading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
              _this.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};