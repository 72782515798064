var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "115px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "el-row-style" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报案保险公司：",
                                prop: "insCorpCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择报案保险公司",
                                    clearable: "",
                                    filterable: "",
                                    disabled: "",
                                  },
                                  model: {
                                    value: _vm.form.insCorpCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "insCorpCode", $$v)
                                    },
                                    expression: "form.insCorpCode",
                                  },
                                },
                                _vm._l(_vm.supplyers, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.shortName,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车牌号码：", prop: "plateNo" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "10",
                                      disabled: _vm.checked,
                                      placeholder: "请输入车牌号",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.plateNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "plateNo", $$v)
                                      },
                                      expression: "form.plateNo",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "padding-left": "10px" } },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: { change: _vm.noPlateNoChange },
                                          model: {
                                            value: _vm.checked,
                                            callback: function ($$v) {
                                              _vm.checked = $$v
                                            },
                                            expression: "checked",
                                          },
                                        },
                                        [_vm._v("未上牌")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号：", prop: "vin" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入车架号",
                                  clearable: "",
                                },
                                on: { input: _vm.inputvin },
                                model: {
                                  value: _vm.form.vin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vin", $$v)
                                  },
                                  expression: "form.vin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "品牌型号：", prop: "modelName" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入品牌型号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.modelName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "modelName", $$v)
                                  },
                                  expression: "form.modelName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报案人姓名：",
                                prop: "reporterName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入报案人姓名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.reporterName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "reporterName", $$v)
                                  },
                                  expression: "form.reporterName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "报案人电话：",
                                prop: "reporterMobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "11",
                                  placeholder: "请输入报案人电话",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.reporterMobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "reporterMobile", $$v)
                                  },
                                  expression: "form.reporterMobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "事故发生时间：",
                                prop: "accidentTime",
                              },
                            },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  placeholder: "请选择事故发生时间",
                                },
                                model: {
                                  value: _vm.form.accidentTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "accidentTime", $$v)
                                  },
                                  expression: "form.accidentTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "事故发生地址：",
                                prop: "accidentAddress",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入事故发生地址",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.accidentAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "accidentAddress", $$v)
                                  },
                                  expression: "form.accidentAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "是否在现场：", prop: "isSite" },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.isSite,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isSite", $$v)
                                    },
                                    expression: "form.isSite",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否可行驶：",
                                prop: "isDriving",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.isDriving,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isDriving", $$v)
                                    },
                                    expression: "form.isDriving",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("是"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("否"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("创建推送修工单")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }