import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/**
 * 批单管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/riskAccidentType/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 14), {
      params: data
    });
  },
  /**
   * 根据保司编码获取非车险类型列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   */
  getAccidentTypeList: function getAccidentTypeList(data) {
    return request.get(getUrl("/riskAccidentType/getAccidentTypeList", 14), {
      params: data
    });
  },
  /**
   * 新增数据
   * @param {*} data 内容
   */
  addData: function addData(data) {
    return request.post(getUrl("/riskAccidentType/create", 14), data);
  },
  /**
   * 修改数据
   * @param {*} data 内容
   */
  updateData: function updateData(id, data) {
    return request.post(getUrl("/riskAccidentType/updateAccidentType?accidentId=".concat(id), 14), data);
  },
  /**
   * 查看数据
   * @param {*} data 内容
   */
  getRiskProductDetail: function getRiskProductDetail(data) {
    return request.get(getUrl("/riskAccidentProduct/getRiskProductDetail", 14), {
      params: data
    });
  },
  /**
   * 删除数据
   * @param {*} id 
   */
  deleteData: function deleteData(id) {
    return request.delete(getUrl("/riskAccidentType/deleteAccidentType?riskAccidentTypeIdList=".concat(id.toString()), 14));
  }
};
export default objApi;