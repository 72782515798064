import "core-js/modules/es.array.concat.js";
/**
 * 车系
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 车型列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/vehicleBrandModel/searchBrands?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 10), {
      params: data
    });
  },
  /**
    * 获取所有非车车型（带层次
    * @param {*} data 内容
    */
  getAllVehicle: function getAllVehicle(data) {
    return request.get(getUrl("/nonauto/brand/getAllVehicle", 10), {
      params: data
    });
  },
  /**
   * 车型  删除
   * @param {*} id 
   */
  delete: function _delete(ids) {
    return request.delete(getUrl("/vehicleBrandModel/deleteBrandModelById?brandModelId=".concat(ids), 10));
  },
  /**
   * 上传
   */
  //  importExcel: function(data) {
  //      return request({
  //          method: 'post',
  //          url: getUrl(`/sales/import`, 1),
  //          data: data,
  //          headers: {
  //              'content-type': 'multipart/form-data'
  //          }
  //      })
  //  },
  /**
  /**
   * 车型  新增
   */
  add: function add(data) {
    return request.post(getUrl("/vehicleBrandModel/createBrandModel", 10), data);
  },
  /**
       * 车型   编辑
       */
  modelsBrand: function modelsBrand(id, data) {
    return request.put(getUrl("/vehicleBrandModel/updateBrandModel?brandModelId=".concat(id), 10), data);
  },
  /**
       * 车型 （车品下》
       */
  allTheModels: function allTheModels(id) {
    return request.get(getUrl("/vehicleBrandModel/getModelListById?brandId=".concat(id), 10));
  },
  /**
       * 所有车型 
       */
  motorcycleOptions: function motorcycleOptions() {
    return request.get(getUrl("/vehicleBrandModel/getBrandModelList", 10));
  },
  /**
       * 品牌选项列表
       */
  brandOptions: function brandOptions() {
    return request.get(getUrl("/vehicleBrand/getBrandList", 10));
  },
  /**
   * 品牌维护列表
   */
  brandList: function brandList(pageNum, pageSize, data) {
    return request.get(getUrl("/vehicleBrand/searchBrands?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 10), {
      params: data
    });
  },
  /**
   * 品牌维护  新增
   */
  brandBrand: function brandBrand(data) {
    return request.post(getUrl("/vehicleBrand/createBrand", 10), data);
  },
  /**
   * 品牌维护  编辑
   */
  updateBrand: function updateBrand(id, data) {
    return request.put(getUrl("/vehicleBrand/updateBrand?id=".concat(id), 10), data);
  },
  /**
   * 品牌  删除
   * @param {*} id 
   */
  brandDelete: function brandDelete(ids) {
    return request.delete(getUrl("/vehicleBrand/deleteBrandById?id=".concat(ids), 10));
  },
  /**
  *获取所有品牌车系列表（在车型用到）/vehicleBrandSeries/getBrandAndSeriesListById
  */
  Options: function Options(id) {
    return request.get(getUrl("/vehicleBrandSeries/getBrandAndSeriesListById?brandId=".concat(id), 10));
  },
  carsOptions: function carsOptions() {
    return request.get(getUrl("/vehicleBrandSeries/getBrandSeriesList", 10));
  },
  /**
  /**
     * 车系列表cars
     */
  carSeriesList: function carSeriesList(pageNum, pageSize, data) {
    return request.get(getUrl("/vehicleBrandSeries/searchBrands?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 10), {
      params: data
    });
  },
  /**
   *车系  新增
   */
  carSeriesBrand: function carSeriesBrand(data) {
    return request.post(getUrl("/vehicleBrandSeries/createBrandSeries", 10), data);
  },
  /**
   *车系  编辑
   */
  carSeriesEditor: function carSeriesEditor(id, data) {
    return request.put(getUrl("/vehicleBrandSeries/updateBrandSeries?seriesId=".concat(id), 10), data);
  },
  /**
       * 车系  删除
       * @param {*} id 
       */
  carSeriesDelete: function carSeriesDelete(ids) {
    return request.delete(getUrl("/vehicleBrandSeries/deleteBrandSeriesById?seriesId=".concat(ids), 10));
  },
  /**
   * 
   * 下载excel模板
   */
  download: function download() {
    return request({
      method: 'get',
      url: getUrl("/sales/downloadTemplate", 1),
      responseType: 'blob'
    });
  },
  /**
   * 非车车型版本
   */
  getNonVehicleModelList: function getNonVehicleModelList(pageNum, pageSize, data) {
    return request.get(getUrl("/nonauto/brand/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 10), {
      params: data
    });
  },
  /**
   * 非车车型版本新增
   */
  addNonBrand: function addNonBrand(data) {
    return request.post(getUrl("/nonauto/brand/create", 10), data);
  },
  /**
   * 非车车型版本修改
   */
  updateNonBrand: function updateNonBrand(data) {
    return request.post(getUrl("/nonauto/brand/update", 10), data);
  },
  /**
   * 非车车型版本修改
   */
  deleteNonBrand: function deleteNonBrand(id) {
    return request.delete(getUrl("/nonauto/brand/delete/".concat(id), 10));
  }
};
export default objApi;