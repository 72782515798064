var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls customer-import-list-dialog",
      staticStyle: { "max-height": "550px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "table-cls normal-btn-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: "390px",
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "quoteNo",
                      label: "业务单号",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "plateNo",
                      label: "车牌号",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.plateNo &&
                            scope.row.plateNo != "未上牌" &&
                            scope.row.plateNo != "新车"
                              ? _c("div", [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.plateNo)),
                                  ]),
                                ])
                              : _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [_c("span", [_vm._v("未上牌")])]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "insCityName",
                      label: "投保城市",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "insuredName",
                      label: "被保人名称",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "salemanName",
                      label: "业务员",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vin",
                      label: "车架号",
                      width: "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ownerName",
                      label: "车主名称",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "salesOrgName",
                      label: "出单网点",
                      width: "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "operatorName",
                      label: "操作人",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateTime",
                      label: "操作时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "投保状态",
                      fixed: "right",
                      "min-width": "180",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.status
                              ? _c(
                                  "div",
                                  _vm._l(
                                    scope.row.status,
                                    function (item, index) {
                                      return _c("div", { key: index }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.insCorpName
                                                  ? item.insCorpName + ":"
                                                  : ""
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "3px",
                                            },
                                            style: {
                                              color: _vm.getStatusColor(
                                                item.proposalStatus
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(item.statusDescription)
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                )
                              : _c(
                                  "div",
                                  { staticStyle: { "text-align": "center" } },
                                  [_c("span", [_vm._v("暂存")])]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }