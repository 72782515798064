var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.formRules,
                          "label-width": "120px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            model: {
                              value: _vm.activeNames,
                              callback: function ($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "基本信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "姓名：",
                                              prop: "ownerName",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "ownerName",
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                placeholder: "请输入姓名",
                                              },
                                              model: {
                                                value: _vm.form.ownerName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "ownerName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.ownerName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "证件类型：",
                                              prop: "ownerCertificateType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                ref: "ownerCertificateType",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  clearable: "",
                                                  placeholder: "请选择证件类型",
                                                },
                                                on: {
                                                  change: _vm.selectCertificate,
                                                },
                                                model: {
                                                  value:
                                                    _vm.form
                                                      .ownerCertificateType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "ownerCertificateType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.ownerCertificateType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.VI_CERTIFICATE_TYPE,
                                                function (group) {
                                                  return _c(
                                                    "el-option-group",
                                                    {
                                                      key: group.label,
                                                      attrs: {
                                                        label: group.label,
                                                      },
                                                    },
                                                    _vm._l(
                                                      group.options,
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label:
                                                              item.dictName,
                                                            value:
                                                              item.dictCode,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "证件号码：",
                                              prop: "ownerCertificateNo",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "ownerCertificateNo",
                                              attrs: {
                                                clearable: "",
                                                maxlength: "20",
                                                placeholder: "请输入证件号码",
                                              },
                                              on: {
                                                blur: _vm.certificateNoChange,
                                                input: _vm.inputCardNo,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.ownerCertificateNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "ownerCertificateNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.ownerCertificateNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "手机号码：",
                                              prop: "ownerPhone",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "ownerPhone",
                                              attrs: {
                                                clearable: "",
                                                maxlength: "11",
                                                placeholder: "请输入手机号码",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.form.ownerPhone =
                                                    _vm.form.ownerPhone.replace(
                                                      /[^\d]/g,
                                                      ""
                                                    )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.ownerPhone,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "ownerPhone",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.ownerPhone",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "邮箱：",
                                              prop: "email",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "email",
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                placeholder: "请输入邮箱",
                                              },
                                              model: {
                                                value: _vm.form.email,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.email",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "所在省市区：",
                                              prop: "cascaderAddr",
                                            },
                                          },
                                          [
                                            _c("el-cascader", {
                                              ref: "cascaderAddr",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                filterable: "",
                                                clearable: "",
                                                options: _vm.regionData,
                                                placeholder: "请选择所在省市区",
                                              },
                                              on: { change: _vm.handleChange },
                                              model: {
                                                value: _vm.form.cascaderAddr,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "cascaderAddr",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.cascaderAddr",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 24,
                                          md: 24,
                                          lg: 16,
                                          xl: 16,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "详细地址：",
                                              prop: "address",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  ref: "address",
                                                  attrs: {
                                                    clearable: "",
                                                    maxlength: "40",
                                                    placeholder:
                                                      "请输入详细地址",
                                                  },
                                                  model: {
                                                    value: _vm.form.address,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "address",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.address",
                                                  },
                                                }),
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                      plain: "",
                                                      icon: "el-icon-search",
                                                    },
                                                  },
                                                  [_vm._v("业务查询")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeNames,
                              callback: function ($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "2" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "车辆信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车牌号：",
                                              prop: "plateNo",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  ref: "plateNo",
                                                  attrs: {
                                                    clearable: "",
                                                    maxlength: "10",
                                                    disabled: _vm.checked,
                                                    placeholder: "请输入车牌号",
                                                  },
                                                  on: {
                                                    input: _vm.inputPlateNo,
                                                  },
                                                  model: {
                                                    value: _vm.form.plateNo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "plateNo",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.plateNo",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "padding-left": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        on: {
                                                          change:
                                                            _vm.noPlateNoChange,
                                                        },
                                                        model: {
                                                          value: _vm.checked,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.checked = $$v
                                                          },
                                                          expression: "checked",
                                                        },
                                                      },
                                                      [_vm._v("未上牌")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车架号：",
                                              prop: "vin",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "vin",
                                              attrs: {
                                                clearable: "",
                                                maxlength: "17",
                                                placeholder: "请输入车架号",
                                              },
                                              on: { input: _vm.inputvin },
                                              model: {
                                                value: _vm.form.vin,
                                                callback: function ($$v) {
                                                  _vm.$set(_vm.form, "vin", $$v)
                                                },
                                                expression: "form.vin",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "发动机号：",
                                              prop: "engineNo",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "engineNo",
                                              attrs: {
                                                maxlength: "40",
                                                placeholder: "请输入发动机号",
                                                clearable: "",
                                              },
                                              on: { input: _vm.inputEngineNo },
                                              model: {
                                                value: _vm.form.engineNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "engineNo",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.engineNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "注册日期：",
                                              prop: "registerDate",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              ref: "registerDate",
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请选择注册日期",
                                                "picker-options":
                                                  _vm.pickerOptions,
                                                format: "yyyy-MM-dd",
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                type: "date",
                                              },
                                              model: {
                                                value: _vm.form.registerDate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "registerDate",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.registerDate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "使用性质：",
                                              prop: "useAttribute",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                ref: "useAttribute",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择使用性质",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.form.useAttribute,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "useAttribute",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.useAttribute",
                                                },
                                              },
                                              _vm._l(
                                                _vm.USE_ATTRIBUTE,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "品牌型号：",
                                              prop: "modelName",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "modelName",
                                              attrs: {
                                                maxlength: "40",
                                                placeholder: "请输入品牌型号",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.form.modelName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "modelName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.modelName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车辆类型：",
                                              prop: "vehicleType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                ref: "vehicleType",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择车辆类型",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.form.vehicleType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "vehicleType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.vehicleType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.VEHICLE_KINDS,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "号牌种类：",
                                              prop: "plateType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                ref: "plateType",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择号牌种类",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.form.plateType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "plateType",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.plateType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.PLATE_TYPE,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "能源类型：",
                                              prop: "fuelType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                ref: "fuelType",
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择能源类型",
                                                  clearable: "",
                                                },
                                                model: {
                                                  value: _vm.form.fuelType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "fuelType",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.fuelType",
                                                },
                                              },
                                              _vm._l(
                                                _vm.ENGRGY_TYPES,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.dictName,
                                                      value: item.dictCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "整备质量(吨)：",
                                              prop: "wholeWeight",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              ref: "wholeWeight",
                                              attrs: {
                                                maxlength: "40",
                                                placeholder:
                                                  "请输入整备质量(吨)",
                                                clearable: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.form.wholeWeight =
                                                    _vm.onInput(
                                                      _vm.form.wholeWeight,
                                                      0,
                                                      1000,
                                                      3
                                                    )
                                                },
                                              },
                                              model: {
                                                value: _vm.form.wholeWeight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "wholeWeight",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.wholeWeight",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeNames,
                              callback: function ($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "3" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "机构信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-table",
                                  {
                                    ref: "multipleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      border: "",
                                      data: _vm.tableData,
                                      "header-cell-style": {
                                        background: "#F7F7F7",
                                      },
                                      "highlight-current-row": "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "",
                                        label: "所属机构",
                                        "min-width": "150",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "",
                                        label: "车架号",
                                        "min-width": "170",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "",
                                        label: "客户来源",
                                        "min-width": "120",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "",
                                        label: "客户经理",
                                        "min-width": "120",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "",
                                        label: "更新时间",
                                        width: "150",
                                        "show-overflow-tooltip": "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c("div", { staticClass: "footer-btn-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-back" },
                        on: { click: _vm.closePage },
                      },
                      [_vm._v("返回")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          icon: "el-icon-check",
                          type: "primary",
                        },
                        on: { click: _vm.save },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }