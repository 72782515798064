import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import policyRequest from '@/api/insurancepolicy';
import { character, commonExport } from '@/utils/common';
export default {
  components: {
    Pagination: Pagination
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentId: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      searchForm: {
        policyImportId: ''
      },
      tableData: [],
      loading: false,
      statusList: [{
        value: 'WAITING',
        label: '等待中'
      }, {
        value: 'SUCCESS',
        label: '成功'
      }, {
        value: 'FAILED',
        label: '失败'
      }, {
        value: 'CANCELED',
        label: '取消'
      }],
      drawer: false,
      insuredDateTime: [],
      modifiedDateTime: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.resetSearch();
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    exportExcels: function exportExcels() {
      //导出
      var searchForm = _objectSpread(_objectSpread(_objectSpread({}, this.searchForm), this.listQuery), {}, {
        count: false
      });
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '保单导入明细',
        exportUrl: '/vi-core-service/policyImport/searchItem',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    dateChange: function dateChange(start, end, val, type) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    inputPlateNo: function inputPlateNo(val) {
      //输入的车牌号中的字母自动转大写
      this.searchForm.plateNo = _.toUpper(val);
    },
    checkValue: function checkValue(val) {
      //输入的车架号中的字母自动转大写，并去除i，o，q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    initData: function initData() {
      this.searchForm.policyImportId = this.currentId;
      this.loadData();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        policyImportId: this.searchForm.policyImportId
      };
      this.insuredDateTime = [];
      this.modifiedDateTime = [];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    breakData: function breakData() {
      // 刷新
      // this.resetSearch();
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.loading = true;
      this.drawer = false;
      if (this.searchForm && this.searchForm.insuredTimeEnd) {
        this.searchForm.insuredTimeEnd = this.searchForm.insuredTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      if (this.searchForm && this.searchForm.modifiedTimeEnd) {
        this.searchForm.modifiedTimeEnd = this.searchForm.modifiedTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      var data = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      policyRequest.getPolicyImportItemList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};