var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls call-report" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticClass: "search-box",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _vm.bizType === "OEM"
                    ? _c(
                        "div",
                        [
                          _c("span", [_vm._v("所属机构：")]),
                          _c("OemSelect", {
                            staticStyle: {
                              "max-width": "150px",
                              display: "inline-block",
                            },
                            attrs: {
                              searchForm: _vm.orgForm,
                              canClickArea: true,
                            },
                            on: { nodeClick: _vm.handleNodeClick },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.bizType === "DEALER"
                    ? _c(
                        "div",
                        [
                          _c("span", [_vm._v("所属机构：")]),
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.searchForm.orgName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "orgName", $$v)
                              },
                              expression: "searchForm.orgName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("通话时间：")]),
                      _c("el-date-picker", {
                        staticStyle: { width: "260px" },
                        attrs: {
                          "start-placeholder": "起期",
                          "end-placeholder": "止期",
                          type: "daterange",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "unlink-panels": "",
                          "range-separator": "——",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.dateChange(
                              "startDate",
                              "endDate",
                              $event
                            )
                          },
                        },
                        model: {
                          value: _vm.startEndTime,
                          callback: function ($$v) {
                            _vm.startEndTime = $$v
                          },
                          expression: "startEndTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: { click: _vm.searchData },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                { staticStyle: { position: "relative" } },
                [
                  _c(
                    "el-tabs",
                    {
                      on: { "tab-click": _vm.handleTabClick },
                      model: {
                        value: _vm.searchForm.category,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "category", $$v)
                        },
                        expression: "searchForm.category",
                      },
                    },
                    [
                      _c("el-tab-pane", {
                        attrs: {
                          label: "按坐席统计(仅当前机构)",
                          name: "SEATS",
                        },
                      }),
                      _vm.bizType !== "DEALER"
                        ? _c("el-tab-pane", {
                            attrs: {
                              label: "按机构汇总统计(仅下级机构)",
                              name: "ORGANIZATION",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "table-btn-cls",
                      staticStyle: {
                        position: "absolute",
                        right: "0",
                        bottom: "22px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.breakData },
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.exportExcels },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightS,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      label: "名称",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "callQty",
                      label: "呼叫总数",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "callOutQty",
                      label: "外呼总数",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "callOutValidQty",
                      label: "外呼有效数",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "callOutValidQuotiety",
                      label: "外呼有效率",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "callOutTotalTime",
                      label: "外呼总时长(秒)",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "callOutAvgTime",
                      label: "外呼平均时长(秒)",
                      "min-width": "130",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "talkDate",
                      label: "通话时间",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }