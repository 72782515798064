//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import request from '@/api/message';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    },
    rangeList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    classifyList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      formLoading: false,
      form: {},
      rules: {
        messageTitle: [{
          required: true,
          message: '请输入标题',
          trigger: 'blur'
        }],
        messageContentTranslated: [{
          required: true,
          message: '请输入内容',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.detail.resetFields();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {};
      if (this.currentType === 'viewDetail') {
        this.$refs.detail.resetFields();
        request.viewMessageReceived(this.currentId).then(function (res) {
          if (res.code === 200 && res.data) {
            _this.form = res.data;
          }
          ;
        });
      }
      ;
    },
    // 保存数据
    // save() {
    //     this.$refs.detail.validate(valid => {
    //         if (valid) {
    //             this.formLoading = true;
    //                 request.viewMessageReceived(this.form).then(res => {
    //                 this.formLoading = false;
    //                 if (res.code === 200) {
    //                     this.$message.success(res.msg);
    //                     this.closePage();
    //                 };
    //             }).catch(err => {
    //                 this.formLoading = false;
    //             });
    //         };
    //     });
    // },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};