import "core-js/modules/es.array.concat.js";
/**
 * 保险业务开通接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 业务申请列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/business/apply/page?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 11), {
      params: data
    });
  },
  /**
   * 业务文件删除
   * @param {*} id 
   */
  delete: function _delete(id) {
    return request.delete(getUrl("/business/deleted/".concat(id), 11));
  },
  /**
  * 业务管理列表
  * @param {*} pageNum 当前页
  * @param {*} pageSize 每页显示条数
  * @param {*} data 内容
  */
  managementList: function managementList(pageNum, pageSize, data) {
    return request.get(getUrl("/business/page/admin?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 11), {
      params: data
    });
  },
  /**
   * 业务管理明细接口
   * @param {*} id 
   */
  query: function query(id) {
    return request.get(getUrl("/business/details/".concat(id), 11));
  },
  /**
     * 业务管理修改状态接口
     * @param {*} data 内容
     */
  update: function update(data) {
    return request.put(getUrl("/business/update/applyStatus", 11), data);
  },
  /**
     * 业务申请提交接口
     * @param {*} data 内容
     */
  create: function create(data) {
    return request.put(getUrl("/business/update", 11), data);
  },
  /**
     * 修改渠道设置状态接口
     * @param {*} id 
     * @param {*} status 状态
     */
  updateChannel: function updateChannel(id, status) {
    return request.put(getUrl("/business/channelSet/".concat(id, "?channelSetStatus=").concat(status), 11));
  }
};
export default objApi;