//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import business from '@/api/insuranceBusiness';
export default {
  props: {
    applyIdArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      form: {
        applyIds: [],
        auditStatus: 'AUDIT_SUCCEEDED',
        rejectCause: ''
      },
      formLoading: false
    };
  },
  watch: {
    applyIdArr: {
      handler: function handler(newValue, oldValue) {
        this.form.applyIds = newValue;
      },
      deep: true
    }
  },
  created: function created() {
    this.form.applyIds = this.applyIdArr;
  },
  methods: {
    // 保存数据
    save: function save() {
      var _this = this;
      this.formLoading = true;
      business.update(this.form).then(function (res) {
        _this.formLoading = false;
        if (res.code === 200) {
          _this.$message.success(res.msg);
          _this.closePage();
        }
      }).catch(function (err) {
        _this.formLoading = false;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    loadData: function loadData() {
      //加载数据
    }
  }
};