import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import Detail from "./detail";
import batchQuoteRequest from '@/api/batchQuote';
import { getUserOrgInfo, getSupplyers, getBeforeDate, getCurrentDate } from '@/utils/common';
export default {
  name: 'BatchQuote',
  components: {
    Pagination: Pagination,
    Detail: Detail,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      supplyers: [],
      drawer: false,
      searchForm: {},
      createDateTime: [getBeforeDate(30), getCurrentDate()],
      list: null,
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      showDialog: false,
      opType: "add",
      monitorId: null,
      activeName: ' ',
      detailTableData: [],
      showDetailDialog: false,
      detailLoading: false,
      taskId: null,
      detailTotal: 0,
      listDetailQuery: {
        pageNum: 1,
        pageSize: 20
      },
      bizType: localStorage.getItem("bizType"),
      dealerName: ""
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getDefaultValue();
    this.loadData();
    this.supplyers = getSupplyers();
  },
  methods: {
    // 获取详情
    getBatchDetailList: function getBatchDetailList() {
      var _this = this;
      this.detailLoading = true;
      batchQuoteRequest.getBatchDetailList(_objectSpread(_objectSpread({}, this.listDetailQuery), {}, {
        taskId: this.taskId
      })).then(function (res) {
        _this.detailLoading = false;
        if (res.code == 200) {
          _this.detailTableData = res.data.list;
          _this.detailTotal = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        return _this.detailLoading = false;
      });
    },
    //日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      var searchForm = {};
      if (val && val.length > 1) {
        searchForm[start] = val[0] + ' 00:00:00';
        searchForm[end] = val[1] + ' 23:59:59';
      } else {
        searchForm[start] = '';
        searchForm[end] = '';
      }
      this.$emit('pickerDateChange', [searchForm[start], searchForm[end]]);
    },
    // 获取默认值
    getDefaultValue: function getDefaultValue() {
      if (this.bizType !== 'OEM') {
        var useInfo = getUserOrgInfo();
        if (!_.isEmpty(useInfo)) {
          this.orgForm = {
            salesOrgType: '',
            salesOrgCode: useInfo.orgCode,
            salesOrgName: useInfo.fullName
          };
          this.searchForm.orgCode = useInfo.orgCode;
          this.dealerName = useInfo.fullName;
        }
      }
    },
    getInsCorpNameByCode: function getInsCorpNameByCode(code) {
      var insCorp = _.find(this.supplyers, function (item) {
        return item.code == code;
      }) || {};
      return insCorp.shortName || "";
    },
    // 关闭弹窗
    closePage: function closePage(isRefresh) {
      if (isRefresh) {
        this.loadData();
      } else {
        this.showDialog = false;
        this.showDetailDialog = false;
      }
    },
    // 机构树点击事件
    handleNodeClick: function handleNodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
    },
    // 详情
    viewDetail: function viewDetail(item) {
      this.showDetailDialog = true;
      this.taskId = item.taskId;
      this.getBatchDetailList();
    },
    // 打开弹窗
    openDialog: function openDialog(type) {
      var item = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.showDialog = true;
      this.opType = type;
      this.monitorId = item.monitorId;
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '批量报价',
        exportUrl: '/vi-core-service/batch/quote/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.createDateTime = [getBeforeDate(30), getCurrentDate()];
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      };
      this.getDefaultValue();
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      if (!_.isEmpty(this.createDateTime)) {
        this.searchForm.createdTimeStart = this.createDateTime[0].split(' ')[0] + ' 00:00:00';
        this.searchForm.createdTimeEnd = this.createDateTime[1].split(' ')[0] + ' 23:59:59';
      }
      this.showDialog = false;
      this.loading = true;
      this.drawer = false;
      this.searchForm.verifyStatus = this.activeName;
      batchQuoteRequest.getList(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm)).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data.list;
          _this2.loading = false;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};