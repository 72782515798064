//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mapStatisticsBoard from './mapStatisticsBoard.vue';
import noVechileMapStatisticsBoard from './noVechileMapStatisticsBoard.vue';
// import {
//     swiper,
//     swiperSlide
// } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
export default {
  name: 'GeneralMapBoard',
  components: {
    mapStatisticsBoard: mapStatisticsBoard,
    noVechileMapStatisticsBoard: noVechileMapStatisticsBoard
    // swiper,
    // swiperSlide,
  },
  data: function data() {
    return {
      looper: true,
      swiperIndex: null,
      title: process.env.VUE_APP_TITLE,
      isFullscreen: false,
      fullscreen: false,
      autoplay: true
    };
  },
  beforeRouteLeave: function beforeRouteLeave(to, form, next) {
    var _this = this;
    console.log('to', to);
    console.log('form', form);
    if (form) {
      // this.autoplay = false
      this.$nextTick(function () {
        _this.$refs.map1.clearTabTimer();
        _this.$refs.map1.clearGetDataTimer();
        _this.$refs.map2.clearTabTimer();
        _this.$refs.map2.clearGetDataTimer();
      });
      next();
    } else {
      next();
    }
  },
  beforeDestroy: function beforeDestroy() {
    console.log(this.$refs.map1);
    // console.log(this.$refs.map2)
    // this.$nextTick(() => {
    this.$refs.map1.clearTabTimer();
    this.$refs.map1.clearGetDataTimer();
    this.$refs.map2.clearTabTimer();
    this.$refs.map2.clearGetDataTimer();
    // })
  },

  computed: {},
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.$refs.carousel.handleMouseEnter = function () {};
      // this.$refs.map1.clearTabTimer()
      // this.$refs.map1.clearGetDataTimer()
      // this.$refs.map2.clearTabTimer()
      // this.$refs.map2.clearGetDataTimer()
    });

    var self = this;
    self.containerH = document.body.clientHeight - 135 + 'px';
    window.onresize = function () {
      if (!self.checkFull()) {
        // 退出全屏后要执行的动作
        self.isFullscreen = false;
        self.containerH = document.body.clientHeight - 135 + 'px';
        self.containerH = this.isFullscreen ? '95vh' : self.containerH;
        self.$forceUpdate();
      }
    };
    // this.switchScreen()
  },

  methods: {
    changeCarousels: function changeCarousels(val) {
      // console.log(this.$refs.map1)
      // console.log(this.$refs.map2)
      if (val == 0) {
        this.$refs.map2.clearTabTimer();
        this.$refs.map2.clearGetDataTimer();
        this.$refs.map1.dateTabChangestart();
      } else if (val == 1) {
        this.$refs.map1.clearTabTimer();
        this.$refs.map1.clearGetDataTimer();
        this.$refs.map2.handleRadio('DAY');
      } else {
        this.$refs.map1.clearTabTimer();
        // this.$refs.map1.clearGetDataTimer()
        this.$refs.map2.clearTabTimer();
        // this.$refs.map2.clearGetDataTimer()
      }
      // if (val == 0) {
      //     // this.$nextTick(() => {
      //     console.log('结束2的所有定时器', val)
      //     this.$refs.map2.clearTabTimer()
      //     this.$refs.map2.clearGetDataTimer()
      //     this.$refs.map1.dateTabChangestart()
      //     // })
      // } else {
      //     // this.$nextTick(() => {
      //     console.log('结束1的所有定时器', val)
      //     this.$refs.map1.clearTabTimer()
      //     this.$refs.map1.clearGetDataTimer()
      //     this.$refs.map2.dateTabChangestart2()
      //     // })
      // }
    },
    delHandleMouseEnter: function delHandleMouseEnter() {
      var _this3 = this;
      this.$nextTick(function () {
        _this3.$refs.carousel.handleMouseEnter = function () {};
      });
    },
    checkFull: function checkFull() {
      //全屏判断状态 取消
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      var isFull = document.mozFullScreen || document.fullScreen ||
      //谷歌浏览器及Webkit内核浏览器
      document.webkitIsFullScreen || document.webkitRequestFullScreen || document.mozRequestFullScreen || document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },
    switchScreen: function switchScreen() {
      var element = document.getElementById('map-container'); //设置后就是   id==con_lf_top_div 的容器全屏
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.containerH = '95vh';
      }
      this.$forceUpdate();
    }
  }
};