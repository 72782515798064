import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    xAxis: {
      type: Array,
      default: []
    },
    series: {
      type: Object,
      default: {}
    },
    lineColor: {
      type: String,
      default: ''
    },
    areaColor: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    series: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions();
    },
    setOptions: function setOptions() {
      var self = this;
      var len = self.series.data.length;
      var value = _.find(self.series.data, function (item) {
        return item < 5 && item > 0;
      });
      if (value) {
        len = 4;
      }
      self.chart.setOption({
        yAxis: {
          show: true,
          splitNumber: len >= 5 ? 5 : 1,
          axisLabel: {
            show: true,
            formatter: function formatter(value) {
              if (self.series.name == "出单") {
                // if (value === 0 || value < 1) {
                //     return Math.ceil(value)
                // } else {
                return "".concat(Math.round(value)) + '单';
                // }
              } else {
                if (value < 10000) {
                  return value;
                } else {
                  if (value < 100000000) {
                    if (value < 10000000) {
                      return "".concat(value / 10000) + '万';
                    } else {
                      return "".concat(value / 10000000) + '千万';
                    }
                  } else {
                    return "".concat(value / 100000000) + '亿';
                  }
                }
              }
            },
            textStyle: {
              color: '#333333',
              //更改坐标轴文字颜色
              fontSize: 12 //更改坐标轴文字大小
            }
          },

          textStyle: {
            color: '#333333',
            //更改坐标轴文字颜色
            fontSize: 12 //更改坐标轴文字大小
          },

          splitLine: {
            //网格线
            lineStyle: {
              type: 'dotted' //设置网格线类型 dotted：点点线   solid:实线
            },

            show: true //隐藏或显示
          },

          axisLine: {
            show: false,
            lineStyle: {
              color: '#DEDEDE'
            }
          }
        },
        grid: {
          left: 55,
          top: 30,
          right: 40,
          bottom: 50,
          containlabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxis,
          boundaryGap: false,
          show: true,
          axisLine: {
            lineStyle: {
              color: '#DEDEDE'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#333333',
              //更改坐标轴文字颜色
              fontSize: 12 //更改坐标轴文字大小
            }
          }
        },

        series: [{
          name: this.series.name,
          symbol: "none",
          data: this.series.data,
          type: 'line',
          smooth: true,
          lineStyle: {
            color: this.lineColor
          },
          areaStyle: {
            color: this.areaColor
          }
        }],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          },

          backgroundColor: 'rgb(0,0,0,0.5)',
          // 提示框浮层的背景颜色。
          borderColor: 'rgb(255,255,255,0.3)',
          // 提示框浮层的边框颜色。
          borderWidth: 0,
          // 提示框浮层的边框宽。
          textStyle: {
            // 提示框浮层的文本样式。
            color: '#fff'
          },
          function: function _function(pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = {
              top: 60
            };
            obj[['left', 'right'][+(pos[0] < size.viewsize[0] / 2)]] = 5;
            return obj;
          }
        }
      });
    }
  }
};