import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import insuranceContractManageRequest from '@/api/insuranceContractManage';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import addDiadlog from './dialog/add';
import dictRequest from '@/api/dictionary';
import ImportDialog from './dialog/importDialog';
import { getSupplyers } from '@/utils/common';
export default {
  name: 'insuranceContractManage',
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    addDiadlog: addDiadlog,
    ImportDialog: ImportDialog
  },
  data: function data() {
    return {
      dialogType: null,
      specialId: null,
      drawer: true,
      loading: true,
      showAddDialog: false,
      searchForm: {},
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      multipleSelection: [],
      riskTypeList: [],
      showImportDialog: false,
      supplyers: []
    };
  },
  created: function created() {
    this.supplyers = getSupplyers();
    // 获取数据列表
    this.getSpecialConfigList();
  },
  mounted: function mounted() {
    this.getDictItemsByCode();
  },
  methods: {
    // 获取险种类型
    getDictItemsByCode: function getDictItemsByCode() {
      var _this = this;
      dictRequest.getDictItemsByCode("RISK_TYPE").then(function (res) {
        if (res.data) {
          _this.riskTypeList = res.data;
        }
      });
    },
    // 获取数据列表
    getSpecialConfigList: function getSpecialConfigList() {
      var _this2 = this;
      this.loading = true;
      var _this$listQuery = this.listQuery,
        pageNum = _this$listQuery.pageNum,
        pageSize = _this$listQuery.pageSize;
      insuranceContractManageRequest.getSpecialConfigList(pageNum, pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = res.data && res.data.list ? res.data.list : [];
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 表格选中操作
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 批量删除
    batchDeletion: function batchDeletion() {
      var _this3 = this;
      this.$confirm('是否确认删除, 确认删除后不能恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this3.multipleSelection) {
          var ids = [];
          _this3.multipleSelection.map(function (item) {
            ids.push(item.specialId);
          });
          _this3.deleteData(ids);
        }
      }).catch(function () {});
    },
    // 重置
    resetSearch: function resetSearch() {
      this.searchForm = {
        insCorpCode: ''
      };
      this.getSpecialConfigList();
    },
    // 查询
    searchData: function searchData() {
      this.getSpecialConfigList();
    },
    // 新增
    showAddDialogClicked: function showAddDialogClicked(type) {
      this.showAddDialog = true;
      this.dialogType = type;
    },
    // 查看
    toView: function toView(type, row) {
      this.showAddDialog = true;
      this.dialogType = type;
      this.specialId = row.specialId.toString();
    },
    // 编辑
    editConfig: function editConfig(type, row) {
      this.showAddDialog = true;
      this.dialogType = type;
      this.specialId = row.specialId.toString();
    },
    // 删除二次确认
    deleteConfig: function deleteConfig(row) {
      var _this4 = this;
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.deleteData([row.specialId]);
      }).catch(function () {});
    },
    // 删除
    deleteData: function deleteData(ids) {
      var _this5 = this;
      insuranceContractManageRequest.deleteData(ids).then(function (res) {
        if (res.code == 200) {
          _this5.$message.success(res.msg);
        }
        _this5.getSpecialConfigList();
      });
    },
    // 关闭弹窗
    loadData: function loadData() {
      this.showAddDialog = false;
      this.showImportDialog = false;
      this.dialogType = null;
      this.getSpecialConfigList();
    }
  }
};