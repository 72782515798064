var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "540px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "标题：", prop: "title" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入标题",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "title", $$v)
                                  },
                                  expression: "form.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label:
                                  _vm.msgAnnouncementStatus ==
                                  "announcementStatus"
                                    ? "公告内容："
                                    : "消息内容：",
                                prop: "content",
                              },
                            },
                            [
                              _vm.msgAnnouncementStatus == "announcementStatus"
                                ? _c("el-input", {
                                    attrs: {
                                      rows: 20,
                                      type: "textarea",
                                      placeholder: "请输入公告内容",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.content,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "content", $$v)
                                      },
                                      expression: "form.content",
                                    },
                                  })
                                : _c("el-input", {
                                    attrs: {
                                      rows: 5,
                                      type: "textarea",
                                      placeholder: "请输入消息内容",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.content,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "content", $$v)
                                      },
                                      expression: "form.content",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.msgAnnouncementStatus == "announcementStatus"
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 22 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "附件上传：" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      staticClass: "upload-demo",
                                      attrs: {
                                        multiple: "",
                                        action: "",
                                        limit: 10,
                                        "http-request": function (param) {
                                          return _vm.uploadFile(
                                            param,
                                            "BUSINESS_LICENSE"
                                          )
                                        },
                                        "show-file-list": false,
                                        "file-list": _vm.fileList,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("选择文件")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.form.file, function (file, i) {
                                    return _c("div", { key: i }, [
                                      _c("div", { staticClass: "file-box" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "file-text",
                                            attrs: { title: file.name },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handle(file)
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(file.name))]
                                        ),
                                        _c("i", {
                                          staticClass: "el-icon-close",
                                          staticStyle: {
                                            "font-size": "18px",
                                            "margin-top": "5px",
                                            cursor: "pointer",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.ondelete("VEHICLE_KM")
                                            },
                                          },
                                        }),
                                      ]),
                                    ])
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }