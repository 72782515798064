import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import Dealers from '@/api/dealerInformation';
import DealerMaintenanceDialog from './dialog/DealerMaintenanceDialog';
import ImportDialog from './dialog/ImportDialog';
import { commonExport } from '@/utils/common';
export default {
  name: 'DealerInformationMaintenance',
  components: {
    Pagination: Pagination,
    DealerMaintenanceDialog: DealerMaintenanceDialog,
    ImportDialog: ImportDialog
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      showImportDialog: false,
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      highlightCurrent: true,
      showDialog: false,
      opType: '',
      insCorpItem: {},
      dealerIdArr: [],
      dealerId: '',
      cityList: [],
      bizType: localStorage.getItem("bizType") || '',
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
      statusData: [{
        value: 'NORMAL',
        label: '已激活'
      }, {
        value: 'INACTIVE',
        label: '未激活'
      }, {
        value: 'INVALID',
        label: '禁用'
      }]
    };
  },
  created: function created() {
    this.cityList = JSON.parse(localStorage.getItem('VI_CityAreaTree')) || [];
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    cityData: function cityData(row) {
      var city = [];
      if (row.provinceName) {
        city.push(row.provinceName);
        if (row.cityName) {
          city.push(row.cityName);
          if (row.districtName) {
            city.push(row.districtName);
          }
        }
      }
      return city.join('-');
    },
    // 切换机构
    nodeClick: function nodeClick(val) {
      this.searchForm.dealerName = val.salesOrgName;
    },
    // 切换经销商状态
    changeDealerState: function changeDealerState(val) {
      var _this = this;
      var postData = {
        dealerId: val.dealerId,
        disabledStatus: val.dealerSatus == 'NORMAL' ? 'DISABLED' : 'ENABLED'
      };
      Dealers.changeDealerState(postData).then(function (res) {
        if (res.code == 200) {
          _this.loadData();
        }
      });
    },
    //省市区选择事件
    handleChange: function handleChange(val) {
      if (!val) return;
      this.searchForm.provinceCode = val[0];
      this.searchForm.cityCode = val[1];
      this.searchForm.districtCode = val[2];
      this.$forceUpdate();
    },
    importFn: function importFn() {
      this.showImportDialog = true;
    },
    // 导出
    exportFn: function exportFn() {
      var searchForm = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      var exporParams = {
        total: this.total,
        limitNum: 200000,
        fileName: '经销商信息维护导出',
        exportUrl: '/tp-organization-service/dealer/info',
        searchForm: searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //删除单条数据
    deleteInsCorp: function deleteInsCorp(item) {
      var _this2 = this;
      this.$confirm('您确定要删除该数据吗', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        _this2.commonDelete(item.dealerId.toString());
      }).catch(function () {});
    },
    //批量删除数据
    batchDeleteInsCorp: function batchDeleteInsCorp() {
      var _this3 = this;
      if (!this.dealerIdArr.length) {
        this.$message.warning('请选择需要删除的数据');
        return;
      }
      this.$confirm('您确定要删除选中的数据吗', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        _this3.commonDelete(_this3.dealerIdArr.join(','));
      }).catch(function () {});
    },
    // 删除方法
    commonDelete: function commonDelete(id) {
      var _this4 = this;
      Dealers.delete(id).then(function (res) {
        if (res.code === 200) {
          _this4.$message({
            message: res.msg,
            type: 'success'
          });
          _this4.loadData();
        }
      });
    },
    //编辑
    editConfig: function editConfig(type, item) {
      this.opType = type;
      this.showDialog = true;
      this.dealerId = item.dealerId.toString();
    },
    //新增
    addInscorp: function addInscorp(type) {
      this.opType = type;
      this.showDialog = true;
    },
    breakData: function breakData() {
      // this.resetSearch()
      this.loadData();
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this5 = this;
      this.loading = true;
      this.dealerIdArr = [];
      this.showImportDialog = false;
      this.showDialog = false;
      Dealers.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm).then(function (res) {
        if (res.code === 200) {
          _this5.tableData = res.data.list;
          _this5.loading = false;
          _this5.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this5.loading = false;
      });
    },
    // 复选框数据
    handleSelectionChange: function handleSelectionChange(val) {
      this.dealerIdArr = val.map(function (val) {
        return val.dealerId;
      });
    }
  }
};