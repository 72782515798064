import "core-js/modules/es.array.concat.js";
/**
 * 服务包模块列表
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取车险产品列表
   */
  getInsuranceProductLists: function getInsuranceProductLists(data) {
    return request.get(getUrl("/scheme/insurance/search", 15), {
      params: data
    });
  },
  /**
   * 车险产品新增
   */
  InsuranceProductAdd: function InsuranceProductAdd(data) {
    return request.post(getUrl("/scheme/insurance/add", 15), data);
  },
  /**
   * 车险产品详情
   */
  getInsuranceProductDetail: function getInsuranceProductDetail(ids) {
    return request.get(getUrl("/scheme/insurance/get?insuranceSchemeId=".concat(ids), 15));
  },
  /**
   * 车险产品删除
   */
  InsuranceProductDel: function InsuranceProductDel(ids) {
    return request.delete(getUrl("/scheme/insurance/delete?ids=".concat(ids), 15));
  },
  /**
   * 车险产品修改
   */
  InsuranceProductUpdate: function InsuranceProductUpdate(ids, data) {
    return request.put(getUrl("/scheme/insurance/update?insuranceSchemeId=".concat(ids), 15), data);
  },
  /**
   * 查询驾意险方案分页列表
   */
  getAccidentLists: function getAccidentLists(data) {
    return request.get(getUrl("/scheme/accident", 15), {
      params: data
    });
  },
  /**
   * 驾意险方案新增
   */
  accidentAdd: function accidentAdd(data) {
    return request.post(getUrl("/scheme/accident", 15), data);
  },
  /**
   * 驾意险方案详情
   */
  getAccidentDetail: function getAccidentDetail(id) {
    return request.get(getUrl("/scheme/accident/".concat(id), 15));
  },
  /**
   * 驾意险方案删除
   */
  AccidentDel: function AccidentDel(id) {
    return request.delete(getUrl("/scheme/accident?ids=".concat(id), 15));
  },
  /**
   * 驾意险方案编辑
   */
  AccidentUpdate: function AccidentUpdate(id, data) {
    return request.put(getUrl("/scheme/accident/".concat(id), 15), data);
  },
  /**
   * 查询服务包方案分页列表
   */
  packageLists: function packageLists(data) {
    return request.get(getUrl("/servicePack/scheme/search", 15), {
      params: data
    });
  },
  /**
   * 新增服务包方案
   */
  packageAdd: function packageAdd(data) {
    return request.post(getUrl("/servicePack/scheme/add", 15), data);
  },
  /**
   * 服务包方案详情
   */
  packageDetail: function packageDetail(id) {
    return request.get(getUrl("/servicePack/scheme/get?packSchemeId=".concat(id), 15));
  },
  /**
   * 服务包方案编辑
   */
  packageUpdate: function packageUpdate(id, data) {
    return request.put(getUrl("/servicePack/scheme/update?packSchemeId=".concat(id), 15), data);
  },
  /**
   * 服务包方案启用禁用
   */
  packageEnabled: function packageEnabled(id, enabled) {
    return request.put(getUrl("/servicePack/scheme/enabled?packSchemeId=".concat(id, "&enabled=").concat(enabled), 15));
  },
  /**
   * 服务包方案删除
   */
  packageDel: function packageDel(ids) {
    return request.delete(getUrl("/servicePack/scheme/delete?ids=".concat(ids), 15));
  },
  /**
   * 服务包订单列表
   */
  getServicePackOrderList: function getServicePackOrderList(data) {
    return request.get(getUrl("/servicePack/order/search", 15), {
      params: data
    });
  },
  /**
   * 查看服务包订单详情
   */
  getServPackOrderDetail: function getServPackOrderDetail(id) {
    return request.get(getUrl("/servicePack/order/getServPackOrder?servPackOrderId=".concat(id), 15));
  },
  /**
   * 拉取(内部/外部)服务包方案
   */
  getServicePacksList: function getServicePacksList(data) {
    return request.post(getUrl("/servicePack/scheme/getServPackPro", 15), data);
  },
  // ************************服务包运营平台临时包维护接口**************************

  /**
   * 分页查询
   */
  getTempServicePacksList: function getTempServicePacksList(data) {
    return request.get(getUrl("/servpack/pro/temp/search", 15), {
      params: data
    });
  },
  /**
   * 新增
   */
  addTempServicePacks: function addTempServicePacks(data) {
    return request.post(getUrl("/servpack/pro/temp/create", 15), data);
  },
  /**
   * 查询一条
   */
  queryTempServicePacks: function queryTempServicePacks(id) {
    return request.get(getUrl("/servpack/pro/temp/get?id=".concat(id), 15));
  },
  /**
   * 更新
   */
  editTempServicePacks: function editTempServicePacks(data) {
    return request.post(getUrl("/servpack/pro/temp/update", 15), data);
  },
  /**
   * 批量删除
   */
  deleteTempServicePacks: function deleteTempServicePacks(ids) {
    return request.delete(getUrl("/servpack/pro/temp/batchDelete/".concat(ids), 15));
  },
  /**
   * 批量导入
   */
  importTempServicePacks: function importTempServicePacks(data) {
    return request.post(getUrl("/servpack/pro/temp/import", 15), data);
  },
  /**
   * 分页查询服务包台账
   */
  getServicePackAccountList: function getServicePackAccountList(data) {
    return request.get(getUrl("/servPack/account/search", 15), {
      params: data
    });
  },
  /**
   * 服务包统计看板分页查询
   */
  getServicePackStatistics: function getServicePackStatistics(data) {
    return request.get(getUrl("/servicePack/statistics/search", 15), {
      params: data
    });
  },
  /**
   * 服务包统计看板汇总数据
   */
  getServicePackStatisticsCount: function getServicePackStatisticsCount(data) {
    return request.get(getUrl("/servicePack/statistics/count", 15), {
      params: data
    });
  }
};
export default objApi;