import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import giftApi from '@/api/giftManagement';
import GiftWriteOffDialog from './dialog/giftWriteOffDialog';
import OemSelect from '@/components/OemSelect';
import { character, downFile, commonExport } from '@/utils/common';
export default {
  name: 'GiftWriteOff',
  components: {
    Pagination: Pagination,
    GiftWriteOffDialog: GiftWriteOffDialog,
    OemSelect: OemSelect
  },
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {},
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false,
      drawer: false,
      showAddDialog: false,
      opType: '',
      currentId: '',
      startEndTime: [],
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      issuedOrgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      isGiftWriteOffRecords: false
    };
  },
  watch: {
    '$route': {
      handler: function handler(val) {
        switch (val.path) {
          // 赠品核销
          case "/giftWriteOff":
            this.isGiftWriteOffRecords = false;
            break;
          // 核销记录
          case "/giftWriteOffRecords":
            this.isGiftWriteOffRecords = true;
            break;
          default:
            return;
        }
        ;
        this.tableData = [];
        this.total = 0;
        this.resetSearch();
        this.loadData();
      },
      immediate: true
    }
  },
  methods: {
    nodeClick: function nodeClick(data) {
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.bizType = 'BROKER';
    },
    issuedNodeClick: function issuedNodeClick(data) {
      this.searchForm.issuedOrgCode = data.salesOrgCode;
      this.searchForm.issuedOrgName = data.salesOrgName;
      this.searchForm.bizType = 'BROKER';
    },
    //点击，使用id下载文件
    handle: function handle(row) {
      if (row.fileId) {
        downFile(row.fileUrl, row.fileNmae);
      }
    },
    dateChange: function dateChange(start, end, val) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = '';
        this.searchForm[end] = '';
      }
      ;
    },
    checkValue: function checkValue(val) {
      //输入的车架号中的字母自动转大写，并去除i，o，q
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
      }
      this.searchForm.vin = character(_.toUpper(val));
    },
    add: function add(type) {
      //安装
      this.opType = type;
      this.showAddDialog = true;
    },
    edit: function edit(type, item) {
      //编辑
      this.opType = type;
      this.currentId = item.deviceId;
      this.showAddDialog = true;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {};
      this.startEndTime = [];
      this.orgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      this.issuedOrgForm = {
        salesOrgCode: '',
        salesOrgName: ''
      };
      this.searchForm.bizType = null;
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 刷新
    breakData: function breakData() {
      // this.resetSearch();
      this.loadData();
    },
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      //获取列表数据
      this.showAddDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.drawer = false;
      if (this.searchForm && this.searchForm.endTime) {
        this.searchForm.endTime = this.searchForm.endTime.split(' ')[0] + ' 23:59:59';
      }
      ;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      var request = !this.isGiftWriteOffRecords ? giftApi.getGiftWriteOffRecordsList(data) : giftApi.getGiftWriteOffList(data);
      request.then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    exportExcels: function exportExcels() {
      //导出
      var exporParams = {
        total: this.total,
        fileName: this.isGiftWriteOffRecords ? "核销记录" : '赠品核销',
        exportUrl: !this.isGiftWriteOffRecords ? '/oc-core-service/cardVoucherVerification/issuedSearch' : '/oc-core-service/cardVoucherVerification',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    }
  }
};