var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls entry-stop-record" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "370px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车牌号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入车牌号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.plateNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "plateNo", $$v)
                                    },
                                    expression: "searchForm.plateNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "车架号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "17",
                                    placeholder: "请输入车架号",
                                    clearable: "",
                                  },
                                  on: { input: _vm.checkValue },
                                  model: {
                                    value: _vm.searchForm.vin,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "vin", $$v)
                                    },
                                    expression: "searchForm.vin",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.bizType === "OEM"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "所属机构" } },
                                  [
                                    _c("OemSelect", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        searchForm: _vm.orgForm,
                                        canClickArea: true,
                                      },
                                      on: { nodeClick: _vm.handleNodeClick },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.bizType === "DEALER"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "进店机构" } },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value: _vm.searchForm.orgName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "orgName",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.orgName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "续保状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择续保状态",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.renewalType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "renewalType",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.renewalType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.dictSource["RENEWALS_STATUS"],
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.dictName,
                                          value: item.dictCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户类别" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择客户类别",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.customerRenewalType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "customerRenewalType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "searchForm.customerRenewalType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.customerCategoryList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.itemName,
                                          value: item.itemCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "进店时间" } },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    pickerDateTime: _vm.startEndTime,
                                    startDate: "startEntryTime",
                                    endDate: "endEntryTime",
                                    width: "100%",
                                  },
                                  on: {
                                    pickerDateChange: function ($event) {
                                      return _vm.getPickersDate(
                                        "startEndTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车牌号：")]),
                        _c("el-input", {
                          staticStyle: { "max-width": "120px" },
                          attrs: {
                            maxlength: "10",
                            placeholder: "请输入车牌号",
                            clearable: "",
                          },
                          on: { input: _vm.inputPlateNo },
                          model: {
                            value: _vm.searchForm.plateNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "plateNo", $$v)
                            },
                            expression: "searchForm.plateNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("车架号：")]),
                        _c("el-input", {
                          staticStyle: { "max-width": "150px" },
                          attrs: {
                            maxlength: "17",
                            placeholder: "请输入车架号",
                            clearable: "",
                          },
                          on: { input: _vm.checkValue },
                          model: {
                            value: _vm.searchForm.vin,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "vin", $$v)
                            },
                            expression: "searchForm.vin",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.bizType === "OEM"
                      ? _c(
                          "div",
                          [
                            _c("span", [_vm._v("进店机构：")]),
                            _c("OemSelect", {
                              staticStyle: {
                                "max-width": "150px",
                                display: "inline-block",
                              },
                              attrs: {
                                searchForm: _vm.orgForm,
                                canClickArea: true,
                              },
                              on: { nodeClick: _vm.handleNodeClick },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.bizType === "DEALER"
                      ? _c(
                          "div",
                          [
                            _c("span", [_vm._v("进店机构：")]),
                            _c("el-input", {
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.searchForm.orgName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "orgName", $$v)
                                },
                                expression: "searchForm.orgName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("续保状态：")]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { "max-width": "120px" },
                            attrs: {
                              placeholder: "请选择续保状态",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.renewalType,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "renewalType", $$v)
                              },
                              expression: "searchForm.renewalType",
                            },
                          },
                          _vm._l(
                            _vm.dictSource["RENEWALS_STATUS"],
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.dictName,
                                  value: item.dictCode,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("客户类别：")]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { "max-width": "120px" },
                            attrs: {
                              placeholder: "请选择客户类别",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.customerRenewalType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "customerRenewalType",
                                  $$v
                                )
                              },
                              expression: "searchForm.customerRenewalType",
                            },
                          },
                          _vm._l(
                            _vm.customerCategoryList,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.itemName,
                                  value: item.itemCode,
                                },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("进店时间：")]),
                        _c("DatePicker", {
                          attrs: {
                            pickerDateTime: _vm.startEndTime,
                            startDate: "startEntryTime",
                            endDate: "endEntryTime",
                            width: "230px",
                          },
                          on: {
                            pickerDateChange: function ($event) {
                              return _vm.getPickersDate("startEndTime", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportExcels },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "prompt-customer" },
                  _vm._l(_vm.renewalsStatusArr, function (item, index) {
                    return _c("span", { key: index }, [
                      _c("i", {
                        staticClass: "all-color",
                        style: { "background-color": item.color },
                      }),
                      _vm._v("表示" + _vm._s(item.label)),
                    ])
                  }),
                  0
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightE,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "plateNo",
                        label: "车牌号",
                        "min-width": "100",
                        fixed: "left",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "prompt-customer" }, [
                                _c("span", [
                                  _vm.getColor(scope.row.renewalType)
                                    ? _c("i", {
                                        staticClass: "all-color",
                                        style: {
                                          "background-color": _vm.getColor(
                                            scope.row.renewalType
                                          ),
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(_vm._s(scope.row.plateNo)),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "vin",
                        label: "车架号",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "entryTime",
                        label: "最新进店时间",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "insCorpName",
                        label: "保险公司",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bzLastYearEndDate",
                        label: "交强险止期",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "距交强险到期天数",
                        "min-width": "130",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.bzOverDueDays)),
                              ]),
                              scope.row.bzOverDueDays &&
                              scope.row.bzEndDateAutoFixFlag
                                ? _c(
                                    "span",
                                    { staticStyle: { padding: "0 3px" } },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "根据历史保单时间推算",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-warning",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bsLastYearEndDate",
                        label: "商业险止期",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "距商业险到期天数",
                        "min-width": "130",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(scope.row.bsOverDueDays)),
                              ]),
                              scope.row.bsOverDueDays &&
                              scope.row.bsEndDateAutoFixFlag
                                ? _c(
                                    "span",
                                    { staticStyle: { padding: "0 3px" } },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "根据历史保单时间推算",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-warning",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "customerRenewalTypeDisplay",
                        label: "客户类别",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.customerRenewalTypeDisplay
                                ? _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          scope.row.customerRenewalTypeDisplay
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _c("span", [_vm._v("在修不在保.")]),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerName",
                        label: "车主名称",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "联系方式",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.ownerMobile &&
                                      scope.row.ownerMobile.replace(
                                        /(\d{3})\d*(\d{2})/s,
                                        "$1******$2"
                                      )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trackStatus",
                        label: "最新跟进状态",
                        width: "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trackTime",
                        label: "最新跟进时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "ownerByName",
                        label: "客户经理",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "source",
                        label: "客户来源",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgCode",
                        label: "进店编码",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "进店机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        width: "200",
                        fixed: "right",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.toQuote(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("报价")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.tracks(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("跟进")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showUpdataDialog,
            "close-on-click-modal": false,
            width: "720px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showUpdataDialog = $event
            },
            handleClose: function ($event) {
              _vm.showUpdataDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("完善客户信息")]
              ),
            ]
          ),
          _c("UpdataDialog", {
            attrs: { refresh: _vm.showUpdataDialog },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showAddTracksDialog,
            "close-on-click-modal": false,
            width: "550px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddTracksDialog = $event
            },
            handleClose: function ($event) {
              _vm.showAddTracksDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("新增跟进")]
              ),
            ]
          ),
          _c("FollowUpDialog", {
            attrs: {
              "customer-item": _vm.currentItem,
              refresh: _vm.showAddTracksDialog,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }