import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
var animationDuration = 6000;
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    chartData: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData.values, this.chartData.name);
    },
    setOptions: function setOptions(item, name) {
      var yDatas = [];
      var timeline = [];
      if (!_.isEmpty(item)) {
        item.forEach(function (element) {
          yDatas.push(element.value);
          timeline.push(element.timeline);
        });
        this.chart.setOption({
          title: {
            text: name,
            x: "center"
          },
          tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgb(0,0,0,0.5)',
            // 提示框浮层的背景颜色。
            borderColor: 'rgb(255,255,255,0.3)',
            // 提示框浮层的边框颜色。
            borderWidth: 0,
            // 提示框浮层的边框宽。
            textStyle: {
              // 提示框浮层的文本样式。
              color: '#fff'
            },
            axisPointer: {
              // 坐标轴指示器，坐标轴触发有效
              type: 'line',
              // 默认为直线，可选为：'line' | 'shadow'
              lineStyle: {
                color: "#18b2ef"
              }
            },
            formatter: function formatter(params) {
              var title = name == "近12个月保费规模趋势" ? "保费规模" : "出单量";
              var temp = timeline[parseInt(params[0].dataIndex)] + "<br/>" + params[0].marker + title + "：" + params[0].data;
              return temp;
            }
          },
          grid: {
            top: 40,
            left: 20,
            right: 20,
            bottom: 0,
            containLabel: true
          },
          xAxis: [{
            type: 'category',
            show: true,
            data: timeline,
            boundaryGap: false //不留白，从0开始
          }],

          yAxis: [{
            type: "value",
            show: true,
            axisLabel: {
              formatter: function formatter(value) {
                var unit = name == "近12个月保费规模趋势" ? "元" : "单";
                return value + unit;
              }
            }
          }],
          series: [{
            name: this.seriesName,
            type: 'line',
            symbol: "none",
            smooth: true,
            itemStyle: {
              color: name == "近12个月保费规模趋势" ? "#fd8f6c" : "#18b2ef"
            },
            areaStyle: {
              //区域颜色
              color: name == "近12个月保费规模趋势" ? "rgba(253,135,98,0.4)" : "rgba(75,193,240,0.4)"
            },
            data: yDatas,
            animationEasing: 'cubicInOut'
          }]
        });
      }
    }
  }
};