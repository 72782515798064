import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from "@/mixins/initHeight";
import dict from "@/mixins/dict";
import Dialog from "@/components/Dialog";
import Pagination from "@/components/Pagination";
import employeeRequest from "@/api/employee";
import servicePackageApi from "@/api/servicePackage";
import commonRequest from "@/api/common";
import { getCurrentDate, getBeforeDate } from '@/utils/common';
var delay = function () {
  var _this = this;
  var timeout;
  return function (callback, ms) {
    if (timeout) clearTimeout(timeout);
    var callNow = !timeout;
    timeout = setTimeout(function () {
      timeout = undefined;
    }, ms);
    if (callNow) callback.apply(_this, [callback, ms]);
  };
}();
export default {
  name: 'InsuranceProducts',
  components: {
    Pagination: Pagination,
    Dialog: Dialog
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      insuranceSchemeId: '',
      insuranceData: [],
      riskKindIdList: [],
      drawer: false,
      searchForm: {
        createdTimeStart: "".concat(getBeforeDate(6), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      },
      form: {
        insuranceSchemeCode: '',
        insuranceSchemeName: '',
        insuranceSchemeOutlining: '',
        defaulted: false,
        enabled: true,
        riskKindList: []
      },
      fileTypeList: [],
      total: 5,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      defaultProps: {
        children: "children",
        label: "fullName"
      },
      tableData: [],
      multipleSelection: [],
      showInsuranceProductDialog: false,
      opType: "add",
      loading: false,
      formLoading: false,
      employeeItem: {},
      formRules: {
        insuranceSchemeCode: [{
          required: true,
          message: "请输入产品编码",
          trigger: "blur"
        }],
        insuranceSchemeName: [{
          required: true,
          message: "请输入产品名称",
          trigger: "blur"
        }]
      },
      isClick: false,
      viewDisabled: false,
      startEndTime: [getBeforeDate(6), getCurrentDate()]
    };
  },
  watch: {
    showInsuranceProductDialog: function showInsuranceProductDialog(newValue) {
      if (newValue) {
        this.getAllDutys();
        if (this.$refs["detail"]) this.$refs["detail"].resetFields();
        if (this.opType == "add") {
          this.form = {
            insuranceSchemeCode: '',
            insuranceSchemeName: '',
            insuranceSchemeOutlining: '',
            defaulted: false,
            enabled: true,
            riskKindList: []
          };
        }
      }
    },
    "multipleSelection": {
      handler: function handler(newVal, oldVal) {
        var codelist = [];
        var _iterator = _createForOfIteratorHelper(newVal),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var i = _step.value;
            codelist.push(i.riskkindCode);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        this.riskKindIdList = codelist;
      }
    }
  },
  created: function created() {
    this.getAllDutys();
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    // 日期选择事件
    dateChange: function dateChange(start, end, val) {
      if (val && val.length > 1) {
        this.searchForm[start] = "".concat(val[0], " 00:00:00");
        this.searchForm[end] = "".concat(val[1], " 23:59:59");
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    getAllDutys: function getAllDutys() {
      var _this2 = this;
      commonRequest.getAllDuty().then(function (res) {
        if (res.code == 200) {
          var resData = _.cloneDeep(res.data);
          var list = [];
          var _iterator2 = _createForOfIteratorHelper(resData),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var i = _step2.value;
              if (i.riskkindShortName == "车辆损失险" || i.riskkindShortName == "第三者责任险" || i.riskkindName == "机动车车上人员司机责任保险" || i.riskkindName == "机动车车上人员乘客责任保险") {
                i.amountDisplay = '';
                list.push(i);
              }
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
          _this2.insuranceData = list ? list : [];
          _this2.$forceUpdate();
        }
      });
    },
    // 重置保额
    resetAmount: function resetAmount(row) {
      var amountDisplay = _.cloneDeep(row.amountDisplay);
      var _iterator3 = _createForOfIteratorHelper(this.insuranceData),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var i = _step3.value;
          if (i.riskkindCode == row.riskkindCode) {
            i.amount = row.amountDisplay;
            i.amountDisplay = amountDisplay.length > 4 ? parseInt(amountDisplay) / 10000 + "万" : amountDisplay + '元';
          }
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    },
    delItem: function delItem(row) {
      var _this3 = this;
      this.$confirm("您确定要将该产品删除吗？", "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: "warning"
      }).then(function () {
        servicePackageApi.InsuranceProductDel(row.insuranceSchemeId.toString()).then(function (res) {
          if (res.code === 200) {
            _this3.$message({
              type: "success",
              message: res.msg
            });
            _this3.loadData();
          }
        });
      });
    },
    // 复选框数据
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 关闭弹窗
    close: function close() {
      this.viewDisabled = false;
      this.form = {
        insuranceSchemeCode: '',
        insuranceSchemeName: '',
        insuranceSchemeOutlining: '',
        defaulted: false,
        enabled: true,
        riskKindList: []
      };
      this.$refs.multipleTable.clearSelection();
      this.multipleSelection = [];
      this.showInsuranceProductDialog = false;
    },
    // 查看或编辑
    viewDetail: function viewDetail(type, item) {
      var _this4 = this;
      this.opType = type;
      this.showInsuranceProductDialog = true;
      this.insuranceSchemeId = item.insuranceSchemeId;
      if (this.insuranceData) {
        setTimeout(function () {
          servicePackageApi.getInsuranceProductDetail(item.insuranceSchemeId).then(function (res) {
            if (res.code == 200) {
              var resData = _.cloneDeep(res.data);
              _this4.form = {
                insuranceSchemeCode: resData.insuranceSchemeCode ? resData.insuranceSchemeCode : '',
                insuranceSchemeId: resData.insuranceSchemeId ? resData.insuranceSchemeId : '',
                insuranceSchemeName: resData.insuranceSchemeName ? resData.insuranceSchemeName : '',
                insuranceSchemeOutlining: resData.insuranceSchemeOutlining ? resData.insuranceSchemeOutlining : '',
                defaulted: resData.defaulted,
                enabled: resData.enabled,
                riskKindList: []
              };
              if (resData.riskKindList) {
                var _iterator4 = _createForOfIteratorHelper(_this4.insuranceData),
                  _step4;
                try {
                  for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                    var i = _step4.value;
                    var _iterator5 = _createForOfIteratorHelper(resData.riskKindList),
                      _step5;
                    try {
                      for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
                        var j = _step5.value;
                        if (i.riskkindCode == j.riskkindCode) {
                          if (j.amount) {
                            i.amountDisplay = j.amount.toString().length > 4 ? parseInt(j.amount) / 10000 + "万" : j.amount + '元';
                          } else {
                            i.amountDisplay = null;
                          }
                          i.amount = Number(j.amount);
                          _this4.$refs.multipleTable.toggleRowSelection(i, true);
                        }
                      }
                    } catch (err) {
                      _iterator5.e(err);
                    } finally {
                      _iterator5.f();
                    }
                    ;
                  }
                } catch (err) {
                  _iterator4.e(err);
                } finally {
                  _iterator4.f();
                }
                ;
              }
            }
          });
        }, 200);
      }
    },
    // 保存
    save: function save() {
      var _this5 = this;
      delay(function () {
        _this5.$refs["detail"].validate(function (valid) {
          if (valid) {
            var data = {};
            var insuranceList = _.cloneDeep(_this5.multipleSelection);
            if (insuranceList.length == 0) {
              _this5.$message.error('请至少选择一个险种');
              return;
            }
            if (_this5.opType == 'add') {
              data = _.cloneDeep(_this5.form);
            } else {
              data = _.cloneDeep(_this5.form);
            }
            var _iterator6 = _createForOfIteratorHelper(insuranceList),
              _step6;
            try {
              for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
                var i = _step6.value;
                data.riskKindList.push({
                  amount: parseInt(i.amount),
                  insuranceRiskkindSchemeOutlining: data.insuranceSchemeOutlining,
                  insuranceSchemeCode: data.insuranceSchemeCode,
                  riskkindCode: i.riskkindCode,
                  riskkindName: i.riskkindName
                });
              }
            } catch (err) {
              _iterator6.e(err);
            } finally {
              _iterator6.f();
            }
            console.log(data);
            if (_this5.opType == 'add') {
              servicePackageApi.InsuranceProductAdd(data).then(function (res) {
                if (res.code == 200) {
                  _this5.$message.success(res.msg);
                  _this5.showInsuranceProductDialog = false;
                  _this5.loadData();
                }
              });
            } else {
              servicePackageApi.InsuranceProductUpdate(_this5.insuranceSchemeId, data).then(function (res) {
                if (res.code == 200) {
                  _this5.$message.success(res.msg);
                  _this5.showInsuranceProductDialog = false;
                  _this5.loadData();
                }
              });
            }
            _this5.$refs.multipleTable.clearSelection();
          } else {
            _this5.isClick = false;
            _this5.$refs.multipleTable.clearSelection();
          }
        });
      }, 1000);
    },
    // 新增
    add: function add(type) {
      var _this6 = this;
      this.viewDisabled = false;
      this.opType = type;
      this.showInsuranceProductDialog = true;
      this.$nextTick(function () {
        _this6.$refs['detail'].clearValidate();
      });
    },
    // 重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {
        createdTimeStart: "".concat(getBeforeDate(6), " 00:00:00"),
        createdTimeEnd: "".concat(getCurrentDate(), " 23:59:59")
      };
      this.startEndTime = [getBeforeDate(6), getCurrentDate()];
    },
    // 搜索 
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this7 = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showInsuranceProductDialog = false;
      if (isDialogNoRefresh) return;
      this.drawer = false;
      this.loading = true;
      var data = _objectSpread(_objectSpread({}, this.listQuery), this.searchForm);
      servicePackageApi.getInsuranceProductLists(data).then(function (res) {
        _this7.loading = false;
        if (res.code == 200) {
          _this7.tableData = res.data.list ? res.data.list : [];
        }
      }).catch(function (err) {
        _this7.loading = false;
      });
    }
  }
};