var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "msg-detail-page",
    },
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: {
                "min-height": "calc(100vh - 130px)",
                padding: "10px",
                "font-size": "15px",
              },
            },
            [
              _c("el-card", { attrs: { shadow: "never" } }, [
                _c("h3", [_vm._v(_vm._s(_vm.form.noticeTitle))]),
                _c(
                  "p",
                  { staticStyle: { "font-size": "12px", color: "#999" } },
                  [_vm._v("级别 : " + _vm._s(_vm.form.noticeLevelDisplay))]
                ),
                _c(
                  "p",
                  { staticStyle: { "font-size": "12px", color: "#999" } },
                  [_vm._v("发布时间 : " + _vm._s(_vm.form.createdTime))]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "line-height": "25px",
                      "font-size": "15px",
                      "white-space": "pre-wrap",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.form.noticeContent) + " ")]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "p",
                      { staticStyle: { color: "#999", "font-size": "13px" } },
                      [_vm._v("附件列表：")]
                    ),
                    _vm._l(_vm.form.noticeFileList, function (file, i) {
                      return _c("div", { key: i }, [
                        _c("div", { staticClass: "file-box" }, [
                          _c(
                            "span",
                            {
                              staticClass: "file-text",
                              attrs: { title: file.fileName },
                              on: {
                                click: function ($event) {
                                  return _vm.handle(file)
                                },
                              },
                            },
                            [_vm._v(_vm._s(file.fileName))]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
                _vm.showReadList
                  ? _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            staticStyle: { color: "#999", "font-size": "13px" },
                          },
                          [_vm._v("阅读列表：")]
                        ),
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              data: _vm.noticeReadList,
                              height: _vm.tableHeightUC,
                              "header-cell-style": { background: "#F7F7F7" },
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "readUserName",
                                "min-width": "150",
                                label: "用户名",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "firstTime",
                                width: "150",
                                label: "首次阅读时间",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "lastTime",
                                width: "150",
                                label: "最后阅读时间",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("pagination", {
                          attrs: {
                            total: _vm.total,
                            page: _vm.listQuery.pageNum,
                            limit: _vm.listQuery.pageSize,
                          },
                          on: {
                            "update:page": function ($event) {
                              return _vm.$set(_vm.listQuery, "pageNum", $event)
                            },
                            "update:limit": function ($event) {
                              return _vm.$set(_vm.listQuery, "pageSize", $event)
                            },
                            pagination: function ($event) {
                              return _vm.loadData()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _c(
              "div",
              { staticClass: "footer-btn-list" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-back", size: "mini" },
                    on: { click: _vm.back },
                  },
                  [_vm._v("返回")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }