var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-drawer",
            {
              attrs: {
                title: "",
                visible: _vm.drawer,
                modal: false,
                "append-to-body": true,
                size: "370px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.drawer = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.searchForm,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "新订单编号" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入新订单编号",
                                  size: "mini",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.newOrderNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "newOrderNo", $$v)
                                  },
                                  expression: "searchForm.newOrderNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "原订单编号" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "100",
                                  placeholder: "请输入原订单编号",
                                  size: "mini",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searchForm.orderNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "orderNo", $$v)
                                  },
                                  expression: "searchForm.orderNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  maxlength: "17",
                                  placeholder: "请输入车架号",
                                  size: "mini",
                                  clearable: "",
                                },
                                on: { input: _vm.checkValue },
                                model: {
                                  value: _vm.searchForm.vin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "vin", $$v)
                                  },
                                  expression: "searchForm.vin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "同步状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    placeholder: "请选择同步状态",
                                  },
                                  model: {
                                    value: _vm.searchForm.syncStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "syncStatus",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.syncStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "成功", value: 0 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "失败", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "余额不足", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "审批状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    placeholder: "请选择审批状态",
                                  },
                                  model: {
                                    value: _vm.searchForm.auditStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "auditStatus",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.auditStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "成功", value: 0 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "失败", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "余额不足", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "注销状态" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    placeholder: "请选择注销状态",
                                  },
                                  model: {
                                    value: _vm.searchForm.terminateStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "terminateStatus",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.terminateStatus",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "成功", value: 0 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "失败", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "余额不足", value: 2 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "原订单创建时间" } },
                            [
                              _c("DatePicker", {
                                attrs: {
                                  pickerDateTime: _vm.createdDateTime,
                                  startDate: "createStartDate",
                                  endDate: "createEndDate",
                                  width: "100%",
                                },
                                on: {
                                  pickerDateChange: function ($event) {
                                    return _vm.getPickersDate(
                                      "createdDateTime",
                                      $event
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "searchBtnStyle" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  icon: "el-icon-refresh-left",
                                },
                                on: { click: _vm.resetSearch },
                              },
                              [_vm._v("重置")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "mini",
                                  type: "primary",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchData },
                              },
                              [_vm._v("查询")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c(
                "div",
                {
                  staticClass: "search-box",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("车架号：")]),
                      _c("el-input", {
                        ref: "plateNo",
                        staticStyle: { width: "160px" },
                        attrs: {
                          size: "mini",
                          maxlength: "40",
                          placeholder: "请输入车架号",
                          clearable: "",
                        },
                        model: {
                          value: _vm.searchForm.vin,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "vin", $$v)
                          },
                          expression: "searchForm.vin",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("同步状态：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            clearable: "",
                            placeholder: "请选择同步状态",
                          },
                          model: {
                            value: _vm.searchForm.syncStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "syncStatus", $$v)
                            },
                            expression: "searchForm.syncStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "成功", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "失败", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "余额不足", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("审批状态：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            clearable: "",
                            placeholder: "请选择审批状态",
                          },
                          model: {
                            value: _vm.searchForm.auditStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "auditStatus", $$v)
                            },
                            expression: "searchForm.auditStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "成功", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "失败", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "余额不足", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("注销状态：")]),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            clearable: "",
                            placeholder: "请选择注销状态",
                          },
                          model: {
                            value: _vm.searchForm.terminateStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "terminateStatus", $$v)
                            },
                            expression: "searchForm.terminateStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "成功", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "失败", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "余额不足", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh-left" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-search",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.searchData()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.refreshClicked },
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.BatchLogout },
                        },
                        [_vm._v("批量注销")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.BatchIssue },
                        },
                        [_vm._v("批量出单")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.exportFn },
                        },
                        [_vm._v("导出")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = true
                            },
                          },
                        },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightJD,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      align: "center",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderId",
                      label: "订单id",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vin",
                      label: "车架号",
                      "min-width": "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "policyNo",
                      label: "保单号",
                      "min-width": "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "salesOrgName",
                      label: "经销店名称",
                      "min-width": "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderNo",
                      label: "原订单编号",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "costPrice",
                      label: "FTMS-成本价(元)",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createdTime",
                      label: "原订单创建时间",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "newOrderNo",
                      label: "新订单编号",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "purchasePrice",
                      label: "DLR采购价(元)",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "terminateStatus",
                      label: "注销状态",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.terminateStatus &&
                                      scope.row.terminateStatus === "0",
                                    expression:
                                      "scope.row.terminateStatus&&scope.row.terminateStatus === '0'",
                                  },
                                ],
                              },
                              [_vm._v("成功")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.terminateStatus &&
                                      scope.row.terminateStatus === "1",
                                    expression:
                                      "scope.row.terminateStatus&&scope.row.terminateStatus === '1'",
                                  },
                                ],
                              },
                              [_vm._v("失败")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "terminateTime",
                      label: "注销时间",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "syncStatus",
                      label: "同步状态",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.syncStatus &&
                                      scope.row.syncStatus === "0",
                                    expression:
                                      "scope.row.syncStatus&&scope.row.syncStatus === '0'",
                                  },
                                ],
                              },
                              [_vm._v("成功")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.syncStatus &&
                                      scope.row.syncStatus === "1",
                                    expression:
                                      "scope.row.syncStatus&&scope.row.syncStatus === '1'",
                                  },
                                ],
                              },
                              [_vm._v("失败")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.syncStatus &&
                                      scope.row.syncStatus === "2",
                                    expression:
                                      "scope.row.syncStatus&&scope.row.syncStatus === '2'",
                                  },
                                ],
                              },
                              [_vm._v("余额不足")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "syncTime",
                      label: "同步时间",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "auditStatus",
                      label: "审批状态",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.auditStatus &&
                                      scope.row.auditStatus === "0",
                                    expression:
                                      "scope.row.auditStatus&&scope.row.auditStatus === '0'",
                                  },
                                ],
                              },
                              [_vm._v("成功")]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.auditStatus &&
                                      scope.row.auditStatus === "1",
                                    expression:
                                      "scope.row.auditStatus&&scope.row.auditStatus === '1'",
                                  },
                                ],
                              },
                              [_vm._v("失败")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "auditTime",
                      label: "审批时间",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "80", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.viewDetail("detail", scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }