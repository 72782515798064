var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              staticClass: "app-m-cls",
              staticStyle: { "min-height": "calc(100vh - 130px)" },
            },
            [
              _c(
                "div",
                { ref: "header", staticClass: "header-cls" },
                [
                  _c(
                    "el-card",
                    { attrs: { shadow: "never" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "form",
                          attrs: {
                            model: _vm.form,
                            rules: _vm.rules,
                            "label-width": "110px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      ref: "name",
                                      attrs: {
                                        label: "方案名称",
                                        prop: "name",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          disabled: _vm.opType == "view",
                                          maxlength: "40",
                                          placeholder: "请输入方案名称",
                                          clearable: "",
                                        },
                                        model: {
                                          value: _vm.form.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "name", $$v)
                                          },
                                          expression: "form.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table-cls normal-btn-cls" },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: { "margin-top": "10px" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("InsuranceRiskForm", {
                        attrs: {
                          "is-main": true,
                          "no-main-duty": _vm.selectNoMainDuty,
                          "main-duty": _vm.selectMainDuty,
                          label: "主险",
                          "duty-list": _vm.mainInsuranceData,
                          "check-duty-list": _vm.checkDutyList,
                          isDisabled: _vm.opType == "view",
                        },
                        on: { exportValue: _vm.getMainInsuranceData },
                      }),
                      _c("InsuranceRiskForm", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.noMainDuty && _vm.noMainDuty.length > 0,
                            expression: "noMainDuty && noMainDuty.length > 0",
                          },
                        ],
                        attrs: {
                          "is-main": false,
                          "no-main-duty": _vm.selectNoMainDuty,
                          "main-duty": _vm.selectMainDuty,
                          label: "附加险",
                          "duty-list": _vm.noMainDuty,
                          "check-duty-list": _vm.checkDutyList,
                          isDisabled: _vm.opType == "view",
                        },
                        on: { exportValue: _vm.getNoMainInsuranceData },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _c(
              "div",
              { staticClass: "footer-btn-list" },
              [
                _vm.opType != "view"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-check",
                          disabled: _vm.isClick,
                        },
                        on: { click: _vm.submit },
                      },
                      [_vm._v(_vm._s(_vm.opType == "update" ? "修改" : "提交"))]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-close" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.opType == "view" ? "关闭" : "取消") + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }