var steps = [{
  element: '#hamburger-container',
  popover: {
    className: 'popover-class',
    title: '折叠',
    description: '展开或者收起侧边栏',
    position: 'bottom'
  }
}, {
  element: '#breadcrumb-container',
  popover: {
    className: 'popover-class',
    title: '导航条',
    description: '表示当前页面的位置',
    position: 'bottom'
  }
}, {
  element: '#themePicker',
  popover: {
    className: 'popover-class',
    title: '主题色',
    description: '可以更改当前系统的主题色',
    position: 'left'
  }
}, {
  element: '#screenfull',
  popover: {
    className: 'popover-class',
    title: '全屏',
    description: '点击可以全屏展示',
    position: 'left'
  }
}, {
  element: '#avatarContainer',
  popover: {
    className: 'popover-class',
    title: '用户头像',
    description: '点击头像可以查看用户信息',
    position: 'left'
  }
}, {
  element: '#tags-view-container',
  popover: {
    className: 'popover-class',
    title: '已打开的标签',
    description: '展示已打开的多个标签页面',
    position: 'bottom'
  },
  padding: 0
}, {
  element: '#dashboard-container',
  popover: {
    className: 'popover-class',
    title: '系统首页',
    description: '展示欢迎界面',
    position: 'bottom'
  },
  padding: 0
}];
export default steps;