import "core-js/modules/es.array.concat.js";
/**
 * 非车险业务产品接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/product/page/products?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 11), {
      params: data
    });
  },
  /**
   * 查询订单详情
   * @param {*} orderNo 内容
   */
  queryOrderDetail: function queryOrderDetail(orderNo) {
    return request.get(getUrl("/renewal/contract/".concat(orderNo), 11));
  },
  /**
   * 付款（修改出单状态）
   * @param {*} orderNo 订单号
   * @param {*} statusCode 编码
   */
  updataOrderStatus: function updataOrderStatus(orderNo, statusCode) {
    return request.put(getUrl("/renewal/contract/".concat(orderNo, "?statusCode=").concat(statusCode), 11));
  },
  // /**
  //  * 提交生成合同信息
  //  */
  // getContractInfo: function(data) {
  //     return request.post(getUrl(`/renewal/contract`, 11), data)
  // },
  /**
   * 保存合同地址
   * @param {*} orderNo 订单号
   * @param {*} id id
   * @param {*} contractDownloadType 类型
   */
  saveContractId: function saveContractId(orderNo, id, contractDownloadType) {
    return request.put(getUrl("/renewal/contract/url/".concat(orderNo, "?contractUrl=").concat(id, "&contractDownloadType=").concat(contractDownloadType), 11));
  },
  /**
   * 下载合同
   * @param {*} orderNo 订单号
   * @param {*} id id
   */
  downLoadContract: function downLoadContract(data) {
    return request({
      method: 'post',
      url: getUrl("/renewal/download/contract", 11),
      data: data,
      responseType: 'blob'
    });
  },
  /**
   * 下载整车延保合同
   * @param {*} orderNo 订单号
   * @param {*} id id
   */
  downLoadCompleteContract: function downLoadCompleteContract(data) {
    return request({
      method: 'post',
      url: getUrl("/warranty/download/contract", 11),
      data: data,
      responseType: 'blob'
    });
  },
  /**
   * 筛选产品
   */
  filterProduct: function filterProduct(data) {
    return request.post(getUrl("/product/order", 11), data);
  },
  /**
   * 上传附件判断
   * @param {*} data
   */
  uploadJudge: function uploadJudge(data) {
    return request({
      method: 'post',
      url: getUrl("/product/export/judge", 11),
      data: data,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  },
  /**
   * 上传文件
   * @param {*} data
   */
  upload: function upload(flag, data) {
    return request({
      method: 'post',
      url: getUrl("/product/export?flag=".concat(flag), 11),
      data: data,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  },
  /**
   * 下载excel模板
   */
  download: function download() {
    return request({
      method: 'get',
      url: getUrl("/product/formwork", 11),
      responseType: 'blob'
    });
  },
  /**
   * 导出
   */
  exportExcel: function exportExcel(data) {
    return request({
      method: 'POST',
      url: getUrl("/product/exportExcel", 1),
      data: data,
      responseType: 'blob'
    });
  },
  /**
   * 删除
   * @param {*} ids
   */
  delete: function _delete(ids) {
    // return request.delete(getUrl(`/product/delete`, 1), ids)
    return request({
      method: 'delete',
      url: getUrl("/product/delete", 11),
      data: ids
    });
  },
  /**
   * 提交订单
   * @param {*} data 内容
   */
  submitOrder: function submitOrder(data) {
    return request.post(getUrl("/renewal/create", 11), data);
  },
  /**
   * 修改订单
   * @param {*} data 内容
   */
  updateOrder: function updateOrder(data) {
    return request.post(getUrl("/renewal/update", 11), data);
  },
  /**
   * 整车延保新增订单
   * @param {*} data 内容
   */
  submitCompleteOrder: function submitCompleteOrder(data) {
    return request.post(getUrl("/warranty/create", 11), data);
  },
  /**
   * 整车延保修改订单
   * @param {*} data 内容
   */
  completeOrderUpdate: function completeOrderUpdate(data) {
    return request.put(getUrl("/warranty/update", 11), data);
  },
  /**
   * fdd签署
   * @param {*} productOrderNo  
   * @param {*} returnUrl 
   */
  fddSign: function fddSign(productOrderNo, returnUrl) {
    return request.get(getUrl("/fdd/sign/".concat(productOrderNo, "?returnUrl=").concat(returnUrl), 11));
    // return request.post(`/vi-core-dev/fdd/sign/${productOrderNo}?returnUrl=${returnUrl}`)
  },

  /**
   * 查看认证接口
   * @param {*} productContractNo  
   */
  certificationStatus: function certificationStatus(productContractNo) {
    return request.get(getUrl("/fdd/certificationStatus/".concat(productContractNo), 11));
  },
  /**
   * 获取合同下载地址
   */
  getDownloadContractUrl: function getDownloadContractUrl(productContractNo) {
    return request.get(getUrl("/fdd/download/contract/".concat(productContractNo), 11));
  },
  /**
   * 文件导入
   *  @param {*} data 内容
   */
  importProduct: function importProduct(data) {
    return request.post(getUrl("/product/importProduct?action=".concat(data.action, "&storageId=").concat(data.storageId), 11));
  },
  /**
   * 法大大整车延保 认证签署接口
   *  @param {*} productOrderNo
   *  @param {*} returnUrl
   */
  fddEwSign: function fddEwSign(productOrderNo, returnUrl) {
    return request.get(getUrl("/fdd/sign/ew/".concat(productOrderNo, "?returnUrl=").concat(returnUrl), 11));
  },
  /**
   * 刷新短链接
   *  @param {*} productOrderNo
   *  @param {*} data
   */
  refreshLinks: function refreshLinks(productOrderNo, data) {
    return request.put(getUrl("/fdd/ew/refresh/".concat(productOrderNo), 11), data);
  },
  /**
   * 主动查询状态
   *  @param {*} productOrderNo
   */
  queryStatus: function queryStatus(productOrderNo) {
    return request.put(getUrl("/fdd/ew/check/".concat(productOrderNo), 11));
  },
  /**
   * 获取支付链接
   *  @param {*} productOrderNo
   *  @param {*} isRefresh
   *  @param {*} returnUrl
   */
  getPayLine: function getPayLine(productOrderNo, isRefresh, returnUrl) {
    return request.get(getUrl("/warranty/pay/orderUrl/".concat(productOrderNo, "?isRefresh=").concat(isRefresh, "&returnUrl=").concat(returnUrl), 11));
  },
  // /**
  //  * 主动查询订单状态
  //  *  @param {*} productOrderNo
  //  *  @param {*} returnUrl
  //  */
  //  queryPayStatus: function(productOrderNo, returnUrl) {
  //     return request.get(getUrl(`/warranty/pay/status/${productOrderNo}?returnUrl=${returnUrl}`, 11))
  // },
  /**
   * 返回第三方跳转链接
   *  @param {*} productOrderNo
   *  @param {*} returnUrl
   */
  getBackJumpLink: function getBackJumpLink(productOrderNo, returnUrl) {
    return request.get(getUrl("/warranty/open/order/".concat(productOrderNo, "?returnUrl=").concat(returnUrl), 11));
  },
  /**
   * 合成合同预览
   *  @param {*} productOrderNo
   */
  getFddContract: function getFddContract(productOrderNo) {
    return request.post(getUrl("/fdd/contract/".concat(productOrderNo), 11));
  },
  //2022-05-20永烈提供的新接口
  getGaAndTySign: function getGaAndTySign(productOrderNo, url) {
    return request.get(getUrl("/fdd/sign/gaAndTySign/".concat(productOrderNo, "?returnUrl=").concat(url), 11));
  },
  /**
   * 产品销售统计
   *  @param {*} data
   */
  getProductList: function getProductList(data) {
    return request.get(getUrl("/renewal/product", 11), {
      params: data
    });
  },
  /**
   * 根据车型获取屏幕产品列表
   *  @param {*} carType
   */
  getScreenType: function getScreenType(carType) {
    return request.get(getUrl("/product/getScreenType?carType=".concat(carType), 11));
  },
  /**
   * 获取订单流程处理链接
   *  @param {*} data
   */
  getOrderProcessUrl: function getOrderProcessUrl(data) {
    return request.get(getUrl("/warranty/open/order/processUrl", 11), {
      params: data
    });
  },
  //  **********************************增值服务保单************************************
  /**
   * 获取gap保单列表（分页）
   *  @param {*} data
   */
  getGapPolicyList: function getGapPolicyList(data) {
    return request.get(getUrl("/gap/policy/search", 11), {
      params: data
    });
  },
  /**
   * 获取gap保单详情
   *  @param {*} productPolicyId
   */
  getGapPolicyDetail: function getGapPolicyDetail(productPolicyId) {
    return request.get(getUrl("/gap/policy/detail?productPolicyId=".concat(productPolicyId), 11));
  },
  /**
   * 保单修改
   *  @param {*} data
   */
  uploadGapPolicy: function uploadGapPolicy(data) {
    return request.post(getUrl("/gap/policy/upload", 11), data);
  },
  /**
   * 批量导入
   *  @param {*} data
   */
  importGapPolicy: function importGapPolicy(data) {
    return request.post(getUrl("/gap/policy/import", 11), data);
  },
  /**
   * 理赔预算
   *  @param {*} data
   */
  queryClaimBudget: function queryClaimBudget(data) {
    return request.get(getUrl("/gap/policy/claimBudget", 11), {
      params: data
    });
  },
  /**
   * 上传导入清单1
   *  @param {*} data
   */
  batchUpload: function batchUpload(data) {
    return request.post(getUrl("/gap/order/batch/upload", 11), data);
  },
  /**
   * 执行导入2
   *  @param {*} importId
   */
  batchExec: function batchExec(importId) {
    return request.post(getUrl("/gap/order/batch/exec?importId=".concat(importId), 11));
  },
  /**
   * 任务导入操作
   *  @param {*} importId
   *  @param {*} operate
   */
  batchOperate: function batchOperate(importId, operate) {
    return request.post(getUrl("/gap/order/batch/operate?importId=".concat(importId, "&operate=").concat(operate), 11));
  },
  /**
   * 分页查询批量出单清单
   *  @param {*} data
   */
  batchSearch: function batchSearch(data) {
    return request.get(getUrl("/gap/order/batch/search", 11), {
      params: data
    });
  },
  /**
   * 分页查询详情
   *  @param {*} data
   */
  batchSearchItem: function batchSearchItem(data) {
    return request.get(getUrl("/gap/order/batch/searchItem", 11), {
      params: data
    });
  },
  // ****************************赠品发放设置************************************
  /**
   * 获取服务类型卡券配置列表
   *  @param {*} data
   */
  getCorpConfigList: function getCorpConfigList(data) {
    return request.get(getUrl("/addService/config/getCorpConfigList", 11), {
      params: data
    });
  },
  /**
   * 增值服务配置新增、编辑
   *  @param {*} data
   */
  createAddServiceConfig: function createAddServiceConfig(data) {
    return request.post(getUrl("/addService/config/create", 11), data);
  },
  /**
   * 删除配置
   *  @param {*} productType
   */
  deleteAddServiceConfig: function deleteAddServiceConfig(productType) {
    return request.post(getUrl("/addService/config/delete?productType=".concat(productType), 11));
  },
  /**
   * 根据订单编码获取卡券发放记录
   *  @param {*} orderNo
   */
  getOrderNoCardVoucherList: function getOrderNoCardVoucherList(orderNo) {
    return request.get(getUrl("/cardVoucherPolicy/getOrderNoCardVoucherList?orderNo=".concat(orderNo), 13));
  },
  /**
   * 赠品发放增值服务检索
   * @param {*} orderNo
   * @param {*} productType
   */
  queryAddServiceInfo: function queryAddServiceInfo(orderNo, productType) {
    return request.get(getUrl("/renewal/contract/productType/".concat(orderNo, "?productType=").concat(productType), 11));
  },
  /**
   * 签约确认
   * @param {*} data
   */
  signConfirm: function signConfirm(data) {
    return request.post(getUrl("/warranty/order/sign/confirm", 11), data);
  },
  /**
   * 签约短信发送
   * @param {*} data
   */
  signSmsSend: function signSmsSend(data) {
    return request.post(getUrl("/warranty/order/sign/smsSend", 11), data);
  },
  /**
   * 获取分期方案
   * @param {*} productNo
   */
  installmentPlan: function installmentPlan(productNo) {
    return request.get(getUrl("/warranty/order/installmentPlan?productNo=".concat(productNo), 11));
  },
  /**
   * 查询订单的分期还款详情
   * @param {*} productOrderNo
   */
  getInstallment: function getInstallment(productOrderNo) {
    return request.get(getUrl("/warranty/order/getInstallment?productOrderNo=".concat(productOrderNo), 11));
  }
};
export default objApi;