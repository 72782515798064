var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls " },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", { staticClass: "search-box" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.bizType === "OEM",
                          expression: "bizType === 'OEM'",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("机构：")]),
                      _c("OemSelect", {
                        staticStyle: {
                          "max-width": "150px",
                          display: "inline-block",
                        },
                        attrs: { searchForm: _vm.orgData, canClickArea: true },
                        on: { nodeClick: _vm.nodeClick },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.bizType === "DEALER",
                          expression: "bizType === 'DEALER'",
                        },
                      ],
                    },
                    [
                      _c("span", [_vm._v("机构：")]),
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.orgData.salesOrgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.orgData, "salesOrgName", $$v)
                          },
                          expression: "orgData.salesOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("截止日期：")]),
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "date",
                          placeholder: "选择截止日期",
                          "picker-options": _vm.disabledLessThan,
                        },
                        on: { change: _vm.dateChange },
                        model: {
                          value: _vm.searchForm.endDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "endDate", $$v)
                          },
                          expression: "searchForm.endDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            disabled: _vm.isDisabled || !_vm.searchForm.orgCode,
                          },
                          on: { click: _vm.loadData },
                        },
                        [_vm._v("重新统计")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                _vm._l(_vm.sumList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: {
                        "line-height": "30px",
                        margin: "5px",
                        "background-color": "#edf4fb",
                        width: "15.5%",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "10px 15px",
                            "border-radius": "2px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                color: "#999",
                              },
                            },
                            [_vm._v(_vm._s(item.displayText))]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "20px",
                                color: "#0d3386",
                              },
                            },
                            [_vm._v(_vm._s(item.displayValue))]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
              !_vm.isOwner
                ? _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c(
                        "el-tabs",
                        {
                          on: { "tab-click": _vm.handleTabClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c("el-tab-pane", {
                            attrs: {
                              label: "当前所选机构",
                              name: "Organization",
                            },
                          }),
                          _c("el-tab-pane", {
                            attrs: {
                              label: "下级业务机构",
                              name: "Department",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm._.isEmpty(_vm.sumList)
                      ? _vm.isOwner
                        ? _vm.tableHeightDF
                        : _vm.tableHeightDFS
                      : _vm.isOwner
                      ? _vm.tableHeightDSF
                      : _vm.tableHeightDSS,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.isOwner ? "客户经理" : "机构名称",
                      "min-width": "150",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isOwner
                                    ? scope.row.ownerName
                                    : scope.row.orgName
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "count",
                      label: "客户总数",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "renewalCount",
                      label: "续保量",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "renewalRate",
                      label: "续保率",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ciitcCount",
                      label: "在保量",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "ciitcRate",
                      label: "在保率",
                      "min-width": "100",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "totalCount",
                      label: "续保在保量",
                      "min-width": "120",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "totalRate",
                      label: "续保在保率",
                      "min-width": "120",
                      align: "right",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }