import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import OemSelect from '@/components/OemSelect';
import underWarrantyQueryRequest from '@/api/underWarrantyQuery';
import { getBeforeDate, getCurrentDate, getUserOrgInfo, commonExport } from '@/utils/common';
export default {
  name: 'UnderWarrantyQuery',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      drawer: false,
      searchForm: {},
      createDateTime: [getBeforeDate(30), getCurrentDate()],
      total: 0,
      bizType: localStorage.getItem("bizType"),
      dealerName: "",
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      listDetailQuery: {
        pageNum: 1,
        pageSize: 20
      },
      detailTotal: 0,
      detailTableData: [],
      tableData: [],
      loading: false,
      highlightCurrent: true,
      showDialog: false,
      detailLoading: false,
      taskId: null,
      showTaskDialog: false,
      orgData: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      searchFormOrgData: {
        salesOrgCode: '',
        salesOrgName: ''
      },
      formLoading: false,
      taskForm: {},
      rules: {
        creatorOrgCode: {
          required: true,
          message: '请选择创建机构',
          trigger: "change"
        },
        taskName: {
          required: true,
          message: '请输入任务名称',
          trigger: "blur"
        },
        intervalSecond: {
          required: true,
          message: '请输入间隔时间(秒)',
          trigger: "blur"
        },
        vinList: {
          required: true,
          message: '请输入车架号',
          trigger: "blur"
        }
      },
      searchDetailForm: {},
      placeholder: "\u8BF7\u5F55\u8F66\u67B6\u53F7\u3002\u4E00\u6761\u4E00\u884C\uFF0C\u4E00\u6B21\u6700\u591A50\u884C\n\u793A\u4F8B\uFF1A\nLFMCC1BR6P0627851\nLFMCC1BR6P0627852",
      activeName: " "
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getDefaultValue();
    this.loadData();
  },
  deactivated: function deactivated() {
    this.showDialog = false;
  },
  methods: {
    // 导出
    detailExportFn: function detailExportFn() {
      var exporParams = {
        total: this.total,
        fileName: '在保任务明细',
        exportUrl: '/vi-core-service/batch/ciitc/itemSearch',
        searchForm: _objectSpread({
          taskId: this.taskId
        }, this.searchDetailForm)
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    resetSearchDetail: function resetSearchDetail() {
      this.searchDetailForm = {};
    },
    getDefaultValue: function getDefaultValue() {
      if (this.bizType !== 'OEM') {
        var orgObj = getUserOrgInfo();
        this.searchForm.creatorOrgCode = orgObj.orgCode;
        this.searchForm.creatorBizType = orgObj.bizType;
        this.searchFormOrgData = {
          salesOrgCode: orgObj.orgCode,
          salesOrgName: orgObj.fullName
        };
        this.dealerName = orgObj.fullName;
      }
    },
    //机构点击事件
    nodeClick: function nodeClick(orgData) {
      this.taskForm.creatorBizType = orgData.bizType;
      this.taskForm.creatorOrgCode = orgData.salesOrgCode;
    },
    //机构点击事件
    nodeSearchFormClick: function nodeSearchFormClick(orgData) {
      this.searchForm.creatorOrgCode = orgData.salesOrgCode;
    },
    // 保存
    save: function save() {
      var _this = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          var postData = _objectSpread(_objectSpread({}, _this.taskForm), {}, {
            vinList: _this.taskForm.vinList.split("\n").filter(function (item) {
              if (item) {
                return item;
              }
            })
          });
          underWarrantyQueryRequest.createUnderWarranty(postData).then(function (res) {
            _this.formLoading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
              _this.closePage(true);
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
      });
    },
    // 打开弹窗
    openDialog: function openDialog() {
      var _this2 = this;
      var orgObj = getUserOrgInfo();
      this.showTaskDialog = true;
      this.taskForm = {
        creatorOrgCode: orgObj.orgCode,
        creatorBizType: orgObj.bizType,
        updateFlag: true
      };
      this.orgData = {
        salesOrgCode: orgObj.orgCode,
        salesOrgName: orgObj.fullName
      };
      this.$nextTick(function () {
        _this2.$refs.detail.clearValidate();
      });
    },
    // 改变续保状态
    changeUnderWarrantyState: function changeUnderWarrantyState(item, operate) {
      var _this3 = this;
      this.$confirm("您确定要执行该操作吗？", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        underWarrantyQueryRequest.changeUnderWarrantyState(item.taskId, operate).then(function (res) {
          if (res.code == 200) {
            _this3.$message.success(res.msg);
            _this3.loadData();
          }
        });
      });
    },
    getBatchDetailList: function getBatchDetailList() {
      var _this4 = this;
      this.detailLoading = true;
      underWarrantyQueryRequest.getBatchDetailList(_objectSpread(_objectSpread({}, this.listDetailQuery), {}, {
        taskId: this.taskId
      }, this.searchDetailForm)).then(function (res) {
        _this4.detailLoading = false;
        if (res.code == 200) {
          _this4.detailTableData = res.data.list;
          _this4.detailTotal = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        return _this4.detailLoading = false;
      });
    },
    // 详情
    viewDetail: function viewDetail(item) {
      this.showDialog = true;
      this.taskId = item.taskId;
      this.listDetailQuery = {
        pageNum: 1,
        pageSize: 20
      };
      this.getBatchDetailList();
    },
    //日期更改监听事件
    dateChange: function dateChange(start, end, val) {
      var searchForm = {};
      if (val && val.length > 1) {
        searchForm[start] = val[0] + ' 00:00:00';
        searchForm[end] = val[1] + ' 23:59:59';
      } else {
        searchForm[start] = '';
        searchForm[end] = '';
      }
      this.$emit('pickerDateChange', [searchForm[start], searchForm[end]]);
    },
    // 关闭弹窗
    closePage: function closePage(isRefresh) {
      if (isRefresh) {
        this.loadData();
      } else {
        this.showDialog = false;
        this.showTaskDialog = false;
      }
    },
    breakData: function breakData() {
      this.loadData();
    },
    // 导出
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '在保任务',
        exportUrl: '/vi-core-service/batch/ciitc/search',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    //重置搜索表单
    resetSearch: function resetSearch() {
      this.searchForm = {};
      this.createDateTime = [getBeforeDate(30), getCurrentDate()];
      this.getDefaultValue();
    },
    //搜索
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    //获取列表数据
    loadData: function loadData() {
      var _this5 = this;
      if (!_.isEmpty(this.createDateTime)) {
        this.searchForm.createdTimeStart = this.createDateTime[0] + " 00:00:00";
        this.searchForm.createdTimeEnd = this.createDateTime[1] + " 23:59:59";
      }
      this.showDialog = false;
      this.showTaskDialog = false;
      this.loading = true;
      this.drawer = false;
      underWarrantyQueryRequest.getList(_objectSpread(_objectSpread(_objectSpread({}, this.listQuery), this.searchForm), {}, {
        ciitcQueryStatus: this.activeName
      })).then(function (res) {
        if (res.code === 200) {
          _this5.tableData = res.data.list;
          _this5.loading = false;
          _this5.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this5.loading = false;
      });
    }
  }
};