import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//

import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import resize from './mixins/resize';
var animationDuration = 6000;
export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    chartData: {
      type: Object,
      default: {}
    },
    toDayPremium: {
      type: Number | String,
      default: "0.00"
    },
    toDayQuantity: {
      type: Number | String,
      default: "0"
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val, this.toDayPremium, this.toDayQuantity);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData, this.toDayPremium, this.toDayQuantity);
    },
    setOptions: function setOptions(item, premium, quantity) {
      if (!_.isEmpty(item)) {
        var insQuantity = item.insQuantity;
        var insPremium = item.insPremium;
        var insXaxis = item.insXaxis;
        var option = {
          //鼠标移入图例
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999"
              }
            },
            textStyle: {
              color: "#fff"
            },
            backgroundColor: "#a5a5a5"
          },
          legend: {
            data: ["\u51FA\u5355\u4FDD\u8D39(\u5143)", "\u51FA\u5355\u91CF"],
            // color:['#7C90B6','#C8AB83',],
            bottom: "-0.5%"
            // 使用回调函数
            // formatter: function (name) {
            //     if (name.indexOf('保费') !== -1) {
            //         return name + '：' + `${premium}`;
            //     } else {
            //         return name + '：' + `${quantity}`;
            //     }
            // }
          },

          // x轴
          xAxis: _defineProperty({
            // type: "category",
            data: insXaxis,
            axisPointer: {
              type: "shadow"
            },
            axisLine: {
              show: true // 隐藏x轴轴线
            },

            axisTick: {
              show: false,
              // 是否隐藏x轴刻度线
              alignWithLabel: true
            },
            axisLabel: {
              //设置坐标轴样式
              axisLabel: {
                show: true,
                textStyle: {
                  color: '#333333',
                  //更改坐标轴文字颜色
                  fontSize: 12 //更改坐标轴文字大小
                }
              }
            }
          }, "axisLine", {
            lineStyle: {
              color: "#6e7079"
            }
          }),
          // y轴刻度尺
          yAxis: [_defineProperty({
            type: "value",
            name: '出单量',
            position: 'left',
            min: 0,
            textStyle: {
              color: "#6e7079"
            },
            axisLabel: {
              formatter: "{value}"
            },
            //设置坐标轴字体颜色和宽度
            axisLine: {
              lineStyle: {
                color: "#6e7079"
              }
            },
            splitLine: {
              show: false //是否隐藏网格线
            }
          }, "axisLabel", {
            formatter: function formatter(value) {
              if (value % 1 == 0) {
                return value;
              }
            }
          }), {
            //第二个y轴在右侧
            position: 'right',
            type: 'value',
            name: '出单保费(元)',
            axisLine: {
              lineStyle: {
                color: "#6e7079"
              }
            }
          }],
          series: [{
            name: '出单量',
            type: 'line',
            // stack: 'one',
            yAxisIndex: 0,
            // lineStyle: {
            //     width: 0
            // },
            // showSymbol: false,
            smooth: true,
            // areaStyle: {
            //     // opacity: 0.8,
            //     //  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            //     //         offset: 0,
            //     //         color: 'rgba(200,171,131,1)'
            //     //     },
            //     //     {
            //     //         offset: 1,
            //     //         color: 'rgba(200,171,131,0.5)'
            //     //     }
            //     // ])

            // },
            data: insQuantity,
            color: "#C8AB83"
          },
          // 折线图
          {
            name: '出单保费(元)',
            type: 'bar',
            // stack: 'one',
            yAxisIndex: 1,
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(124,144,182,1)'
              }, {
                offset: 1,
                color: 'rgba(124,144,182,0.5)'
              }])
            },
            data: insPremium,
            color: "#7C90B6",
            barWidth: 50 //柱图宽度
          }]
        };

        this.chart.setOption(option);
      }
    }
  }
};