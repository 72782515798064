import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    dataList: {
      type: Array,
      required: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    rulesItems: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      form: {},
      formData: {}
    };
  },
  computed: {},
  created: function created() {
    this.form = this.dataList;
    this.addRulesItem(this.form);
  },
  watch: {
    form: function form(newValue) {
      this.exportvalue();
    },
    deep: true
  },
  methods: {
    exportvalue: function exportvalue() {
      this.$emit('exportvalue', this.form);
    },
    addRulesItem: function addRulesItem(dalaList) {
      var _this = this;
      dalaList.forEach(function (item) {
        var rulesItem = [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }];
        if (item.code) {
          rulesItem[0].message = "\u8BF7\u8F93\u5165".concat(item.name), _this.rulesItems[item.code] = rulesItem;
        }
      });
    },
    validataForm: function validataForm() {
      this.$refs["dynamicForm"].validate();
    }
  }
};