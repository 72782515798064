var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "550px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _vm.currentType !== "detail"
            ? _c(
                "el-card",
                { attrs: { shadow: "never" } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "detail",
                      staticStyle: { "margin-bottom": "-20px" },
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "110px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "标题：",
                                    prop: "noticeTitle",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入标题",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.noticeTitle,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "noticeTitle", $$v)
                                      },
                                      expression: "form.noticeTitle",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "级别：",
                                    prop: "noticeLevel",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.noticeLevel,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "noticeLevel", $$v)
                                        },
                                        expression: "form.noticeLevel",
                                      },
                                    },
                                    _vm._l(
                                      _vm.noticeLevelList,
                                      function (item) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: item.value,
                                            attrs: { label: item.value },
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "发送范围：",
                                    prop: "noticeRange",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.noticeRangeChange },
                                      model: {
                                        value: _vm.form.noticeRange,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "noticeRange", $$v)
                                        },
                                        expression: "form.noticeRange",
                                      },
                                    },
                                    _vm._l(
                                      _vm.noticeRangeList,
                                      function (item) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: item.value,
                                            attrs: { label: item.value },
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.noticeRange === "ORG"
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "指定机构：",
                                        prop: "noticeTargetList",
                                      },
                                    },
                                    [
                                      _c("OrgSelect", {
                                        ref: "orgSelect",
                                        attrs: {
                                          isMultiple: true,
                                          multipleDefaultCheckedKeys:
                                            _vm.multipleDefaultCheckedKeys,
                                          multipleNodeKey: "orgCode",
                                          multiplePlaceholder: "请选择指定机构",
                                          searchForm: _vm.orgForm,
                                          showMultipleBtn: true,
                                        },
                                        on: { nodeClick: _vm.nodeClick },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "是否发布：",
                                    prop: "published",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.form.published,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "published", $$v)
                                        },
                                        expression: "form.published",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: true } },
                                        [_vm._v("是")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: false } },
                                        [_vm._v("否")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "内容：",
                                    prop: "noticeContent",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      placeholder: "请输入内容",
                                      autosize: { minRows: 10, maxRows: 20 },
                                      resize: "none",
                                    },
                                    model: {
                                      value: _vm.form.noticeContent,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "noticeContent", $$v)
                                      },
                                      expression: "form.noticeContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "附件：",
                                    prop: "noticeFileList",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload-demo",
                                      attrs: {
                                        "before-remove": function () {
                                          return (_vm.formData = "")
                                        },
                                        action: "",
                                        multiple: "",
                                        "show-file-list": false,
                                        "http-request": _vm.uploadFile,
                                        "before-upload": _vm.beforeUpload,
                                        "file-list": _vm.fileList,
                                        accept: _vm.uploadType,
                                        "list-type": "text",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("选择附件")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "table",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "10px",
                                      },
                                      attrs: { border: "1" },
                                    },
                                    [
                                      _c("colgroup", [
                                        _c("col", {
                                          staticStyle: { width: "47.5%" },
                                        }),
                                        _c("col", {
                                          staticStyle: { width: "5%" },
                                        }),
                                      ]),
                                      _c(
                                        "thead",
                                        {
                                          staticStyle: {
                                            "background-color": "#eeee",
                                          },
                                        },
                                        [
                                          _c(
                                            "tr",
                                            {
                                              staticStyle: {
                                                "font-size": "13px",
                                              },
                                            },
                                            [
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("文件名")]
                                              ),
                                              _c(
                                                "th",
                                                { attrs: { scope: "col" } },
                                                [_vm._v("操作")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.form.noticeFileList,
                                          function (item, index) {
                                            return _c("tr", { key: index }, [
                                              _c(
                                                "td",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handle(item)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "file-text",
                                                      attrs: {
                                                        title: item.fileName,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.fileName)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("td", [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-remove-outline",
                                                  staticStyle: {
                                                    "font-size": "24px",
                                                    color: "rgb(237, 98, 98)",
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteFile(
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("el-card", { attrs: { shadow: "never" } }, [
                _c("h3", [_vm._v(_vm._s(_vm.form.noticeTitle))]),
                _c(
                  "p",
                  { staticStyle: { "font-size": "12px", color: "#999" } },
                  [_vm._v("级别 : " + _vm._s(_vm.form.noticeLevelDisplay))]
                ),
                _c(
                  "p",
                  { staticStyle: { "font-size": "12px", color: "#999" } },
                  [_vm._v("发布时间 : " + _vm._s(_vm.form.createdTime))]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "line-height": "25px",
                      "font-size": "15px",
                      "white-space": "pre-wrap",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.form.noticeContent) + " ")]
                ),
                _c(
                  "div",
                  [
                    _c(
                      "p",
                      { staticStyle: { color: "#999", "font-size": "13px" } },
                      [_vm._v("附件列表：")]
                    ),
                    _vm._l(_vm.form.noticeFileList, function (file, i) {
                      return _c("div", { key: i }, [
                        _c("div", { staticClass: "file-box" }, [
                          _c(
                            "span",
                            {
                              staticClass: "file-text",
                              attrs: { title: file.fileName },
                              on: {
                                click: function ($event) {
                                  return _vm.handle(file)
                                },
                              },
                            },
                            [_vm._v(_vm._s(file.fileName))]
                          ),
                        ]),
                      ])
                    }),
                  ],
                  2
                ),
                _vm.showReadList
                  ? _c(
                      "div",
                      [
                        _c(
                          "p",
                          {
                            staticStyle: { color: "#999", "font-size": "13px" },
                          },
                          [_vm._v("阅读列表：")]
                        ),
                        _c(
                          "el-table",
                          {
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              size: "mini",
                              border: "",
                              data: _vm.noticeReadList,
                              "header-cell-style": { background: "#F7F7F7" },
                              "highlight-current-row": "",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "readUserName",
                                "min-width": "150",
                                label: "用户名",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "firstTime",
                                width: "150",
                                label: "首次阅读时间",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "lastTime",
                                width: "150",
                                label: "最后阅读时间",
                                "show-overflow-tooltip": "",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("pagination", {
                          attrs: {
                            total: _vm.total,
                            page: _vm.listQuery.pageNum,
                            limit: _vm.listQuery.pageSize,
                          },
                          on: {
                            "update:page": function ($event) {
                              return _vm.$set(_vm.listQuery, "pageNum", $event)
                            },
                            "update:limit": function ($event) {
                              return _vm.$set(_vm.listQuery, "pageSize", $event)
                            },
                            pagination: function ($event) {
                              return _vm.loadData()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _vm.currentType !== "detail"
            ? _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-check",
                    type: "primary",
                    plain: "",
                    size: "mini",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }