import "core-js/modules/es.array.concat.js";
/**
 * 网关管配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 路由列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} enabled 是否启用
   * @param {*} httpMethod 请求方式,可用值:GET,POST,PUT,DELETE
   * @param {*} name 路由名称(模糊搜索)
   * @param {*} routePath 路由地址(模糊搜索)
   */
  getRouteList: function getRouteList(pageNum, pageSize, data) {
    return request.get(getUrl("/apiRoute/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 5), {
      params: data
    });
  },
  /**
   * 单独获取一条信息
   * @param {*} data 内容
   */
  getOneRoute: function getOneRoute(data) {
    return request.get(getUrl("/apiRoute/get?id=".concat(data.id), 5));
  },
  /**
   * 批量导入
   * @param {*} data 内容
   */
  batchImport: function batchImport(data) {
    return request.post(getUrl("/apiRoute/batchImport?objStorageId=".concat(data), 5));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  createRoute: function createRoute(data) {
    return request.post(getUrl("/apiRoute/create", 5), data);
  },
  /**
   * 删除
   * @param {*} data 内容
   */
  deleteRoute: function deleteRoute(data) {
    return request.delete(getUrl("/apiRoute/delete", 5), {
      params: data
    });
  },
  /**
   * 是否启用,编辑
   * @param {*} data 内容
   */
  updateRoute: function updateRoute(data) {
    return request.put(getUrl("/apiRoute/update?id=".concat(data.id), 5), data);
  },
  /**
   * 路由组列表
   * @param {*} data 内容
   */
  RouteGroupLists: function RouteGroupLists(data) {
    return request.get(getUrl("/apiGroup/search", 5), {
      params: data
    });
  },
  /**
   * 新增一个路由组 (data)
   * @param {*} data 内容
   */
  createRouteGroup: function createRouteGroup(data) {
    return request.post(getUrl("/apiGroup/create", 5), data);
  },
  /**
   * 删除一个路由组 (拼接)
   * @param {*} data 内容
   */
  deleteRouteGroup: function deleteRouteGroup(data) {
    return request.delete(getUrl("/apiGroup/delete", 5), {
      params: data
    });
  },
  /**
   * 修改一个路由组 (拼接+data)
   * @param {*} data 内容
   */
  updateRouteGroup: function updateRouteGroup(data) {
    return request.put(getUrl("/apiGroup/update?id=".concat(data.id), 5), data);
  },
  /**
   * 获取一个路由组 (拼接)
   * @param {*} data 内容
   */
  getOneRouteGroup: function getOneRouteGroup(data) {
    return request.get(getUrl("/apiGroup/get", 5), {
      params: data
    });
  },
  /**
   * 接入应用列表 (拼接)
   * @param {*} data 内容
   */
  applicationLists: function applicationLists(data) {
    return request.get(getUrl("/application/search", 5), {
      params: data
    });
  },
  /**
   * 新建应用 (data)
   * @param {*} data 内容
   */
  createApplication: function createApplication(data) {
    return request.post(getUrl("/application/create", 5), data);
  },
  /**
   * 删除应用 (拼接)
   * @param {*} data 内容
   */
  deleteApplication: function deleteApplication(data) {
    return request.delete(getUrl("/application/delete", 5), {
      params: data
    });
  },
  /**
   * 编辑应用 (拼接+data)
   * @param {*} data 内容
   */
  updateApplication: function updateApplication(data) {
    return request.put(getUrl("/application/update?id=".concat(data.applicationId), 5), data);
  },
  /**
   * 获取单个应用详情 (拼接)
   * @param {*} data 内容
   */
  getOneApplication: function getOneApplication(data) {
    return request.get(getUrl("/application/getByAppId", 5), {
      params: data
    });
  }
};
export default objApi;