var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.formLoading,
            expression: "formLoading",
          },
        ],
        staticClass: "app-m-cls",
      },
      [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "登录账号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "15",
                                    placeholder: "请输入登录账号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.account,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "account", $$v)
                                    },
                                    expression: "searchForm.account",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "姓名" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入姓名",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.userName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "userName", $$v)
                                    },
                                    expression: "searchForm.userName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.bizType == "BROKER"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "所属机构" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        ref: "orgSelect1",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          clearable: "",
                                          filterable: "",
                                          "filter-method": _vm.dataFilterS,
                                          placeholder: "请选择所属机构",
                                        },
                                        model: {
                                          value: _vm.searchForm.fullName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "fullName",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.fullName",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-option",
                                          {
                                            staticStyle: {
                                              height: "auto",
                                              "background-color": "white",
                                            },
                                            attrs: {
                                              value: _vm.searchForm.orgCode,
                                            },
                                          },
                                          [
                                            _c("el-tree", {
                                              ref: "trees",
                                              attrs: {
                                                "expand-on-click-node": false,
                                                "node-key": "code",
                                                "highlight-current":
                                                  _vm.highlightCurrent,
                                                data: _vm.orgData,
                                                props: _vm.defaultProps,
                                                "filter-node-method":
                                                  _vm.filterNodeS,
                                              },
                                              on: {
                                                "node-click":
                                                  _vm.handleNodeClickS,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "search-box",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("登录账号：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "15",
                            placeholder: "请输入登录账号",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.account,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "account", $$v)
                            },
                            expression: "searchForm.account",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("姓名：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入姓名",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.userName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "userName", $$v)
                            },
                            expression: "searchForm.userName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm.orgName === "DEALER" && _vm.bizType === "OEM"
                          ? _c("span", [_vm._v("所属机构：")])
                          : _vm._e(),
                        _vm.orgName === "DEALER" && _vm.bizType === "OEM"
                          ? _c("OemSelect", {
                              staticStyle: {
                                "max-width": "150px",
                                display: "inline-block",
                              },
                              attrs: {
                                searchForm: _vm.orgForm,
                                canClickArea: true,
                              },
                              on: { nodeClick: _vm.nodeClick },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "8px" },
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _vm.bizType === "DEALER"
                      ? _c(
                          "div",
                          { staticClass: "search-btn" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.breakData },
                              },
                              [_vm._v("刷新")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.add("add")
                                  },
                                },
                              },
                              [_vm._v("添加")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm.bizType === "OEM"
                  ? _c(
                      "div",
                      { staticStyle: { position: "relative", height: "54px" } },
                      [
                        _c(
                          "el-tabs",
                          {
                            on: { "tab-click": _vm.orgTabClick },
                            model: {
                              value: _vm.orgName,
                              callback: function ($$v) {
                                _vm.orgName = $$v
                              },
                              expression: "orgName",
                            },
                          },
                          [
                            _c("el-tab-pane", {
                              attrs: { label: "主机厂 ", name: "OEM" },
                            }),
                            _c("el-tab-pane", {
                              attrs: { label: "经销商", name: "DEALER" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "table-btn-cls",
                            staticStyle: {
                              position: "absolute",
                              right: "0",
                              bottom: "22px",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: { click: _vm.breakData },
                              },
                              [_vm._v("刷新")]
                            ),
                            _vm.orgName === "OEM"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.add("add")
                                      },
                                    },
                                  },
                                  [_vm._v("添加")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height:
                        _vm.bizType === "OEM"
                          ? _vm.tableHeightS
                          : _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "account",
                        label: "登录账号",
                        align: "center",
                        width: "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "userName",
                        label: "姓名",
                        "min-width": "100",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "phone",
                        label: "联系电话",
                        "min-width": "120",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "jobNo",
                        label: "工号",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "用户角色",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getRoleNames(scope.row.roleVOS)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "entryTime",
                        label: "入职日期",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.entryTime
                                      ? scope.row.entryTime.split(" ")[0]
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "employeeStatusDisplay",
                        label: "员工状态",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "所属机构",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "modifiedTime",
                        label: "更新时间",
                        "min-width": "150",
                        align: "center",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "left",
                        width: _vm.setWidth,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail("view", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _vm.orgName === "OEM"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.viewDetail(
                                            "edit",
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  )
                                : _vm._e(),
                              _vm.userInfo.user.userId !== scope.row.userId &&
                              _vm.orgName === "OEM"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "danger",
                                        plain: "",
                                        size: "mini",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteEmployee(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "warning",
                                    plain: "",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.resetPassword(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("重置密码")]
                              ),
                              _vm.bizType === "OEM"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.login(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("登录")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "Dialog",
          {
            attrs: {
              title:
                (_vm.opType == "view"
                  ? "查看"
                  : _vm.opType == "edit"
                  ? "编辑"
                  : "新增") + "人员",
              "form-loading": _vm.formLoading,
            },
            on: { handleClose: _vm.loadData },
            model: {
              value: _vm.showEmployeeDialog,
              callback: function ($$v) {
                _vm.showEmployeeDialog = $$v
              },
              expression: "showEmployeeDialog",
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "detail",
                staticClass: "formDialog",
                attrs: {
                  slot: "content",
                  model: _vm.form,
                  rules: _vm.formRules,
                  "label-width": "135px",
                  size: "mini",
                },
                slot: "content",
              },
              [
                _vm.bizType == "BROKER"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "员工类型：",
                              prop: "employeeType",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.opType == "view" },
                                model: {
                                  value: _vm.form.employeeType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "employeeType", $$v)
                                  },
                                  expression: "form.employeeType",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  { attrs: { label: "OFFICE_WORK" } },
                                  [_vm._v("内勤")]
                                ),
                                _c(
                                  "el-radio",
                                  { attrs: { label: "SALESMAN" } },
                                  [_vm._v("业务员")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.bizType == "DEALER"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "员工类型：",
                              prop: "employeeType",
                            },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.opType == "view" },
                                model: {
                                  value: _vm.form.employeeType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "employeeType", $$v)
                                  },
                                  expression: "form.employeeType",
                                },
                              },
                              [
                                _c(
                                  "el-radio",
                                  { attrs: { label: "OFFICE_WORK" } },
                                  [_vm._v("内勤")]
                                ),
                                _c(
                                  "el-radio",
                                  { attrs: { label: "SALESMAN" } },
                                  [_vm._v("业务员")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.bizType == "BROKER"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "所属机构：", prop: "fullName" } },
                          [
                            _c(
                              "el-select",
                              {
                                ref: "orgSelect",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  "filter-method": _vm.dataFilter,
                                  placeholder: "请选择所属机构",
                                  disabled: _vm.opType !== "add",
                                },
                                model: {
                                  value: _vm.form.fullName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "fullName", $$v)
                                  },
                                  expression: "form.fullName",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  {
                                    staticStyle: {
                                      height: "auto",
                                      "background-color": "white",
                                    },
                                    attrs: { value: _vm.form.orgCode },
                                  },
                                  [
                                    _c("el-tree", {
                                      ref: "tree",
                                      attrs: {
                                        "expand-on-click-node": false,
                                        "node-key": "code",
                                        "highlight-current":
                                          _vm.highlightCurrent,
                                        data: _vm.orgData,
                                        props: _vm.defaultProps,
                                        "default-expand-all": "",
                                        "filter-node-method": _vm.filterNode,
                                      },
                                      on: { "node-click": _vm.handleNodeClick },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "登录账号：", prop: "account" } },
                          [
                            _c("el-input", {
                              attrs: {
                                autocomplete: "off",
                                maxlength: "40",
                                disabled: _vm.opType !== "add",
                                placeholder: "请输入登录账号",
                                clearable: "",
                                onkeyup:
                                  "this.value=this.value.replace(/[\\u4E00-\\u9FA5]/g,'')",
                              },
                              model: {
                                value: _vm.form.account,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "account",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.account",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.opType == "add"
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "登录密码：",
                                  prop: "password",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    autocomplete: "new-password",
                                    maxlength: "24",
                                    placeholder: "请输入登录密码",
                                    clearable: "",
                                    "show-password": "",
                                    disabled: _vm.opType == "view",
                                  },
                                  model: {
                                    value: _vm.form.password,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "password",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "form.password",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "姓名：", prop: "userName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                disabled: _vm.opType == "view",
                                placeholder: "请输入姓名",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.userName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "userName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.userName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              disabled: "",
                              label: "性别：",
                              prop: "sex",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  label: "MALE",
                                  disabled: _vm.opType == "view",
                                },
                                model: {
                                  value: _vm.form.sex,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sex", $$v)
                                  },
                                  expression: "form.sex",
                                },
                              },
                              [_vm._v("男")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: {
                                  label: "FEMALE",
                                  disabled: _vm.opType == "view",
                                },
                                model: {
                                  value: _vm.form.sex,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sex", $$v)
                                  },
                                  expression: "form.sex",
                                },
                              },
                              [_vm._v("女")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "联系电话：", prop: "phone" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "11",
                                disabled: _vm.opType == "view",
                                placeholder: "请输入联系电话",
                                oninput:
                                  "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.phone,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "phone",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "员工状态：",
                              prop: "employeeStatus",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择员工状态",
                                  disabled: _vm.opType == "view",
                                },
                                model: {
                                  value: _vm.form.employeeStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "employeeStatus", $$v)
                                  },
                                  expression: "form.employeeStatus",
                                },
                              },
                              _vm._l(
                                _vm.EMPLOYEE_STATES,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.dictName,
                                      value: item.dictCode,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        { staticClass: "custom-div" },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "系统角色：", prop: "roleIds" } },
                            [
                              _vm.opType != "view"
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "max-height": "40px",
                                      },
                                      attrs: {
                                        multiple: "",
                                        "collapse-tags": "",
                                        placeholder: "请选择系统角色",
                                        disabled:
                                          _vm.opType == "view" ||
                                          (_vm.adminRoles &&
                                            _vm.adminRoles.length == 1 &&
                                            _vm.form.roleNames &&
                                            _vm.form.roleNames.indexOf(
                                              "管理员"
                                            ) != -1),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                      model: {
                                        value: _vm.form.roleIds,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "roleIds", $$v)
                                        },
                                        expression: "form.roleIds",
                                      },
                                    },
                                    _vm._l(_vm.roles, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          disabled: !item.enabled,
                                          label:
                                            "" +
                                            (!item.enabled
                                              ? item.roleName + "(已禁用)"
                                              : item.roleName),
                                          value: item.roleId,
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "max-height": "40px",
                                      },
                                      attrs: {
                                        multiple: "",
                                        "collapse-tags": "",
                                        placeholder: "请选择系统角色",
                                        disabled:
                                          _vm.opType == "view" ||
                                          (_vm.adminRoles &&
                                            _vm.adminRoles.length == 1 &&
                                            _vm.form.roleNames &&
                                            _vm.form.roleNames.indexOf(
                                              "管理员"
                                            ) != -1),
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                      model: {
                                        value: _vm.form.roleIds,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "roleIds", $$v)
                                        },
                                        expression: "form.roleIds",
                                      },
                                    },
                                    _vm._l(
                                      _vm.dealerRoles,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            disabled: !item.enabled,
                                            label:
                                              "" +
                                              (!item.enabled
                                                ? item.roleName + "(已禁用)"
                                                : item.roleName),
                                            value: item.roleId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "工号：", prop: "jobNo" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                disabled: _vm.opType == "view",
                                placeholder: "请输入工号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.jobNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "jobNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "form.jobNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.opType == "edit" &&
                _vm.adminRoles &&
                _vm.adminRoles.length == 1 &&
                _vm.form.roleNames &&
                _vm.form.roleNames.indexOf("管理员") != -1
                  ? _c("el-row", [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-left": "50px",
                            "padding-bottom": "10px",
                          },
                        },
                        [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "*不允许修改系统角色，请联系一店一群小助手解决"
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.bizType == "OEM" && _vm.orgName == "OEM"
                  ? _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "custom-div" },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "关联区域：",
                                    prop: "areaId",
                                  },
                                },
                                [
                                  _c("el-cascader", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "max-length": "10",
                                      options: _vm.cityList,
                                      props: _vm.props,
                                      "collapse-tags": "",
                                      clearable: "",
                                      disabled: _vm.opType == "view",
                                    },
                                    model: {
                                      value: _vm.form.areaId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "areaId", $$v)
                                      },
                                      expression: "form.areaId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "证件类型：",
                                  prop: "salesman.idTypeCode",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      disabled: _vm.opType == "view",
                                      placeholder: "请选择证件类型",
                                      clearable: false,
                                    },
                                    model: {
                                      value: _vm.form.salesman.idTypeCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.salesman,
                                          "idTypeCode",
                                          $$v
                                        )
                                      },
                                      expression: "form.salesman.idTypeCode",
                                    },
                                  },
                                  _vm._l(
                                    _vm.VI_CERTIFICATE_TYPE,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.dictName,
                                          value: item.dictCode,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "证件号：",
                                  prop: "salesman.idNo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入证件号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.idNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form.salesman, "idNo", $$v)
                                    },
                                    expression: "form.salesman.idNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "入职日期：",
                                  prop: "salesman.hiredate",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    type: "date",
                                    placeholder: "选择入职日期",
                                    disabled: _vm.opType == "view",
                                  },
                                  model: {
                                    value: _vm.form.salesman.hiredate,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "hiredate",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.hiredate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "国籍：",
                                  prop: "salesman.nationality",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入国籍",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.nationality,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "nationality",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.nationality",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "生日：",
                                  prop: "salesman.birthday",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    type: "date",
                                    placeholder: "选择生日-日期",
                                    disabled: _vm.opType == "view",
                                  },
                                  model: {
                                    value: _vm.form.salesman.birthday,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "birthday",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.birthday",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "地址：",
                                  prop: "salesman.address",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入地址",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.address,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "address",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.address",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "学历：",
                                  prop: "salesman.education",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入学历",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.education,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "education",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.education",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "婚姻状态：",
                                  prop: "salesman.maritalStatus",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入婚姻状态",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.maritalStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "maritalStatus",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.maritalStatus",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "民族：",
                                  prop: "salesman.nation",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入民族",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.nation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form.salesman, "nation", $$v)
                                    },
                                    expression: "form.salesman.nation",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "政治面貌：",
                                  prop: "salesman.politicalStatus",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入政治面貌",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.politicalStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "politicalStatus",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.politicalStatus",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "紧急联系人：",
                                  prop: "salesman.contactName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入紧急联系人",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.contactName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "contactName",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.contactName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "紧急联系电话：",
                                  prop: "salesman.contactPhone",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入紧急联系电话",
                                    oninput:
                                      "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.contactPhone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "contactPhone",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.contactPhone",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "邮箱：",
                                  prop: "salesman.email",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入邮箱",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form.salesman, "email", $$v)
                                    },
                                    expression: "form.salesman.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "备注：",
                                  prop: "salesman.remark",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入备注",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form.salesman, "remark", $$v)
                                    },
                                    expression: "form.salesman.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "资格证号：",
                                  prop: "salesman.qualificationNo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入资格证号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.qualificationNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "qualificationNo",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.qualificationNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "资格证号取得时间：",
                                  prop: "salesman.qualificationAcquisitionTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    type: "date",
                                    placeholder: "选择资格证号取得时间",
                                    disabled: _vm.opType == "view",
                                  },
                                  model: {
                                    value:
                                      _vm.form.salesman
                                        .qualificationAcquisitionTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "qualificationAcquisitionTime",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.salesman.qualificationAcquisitionTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "资格证号到期时间：",
                                  prop: "salesman.qualificationExpirationTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    type: "date",
                                    placeholder: "选择资格证号到期时间",
                                    disabled: _vm.opType == "view",
                                  },
                                  model: {
                                    value:
                                      _vm.form.salesman
                                        .qualificationExpirationTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "qualificationExpirationTime",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.salesman.qualificationExpirationTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "执业证号：",
                                  prop: "salesman.practiceNo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入执业证号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.practiceNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "practiceNo",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.practiceNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "银行卡号：",
                                  prop: "salesman.bankCardNo",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入银行卡号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.bankCardNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "bankCardNo",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.bankCardNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.form.employeeType === "SALESMAN"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "开户银行：",
                                  prop: "salesman.bankName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入开户银行",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.bankName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "bankName",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.bankName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "开户支行：",
                                  prop: "salesman.bankSubBranch",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    disabled: _vm.opType == "view",
                                    placeholder: "请输入开户支行",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.salesman.bankSubBranch,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.salesman,
                                        "bankSubBranch",
                                        $$v
                                      )
                                    },
                                    expression: "form.salesman.bankSubBranch",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.hidden && _vm.form.employeeType == "SALESMAN"
                  ? _c(
                      "div",
                      [
                        _vm.hidden ? _c("hr") : _vm._e(),
                        _vm.hidden
                          ? _c(
                              "el-row",
                              { staticStyle: { "line-height": "46px" } },
                              [
                                _c("el-col", { attrs: { span: 20 } }, [
                                  _c("span", [_vm._v("合同资料")]),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "10px",
                                        color: "#2150A3",
                                        "font-weight": "600",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " (支持格式:jpg、png、jpeg、gif、xls、xlsx、doc、docx、pdf)"
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "el-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        ref: "upload",
                                        staticStyle: { float: "right" },
                                        attrs: {
                                          disabled: _vm.viewDisabled,
                                          "before-remove": function () {
                                            return (_vm.formData = "")
                                          },
                                          action: "",
                                          "show-file-list": false,
                                          "http-request": _vm.uploadFile,
                                          "file-list": _vm.fileList,
                                          accept: "*",
                                          "list-type": "text",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              icon: "el-icon-upload2",
                                              size: "mini",
                                              type: "primary",
                                              disabled: _vm.viewDisabled,
                                              plain: "",
                                            },
                                          },
                                          [_vm._v("上传文件")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.employeeType === "SALESMAN"
                          ? _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-tabs",
                                      {
                                        on: { "tab-click": _vm.handleTabClick },
                                        model: {
                                          value: _vm.activeName,
                                          callback: function ($$v) {
                                            _vm.activeName = $$v
                                          },
                                          expression: "activeName",
                                        },
                                      },
                                      [
                                        _c("el-tab-pane", {
                                          attrs: { label: "合同", name: "HT" },
                                        }),
                                        _c("el-tab-pane", {
                                          attrs: {
                                            label: "执业证",
                                            name: "ZYZ",
                                          },
                                        }),
                                        _c("el-tab-pane", {
                                          attrs: {
                                            label: "银行卡",
                                            name: "YHK",
                                          },
                                        }),
                                        _c("el-tab-pane", {
                                          attrs: {
                                            label: "身份证",
                                            name: "SFZ",
                                          },
                                        }),
                                        _c("el-tab-pane", {
                                          attrs: { label: "其他", name: "QT" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.employeeType === "SALESMAN"
                          ? _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-table",
                                      {
                                        directives: [
                                          {
                                            name: "loading",
                                            rawName: "v-loading",
                                            value: _vm.loading,
                                            expression: "loading",
                                          },
                                        ],
                                        ref: "multipleTable",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          size: "mini",
                                          border: "",
                                          data: _vm.fileList,
                                          "header-cell-style": {
                                            background: "#F7F7F7",
                                          },
                                          "highlight-current-row": "",
                                        },
                                      },
                                      [
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "name",
                                            label: "文件名",
                                            "show-overflow-tooltip": "",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "attachmentTypeDisplay",
                                            label: "附件类型",
                                            "min-width": "120",
                                            "show-overflow-tooltip": "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "attachmentTypeDisplayFilters"
                                                          )(
                                                            scope.row
                                                              .attachmentTypeDisplay
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3656497019
                                          ),
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            prop: "type",
                                            label: "状态",
                                            "min-width": "120",
                                            "show-overflow-tooltip": "",
                                          },
                                        }),
                                        _c("el-table-column", {
                                          attrs: {
                                            label: "操作",
                                            align: "center",
                                            width: "110",
                                            fixed: "right",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (scope) {
                                                  return [
                                                    _vm.opType == "edit"
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              size: "mini",
                                                              type: "danger",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteFile(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("删除")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.opType == "view"
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              type: "primary",
                                                              plain: "",
                                                              size: "mini",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.handleDownload(
                                                                  scope.row
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("下载")]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2420734607
                                          ),
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { attrs: { slot: "footer" }, slot: "footer" },
              [
                _vm.opType !== "view"
                  ? _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-check",
                        },
                        on: { click: _vm.save },
                      },
                      [_vm._v(" 保存 ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", icon: "el-icon-close" },
                    on: { click: _vm.close },
                  },
                  [_vm._v(" 取消 ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }