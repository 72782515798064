import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _objApi;
/**
 * 理赔管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = (_objApi = {
  //  **********************************理赔案件************************************
  /**
   * 分页查询理赔案件
   * @param {*} data 数据
   */
  getClaimsCaseList: function getClaimsCaseList(data) {
    return request.get(getUrl("/claimscase/search", 1), {
      params: data
    });
  },
  /**
   * 通过id获取理赔案件
   * @param {*} data 数据
   */
  getClaimsCaseDetails: function getClaimsCaseDetails(data) {
    return request.get(getUrl("/claimscase/get", 1), {
      params: data
    });
  }
}, _defineProperty(_objApi, "getClaimsCaseDetails", function getClaimsCaseDetails(claimsCaseId) {
  return request.get(getUrl("/claimscase/get?claimsCaseId=".concat(claimsCaseId), 1));
}), _defineProperty(_objApi, "getClaimsCaseStatusList", function getClaimsCaseStatusList(claimsCaseId) {
  return request.get(getUrl("/claimscase/getClaimsCaseStatusList?claimsCaseId=".concat(claimsCaseId), 1));
}), _defineProperty(_objApi, "getClaimsCaseStatusSdram", function getClaimsCaseStatusSdram(claimsCaseStatusId) {
  return request.get(getUrl("/claimscase/getClaimsCaseStatusSdram?claimsCaseStatusId=".concat(claimsCaseStatusId), 1));
}), _defineProperty(_objApi, "getClaimsCaseStatistics", function getClaimsCaseStatistics(data) {
  return request.get(getUrl("/claimscase/statistics/insCorp", 1), {
    params: data
  });
}), _defineProperty(_objApi, "getClaimsReportList", function getClaimsReportList(data) {
  return request.get(getUrl("/claims/report/search", 1), {
    params: data
  });
}), _defineProperty(_objApi, "deleteClaimsReport", function deleteClaimsReport(reportIds) {
  return request.delete(getUrl("/claims/report/batchDelete?reportIds=".concat(reportIds), 1));
}), _defineProperty(_objApi, "getClaimsReportInfo", function getClaimsReportInfo(reportId) {
  return request.get(getUrl("/claims/report/get?reportId=".concat(reportId), 1));
}), _defineProperty(_objApi, "transformRepair", function transformRepair(data) {
  return request.post(getUrl("/claims/report/transformRepair", 1), data);
}), _objApi);
export default objApi;