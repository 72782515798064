var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          "label-width": "120px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            model: {
                              value: _vm.activeNames,
                              callback: function ($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "table-btn-cls" },
                                  _vm._l(
                                    _vm.form.netUserTypeList,
                                    function (item, index) {
                                      return _c(
                                        "el-tag",
                                        {
                                          key: index,
                                          style: {
                                            margin: "0 5px 5px 0",
                                            color: item.typeColor,
                                          },
                                          attrs: { color: "#f5f5f5" },
                                        },
                                        [_vm._v(_vm._s(item.typeName))]
                                      )
                                    }
                                  ),
                                  1
                                ),
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-between",
                                      staticStyle: {
                                        width: "100%",
                                        "line-height": "25px",
                                      },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "基本信息" },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-right": "20px",
                                            "z-index": "999",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.correlation($event)
                                                },
                                              },
                                            },
                                            [_vm._v(" 关联 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                plain: "",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.edit("edit")
                                                },
                                              },
                                            },
                                            [_vm._v(" 编辑 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "姓名：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.form.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.name",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件类型：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                disabled: "",
                                              },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .certificateTypeDisplay,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "certificateTypeDisplay",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.certificateTypeDisplay",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件号码：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "20",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.form.certificateNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "certificateNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.certificateNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "用户标识：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.form.netUserId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "netUserId",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.netUserId",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "业务人员：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.form.businessPerson,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "businessPerson",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.businessPerson",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "手机号码：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "11",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.form.mobile,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "mobile",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.mobile",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "邮箱：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.form.email,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "email",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.email",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "所在省市区：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.form.regionName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "regionName",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.regionName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 24,
                                          sm: 12,
                                          md: 12,
                                          lg: 8,
                                          xl: 8,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "详细地址：" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                clearable: "",
                                                maxlength: "40",
                                                disabled: "",
                                              },
                                              model: {
                                                value: _vm.form.address,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "address",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.address",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "table-cls normal-btn-cls" },
                  [
                    _c(
                      "el-card",
                      {
                        style: { height: _vm.scrollerHeight, overflow: "auto" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c(
                          "el-tabs",
                          {
                            model: {
                              value: _vm.tabActiveName,
                              callback: function ($$v) {
                                _vm.tabActiveName = $$v
                              },
                              expression: "tabActiveName",
                            },
                          },
                          [
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "车辆信息",
                                  name: "VEHICLEINFO",
                                },
                              },
                              [
                                _c("VehicleInfo", {
                                  attrs: { netUserId: _vm.netUserId },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "业务信息",
                                  name: "BUSINESSINFO",
                                },
                              },
                              [
                                _c("BusinessInfo", {
                                  attrs: { netUserId: _vm.netUserId },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-tab-pane",
                              {
                                attrs: {
                                  label: "用户来源",
                                  name: "USERSOURCE",
                                },
                              },
                              [
                                _c("UserSource", {
                                  attrs: { netUserId: _vm.netUserId },
                                }),
                              ],
                              1
                            ),
                            false
                              ? _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "机构信息",
                                      name: "ORGINFO",
                                    },
                                  },
                                  [_c("OrgInfo")],
                                  1
                                )
                              : _vm._e(),
                            false
                              ? _c(
                                  "el-tab-pane",
                                  {
                                    attrs: {
                                      label: "用户行为",
                                      name: "USERBEHAVIOR",
                                    },
                                  },
                                  [_c("UserBehavior")],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c("div", { staticClass: "footer-btn-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-back" },
                        on: { click: _vm.closePage },
                      },
                      [_vm._v("返回")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showNetUserDialog,
            "close-on-click-modal": false,
            width: "450px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showNetUserDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [
                  _vm._v(
                    _vm._s(_vm.dialogType === "edit" ? "编辑" : "新增") +
                      "基本信息"
                  ),
                ]
              ),
            ]
          ),
          _c("NetUserDialog", {
            attrs: {
              opType: _vm.dialogType,
              refresh: _vm.showNetUserDialog,
              currentId: _vm.netUserId,
            },
            on: { closePage: _vm.getNetUserInfo },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showCorrelationDialog,
            "close-on-click-modal": false,
            width: "600px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showCorrelationDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("关联客户类型")]
              ),
            ]
          ),
          _c("CorrelationDialog", {
            attrs: {
              opType: _vm.dialogType,
              refresh: _vm.showCorrelationDialog,
              currentId: _vm.netUserId,
              typeList: _vm.form.netUserTypeList,
            },
            on: { closePage: _vm.getNetUserInfo },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }