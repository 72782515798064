var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticStyle: { width: "100%" } }, [
      _vm._m(0),
      _vm._m(1),
      _c("tbody", [
        _c("tr", [
          _c("td", [_vm._v(_vm._s(_vm.couponActivityName))]),
          _c("td", [_vm._v(_vm._s(_vm.couponActivityRuleCode))]),
          _c("td", [_vm._v(_vm._s(_vm.couponActivityRuleName))]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col", { staticStyle: { width: "35%" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticStyle: { "background-color": "#e0efff" } }, [
      _c("tr", { staticStyle: { "font-size": "13px" } }, [
        _c("th", { attrs: { scope: "col" } }, [_vm._v(" 政策名称 ")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("规则编码")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("规则名称")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }