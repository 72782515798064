var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "560px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-form",
            {
              ref: "detail",
              staticStyle: { "margin-bottom": "-20px" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
                size: "mini",
              },
            },
            [
              _c("el-card", { attrs: { shadow: "never" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "100%", height: "30px" } },
                      [_vm._v("培训信息")]
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticClass: "userNameBox",
                                attrs: { label: "人员姓名：", prop: "userId" },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      placeholder: "请选择人员",
                                      disabled: _vm.opType == "edit",
                                    },
                                    on: { change: _vm.getUserId },
                                    model: {
                                      value: _vm.form.userId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "userId", $$v)
                                      },
                                      expression: "form.userId",
                                    },
                                  },
                                  _vm._l(_vm.options, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.userName,
                                        value: item.userId,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "培训日期：",
                                  prop: "trainTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择培训日期",
                                    type: "date",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                  },
                                  model: {
                                    value: _vm.form.trainTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "trainTime", $$v)
                                    },
                                    expression: "form.trainTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "培训时长：",
                                  prop: "trainDuration",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "85%" },
                                  attrs: {
                                    maxlength: "10",
                                    oninput:
                                      "value=value.replace(/[^0-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                    placeholder: "请输入培训时长",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.trainDuration,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "trainDuration", $$v)
                                    },
                                    expression: "form.trainDuration",
                                  },
                                }),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#c0c4cc" } },
                                  [_vm._v(" 小时")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 10 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "考试结果：",
                                  prop: "checkStatus",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.form.checkStatus,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "checkStatus", $$v)
                                      },
                                      expression: "form.checkStatus",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "PASS" } },
                                      [_vm._v("通过")]
                                    ),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "NOPASS" } },
                                      [_vm._v("不通过")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "培训内容：",
                                  prop: "trainCourse",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "400",
                                    "show-word-limit": "",
                                    type: "textarea",
                                    rows: 2,
                                    placeholder: "请输入内容",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.trainCourse,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "trainCourse",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "form.trainCourse",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticStyle: { "max-width": "650px", margin: "30px auto" },
              attrs: { shadow: "never" },
            },
            [
              _c(
                "el-form",
                {
                  staticStyle: { margin: "10px 0" },
                  attrs: {
                    model: _vm.form,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c("span", [_vm._v("培训附件")]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              attrs: {
                                limit: 3,
                                "before-remove": function () {
                                  return (_vm.formData = "")
                                },
                                action: "",
                                "show-file-list": false,
                                "http-request": _vm.uploadFile,
                                "file-list": _vm.fileList,
                                accept: "",
                                "list-type": "text",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    icon: "el-icon-upload2",
                                    size: "mini",
                                    plain: "",
                                  },
                                },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              attrs: {
                                size: "mini",
                                border: "",
                                data: _vm.fileList,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "文件名",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass: " itemclass",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handle(scope.row)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#1890ff",
                                                  cursor: "pointer",
                                                },
                                              },
                                              [_vm._v(_vm._s(scope.row.name))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "status",
                                  label: "状态",
                                  "min-width": "120",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "操作",
                                  align: "center",
                                  width: "110",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              type: "danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.ondelete(
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }