var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageContainer", [
        _c("div", { attrs: { slot: "main" }, slot: "main" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading",
                },
              ],
              staticClass: "app-m-cls",
              staticStyle: { "min-height": "calc(100vh - 130px)" },
            },
            [
              _c(
                "div",
                { ref: "header", staticClass: "header-cls" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "120px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-card",
                        { attrs: { shadow: "never" } },
                        [
                          _c(
                            "div",
                            { staticClass: "title" },
                            [
                              _c("FormLabel", {
                                attrs: { "form-label": "基础信息" },
                              }),
                              _c("div", { staticStyle: { height: "10px" } }),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 0 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "供应商服务配置",
                                        prop: "supplierServiceConfigId",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请选择供应商服务配置",
                                            clearable: "",
                                            disabled: _vm.opType != "add",
                                          },
                                          on: {
                                            change: _vm.SupplierConfigChange,
                                          },
                                          model: {
                                            value:
                                              _vm.form.supplierServiceConfigId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "supplierServiceConfigId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.supplierServiceConfigId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.SupplierConfigList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.serviceName,
                                                value:
                                                  item.supplierServiceConfigId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "供应商",
                                        prop: "supplierCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            disabled: _vm.opType != "add",
                                            filterable: "",
                                            placeholder: "请选择供应商",
                                            clearable: "",
                                          },
                                          on: { change: _vm.SupplierChange },
                                          model: {
                                            value: _vm.form.supplierCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "supplierCode",
                                                $$v
                                              )
                                            },
                                            expression: "form.supplierCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.SupplierList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.supplierName,
                                                value: item.supplierCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品分类",
                                        prop: "productClassifyCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            disabled: _vm.opType != "add",
                                            filterable: "",
                                            placeholder: "请选择产品分类",
                                            clearable: "",
                                          },
                                          on: {
                                            change: _vm.ProductClassifyChange,
                                          },
                                          model: {
                                            value: _vm.form.productClassifyCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "productClassifyCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.productClassifyCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.ProductClassifyList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.productClassifyName,
                                                value: item.productClassifyCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-card",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.form.configInfo &&
                                _vm.form.configInfo.items &&
                                _vm.form.configInfo.items.length > 0,
                              expression:
                                "form.configInfo && form.configInfo.items && form.configInfo.items.length > 0",
                            },
                          ],
                          staticStyle: { "margin-top": "15px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("FormLabel", {
                                staticStyle: {
                                  flex: "1",
                                  "line-height": "25px",
                                },
                                attrs: {
                                  "form-label":
                                    "" +
                                    ((_vm.form &&
                                      _vm.form.configInfo.categoryName) ||
                                      ""),
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.form.configInfo
                            ? _c(
                                "el-row",
                                { staticStyle: { "padding-bottom": "10px" } },
                                _vm._l(
                                  _vm.form.configInfo.items,
                                  function (item, index) {
                                    return _c(
                                      "el-col",
                                      { key: index, attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "line-height": "40px",
                                            },
                                            attrs: {
                                              "label-width": "200px",
                                              label: item.display,
                                              prop:
                                                "configInfo.items." +
                                                index +
                                                ".storeValue",
                                              rules: {
                                                required: item.required,
                                                message:
                                                  "请输入" + item.display,
                                                trigger: ["blur", "change"],
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "max-width": "320px",
                                                },
                                              },
                                              [
                                                _vm._.toLower(item.inputType) !=
                                                  "select" &&
                                                _vm._.toLower(item.inputType) !=
                                                  "multi_ select"
                                                  ? _c("el-input", {
                                                      attrs: {
                                                        clearable: "",
                                                        size: "mini",
                                                        disabled:
                                                          _vm.opType == "view",
                                                        type: _vm._.toLower(
                                                          item.inputType
                                                        ),
                                                      },
                                                      on: {
                                                        input: _vm.inputValue,
                                                      },
                                                      model: {
                                                        value: item.storeValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "storeValue",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.storeValue",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._.toLower(item.inputType) ==
                                                  "select" ||
                                                _vm._.toLower(item.inputType) ==
                                                  "multi_ select"
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          placeholder: "请选择",
                                                          multiple:
                                                            _vm._.toLower(
                                                              item.inputType
                                                            ) ==
                                                            "multi_ select",
                                                          size: "mini",
                                                          clearable: "",
                                                          disabled:
                                                            _vm.opType ==
                                                            "view",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.changeValue,
                                                        },
                                                        model: {
                                                          value:
                                                            item.storeValue,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "storeValue",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.storeValue",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.options,
                                                        function (
                                                          _item,
                                                          _index
                                                        ) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: _index,
                                                              attrs: {
                                                                label:
                                                                  _item.name,
                                                                value:
                                                                  _item.value,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "max-width": "320px",
                                                  "justify-content":
                                                    "space-between",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "#999",
                                                      "line-height": "20px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        "[" +
                                                          item.storeKey +
                                                          "]"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                item.description
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          color: "red",
                                                          "line-height": "20px",
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            "[" +
                                                              item.description +
                                                              "]"
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
          _c("div", { staticClass: "footer-btn" }, [
            _c(
              "div",
              { staticClass: "footer-btn-list" },
              [
                _vm.opType != "view"
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.submit },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.opType == "add" ? "保存" : "保存") +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.goBack } },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.opType == "view" ? "关闭" : "取消") + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }