var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.riskTypeDisabled,
          expression: "riskTypeDisabled",
        },
      ],
      staticClass: "mapMask",
      staticStyle: { background: "#18232b", color: "white" },
      attrs: {
        id: "map-container",
        "element-loading-text": "查询中...",
        "element-loading-background": "rgba(0, 0, 0, 0.7)",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            padding: "5px 15px",
            background: "#163456",
            height: "50px",
            "align-items": "center",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "450px",
                display: "flex",
                "align-items": "center",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "radio_group",
                  attrs: { fill: "#00579c" },
                  on: { change: _vm.handleRadio },
                  model: {
                    value: _vm.tabPosition,
                    callback: function ($$v) {
                      _vm.tabPosition = $$v
                    },
                    expression: "tabPosition",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "TODAY" } }, [
                    _vm._v("今天"),
                  ]),
                  _c(
                    "el-radio-button",
                    {
                      staticStyle: { margin: "0 2px" },
                      attrs: { label: "THIS_WEEK" },
                    },
                    [_vm._v("本周")]
                  ),
                  _c("el-radio-button", { attrs: { label: "THIS_MONTH" } }, [
                    _vm._v("本月"),
                  ]),
                  _c(
                    "el-radio-button",
                    {
                      staticStyle: { margin: "0 2px" },
                      attrs: { label: "THIS_YEAR" },
                    },
                    [_vm._v("今年")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                flex: "1",
                "text-align": "center",
                "font-size": "18px",
                "font-weight": "bold",
              },
            },
            [_vm._v("渠道交互看板")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "450px",
                display: "flex",
                "justify-content": "flex-end",
                "align-items": "center",
                "font-size": "14px",
              },
            },
            [
              _c(
                "span",
                { staticStyle: { "margin-right": "15px" } },
                [
                  _vm._v("当前位置： "),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-weight": "normal",
                        color: "#f7f7f7",
                        "margin-bottom": "2px",
                      },
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          return _vm.switchMap(_vm.defaultCity)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.defaultCity))]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentCity,
                          expression: "currentCity",
                        },
                      ],
                    },
                    [_vm._v(" | ")]
                  ),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-weight": "normal",
                        color: "#f7f7f7",
                        "margin-bottom": "2px",
                      },
                      attrs: { underline: false },
                      on: {
                        click: function ($event) {
                          return _vm.switchMap(_vm.currentCity)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.currentCity))]
                  ),
                ],
                1
              ),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "" + (!_vm.isFullscreen ? "全屏" : "退出全屏"),
                    effect: "dark",
                    placement: "bottom",
                  },
                },
                [
                  _c("svg-icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isFullscreen,
                        expression: "!isFullscreen",
                      },
                    ],
                    attrs: {
                      "icon-class": _vm.isFullscreen
                        ? "exit-fullscreen"
                        : "fullscreen",
                    },
                    on: { click: _vm.switchScreen },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          style: {
            "overflow-y": "auto",
            padding: "10px",
            height: _vm.containerH,
            "overflow-y": "auto",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        height: "270px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [_vm._v("交互走势图")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c("LineChannelChart", {
                              attrs: {
                                xAxis: _vm.xlineData,
                                series: _vm.seriesLineData,
                                height: "240px",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        "margin-top": "10px",
                        height: "270px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [_vm._v("失败走势图")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { padding: "10px" } },
                          [
                            _c("LineChannelChart", {
                              attrs: {
                                xAxis: _vm.xlineData,
                                series: _vm.seriesLineData1,
                                height: "240px",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { position: "relative" }, attrs: { span: 12 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "grid-content bg-purple-dark",
                      staticStyle: { color: "white," },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { padding: "5px" } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      opacity: "0.5",
                                    },
                                  },
                                  [_vm._v("总交互数")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-top": "5px",
                                      "font-size": "18px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.total || 0))]
                                ),
                              ]
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      opacity: "0.5",
                                    },
                                  },
                                  [_vm._v("失败几率")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "padding-top": "5px",
                                      "font-size": "18px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.errRate || "0"))]
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("ChinaMapChart", {
                        ref: "chinaMap",
                        attrs: {
                          isChannelBoard: "",
                          id: _vm.mapId,
                          "chart-data": _vm.mapData,
                          width: _vm.mapW,
                          height: _vm.mapH,
                        },
                        on: { selectedCity: _vm.selectedCity },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        height: "270px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("div", { staticClass: "chart-wrapper" }, [
                        _c(
                          "div",
                          {
                            staticClass: "header",
                            staticStyle: { "font-weight": "700" },
                          },
                          [_vm._v("方法排名情况")]
                        ),
                        !_vm._.isEmpty(_vm.methodsSort)
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "0 5px",
                                  "font-size": "12px",
                                  "overflow-y": "auto",
                                  "max-height": "220px",
                                },
                              },
                              _vm._l(_vm.methodsSort, function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        padding: "5px 0",
                                        "border-bottom": "1px dashed #294664",
                                        display: "flex",
                                        "justify-content": "space-between",
                                      },
                                    },
                                    [
                                      _vm._v(_vm._s(item.methodName)),
                                      _c("span", [_vm._v(_vm._s(item.total))]),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "5px",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v(" 暂无方法排名情况数据！ ")]
                            ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: {
                        border: "1px solid #173551",
                        background: "#163456",
                        "margin-top": "10px",
                        height: "270px",
                        color: "white",
                      },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "chart-wrapper" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "header",
                              staticStyle: { "font-weight": "700" },
                            },
                            [_vm._v("失败类型情况")]
                          ),
                          _c("PieChannelCharts", {
                            attrs: {
                              legendColor: "#fff",
                              "chart-data": _vm.errType,
                              height: "300px",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "box-card",
              staticStyle: {
                background: "#163456",
                border: "1px solid #173551",
                padding: "5px",
                "margin-top": "10px",
                "border-radius": "4px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "700",
                            padding: "5px",
                            "line-height": "40px",
                            color: "white",
                          },
                        },
                        [_vm._v("保司交互情况")]
                      ),
                      _c("BarChannelChart", {
                        attrs: {
                          xAxis: _vm.insCorpXlineData,
                          series: _vm.insCorpSeriesLineData,
                          height: "240px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "padding-left": "10px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-weight": "700",
                            padding: "5px",
                            "line-height": "40px",
                            color: "white",
                          },
                        },
                        [_vm._v("问题情况前十排名")]
                      ),
                      _c("BarChannelChart", {
                        attrs: {
                          xAxis: _vm.problemXlineData,
                          series: _vm.problemSeriesLineData,
                          height: "240px",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }