import "core-js/modules/es.array.concat.js";
/**
 * 用户管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 获取经销商--列表
   * @param {*} data 内容
   */
  getDealerList: function getDealerList(data) {
    return request.get(getUrl("/dealer/employee", 12), {
      params: data
    });
  },
  /**
    *  经销商--新增
    * @param {*} data 内容
    */
  // dealerAdd: function (data) {
  //     return request.post(getUrl(`/dealer/employee/create`, 12), data)
  // },
  /**
    *  经销商--新增
    * @param {*} data 内容
    */
  dealerAdd: function dealerAdd(data) {
    return request.post(getUrl("/dealer/employee/add", 12), data);
  },
  /**
  *  经销商--查看
  * @param {*} employeeId 用户id
  */
  dealerVetail: function dealerVetail(employeeId) {
    return request.get(getUrl("/dealer/employee/get/".concat(employeeId), 12));
  },
  /**
  *  经销商--查看
  * @param {*} employeeId 用户id
  */
  //   dealerVetail: function (account) {
  //     return request.get(getUrl(`/dealer/employee/account/${account}`, 12))
  // },

  /**
   *  经销商--删除
   * @param {*} employeeId 用户id
   */
  dealerDelete: function dealerDelete(employeeId) {
    return request.post(getUrl("/dealer/employee/delete/".concat(employeeId), 12));
  },
  /**
   *  经销商--重置密码
   * @param {*} employeeId 用户id
   */
  dealerResetPW: function dealerResetPW(employeeId) {
    return request.post(getUrl("/dealer/employee/reset/".concat(employeeId, "/password"), 12));
  },
  /**
  *  经销商--更新员工数据
  * @param {*} employeeId 用户id
  */
  //   dealerUpdate: function (employeeId,data) {
  //     return request.post(getUrl(`/dealer/employee/update/${employeeId}`, 12),data)
  // },
  /**
  *  经销商--更新员工数据
  * @param {*} employeeId 用户id
  */
  dealerUpdate: function dealerUpdate(employeeId, data) {
    return request.put(getUrl("/dealer/employee/modify/".concat(employeeId), 12), data);
  },
  /**
  * 获取主机厂--列表
  * @param {*} data 内容
  */
  getOemList: function getOemList(data) {
    return request.get(getUrl("/oem/employee", 13), {
      params: data
    });
  },
  /**
  * 获取主机厂下级经销商--列表
  * @param {*} orgCode 编码
  * @param {*} areaType 区域类型
  * @param {*} data 内容
  */
  getOemSubordinateList: function getOemSubordinateList(data) {
    return request.get(getUrl("/oem/employee/searchDealerEmployee", 13), {
      params: data
    });
  },
  /**
       * 主机厂--新增
       * @param {*} data 内容
       */
  oemAdd: function oemAdd(data) {
    return request.post(getUrl("/oem/employee/create", 13), data);
  },
  /**
  *  主机厂--查看
  * @param {*} employeeId 用户id
  */
  oemVetail: function oemVetail(employeeId) {
    return request.get(getUrl("/oem/employee/get/".concat(employeeId), 13));
  },
  /**
   *  主机厂--删除
   * @param {*} employeeId 用户id
   */
  oemDelete: function oemDelete(employeeId) {
    return request.post(getUrl("/oem/employee/delete/".concat(employeeId), 13));
  },
  /**
   *  主机厂--重置密码
   * @param {*} employeeId 用户id
   */
  oemResetPW: function oemResetPW(employeeId) {
    return request.post(getUrl("/oem/employee/reset/".concat(employeeId, "/password"), 13));
  },
  /**
  *  主机厂--更新员工数据
  * @param {*} employeeId 用户id
  */
  oemUpdate: function oemUpdate(employeeId, data) {
    return request.post(getUrl("/oem/employee/update/".concat(employeeId), 13), data);
  },
  /**
  * 获取经纪端--列表
  * @param {*} data 内容
  */
  getBrokerList: function getBrokerList(data) {
    return request.get(getUrl("/broker/employee", 4), {
      params: data
    });
  },
  /**
       * 经纪端--新增
       * @param {*} data 内容
       */
  brokerAdd: function brokerAdd(data) {
    return request.post(getUrl("/broker/employee/create", 4), data);
  },
  /**
  *  经纪端--查看
  * @param {*} employeeId 用户id
  */
  brokerVetail: function brokerVetail(employeeId) {
    return request.get(getUrl("/broker/employee/get/".concat(employeeId), 4));
  },
  /**
   *  经纪端--删除
   * @param {*} employeeId 用户id
   */
  brokerDelete: function brokerDelete(employeeId) {
    return request.post(getUrl("/broker/employee/delete/".concat(employeeId), 4));
  },
  /**
   *  经纪端--重置密码
   * @param {*} employeeId 用户id
   */
  brokerResetPW: function brokerResetPW(employeeId) {
    return request.post(getUrl("/broker/employee/reset/".concat(employeeId, "/password"), 4));
  },
  /**
  *  经纪端--更新员工数据
  * @param {*} employeeId 用户id
  */
  brokerUpdate: function brokerUpdate(employeeId, data) {
    return request.post(getUrl("/broker/employee/update/".concat(employeeId), 4), data);
  },
  /**
   * 列表
   * @param {*} data 数据
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示数量
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/employee?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 4), {
      params: data
    });
  },
  /**
   * 查询
   * @param {*} id 
   */
  query: function query(id) {
    return request.get(getUrl("/employee/".concat(id), 4));
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  add: function add(data) {
    return request.post(getUrl("/employee", 4), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(id, data) {
    return request.put(getUrl("/employee/".concat(id), 4), data);
  },
  /**
   * 删除
   * @param {*} id
   */
  delete: function _delete(id) {
    return request.delete(getUrl("/employee/".concat(id), 4));
  },
  /**
   * 重置
   * @param {*} id
   */
  resetPwd: function resetPwd(id) {
    return request.delete(getUrl("/employee/".concat(id, "/password"), 4));
  },
  /**
   * 查询当前机构下的人员
   * @param {*} orgCode
   */
  salesmanByOrgCode: function salesmanByOrgCode(orgCode) {
    return request.get(getUrl("/employee/".concat(orgCode, "/org"), 4));
  },
  /**
   * 修改密码
   * @param {*} userId
   */
  modifyPwd: function modifyPwd(userId, data) {
    return request.put(getUrl("/users/".concat(userId, "/password"), 5), data);
  }
};
export default objApi;