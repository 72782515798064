import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from '@/components/FormSubLabel';
import InsuranceDetail from '@/components/InsuranceDetail';
import ActivityPolicy from '@/components/ActivityPolicy';
import PageContainer from '@/components/PageContainer';
import request from '../../api/insurancepolicy';
import dict from '@/mixins/dict';
import ViewImgForm from '@/components/ViewImgForm';
import storageRequest from '@/api/storage';
import customersRequest from '@/api/customers';
import { downFile, getBeforeOfDate } from '@/utils/common';
export default {
  name: 'InsurancePolicyDetail',
  components: {
    FormLabel: FormLabel,
    InsuranceDetail: InsuranceDetail,
    PageContainer: PageContainer,
    ViewImgForm: ViewImgForm,
    ActivityPolicy: ActivityPolicy
  },
  mixins: [dict],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    }
  },
  watch: {
    sidebar: {
      handler: function handler(newValue, oldValue) {
        this.sidebarOpened = newValue.opened;
        this.calcWidth();
      },
      deep: true
    },
    '$route': function $route(to, from) {
      //监听路由是否变化）
      this.initView();
    }
  },
  data: function data() {
    return {
      vehicleTypeLength: '',
      rowWidth: 0,
      brandLength: 0,
      sidebarOpened: true,
      formWidth: 0,
      policyId: '',
      policyNo: '',
      form: {
        policyVehicle: {
          salesDate: ''
        }
      },
      policyHolder: {},
      recognizee: {},
      dutyList: [],
      vehiclePrice: '',
      fullscreenLoading: true,
      pdfObjId: null,
      VEHICLE_KIND: [],
      USE_ATTRIBUTE: [],
      USE_ATTRIBUTE_BRANCH: [],
      PLATE_TYPE: [],
      VI_CERTIFICATE_TYPE: [],
      backUrl: '',
      correctionList: [],
      clientType: '',
      imgList: [],
      showImgDialog: false,
      refreshing: false
    };
  },
  created: function created() {
    this.initView();
  },
  mounted: function mounted() {
    this.sidebarOpened = this.sidebar.opened;
    this.calcWidth();
    this.VEHICLE_KIND = JSON.parse(localStorage.getItem('VI_VEHICLE_KIND')) || [];
    this.USE_ATTRIBUTE = JSON.parse(localStorage.getItem('VI_USE_ATTRIBUTE')) || [];
    this.USE_ATTRIBUTE_BRANCH = JSON.parse(localStorage.getItem('VI_USE_ATTRIBUT_DETAIL')) || [];
    this.PLATE_TYPE = JSON.parse(localStorage.getItem('VI_PLATE_TYPE')) || [];
    this.VI_CERTIFICATE_TYPE = JSON.parse(localStorage.getItem('VI_CERTIFICATE_TYPE')) || [];
  },
  methods: {
    initView: function initView() {
      this.policyId = this.$route.query.policyId;
      this.policyNo = this.$route.query.policyNo;
      this.clientType = this.$route.query.clientType;
      this.backUrl = this.$route.query.back;
      this.viewDetail(this.policyId, this.policyNo);
      sessionStorage.removeItem("policyPath");
    },
    getTaxTypeName: function getTaxTypeName(type) {
      //获取纳税类型中文名称
      if (!type) return '';
      var dictBj = _.find(this.dictSource['TAX_TYPE_BJ'], function (item) {
        return item.dictCode == type;
      });
      if (dictBj) {
        return dictBj.dictName || '';
      } else {
        var _dict = _.find(this.dictSource['TAX_TYPE'], function (item) {
          return item.dictCode == type;
        });
        return _dict.dictName || '';
      }
    },
    cancel: function cancel() {
      //关闭附件弹窗
      this.showImgDialog = false;
    },
    viewImg: function viewImg() {
      //显示附件弹窗
      this.showImgDialog = true;
    },
    viewCorrection: function viewCorrection() {
      //查看批改单列表
      if (!_.isEmpty(this.correctionList) && this.correctionList.length == 1) {
        this.viewCorrectionItem(this.correctionList[0]);
      }
    },
    viewCorrectionItem: function viewCorrectionItem(item) {
      //查看批改单
      var router_path = this.$route.path;
      sessionStorage.setItem("policyPath", this.$route.fullPath);
      this.$router.push({
        path: '/correction/detail',
        query: {
          endorsementId: item.endorsementId,
          back: router_path
        }
      });
    },
    getEffectiveStartTime: function getEffectiveStartTime(time) {
      //起保日期即时起保
      if (!time) return "-";
      if (time.indexOf("00:00") != -1 || time.indexOf("00:00:00") != -1) {
        return time.slice(0, 13) + "时";
      }
      return time.slice(0, 13) + "时" + time.slice(14, 16) + '分';
    },
    getEffectiveEndTime: function getEffectiveEndTime(time) {
      //终保日期默认为前一天的24时
      if (!time) return "";
      if (time.indexOf("00:00") != -1 || time.indexOf("00:00:00") != -1) {
        var timeStr = time.substring(0, 10);
        var hour = time.substring(11, 13);
        var min = time.substring(14, 16);
        var date = getBeforeOfDate(timeStr, 1);
        if (hour != '00' || min != '00') {
          date = getBeforeOfDate(timeStr, 0);
        }
        var endTime = hour != '00' ? date + ' ' + hour + '时' : date + " 24时";
        return endTime;
      }
      return time.slice(0, 13) + "时" + time.slice(14, 16) + '分';
    },
    getCertificateTypeName: function getCertificateTypeName(code) {
      //获取证件类型中文名称
      if (!code) return '-';
      var cType = _.find(this.VI_CERTIFICATE_TYPE, function (item) {
        return item.dictCode == code;
      });
      return cType ? cType.dictName : '-';
    },
    getUseAttributeName: function getUseAttributeName(code) {
      //获取使用性质中文名称
      if (!code) return '-';
      var uType = _.find(this.USE_ATTRIBUTE, function (item) {
        return item.dictCode == code;
      });
      return uType ? uType.dictName : '-';
    },
    getUseAttributDetailCodeName: function getUseAttributDetailCodeName(code) {
      //获取使用性质细分中文名称
      if (!code) return '-';
      var uType = _.find(this.USE_ATTRIBUTE_BRANCH, function (item) {
        return item.dictCode == code;
      });
      return uType ? uType.dictName : '无';
    },
    getVehicleTypeName: function getVehicleTypeName(code) {
      //获取行驶证车辆类型中文名称
      if (!code) return '-';
      var uType = _.find(this.VEHICLE_KIND, function (item) {
        return item.dictCode == code;
      });
      return uType ? uType.dictName : '-';
    },
    getPlateTypeName: function getPlateTypeName(code) {
      //获取号牌种类中文名称
      if (!code) return '-';
      var uType = _.find(this.PLATE_TYPE, function (item) {
        return item.dictCode == code;
      });
      return uType ? uType.dictName : '-';
    },
    view: function view() {
      var _this = this;
      //查看保单
      this.form.viewing = true;
      this.$forceUpdate();
      request.policyCodeGetPDFInfo(this.policyNo).then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          _this.form.viewing = false;
          var repData = res.data;
          if (_.isEmpty(repData)) {
            _this.$message({
              type: "warning",
              message: "未获取到电子保单，请稍候再试！"
            });
            _this.$forceUpdate();
            return;
          }
          ;
          repData.forEach(function (pItem) {
            window.open(pItem.fileUrl, "_blank");
          });
        }
      }).catch(function (error) {
        _this.form.viewing = false;
        _this.$forceUpdate();
        console.log(error);
      });
    },
    download: function download() {
      var _this2 = this;
      //下载保单文件
      this.form.downLoading = true;
      this.$forceUpdate();
      request.policyCodeGetPDFInfo(this.policyNo).then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          _this2.form.downLoading = false;
          var repData = res.data;
          if (_.isEmpty(repData)) {
            _this2.$message({
              type: "warning",
              message: "未获取到电子保单，请稍候再试！"
            });
            _this2.$forceUpdate();
            return;
          }
          ;
          repData.forEach(function (pItem) {
            downFile(pItem.fileUrl, pItem.pdfFileName);
          });
        }
      }).catch(function (error) {
        _this2.form.downLoading = false;
        _this2.$forceUpdate();
        console.log(error);
      });
    },
    viewInsPolicy: function viewInsPolicy() {
      //切换保单
      this.$router.replace({
        path: "/policy/detail",
        query: {
          policyId: this.form.relationPolicyId
        }
      });
      this.policyId = this.form.relationPolicyId;
      this.policyNo = this.form.policyNo;
      this.imgList = [];
      this.viewDetail(this.form.relationPolicyId, this.policyNo);
    },
    queryPolicyDocument: function queryPolicyDocument(id) {
      var _this3 = this;
      //获取保单的附件
      request.queryPolicyDocument(id).then(function (res) {
        if (res.code == RESPONSE_SUCCESS) {
          _this3.imgList = res.data;
        }
      });
    },
    viewDetail: function viewDetail(id, policyNo) {
      var _this4 = this;
      //获取保单详情
      this.fullscreenLoading = true;
      this.queryPolicyDocument(id);
      request.getDetailByCode(policyNo).then(function (res) {
        _this4.fullscreenLoading = false;
        if (res.data && res.code == RESPONSE_SUCCESS) {
          _this4.form = res.data;
          _this4.form.insuredType = _this4.form.riskType == '1' ? '0' : _this4.form.riskType == '0' ? '1' : '2', _this4.form.policyDocumentsBs = _this4.form.insuredType == '0' ? _this4.form.policyDocuments : [], _this4.form.policyDocumentsBz = _this4.form.insuredType == '1' ? _this4.form.policyDocuments : [], _this4.form.policyDocumentsAccident = _this4.form.insuredType == '2' ? _this4.form.policyDocuments : [],
          // this.getCorrectionList(this.form.policyNo);
          _this4.dutyList = [];
          _this4.form.policyDuties.forEach(function (itemz) {
            _this4.dutyList.push(itemz);
          });
          var item = _this4.form.policyVehicle;
          if (!item || !item.modelName) return "";
          //车型展示规则
          var modelCode = item.makerModelCode ? item.makerModelCode : item.industryModelCode;
          var remark = item.remark ? item.remark : item.vehicleAlias;

          // let name = _.join([modelCode, item.modelName, item.brandName, remark, `${item.seats}座`, item.purchasePrice, item.yearPattern], "/");
          var name = modelCode ? _.join([modelCode, item.modelName], '/') : item.modelName;
          name = item.brandName ? _.join([name, item.brandName], '/') : name;
          name = remark ? _.join([name, remark], '/') : name;
          name = item.seats ? _.join([name, "".concat(item.seats, "\u5EA7")], '/') : name;
          name = item.purchasePrice ? _.join([name, item.purchasePrice], '/') : name;
          name = item.yearPattern ? _.join([name, item.yearPattern], '/') : name;
          _this4.vehiclePrice = name;
          _this4.showDialog = true;
        }
      }).catch(function (err) {
        _this4.fullscreenLoading = false;
      });
    },
    calcWidth: function calcWidth() {
      //动态设置表单的宽度
      var width = document.body.clientWidth;
      width = width - (this.sidebarOpened ? 210 : 54) - 30 - 20;
      var w = parseInt(width / 3);
      this.formWidth = width + 50;
      this.vehicleTypeLength = w + w + 110 + 320;
      this.brandLength = w + 110 + 320;
    },
    goBack: function goBack() {
      //返回到上一页
      this.$store.dispatch('tagsView/delView', this.$route);
      var correctionPath = sessionStorage.getItem('correctionPath');
      if (correctionPath) {
        this.$router.push("".concat(correctionPath));
        sessionStorage.removeItem('correctionPath');
      } else {
        this.$router.go(-1);
      }
    },
    // 下载电子保单
    handle: function handle(row) {
      if (row.fileStorageId) {
        downFile(row.fileUrl, row.fileName);
      }
    },
    getVehicleSalesData: function getVehicleSalesData() {
      var _this5 = this;
      if (!this.form.policyVehicle.vin) {
        this.$message.warning('车架号不能为空');
        return;
      }
      ;
      this.refreshing = true;

      //根据来源判断，保单录入和保单导入为 true， 在线出单给false或者null。
      var _whetherHistoryData = false;
      if (_.toLower(this.form.source) == 'add' || _.toLower(this.form.source) == 'import') {
        _whetherHistoryData = true;
      }
      var vehicleData = {
        vin: this.form.policyVehicle.vin || '',
        cacheSwitch: true,
        whetherHistoryData: _whetherHistoryData //true为查历史车销，不查TACT; false或者为null 不查历史车销，查TACT车销
      };

      customersRequest.getVehicleSales(vehicleData).then(function (res) {
        _this5.refreshing = false;
        if (res.code == 200) {
          _this5.$message.success(res.msg);
          _this5.form.policyVehicle.salesDate = res.data ? res.data.salesDate : '';
          _this5.form.policyVehicle.cjVhcBrandNo = res.data ? res.data.cjVhcBrandNo : '';
          _this5.form.policyVehicle.cjVhcBrandName = res.data ? res.data.cjVhcBrandName : '';
          _this5.form.policyVehicle.cjVhcSeriesNo = res.data ? res.data.cjVhcSeriesNo : '';
          _this5.form.policyVehicle.cjVhcSeriesName = res.data ? res.data.cjVhcSeriesName : '';
          _this5.form.policyVehicle.cjVhcModelNo = res.data ? res.data.cjVhcModelNo : '';
          // this.form.policyVehicle.cjVhcModelName = res.data ? res.data.cjVhcModelName : '';
          var params = {
            policyId: _this5.form.policyId,
            salesDate: _this5.form.policyVehicle.salesDate,
            vhcBrandNo: _this5.form.policyVehicle.cjVhcBrandNo,
            vhcSeriesNo: _this5.form.policyVehicle.cjVhcSeriesNo,
            vhcModelNo: _this5.form.policyVehicle.cjVhcModelNo
          };
          if (_this5.form.policyVehicle.salesDate) {
            request.updateSalesDate(params).then(function (res) {});
          }
          ;
        }
        ;
      }).catch(function (err) {
        _this5.refreshing = false;
      });
    }
  }
};