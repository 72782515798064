var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls call-record" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "所属机构" } },
                              [
                                _c("OemSelect", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    searchForm: _vm.orgForm,
                                    canClickArea: true,
                                  },
                                  on: { nodeClick: _vm.handleNodeClick },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户名称" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入客户名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.customerName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "customerName",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.customerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "通话号码" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入通话号码",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.customerMobile,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "customerMobile",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.customerMobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "通话时间" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "start-placeholder": "起期",
                                    "end-placeholder": "止期",
                                    type: "daterange",
                                    format: "yyyy-MM-dd",
                                    "value-format": "yyyy-MM-dd",
                                    "unlink-panels": "",
                                    "range-separator": "——",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.dateChange(
                                        "callStartDate",
                                        "callEndDate",
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.startEndTime,
                                    callback: function ($$v) {
                                      _vm.startEndTime = $$v
                                    },
                                    expression: "startEndTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "坐席人员" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "请输入坐席人员",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.userName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "userName", $$v)
                                    },
                                    expression: "searchForm.userName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "呼叫状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "请选择呼叫状态",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.searchForm.callStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.searchForm,
                                          "callStatus",
                                          $$v
                                        )
                                      },
                                      expression: "searchForm.callStatus",
                                    },
                                  },
                                  _vm._l(
                                    _vm.callStateList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("div", { staticClass: "search-box" }, [
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("所属机构：")]),
                        _c("OemSelect", {
                          attrs: {
                            searchForm: _vm.orgForm,
                            canClickArea: true,
                          },
                          on: { nodeClick: _vm.handleNodeClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("客户名称：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入客户名称",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.customerName,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "customerName", $$v)
                            },
                            expression: "searchForm.customerName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("通话号码：")]),
                        _c("el-input", {
                          attrs: {
                            maxlength: "40",
                            placeholder: "请输入通话号码",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.customerMobile,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "customerMobile", $$v)
                            },
                            expression: "searchForm.customerMobile",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("span", [_vm._v("通话时间：")]),
                        _c("el-date-picker", {
                          staticStyle: { width: "220px" },
                          attrs: {
                            "start-placeholder": "起期",
                            "end-placeholder": "止期",
                            type: "daterange",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "unlink-panels": "",
                            "range-separator": "——",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.dateChange(
                                "callStartDate",
                                "callEndDate",
                                $event
                              )
                            },
                          },
                          model: {
                            value: _vm.startEndTime,
                            callback: function ($$v) {
                              _vm.startEndTime = $$v
                            },
                            expression: "startEndTime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-refresh-left",
                            },
                            on: { click: _vm.resetSearch },
                          },
                          [_vm._v("重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "primary",
                              icon: "el-icon-search",
                            },
                            on: { click: _vm.searchData },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "search-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.breakData },
                          },
                          [_vm._v("刷新")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              disabled: _vm._.isEmpty(_vm.multipleSelection),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.batchDelete()
                              },
                            },
                          },
                          [_vm._v("批量删除")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: { click: _vm.exportExcels },
                          },
                          [_vm._v("导出")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function ($event) {
                                _vm.drawer = true
                              },
                            },
                          },
                          [_vm._v("搜索")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightJD,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                        fixed: "left",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "talkDate",
                        label: "通话时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "customerName",
                        label: "客户名称",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "customerMobile",
                        label: "通话号码",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "所属机构",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "userName",
                        label: "坐席人员",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "callDuration",
                        label: "通话时长",
                        "min-width": "100",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "通话录音",
                        "min-width": "330",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.filePath
                                ? _c("audio", {
                                    staticStyle: { height: "22px" },
                                    attrs: {
                                      controls: "",
                                      src: scope.row.filePath,
                                      id: "audio" + scope.$index,
                                    },
                                    on: {
                                      play: function (val) {
                                        return _vm.playChange(val, scope.$index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "callStatusDisplay",
                        label: "通话状态",
                        "min-width": "80",
                        "show-overflow-tooltip": "",
                      },
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: function ($event) {
                      return _vm.loadData()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showScoreDialog,
            "close-on-click-modal": false,
            width: "450px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showScoreDialog = $event
            },
            handleClose: function ($event) {
              _vm.showScoreDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("评价")]
              ),
            ]
          ),
          _c("ScoreDialog", {
            attrs: {
              currentScore: _vm.currentScore,
              refresh: _vm.showScoreDialog,
              currentData: _vm.currentData,
            },
            on: { closePage: _vm.loadData },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showRemarkDialog,
            "close-on-click-modal": false,
            width: "500px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showRemarkDialog = $event
            },
            handleClose: function ($event) {
              _vm.showRemarkDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("备注")]
              ),
            ]
          ),
          _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
            _c(
              "div",
              {
                ref: "header",
                staticClass: "header-cls",
                staticStyle: { "max-height": "500px", overflow: "auto" },
              },
              [
                _c(
                  "el-card",
                  { attrs: { shadow: "never" } },
                  [
                    _c("el-input", {
                      attrs: {
                        maxlength: "40",
                        type: "textarea",
                        rows: 3,
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.currenRemark,
                        callback: function ($$v) {
                          _vm.currenRemark = $$v
                        },
                        expression: "currenRemark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "center",
                  "margin-top": "8px",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-check",
                      type: "primary",
                      plain: "",
                      size: "mini",
                    },
                    on: { click: _vm.loadData },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { icon: "el-icon-close", size: "mini" },
                    on: {
                      click: function ($event) {
                        _vm.showRemarkDialog = false
                      },
                    },
                  },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showVehicleDialog,
            "close-on-click-modal": false,
            width: "720px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showVehicleDialog = $event
            },
            handleClose: function ($event) {
              _vm.showVehicleDialog = false
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("查看车辆")]
              ),
            ]
          ),
          _c("VehicleDialog", {
            on: {
              closePage: function ($event) {
                _vm.showVehicleDialog = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }