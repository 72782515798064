var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.importLoading,
            expression: "importLoading",
          },
        ],
        staticClass: "app-m-cls",
        staticStyle: {},
      },
      [
        _c(
          "div",
          {
            ref: "header",
            staticClass: "header-cls",
            staticStyle: { "max-height": "500px", overflow: "auto" },
          },
          [
            _c("el-card", { attrs: { shadow: "never" } }, [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "detail",
                      attrs: {
                        rules: _vm.formRules,
                        model: _vm.uploadForm,
                        "label-width": "110px",
                        size: "mini",
                      },
                    },
                    [
                      _vm.needUser
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "业务机构：",
                                prop: "ownerOrgName",
                              },
                            },
                            [
                              _vm.bizType === "BROKER"
                                ? _c(
                                    "el-select",
                                    {
                                      ref: "orgSelect",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: "",
                                        disabled: !_vm.needUser,
                                        filterable: "",
                                        "filter-method": _vm.dataFilter,
                                        placeholder: "请选择业务机构",
                                      },
                                      on: {
                                        change: _vm.orgChange,
                                        focus: _vm.orgFocus,
                                        "visible-change": _vm.visibleChange,
                                      },
                                      model: {
                                        value: _vm.uploadForm.ownerOrgName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.uploadForm,
                                            "ownerOrgName",
                                            $$v
                                          )
                                        },
                                        expression: "uploadForm.ownerOrgName",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-option",
                                        {
                                          staticStyle: {
                                            height: "auto",
                                            "background-color": "white",
                                          },
                                          attrs: {
                                            value: _vm.uploadForm.ownerOrgCode,
                                          },
                                        },
                                        [
                                          _c("el-tree", {
                                            ref: "tree",
                                            staticClass: "filter-tree",
                                            attrs: {
                                              "node-key": "organizationId",
                                              "highlight-current": true,
                                              data: _vm.orgData,
                                              props: _vm.defaultProps,
                                              "filter-node-method":
                                                _vm.filterNode,
                                            },
                                            on: {
                                              "node-click": _vm.handleNodeClick,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.bizType === "OEM"
                                ? _c("OemSelect", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      disabled: !_vm.needUser,
                                      searchForm: _vm.orgForm,
                                      isGetUserList: true,
                                      isContainOem: false,
                                    },
                                    on: {
                                      nodeClick: _vm.handleNodeClick,
                                      getCurrentOrgUsers: _vm.getOemUsers,
                                    },
                                  })
                                : _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.uploadForm.ownerOrgName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.uploadForm,
                                          "ownerOrgName",
                                          $$v
                                        )
                                      },
                                      expression: "uploadForm.ownerOrgName",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.needUser
                        ? _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "客户经理：",
                                        prop: "userName",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            clearable: "",
                                            placeholder: "请选择客户经理",
                                          },
                                          on: { change: _vm.salemanNameChange },
                                          model: {
                                            value: _vm.uploadForm.userName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.uploadForm,
                                                "userName",
                                                $$v
                                              )
                                            },
                                            expression: "uploadForm.userName",
                                          },
                                        },
                                        _vm._l(
                                          _vm.users,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.userName,
                                                value: item.userId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#ccc",
                                      "line-height": "28px",
                                      "margin-left": "5px",
                                    },
                                  },
                                  [_vm._v(" 不选择客户经理，则默认不分配客户 ")]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "must-star",
                          attrs: { label: "客户列表：" },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              attrs: {
                                "before-remove": function () {
                                  return (_vm.uploadForm.fileId = "")
                                },
                                limit: 1,
                                action: "",
                                "http-request": _vm.uploadFile,
                                "file-list": _vm.fileList,
                                accept: ".xlsx",
                                "list-type": "text",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "mini", plain: "" } },
                                [_vm._v("选择文件")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "margin-top": "8px",
            },
          },
          [
            _c(
              "el-button",
              {
                attrs: {
                  icon: "el-icon-download",
                  type: "primary",
                  plain: "",
                  size: "mini",
                },
                on: { click: _vm.download },
              },
              [_vm._v("下载模板")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  icon: "el-icon-upload2",
                  size: "mini",
                  type: "primary",
                  disabled: !_vm.uploadForm.fileId,
                },
                on: { click: _vm.saveUpload },
              },
              [_vm._v("导入")]
            ),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-close", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.closePage(true)
                  },
                },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }