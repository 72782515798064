var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "600px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { staticStyle: { height: "100%" }, attrs: { shadow: "never" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: { padding: "10px", "box-sizing": "border-box" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "detail",
                          staticStyle: { "margin-bottom": "-20px" },
                          attrs: {
                            model: _vm.form,
                            rules: _vm.rules,
                            "label-width": "90px",
                            size: "mini",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "险种类型：" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            filterable: "",
                                            placeholder: "请选择险种类型",
                                          },
                                          model: {
                                            value: _vm.form.riskType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "riskType",
                                                $$v
                                              )
                                            },
                                            expression: "form.riskType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.InsuranceList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "地区：" } },
                                    [
                                      _c("el-cascader", {
                                        ref: "cascaderAddr",
                                        staticStyle: { "margin-right": "8px" },
                                        attrs: { options: _vm.cityOptions },
                                        on: { change: _vm.handleChange },
                                        model: {
                                          value: _vm.cascaderAddr,
                                          callback: function ($$v) {
                                            _vm.cascaderAddr = $$v
                                          },
                                          expression: "cascaderAddr",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "保险公司：",
                                        prop: "insCorpCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "请选择保险公司",
                                          },
                                          on: { change: _vm.getInsCorpName },
                                          model: {
                                            value: _vm.form.insCorpCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "insCorpCode",
                                                $$v
                                              )
                                            },
                                            expression: "form.insCorpCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.supplyers,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.shortName,
                                                value: item.code,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "解析内容：" } },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "upload",
                                      attrs: {
                                        "before-remove": _vm.removeFile,
                                        limit: 1,
                                        action: "",
                                        "http-request": _vm.uploadFile,
                                        "file-list": _vm.fileList,
                                        accept: ".pdf",
                                        "list-type": "text",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { size: "mini", plain: "" } },
                                        [_vm._v("选择文件")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("el-input", {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: {
                                      disabled: "",
                                      clearable: "",
                                      type: "textarea",
                                      rows: 12,
                                    },
                                    model: {
                                      value: _vm.PDFContent,
                                      callback: function ($$v) {
                                        _vm.PDFContent = $$v
                                      },
                                      expression: "PDFContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        plain: "",
                                        type: "primary",
                                      },
                                      on: { click: _vm.analysisJson },
                                    },
                                    [_vm._v("解析")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "匹配结果：" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder: "请输入模板内容",
                                      clearable: "",
                                      type: "textarea",
                                      rows: 12,
                                    },
                                    model: {
                                      value: _vm.templateContent,
                                      callback: function ($$v) {
                                        _vm.templateContent = $$v
                                      },
                                      expression: "templateContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }