//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
export default {
  mixins: [initHeight],
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      }
    };
  },
  methods: {
    loadData: function loadData() {}
  }
};