var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            size: "mini",
            data: _vm.vouchersTableData,
            height: _vm.tableHeightY,
            "header-cell-style": { background: "#F7F7F7" },
            "element-loading-text": "加载中...",
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "cardVoucherName",
              label: "赠品名称",
              "min-width": "120",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "issueNumber",
              label: "数量",
              "min-width": "80",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "value",
              label: "总价值",
              "min-width": "100",
              align: "right",
              "show-overflow-tooltip": "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.value) + " 元 ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "equities",
              label: "权益说明",
              "min-width": "150",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "givingDate",
              label: "赠送时间",
              width: "150",
              "show-overflow-tooltip": "",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }