import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import userCenterApi from '@/api/userCenter';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        netChannelCode: '',
        netChannelName: '',
        enabled: true,
        isManualRegister: false
      },
      rules: {
        netChannelCode: {
          required: true,
          message: '请输入渠道编码',
          trigger: "blur"
        },
        netChannelName: {
          required: true,
          message: '请输入渠道名称',
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        netChannelCode: '',
        netChannelName: '',
        enabled: true,
        isManualRegister: false
      };
      if (this.opType === "edit") {
        this.formLoading = true;
        userCenterApi.getNetChannelInfo(this.currentId).then(function (res) {
          _this.formLoading = false;
          if (res.code === 200) {
            _this.form = res.data;
          }
          ;
        }).catch(function (err) {
          _this.formLoading = false;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 监听输入事件
    inputNetChannelCode: function inputNetChannelCode(val) {
      this.form.netChannelCode = val.replace(/[^A-Za-z0-9_-]/g, '');
    },
    // 保存数据
    save: function save() {
      var _this2 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this2.formLoading = true;
          var req = _this2.opType === 'add' ? userCenterApi.createNetChannel(_this2.form) : userCenterApi.updateNetChannel(_this2.currentId, _this2.form);
          req.then(function (res) {
            _this2.formLoading = false;
            if (res.code === 200) {
              _this2.$message.success(res.msg);
              _this2.closePage(false);
            }
            ;
          }).catch(function (err) {
            _this2.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$emit('closePage', boolean);
    }
  }
};