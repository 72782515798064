import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import insuranceContractManageRequest from '@/api/insuranceContractManage';
import drivingAccidentInsuranceManageRequest from '@/api/drivingAccidentInsuranceManage.js';
import { getSupplyers } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    DialogType: {
      type: String,
      default: {}
    },
    riskProductId: {
      type: String,
      default: ''
    },
    riskTypeList: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      accidentList: [],
      addForm: {
        accidentId: '',
        insCorpCode: '',
        risProductCode: '',
        risProductName: '',
        group: false,
        riskAccidentPackageSaveParams: [{
          formInsurance: true,
          //以报价结果为准
          insuranceCode: "",
          //套餐编码
          insuranceName: "",
          //套餐名称
          minSeat: '',
          //最小座位数
          maxSeat: '',
          //最小座位数
          maxNumber: '',
          //最大购买份数
          minInsureAge: '',
          //最小投保年龄
          maxInsureAge: '',
          //最大投保年龄
          whetherCalculate: false,
          //是否加入乘客计算
          insuredAmount: '',
          //保额
          premium: '',
          //保费
          riskList: [{
            riskCode: "",
            //代码
            riskName: "",
            //险种
            riskType: "",
            //类别
            riskAmount: "" //保额
          }]
        }]
      },

      // riskAccidentPackageSaveParams: [{
      //     insuranceCode: "", //套餐编码
      //     insuranceName: "", //套餐名称
      //     minSeat: '', //最小座位数
      //     maxSeat: '', //最小座位数
      //     maxNumber: '', //最大购买份数
      //     minInsureAge: '', //最小投保年龄
      //     maxInsureAge: '', //最大投保年龄
      //     whetherCalculate: false, //是否加入乘客计算
      //     insuredAmount: '', //保额
      //     premium: '', //保费
      //     riskList: [{
      //         riskCode: "", //代码
      //         riskName: "", //险种
      //         riskType: "", //类别
      //         riskAmount: "" //保额
      //     }],
      // }],
      formLoading: false,
      formRules: {
        insCorpCode: [{
          required: true,
          message: '请选择保险公司',
          trigger: 'change'
        }],
        accidentId: [{
          required: true,
          message: '请选择险种类别',
          trigger: 'change'
        }],
        risProductCode: [{
          required: true,
          message: '请输入产品编码',
          trigger: 'blur'
        }],
        risProductName: [{
          required: true,
          message: '请输入产品名称',
          trigger: 'blur'
        }]
      },
      routesData: [],
      routesList: [],
      supplyers: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        //保司
        this.supplyers = getSupplyers();
        if (this.DialogType == 'edit' || this.DialogType == 'view') {
          this.initData(); //初始化数据
        }
        // else {
        //     this.$refs.addForm.resetFields();
        // }
      }
      ;
    }
  },
  mounted: function mounted() {
    //保司
    this.supplyers = getSupplyers();
    if (this.DialogType == 'edit' || this.DialogType == 'view') {
      this.initData(); //初始化数据
    }
  },

  methods: {
    formInsuranceChange: function formInsuranceChange(val, index) {
      if (val) {
        this.addForm.riskAccidentPackageSaveParams[index].premium = null;
      }
      this.$forceUpdate();
    },
    tableRowClassName: function tableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      row.index = rowIndex;
    },
    onRowClick: function onRowClick(row, event, column) {
      var index = row.index;
    },
    copyList: function copyList(index) {
      console.log(index);
      // console.log(this.riskAccidentPackageSaveParams[index])
      var obj = JSON.parse(JSON.stringify(this.addForm.riskAccidentPackageSaveParams[index]));
      this.addForm.riskAccidentPackageSaveParams.push(obj);
    },
    addList: function addList() {
      this.addForm.riskAccidentPackageSaveParams.push({
        formInsurance: true,
        //以报价结果为准
        insuranceCode: "",
        //套餐编码
        insuranceName: "",
        //套餐名称
        minSeat: '',
        //最小座位数
        maxSeat: '',
        //最小座位数
        maxNumber: '',
        //最大购买份数
        minInsureAge: '',
        //最小投保年龄
        maxInsureAge: '',
        //最大投保年龄
        whetherCalculate: false,
        //是否加入乘客计算
        insuredAmount: '',
        //保额
        premium: '',
        //保费
        riskList: [{
          riskCode: "",
          //代码
          riskName: "",
          //险种
          riskType: "",
          //类别
          riskAmount: "" //保额
        }]
      });
    },
    addriskList: function addriskList(index) {
      if (this.DialogType != 'view') {
        this.addForm.riskAccidentPackageSaveParams[index].riskList.push({
          riskCode: "",
          //代码
          riskName: "",
          //险种
          riskType: "",
          //类别
          riskAmount: "" //保额
        });
      }
    },
    delList: function delList(index) {
      if (index != 0) {
        this.addForm.riskAccidentPackageSaveParams.splice(index, 1);
      }
    },
    delriskList: function delriskList(val, index) {
      if (this.DialogType != 'view') {
        if (this.addForm.riskAccidentPackageSaveParams[index].riskList.length != 1) {
          this.addForm.riskAccidentPackageSaveParams[index].riskList.splice(val.index, 1);
        }
      }
    },
    getAccidentType: function getAccidentType(val) {
      var _this = this;
      drivingAccidentInsuranceManageRequest.getAccidentTypeList({
        insCorpCode: val
      }).then(function (res) {
        if (res.code == 200 && res.data) {
          _this.accidentList = res.data;
          _this.addForm.accidentId = '';
        }
      });
    },
    getAccidentType1: function getAccidentType1(val) {
      var _this2 = this;
      drivingAccidentInsuranceManageRequest.getAccidentTypeList({
        insCorpCode: val
      }).then(function (res) {
        if (res.code == 200 && res.data) {
          _this2.accidentList = res.data;
        }
      });
    },
    //初始化数据
    initData: function initData() {
      var _this3 = this;
      this.formLoading = true;
      drivingAccidentInsuranceManageRequest.getRiskProductDetail({
        riskProductId: this.riskProductId
      }).then(function (res) {
        _this3.formLoading = false;
        if (res.code == 200 && res.data) {
          _this3.addForm = res.data ? res.data : {};
          _this3.addForm.riskAccidentPackageSaveParams = res.data.riskAccidentPackageSaveParams ? res.data.riskAccidentPackageSaveParams : [];
          _this3.getAccidentType1(_this3.addForm.insCorpCode);
        }
      }).catch(function (err) {
        _this3.formLoading = false;
      });
    },
    //保存数据
    saveClicked: function saveClicked() {
      var _this4 = this;
      // insuranceCode: "", //套餐编码
      //     insuranceName: "", //套餐名称
      //     minSeat: '', //最小座位数
      //     maxSeat: '', //最小座位数
      //     maxNumber: '', //最大购买份数
      //     minInsureAge: '', //最小投保年龄
      //     maxInsureAge: '', //最大投保年龄
      //     whetherCalculate: false, //是否加入乘客计算
      //     insuredAmount: '', //保额
      //     premium: '', //保费
      console.log(this.addForm.riskAccidentPackageSaveParams);
      // this.addForm.insuranceCode
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          // console.log(this.addForm)
          // return
          if (_this4.DialogType == 'add') {
            _this4.addForm.riskAccidentPackageSaveParams = _this4.addForm.riskAccidentPackageSaveParams;
            // return
            drivingAccidentInsuranceManageRequest.addData(_this4.addForm).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this4.formLoading = false;
                _this4.$message.success(res.msg);
                _this4.closePage();
              }
            });
          } else {
            _this4.addForm.riskAccidentPackageSaveParams = _this4.addForm.riskAccidentPackageSaveParams;
            _this4.addForm.riskAccidentPackageSaveParams.map(function (item) {
              delete item.accidentId;
              delete item.channelId;
              delete item.group;
              delete item.riskText;
            });
            // return
            drivingAccidentInsuranceManageRequest.updateData(_this4.riskProductId, _this4.addForm).then(function (res) {
              if (res.code == 200 && res.success == true) {
                _this4.formLoading = false;
                _this4.$message.success(res.msg);
                _this4.closePage();
              }
            });
          }
        }
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$refs.addForm.resetFields();
      this.addForm.riskAccidentPackageSaveParams = [{
        formInsurance: true,
        //以报价结果为准
        insuranceCode: "",
        //套餐编码
        insuranceName: "",
        //套餐名称
        minSeat: '',
        //最小座位数
        maxSeat: '',
        //最小座位数
        maxNumber: '',
        //最大购买份数
        minInsureAge: '',
        //最小投保年龄
        maxInsureAge: '',
        //最大投保年龄
        whetherCalculate: false,
        //是否加入乘客计算
        insuredAmount: '',
        //保额
        premium: '',
        //保费
        riskList: [{
          riskCode: "",
          //代码
          riskName: "",
          //险种
          riskType: "",
          //类别
          riskAmount: "" //保额
        }]
      }], this.$emit('closePage');
    }
  }
};