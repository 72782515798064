var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "550px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "140px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "短信模板：",
                                prop: "smsTemplate",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择短信模板" },
                                  model: {
                                    value: _vm.form.smsTemplate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "smsTemplate", $$v)
                                    },
                                    expression: "form.smsTemplate",
                                  },
                                },
                                _vm._l(
                                  _vm.msgTemplateList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "短信签名：",
                                prop: "smsSignature",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入短信签名",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.smsSignature,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "smsSignature", $$v)
                                  },
                                  expression: "form.smsSignature",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "短信内容：", prop: "smsContent" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "337",
                              placeholder:
                                "内容长度不能超过337个字（含短信签名）",
                              type: "textarea",
                              autosize: { minRows: 4, maxRows: 4 },
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.smsContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "smsContent", $$v)
                              },
                              expression: "form.smsContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "添加接收人手机号：", prop: "phone" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder:
                                "可添加多个手机号码，每行限一个(必须输入数字)，并且一次最多可发送给1000人",
                              type: "textarea",
                              autosize: { minRows: 4, maxRows: 4 },
                            },
                            model: {
                              value: _vm.form.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }