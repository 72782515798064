//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customersRequest from '@/api/customers';
import initHeight from '@/mixins/initHeight';
import { commonExport } from '@/utils/common';
export default {
  name: 'TaskDetail',
  mixins: [initHeight],
  data: function data() {
    return {
      searchForm: {
        statisticsId: ''
      },
      tableData: [],
      loading: false,
      currentItem: {},
      sumList: [{
        prop: "ownerByName",
        label: "客户经理"
      }, {
        prop: "importCount",
        label: "任务数量"
      }, {
        prop: "quoteCount",
        label: "报价数量"
      }, {
        prop: "quoteRate",
        label: "报价率"
      }, {
        prop: "renewalCount",
        label: "续保数量"
      }, {
        prop: "renewalRate",
        label: "续保率"
      }, {
        prop: "policyCount",
        label: "保单数量"
      }, {
        prop: "renewalPremium",
        label: "保费"
      }, {
        prop: "onLinePolicyCount",
        label: "在线出单量"
      }, {
        prop: "onLinePolicyPremium",
        label: "在线保费"
      }, {
        prop: "drainCount",
        label: "流失量"
      }, {
        prop: "drainRate",
        label: "流失率"
      }, {
        prop: "firstTrackCount",
        label: "首次跟进量"
      }, {
        prop: "firstTrackRate",
        label: "首次跟进率"
      }, {
        prop: "trackCount",
        label: "跟进次数"
      }, {
        prop: "avgTrackCount",
        label: "平均跟进次数"
      }, {
        prop: "successTrackCount",
        label: "成功跟进量"
      }, {
        prop: "successNonPolicyCount",
        label: "成功未录单量"
      }, {
        prop: "defeatCount",
        label: "战败次数"
      }, {
        prop: "defeatRate",
        label: "战败率"
      }, {
        prop: "trackingCount",
        label: "跟进中&未跟进量"
      }, {
        prop: "trackingRate",
        label: "跟进中&未跟进率"
      }]
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.loadData();
      }
      ;
    }
  },
  created: function created() {
    this.searchForm.statisticsId = this.$route.query.customerRenewalStatisticsId || '';
    this.getDetails();
    this.loadData();
  },
  methods: {
    exportFn: function exportFn() {
      var exporParams = {
        total: this.tableData.length,
        fileName: '任务详情',
        exportUrl: '/vi-core-service/customerStatistics/ownerDetails',
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.tableData = [];
      this.loading = true;
      customersRequest.ownerDetails(this.searchForm).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data ? res.data : [];
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    getDetails: function getDetails() {
      var _this2 = this;
      customersRequest.getStatisticsInfo(this.searchForm.statisticsId).then(function (res) {
        if (res.code === 200) {
          _this2.currentItem = res.data;
        }
        ;
      });
    }
  }
};