var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "700px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.formRules,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _vm.invokeMonitorId
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "保险公司:" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.insCorpName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "方法名称:" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.methodName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "投保城市:" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.insCityName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "渠道名称:" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.insChannelName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "交互时间:" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.addForm.createdTime) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "耗时(ms):" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.addForm.interval) + " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 16 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "请求ID:" } },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.addForm.traceId) + " "
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:copy",
                                              value: _vm.addForm.traceId,
                                              expression: "addForm.traceId",
                                              arg: "copy",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:success",
                                              value: _vm.onCopy1,
                                              expression: "onCopy1",
                                              arg: "success",
                                            },
                                            {
                                              name: "clipboard",
                                              rawName: "v-clipboard:error",
                                              value: _vm.onError1,
                                              expression: "onError1",
                                              arg: "error",
                                            },
                                          ],
                                          attrs: { size: "mini" },
                                        },
                                        [_vm._v("复制")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "请求状态:" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.addForm.success
                                              ? "成功"
                                              : "失败"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "关键字信息:" } },
                                    [
                                      _c("div", [
                                        _vm._v(_vm._s(_vm.addForm.extract)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          !_vm.addForm.success
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "错误信息:" } },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.addForm.errorMessage)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.addForm.success
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "错误堆栈:" } },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "clipboard",
                                                  rawName: "v-clipboard:copy",
                                                  value: _vm.addForm.errorStack,
                                                  expression:
                                                    "addForm.errorStack",
                                                  arg: "copy",
                                                },
                                                {
                                                  name: "clipboard",
                                                  rawName:
                                                    "v-clipboard:success",
                                                  value: _vm.onCopy,
                                                  expression: "onCopy",
                                                  arg: "success",
                                                },
                                                {
                                                  name: "clipboard",
                                                  rawName: "v-clipboard:error",
                                                  value: _vm.onError,
                                                  expression: "onError",
                                                  arg: "error",
                                                },
                                              ],
                                              attrs: { size: "mini" },
                                            },
                                            [_vm._v("复制")]
                                          ),
                                          _c(
                                            "pre",
                                            { staticClass: "errorStack" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.addForm.errorStack)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.canViewSolution &&
                          !_vm._.isEmpty(_vm.addForm.solutionList)
                            ? _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "解决方案:" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "loading",
                                                  rawName: "v-loading",
                                                  value:
                                                    _vm.solutionSchemeLoading,
                                                  expression:
                                                    "solutionSchemeLoading",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { size: "mini" },
                                                  on: {
                                                    click: _vm.refreshScheme,
                                                  },
                                                },
                                                [_vm._v("刷新")]
                                              ),
                                              _vm._l(
                                                _vm.addForm.solutionList,
                                                function (item, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "el-link",
                                                            {
                                                              attrs: {
                                                                type: "primary",
                                                                underline: false,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.viewSolutionScheme(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.knowledgeTitle
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "错误分类:", prop: "categoryId" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择错误分类",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.addForm.categoryId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addForm, "categoryId", $$v)
                                    },
                                    expression: "addForm.categoryId",
                                  },
                                },
                                _vm._l(_vm.allCategory, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.categoryName,
                                      value: item.categoryId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "错误分析:",
                                prop: "errorAnalysisContent",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入错误分析",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.addForm.errorAnalysisContent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "errorAnalysisContent",
                                      $$v
                                    )
                                  },
                                  expression: "addForm.errorAnalysisContent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(false)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showPreview,
            "close-on-click-modal": false,
            width: "1024px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPreview = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("查看解决方案")]
              ),
            ]
          ),
          _c("div", [
            _c("iframe", {
              style: { width: "1000px", height: "calc(100vh - 210px)" },
              attrs: {
                id: "previewIframe",
                src: _vm.previewUrl,
                frameborder: "0",
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }