import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import customersRequest from '@/api/customers';
import OemSelect from '@/components/OemSelect';
import ImportDetailDialog from './importDetailDialog';
import CustomerImportDialog from './customerImportDialog';
import storageRequest from '@/api/storage';
import { downFile } from "@/utils/common";
export default {
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect,
    ImportDetailDialog: ImportDetailDialog,
    CustomerImportDialog: CustomerImportDialog
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentType: {
      type: String,
      default: 'customerResource'
    }
  },
  data: function data() {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      searchForm: {
        importBizType: 'BASIC',
        lookSelf: false
      },
      tableData: [],
      loading: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      showDialog: false,
      showImportDialog: false,
      currentId: '',
      importStatusList: [{
        value: 'UPDATED',
        label: '已上传'
      }, {
        value: 'INQUEUE',
        label: '排队中'
      }, {
        value: 'INPROGRESS',
        label: '进行中'
      }, {
        value: 'DONE',
        label: '已完成'
      }, {
        value: 'PAUSED',
        label: '已暂停'
      }, {
        value: 'CANCELED',
        label: '已取消'
      }]
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.loadData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    handleNodeClick: function handleNodeClick(data) {
      // 机构树点击事件
      this.searchForm.orgCode = data.salesOrgCode;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        importBizType: 'BASIC',
        lookSelf: this.searchForm.lookSelf
      };
      this.orgForm = {
        salesOrgName: '',
        salesOrgType: '',
        salesOrgCode: ''
      };
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    breakData: function breakData() {
      // 刷新
      // this.resetSearch();
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      var isDialogNoRefresh = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDialog = false;
      this.showImportDialog = false;
      if (isDialogNoRefresh) return;
      this.loading = true;
      this.searchForm.lookSelf = this.currentType === 'myCustomer' ? true : false;
      var data = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      customersRequest.getCustomerImportList(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    },
    // 详情
    toDetails: function toDetails(item) {
      this.currentId = item.customerImportMainId;
      this.showDialog = true;
    },
    // 导入
    importFile: function importFile(item) {
      var _this2 = this;
      customersRequest.importCustomerImport(item.customerImportMainId).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success("\u5BFC\u5165\u6210\u529F\uFF0C\u540E\u53F0\u6B63\u5728\u6267\u884C\u5BFC\u5165\u4EFB\u52A1\uFF0C\u8BF7\u8010\u5FC3\u7B49\u5F85\u3002");
          _this2.loadData();
        }
        ;
      });
    },
    // 下载模板
    downloadTemplate: function downloadTemplate() {
      var _this3 = this;
      storageRequest.downFileTemplate("CUSTOMER-IMPORT-TEMPLATE").then(function (res) {
        if (res.data && res.data.fileStorageId) {
          downFile(res.data.fileUrl, res.data.fileName);
        } else {
          _this3.$message.warning('暂无模板，请联系客服！');
        }
        ;
      });
    },
    // 打开文件
    openFile: function openFile(item) {
      downFile(item.fileUrl, item.fileName);
    },
    // 上传文件
    uploadFile: function uploadFile() {
      this.showImportDialog = true;
    },
    // 暂停、恢复、取消
    operation: function operation(item, type) {
      var _this4 = this;
      this.$confirm('您确定要执行该操作吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        customersRequest.operateCustomerImport(item.customerImportMainId, type).then(function (res) {
          if (res.code === 200) {
            _this4.$message.success(res.msg);
            _this4.loadData();
          }
          ;
        });
      }).catch(function () {});
    }
  }
};