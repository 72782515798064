import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OemSelect from '@/components/OemSelect';
import recoReq from '@/api/recognition';
import { getUserOrgInfoDealerOem } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentConfigId: {
      type: String,
      default: ''
    },
    supplierList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    supplyers: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {
    OemSelect: OemSelect
  },
  data: function data() {
    return {
      form: {
        applyBizType: '',
        applyOrgCode: '',
        applyOrgName: '',
        allowInherit: null,
        supportInsCorp: null,
        supplierCode: null,
        configItem: null,
        configItems: []
      },
      rules: {
        applyOrgCode: [{
          required: true,
          message: '请选择应用机构',
          trigger: 'change'
        }],
        allowInherit: [{
          required: true,
          message: '请选择是否下级可用',
          trigger: 'change'
        }],
        supplierCode: [{
          required: true,
          message: '请选择供应商',
          trigger: 'change'
        }],
        supportInsCorp: [{
          required: true,
          message: '请选择支持的保司',
          trigger: 'change'
        }]
      },
      orgForm: {
        salesOrgCode: "",
        salesOrgName: ""
      },
      formLoading: false,
      bizType: localStorage.getItem("bizType") || ''
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 树形点击
    nodeClick: function nodeClick(data) {
      console.log('data', data);
      this.form.applyOrgCode = data.salesOrgCode;
      this.form.applyOrgName = data.salesOrgName;
      this.form.applyBizType = data.bizType || this.bizType;
      this.searchForm.areaType = data.salesOrgType;
    },
    // 初始化数据
    initData: function initData() {
      var _this = this;
      this.form = {
        applyBizType: '',
        applyOrgCode: '',
        applyOrgName: '',
        allowInherit: null,
        supportInsCorp: null,
        supplierCode: null,
        configItem: null,
        configItems: []
      };
      this.orgForm = {
        salesOrgCode: "",
        salesOrgName: ""
      };
      this.$nextTick(function () {
        _this.$refs.detail.clearValidate();
      });
      if (this.opType === "add") {
        var orgObj = getUserOrgInfoDealerOem(this.bizType);
        this.form.applyOrgCode = orgObj.salesOrgCode;
        this.form.applyOrgName = orgObj.salesOrgName;
        this.form.applyBizType = orgObj.bizType || this.bizType;
      } else {
        this.formLoading = true;
        // 获取配置详情
        recoReq.getPolicyIdentifyConfigDetail({
          configId: this.currentConfigId
        }).then(function (res) {
          if (res.code === 200 && res.data) {
            var resData = res.data;
            _this.form.configId = _this.currentConfigId;
            _this.form.applyBizType = resData.applyBizType;
            _this.form.applyOrgCode = resData.applyOrgCode;
            _this.form.applyOrgName = resData.applyOrgName;
            _this.form.allowInherit = resData.allowInherit;
            _this.form.supportInsCorp = resData.supportInsCorp && _.split(resData.supportInsCorp, ',');
            _this.form.supplierCode = resData.supplierCode;
            _this.form.configItem = resData.configItem;
            // 获取动态表单
            var _item = JSON.parse(resData.configItem);
            _this.getPolicyIdentifyConfigSchema(_item, resData.supplierCode);
            _this.orgForm = {
              salesOrgCode: _this.form.applyOrgCode,
              salesOrgName: _this.form.applyOrgName
            };
          } else {
            _this.formLoading = false;
          }
        }).catch(function (err) {
          _this.formLoading = false;
        });
      }
      ;
    },
    // 获取供应商对应的动态配置项
    getPolicyIdentifyConfigSchema: function getPolicyIdentifyConfigSchema(_item, supplierCode) {
      var _this2 = this;
      recoReq.getPolicyIdentifyConfigSchema({
        supplierCode: supplierCode
      }).then(function (res) {
        _this2.formLoading = false;
        var dataList = res.data.items;
        if (!_.isEmpty(_item)) {
          // 遍历动态表单赋值
          dataList.forEach(function (item) {
            for (var key in _item) {
              if (item.storeKey == key) {
                if (_.toUpper(item.inputType) == 'MULTI_SELECT') {
                  if (_item[key]) item.storeValue = _item[key].split(',');
                } else {
                  item.storeValue = _item[key];
                }
              }
            }
          });
        }
        _this2.form.configItems = dataList;
        _this2.$forceUpdate();
      });
    },
    // 供应商选择事件
    supplierChange: function supplierChange(val) {
      if (val) {
        this.getPolicyIdentifyConfigSchema(null, val);
      }
    },
    // 保存数据
    submit: function submit() {
      var _this3 = this;
      this.$refs['detail'].validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          var configItem = {};
          _this3.form.configItems.forEach(function (item) {
            if (item.storeValue) {
              var newField = item.storeKey;
              if (_.toUpper(item.inputType) == 'MULTI_SELECT') {
                configItem[newField] = item.storeValue.toString();
              } else {
                configItem[newField] = item.storeValue;
              }
            }
          });
          var postData = _this3.form;
          postData.configItem = JSON.stringify(configItem);
          postData.configItems = null;
          postData.supportInsCorp = postData.supportInsCorp.toString();
          var localRequest = _this3.opType == 'edit' ? recoReq.updatePolicyIdentifyConfig(postData) : recoReq.createPolicyIdentifyConfig(postData);
          localRequest.then(function (res) {
            if (res.code == RESPONSE_SUCCESS) {
              _this3.$refs['detail'].resetFields();
              _this3.$emit('closePage', false);
            }
            _this3.formLoading = false;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      this.$emit('closePage', boolean);
    }
  }
};