import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.fill.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PageContainer from "@/components/PageContainer";
import FormLabel from "@/components/FormSubLabel";
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import activityRequest from '../../api/insuranceActivities';
import { commonExport } from '@/utils/common';
export default {
  name: "ProgressDetail",
  components: {
    PageContainer: PageContainer,
    FormLabel: FormLabel,
    Pagination: Pagination
  },
  mixins: [dict],
  data: function data() {
    return {
      form: {
        activeRequireList: new Array(1).fill().map(function (val) {
          return {
            vehicleNameCode: '',
            indicators: '',
            conditions: '',
            rate: '',
            relevanceStatus: []
          };
        })
      },
      vehicleList: [],
      indicatorsList: [{
        name: '新车投保占比',
        value: 'RATE1'
      }, {
        name: '综合续保率',
        value: 'RATE2'
      }, {
        name: '次新车续保率',
        value: 'RATE3'
      }, {
        name: '延保产品渗透率',
        value: 'RATE4'
      }, {
        name: '延保销售贡献度',
        value: 'RATE5'
      }, {
        name: '代步服务产品渗透率',
        value: 'RATE6'
      }, {
        name: '代步服务产品销售贡献度',
        value: 'RATE7'
      }],
      conditionsList: [{
        name: '大于',
        value: 'GT'
      }, {
        name: '小于',
        value: 'LT'
      }, {
        name: '等于',
        value: 'EQ'
      }],
      relevanceStatusTypeList: [{
        name: '与',
        value: '与'
      }, {
        name: '或',
        value: '或'
      }],
      activeOne: '1',
      activeTwo: '1',
      activeThree: '1',
      formLoading: false,
      searchForm: {
        customerName: '',
        vin: ''
      },
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      loading: false
    };
  },
  watch: {
    '$route': function $route(to, from) {
      //监听路由是否变化）
      this.initData();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    exportFn: function exportFn() {
      var exporParams = {
        total: this.total,
        fileName: '活动参与进度详情客户列表',
        exportUrl: "/ps-ftms-service/activitys/".concat(this.$route.query.activityId, "/").concat(this.$route.query.dealerCode, "/customer"),
        searchForm: this.searchForm
      };
      commonExport(exporParams, this.$refs.multipleTable);
    },
    initData: function initData() {
      var _this = this;
      activityRequest.getVehicleModelsList().then(function (res) {
        // 获取车型列表
        if (res.code == RESPONSE_SUCCESS) {
          _this.vehicleList = res.data;
        }
      }).catch(function (err) {
        _this.loading = false;
      });
      if (this.$route.query.activityId) {
        // 获取活动详情
        this.formLoading = true;
        activityRequest.getDealerDetail(this.$route.query.activityId, this.$route.query.dealerCode).then(function (res) {
          _this.formLoading = false;
          if (res.code == RESPONSE_SUCCESS) {
            _this.form = res.data;
            _this.tableData = _this.form.activityCustomerPagination.list;
            _this.form.activeRequireList.forEach(function (item) {
              item.vehicleNameCode = item.vehicleNameCode.split(',');
              item.relevanceStatus = item.relevanceStatus.split(',');
            });
          }
        }).catch(function (err) {
          _this.loading = false;
        });
      }
    },
    cancel: function cancel() {
      this.$store.dispatch('tagsView/delView', this.$route);
      this.$router.go(-1);
    },
    resetSearch: function resetSearch() {
      this.searchForm = {
        customerName: '',
        vin: ''
      };
      this.searchData();
    },
    searchData: function searchData() {
      this.listQuery.pageNum = 1;
      this.getDealerList();
    },
    getDealerList: function getDealerList() {
      var _this2 = this;
      this.loading = true;
      var params = {};
      params.pageNum = this.listQuery.pageNum;
      params.pageSize = this.listQuery.pageSize;
      params.customerName = this.searchForm.customerName;
      params.vin = this.searchForm.vin;
      activityRequest.getDealerActivityCustomers(this.$route.query.activityId, this.$route.query.dealerCode, params).then(function (res) {
        _this2.loading = false;
        if (!_.isEmpty(res.data)) {
          _this2.tableData = res.data.list;
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    }
  }
};