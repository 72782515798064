var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-width": "120px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否下级可用：",
                                prop: "allowInherit",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    placeholder: "请选择是否下级可用",
                                  },
                                  model: {
                                    value: _vm.form.allowInherit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "allowInherit", $$v)
                                    },
                                    expression: "form.allowInherit",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: true },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: false },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "供应商：",
                                prop: "supplierCode",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择供应商",
                                    clearable: "",
                                  },
                                  on: { change: _vm.supplierChange },
                                  model: {
                                    value: _vm.form.supplierCode,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "supplierCode", $$v)
                                    },
                                    expression: "form.supplierCode",
                                  },
                                },
                                _vm._l(
                                  _vm.supplierList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.description,
                                        value: item.code,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "支持的保司：",
                                prop: "supportInsCorp",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    multiple: "",
                                    "collapse-tags": "",
                                    placeholder: "请选择保险公司",
                                  },
                                  model: {
                                    value: _vm.form.supportInsCorp,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "supportInsCorp", $$v)
                                    },
                                    expression: "form.supportInsCorp",
                                  },
                                },
                                _vm._l(_vm.supplyers, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.shortName,
                                      value: item.code,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm._.isEmpty(_vm.form.configItems)
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "15px",
                            "padding-bottom": "15px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { height: "15px" } },
                            [
                              _c(
                                "el-divider",
                                { attrs: { "content-position": "left" } },
                                [_vm._v("配置项")]
                              ),
                            ],
                            1
                          ),
                          _vm._l(_vm.form.configItems, function (item, index) {
                            return _c(
                              "el-form-item",
                              {
                                key: index,
                                staticStyle: {
                                  "line-height": "40px",
                                  width: "100%",
                                },
                                attrs: {
                                  "label-width": "130px",
                                  label: item.display,
                                  prop: "configItems." + index + ".storeValue",
                                  rules: {
                                    required: item.required,
                                    message: "请输入" + item.display,
                                    trigger: "blur",
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "max-width": "320px",
                                    },
                                  },
                                  [
                                    (_vm._.toLower(item.inputType) == "text" ||
                                      _vm._.toLower(item.inputType) ==
                                        "email") &&
                                    _vm._.toLower(item.inputType) != "number" &&
                                    _vm._.toLower(item.inputType) != "select"
                                      ? _c("el-input", {
                                          attrs: {
                                            clearable: "",
                                            size: "mini",
                                            disabled: _vm.opType == "view",
                                          },
                                          model: {
                                            value: item.storeValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "storeValue", $$v)
                                            },
                                            expression: "item.storeValue",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._.toLower(item.inputType) == "number"
                                      ? _c("el-input", {
                                          attrs: {
                                            clearable: "",
                                            size: "mini",
                                            disabled: _vm.opType == "view",
                                          },
                                          on: {
                                            input: function ($event) {
                                              item.storeValue =
                                                item.storeValue.replace(
                                                  /[^\d]/g,
                                                  ""
                                                )
                                            },
                                          },
                                          model: {
                                            value: item.storeValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "storeValue", $$v)
                                            },
                                            expression: "item.storeValue",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._.toLower(item.inputType) == "textarea"
                                      ? _c("el-input", {
                                          attrs: {
                                            clearable: "",
                                            size: "mini",
                                            disabled: _vm.opType == "view",
                                            type: "textarea",
                                            rows: 5,
                                          },
                                          model: {
                                            value: item.storeValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "storeValue", $$v)
                                            },
                                            expression: "item.storeValue",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._.toLower(item.inputType) == "select"
                                      ? _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择",
                                              size: "mini",
                                              clearable: "",
                                              disabled: _vm.opType == "view",
                                            },
                                            model: {
                                              value: item.storeValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "storeValue",
                                                  $$v
                                                )
                                              },
                                              expression: "item.storeValue",
                                            },
                                          },
                                          _vm._l(
                                            item.options,
                                            function (_item, _index) {
                                              return _c("el-option", {
                                                key: _index,
                                                attrs: {
                                                  label: _item.name,
                                                  value: _item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._.toLower(item.inputType) == "datetime"
                                      ? _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "value-format":
                                              "yyyy-MM-dd HH:mm:ss",
                                            type: "datetime",
                                            placeholder: "请选择",
                                            size: "mini",
                                            disabled: _vm.opType == "view",
                                          },
                                          model: {
                                            value: item.storeValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "storeValue", $$v)
                                            },
                                            expression: "item.storeValue",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._.toLower(item.inputType) == "time"
                                      ? _c("el-time-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "value-format": "HH:mm:ss",
                                            size: "mini",
                                            disabled: _vm.opType == "view",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: item.storeValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "storeValue", $$v)
                                            },
                                            expression: "item.storeValue",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._.toLower(item.inputType) == "date"
                                      ? _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            format: "yyyy-MM-dd",
                                            "value-format": "yyyy-MM-dd",
                                            size: "mini",
                                            disabled: _vm.opType == "view",
                                            type: "date",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: item.storeValue,
                                            callback: function ($$v) {
                                              _vm.$set(item, "storeValue", $$v)
                                            },
                                            expression: "item.storeValue",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "max-width": "320px",
                                      "justify-content": "space-between",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#999",
                                          "line-height": "20px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s("[" + item.storeKey + "]")
                                        ),
                                      ]
                                    ),
                                    item.description
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              color: "red",
                                              "line-height": "20px",
                                              "margin-left": "10px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                "[" + item.description + "]"
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.submit },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }