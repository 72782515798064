var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-m-cls" }, [
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-drawer",
              {
                attrs: {
                  title: "",
                  visible: _vm.drawer,
                  modal: false,
                  "append-to-body": true,
                  size: "350px",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.drawer = $event
                  },
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.searchForm,
                      "label-width": "110px",
                      size: "mini",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        !_vm.disableTreeSelect
                          ? _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "所属机构" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        ref: "orgSelect",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          "filter-method": _vm.dataFilter,
                                          placeholder: "请选择机构",
                                          clearable: false,
                                        },
                                        on: {
                                          change: _vm.orgChange,
                                          focus: _vm.orgFocus,
                                        },
                                        model: {
                                          value: _vm.searchForm.orgName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.searchForm,
                                              "orgName",
                                              $$v
                                            )
                                          },
                                          expression: "searchForm.orgName",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-option",
                                          {
                                            staticStyle: {
                                              height: "auto",
                                              "background-color": "white",
                                            },
                                            attrs: {
                                              value: _vm.searchForm.orgCode,
                                            },
                                          },
                                          [
                                            _c("el-tree", {
                                              ref: "tree",
                                              staticClass: "filter-tree",
                                              attrs: {
                                                "node-key": "code",
                                                "highlight-current":
                                                  _vm.highlightCurrent,
                                                data: _vm.orgData,
                                                props: _vm.defaultProps,
                                                "filter-node-method":
                                                  _vm.filterNode,
                                              },
                                              on: {
                                                "node-click":
                                                  _vm.handleNodeClick,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "姓名" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "20",
                                    placeholder: "请输入姓名",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.salerName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "salerName", $$v)
                                    },
                                    expression: "searchForm.salerName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "手机号码" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "11",
                                    placeholder: "请输入手机号码",
                                    clearable: "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      _vm.searchForm.salerMobile =
                                        _vm.searchForm.salerMobile.replace(
                                          /[^\d]/g,
                                          ""
                                        )
                                    },
                                  },
                                  model: {
                                    value: _vm.searchForm.salerMobile,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "salerMobile",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.salerMobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _vm.disableTreeSelect
                          ? _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "证件号码" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "18",
                                        placeholder: "请输入证件号码",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.searchForm.salerIdNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "salerIdNo",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.salerIdNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    !_vm.disableTreeSelect
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 22 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "证件号码" } },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "18",
                                        placeholder: "请输入证件号码",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.searchForm.salerIdNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.searchForm,
                                            "salerIdNo",
                                            $$v
                                          )
                                        },
                                        expression: "searchForm.salerIdNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      [
                        _c("el-col", { attrs: { span: 24 } }, [
                          _c(
                            "div",
                            { staticClass: "searchBtnStyle" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-refresh-left",
                                  },
                                  on: { click: _vm.resetSearch },
                                },
                                [_vm._v("重置")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "mini",
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.searchData },
                                },
                                [_vm._v("查询")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table-cls normal-btn-cls" },
          [
            _c(
              "el-card",
              { staticClass: "box-card", attrs: { shadow: "never" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "table-btn-cls",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.addItem } },
                      [_vm._v("新增")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          disabled: _vm._.isEmpty(_vm.multipleSelection),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.removeItems()
                          },
                        },
                      },
                      [_vm._v("批量删除")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.drawer = true
                          },
                        },
                      },
                      [_vm._v("搜索")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      border: "",
                      data: _vm.tableData,
                      height: _vm.tableHeightH,
                      "header-cell-style": { background: "#F7F7F7" },
                      "highlight-current-row": "",
                    },
                    on: {
                      "row-click": _vm.handleRowChange,
                      "selection-change": _vm.handleSelectionChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "55",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "salerName",
                        label: "姓名",
                        width: "120px",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "salerIdNo",
                        label: "证件号码",
                        width: "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "salerMobile",
                        label: "手机号码",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orgName",
                        label: "所属机构",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "supportInsCorpCodes",
                        label: "保险公司",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getInsOrgName(
                                      scope.row.supportInsCorpCodes
                                    )
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createdTime",
                        label: "创建时间",
                        width: "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", align: "center", width: "150px" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    plain: "",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.viewDetail("update", scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    plain: "",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.removeItems(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("pagination", {
                  attrs: {
                    total: _vm.total,
                    page: _vm.listQuery.pageNum,
                    limit: _vm.listQuery.pageSize,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageNum", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.listQuery, "pageSize", $event)
                    },
                    pagination: _vm.loadData,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "Dialog",
        {
          attrs: {
            title:
              "双录人员" +
              (_vm.opType == "view"
                ? "详情"
                : _vm.opType == "update"
                ? "编辑"
                : "新增"),
            width: "500px",
            "form-loading": _vm.formLoading,
          },
          on: { handleClose: _vm.cancel },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                slot: "content",
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "130px",
              },
              slot: "content",
            },
            [
              _c(
                "el-row",
                [
                  !_vm.disableTreeSelect
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所属机构", prop: "orgCode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "orgSelects",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    "filter-method": _vm.dataFilters,
                                    placeholder: "请选择机构",
                                    clearable: "",
                                    disabled: _vm.opType == "view",
                                  },
                                  on: {
                                    change: _vm.orgChanges,
                                    focus: _vm.orgFocuss,
                                  },
                                  model: {
                                    value: _vm.form.orgName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "orgName", $$v)
                                    },
                                    expression: "form.orgName",
                                  },
                                },
                                [
                                  _c(
                                    "el-option",
                                    {
                                      staticStyle: {
                                        height: "auto",
                                        "background-color": "white",
                                      },
                                      attrs: { value: _vm.form.orgCode },
                                    },
                                    [
                                      _c("el-tree", {
                                        ref: "trees",
                                        staticClass: "filter-tree",
                                        attrs: {
                                          "node-key": "code",
                                          "highlight-current":
                                            _vm.highlightCurrent,
                                          data: _vm.orgData,
                                          props: _vm.defaultProps,
                                          "filter-node-method": _vm.filterNode,
                                        },
                                        on: {
                                          "node-click": _vm.handleNodeClicks,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "姓名", prop: "salerName" } },
                        [
                          _c("el-input", {
                            staticClass: "form-item-cls",
                            attrs: {
                              maxlength: "40",
                              disabled: _vm.opType == "view",
                              placeholder: "请输入",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.salerName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "salerName", $$v)
                              },
                              expression: "form.salerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "证件号码", prop: "salerIdNo" } },
                        [
                          _c("el-input", {
                            staticClass: "form-item-cls",
                            attrs: {
                              disabled: _vm.opType == "view",
                              maxlength: "18",
                              placeholder: "请输入",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.salerIdNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "salerIdNo", $$v)
                              },
                              expression: "form.salerIdNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号码", prop: "salerMobile" } },
                        [
                          _c("el-input", {
                            staticClass: "form-item-cls",
                            attrs: {
                              disabled: _vm.opType == "view",
                              maxlength: "11",
                              placeholder: "请输入",
                              clearable: "",
                            },
                            model: {
                              value: _vm.form.salerMobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "salerMobile", $$v)
                              },
                              expression: "form.salerMobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "保险公司", prop: "supportInsCorpCodes" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-item-cls",
                      attrs: {
                        disabled: _vm.opType == "view",
                        placeholder: "请选择保险公司",
                        multiple: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.form.supportInsCorpCodes,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "supportInsCorpCodes", $$v)
                        },
                        expression: "form.supportInsCorpCodes",
                      },
                    },
                    _vm._l(_vm.supplyers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.shortName, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _vm.opType != "view"
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                        disabled: _vm.isClick,
                      },
                      on: { click: _vm.submit },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.opType == "update" ? "保存" : "保存") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: { click: _vm.cancel },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.opType == "view" ? "关闭" : "取消") + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }