var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.infoLoading,
              expression: "infoLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 150px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-card",
                      {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { shadow: "never" },
                      },
                      [
                        _c("div", { staticStyle: { padding: "10px" } }, [
                          _c(
                            "div",
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "30px" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.claimDetail.classifyName || "-")
                                  ),
                                ]
                              ),
                              _c("el-divider", {
                                attrs: { direction: "vertical" },
                              }),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "font-size": "15px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "理赔状态：" +
                                      _vm._s(
                                        _vm.claimDetail.claimStatusDisplay ||
                                          "-"
                                      )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                "font-size": "14px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "line-height": "28px",
                                  },
                                },
                                [
                                  _c("div", [_vm._v("理赔申请编号：")]),
                                  _c(
                                    "div",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.claimDetail.claimNo
                                              ? _vm.claimDetail.claimNo
                                              : "-"
                                          ) +
                                          " "
                                      ),
                                      _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          "报案号：" +
                                            _vm._s(
                                              _vm.claimDetail.reportNo
                                                ? _vm.claimDetail.reportNo
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _vm.claimDetail.claimStatus == "CS01" &&
                                      _vm.claimDetail.reportNo
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                                plain: "",
                                              },
                                              on: { click: _vm.ClaimRefresh },
                                            },
                                            [_vm._v(" 刷新 ")]
                                          )
                                        : _vm._e(),
                                      _vm.claimDetail.claimStatus == "CS01" &&
                                      _vm.claimDetail.reportNo
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                              },
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                                plain: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.ClaimTerminateShow = true
                                                },
                                              },
                                            },
                                            [_vm._v(" 撤销申请 ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "理赔结果：" +
                                        _vm._s(
                                          _vm.claimDetail.claimResult
                                            ? _vm.claimDetail.claimResult
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _vm.policyInfo.productClassCode == "DBC"
                                    ? _c("svg-icon", {
                                        attrs: {
                                          "icon-class": _vm.policyInfo
                                            .supplierCode
                                            ? _vm.policyInfo.supplierCode.split(
                                                "_"
                                              )[1] &&
                                              _vm.policyInfo.supplierCode
                                                .split("_")[1]
                                                .toLowerCase()
                                            : "-",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.policyInfo.productClassCode == "DBC"
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getInsCorpName(
                                              _vm.policyInfo.supplierCode.split(
                                                "_"
                                              )[1] &&
                                                _vm.policyInfo.supplierCode
                                                  .split("_")[1]
                                                  .toLowerCase()
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.policyInfo.productClassCode == "DBC"
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" },
                                      })
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      "保单号：" +
                                        _vm._s(
                                          _vm.policyInfo.policyNo
                                            ? _vm.policyInfo.policyNo
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "投保单号：" +
                                        _vm._s(
                                          _vm.policyInfo.applyPolicyNo
                                            ? _vm.policyInfo.applyPolicyNo
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "申请金额：" +
                                        _vm._s(
                                          _vm.claimDetail.applyAmount
                                            ? _vm.claimDetail.applyAmount
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "赔付金额：" +
                                        _vm._s(
                                          _vm.claimDetail.compensateAmount
                                            ? _vm.claimDetail.compensateAmount
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "出单机构：" +
                                        _vm._s(
                                          _vm.policyInfo.salesOrgName
                                            ? _vm.policyInfo.salesOrgName
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "业务员：" +
                                        _vm._s(
                                          _vm.policyInfo.salemanName
                                            ? _vm.policyInfo.salemanName
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "操作人：" +
                                        _vm._s(
                                          _vm.claimDetail.creatorName
                                            ? _vm.claimDetail.creatorName
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "line-height": "28px" } },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "理赔申请时间：" +
                                        _vm._s(
                                          _vm.claimDetail.createdTime
                                            ? _vm.claimDetail.createdTime
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.claimDetail,
                          "label-width": "180px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeOne3,
                              callback: function ($$v) {
                                _vm.activeOne3 = $$v
                              },
                              expression: "activeOne3",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "客户信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "客户名称：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .targetOwnerName || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件类型：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .targetOwnerCertificateTypeDisplay ||
                                                    "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "证件号码：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .targetOwnerCertificateNo ||
                                                    "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "联系电话：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .targetOwnerMobile || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "电子邮箱：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .targetOwnerEmail || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "所在省市：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .targetOwnerProviceName ||
                                                    "-"
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .targetOwnerCityName || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 12,
                                          lg: 12,
                                          xl: 12,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "地址：" } },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.policyInfo
                                                    .targetOwnerAddress || "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTwo,
                              callback: function ($$v) {
                                _vm.activeTwo = $$v
                              },
                              expression: "activeTwo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "服务信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm.claimDetail.classifyCode != "SB"
                                  ? _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "服务产品名称：",
                                                },
                                              },
                                              [
                                                _vm.claimDetail.classifyCode ==
                                                "BZHG"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.policyInfo &&
                                                              _vm.policyInfo
                                                                .productName
                                                              ? _vm.policyInfo
                                                                  .productName
                                                              : _vm.policyInfo
                                                                  .policyProduct &&
                                                                _vm.policyInfo
                                                                  .policyProduct
                                                                  .productName
                                                              ? _vm.policyInfo
                                                                  .policyProduct
                                                                  .productName
                                                              : "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            (_vm.policyInfo &&
                                                              _vm.policyInfo
                                                                .productName) ||
                                                              "-"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "服务保障期限(月)：",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .productTimeLimit || "-"
                                                    ) +
                                                    " "
                                                ),
                                                _vm.policyInfo.productTimeLimit
                                                  ? _c("span", [_vm._v("个月")])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "服务生效起期：",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.policyInfo.startTime
                                                        ? _vm.policyInfo.startTime.slice(
                                                            0,
                                                            10
                                                          )
                                                        : "-"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "服务生效止期：",
                                                },
                                              },
                                              [
                                                _vm.policyInfo &&
                                                _vm.policyInfo
                                                  .productClassCode == "YB"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo &&
                                                            _vm.policyInfo
                                                              .targetGuaranteeRange ==
                                                              "双擎动力蓄电池"
                                                            ? "终身"
                                                            : _vm.policyInfo
                                                                .endTime
                                                            ? _vm.policyInfo.endTime.slice(
                                                                0,
                                                                10
                                                              )
                                                            : "-"
                                                        )
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo.endTime
                                                            ? _vm.policyInfo.endTime.slice(
                                                                0,
                                                                10
                                                              )
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "销售方式：" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .salesMethodDisplay ||
                                                        "-"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "服务销售价(元)：",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.policyInfo
                                                        .productRetailPrice
                                                        ? parseFloat(
                                                            _vm.policyInfo
                                                              .productRetailPrice
                                                          ).toFixed(2)
                                                        : 0
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c(
                                          "el-row",
                                          {
                                            staticClass: "flexWarp",
                                            attrs: { gutter: 0 },
                                          },
                                          [
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("服务产品名称：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .productName || "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("销售方式：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .salesMethodDisplay
                                                            ? _vm.policyInfo
                                                                .salesMethodDisplay
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "服务销售价(元)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .targetSalesPrice
                                                            ? _vm.policyInfo.targetSalesPrice.toFixed(
                                                                2
                                                              )
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "el-divider",
                                                      {
                                                        attrs: {
                                                          "content-position":
                                                            "left",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#333",
                                                              "font-size":
                                                                "14px",
                                                              "font-weight":
                                                                "600",
                                                            },
                                                          },
                                                          [_vm._v("三保方案")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "原厂质保公里数(万)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .targetOriginalWarrantyMileage
                                                            ? _vm.policyInfo
                                                                .targetOriginalWarrantyMileage
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "原厂质保期限(年)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .originalWarrantyPeriod
                                                            ? _vm.policyInfo
                                                                .originalWarrantyPeriod
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("原厂保修起期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .originalStartTime
                                                            ? _vm.policyInfo.originalStartTime.slice(
                                                                0,
                                                                10
                                                              ) + " 0时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("原厂保修止期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .originalEndTime
                                                            ? _vm.policyInfo.originalEndTime.slice(
                                                                0,
                                                                10
                                                              ) + " 24时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "el-divider",
                                                      {
                                                        attrs: {
                                                          "content-position":
                                                            "left",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#333",
                                                              "font-size":
                                                                "14px",
                                                              "font-weight":
                                                                "600",
                                                            },
                                                          },
                                                          [_vm._v("延保方案")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("延保保障范围：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .targetGuaranteeRange
                                                            ? _vm.policyInfo
                                                                .targetGuaranteeRange
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("延保方案：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .ternScheme
                                                            ? _vm.policyInfo
                                                                .ternScheme
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("延保起期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .startTime
                                                            ? _vm.policyInfo.startTime.slice(
                                                                0,
                                                                10
                                                              ) + " 0时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("延保止期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo.endTime
                                                            ? _vm.policyInfo
                                                                .extensionScheme ==
                                                              "终身"
                                                              ? "终身"
                                                              : _vm.policyInfo.endTime.slice(
                                                                  0,
                                                                  10
                                                                ) + " 24时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("已行驶里程：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .targetMileage
                                                            ? _vm.policyInfo
                                                                .targetMileage
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "延长公里数(万)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .targetExtendMileage
                                                            ? _vm.policyInfo
                                                                .targetExtendMileage
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "el-divider",
                                                      {
                                                        attrs: {
                                                          "content-position":
                                                            "left",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#333",
                                                              "font-size":
                                                                "14px",
                                                              "font-weight":
                                                                "600",
                                                            },
                                                          },
                                                          [_vm._v("代步车方案")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "代步车年限(年)："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .targetScooterYearLimit ||
                                                            _vm.policyInfo
                                                              .targetScooterYearLimit ===
                                                              0
                                                            ? _vm.policyInfo
                                                                .targetScooterYearLimit
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "代步车服务起期："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .dbcStartTime
                                                            ? _vm.policyInfo.dbcStartTime.slice(
                                                                0,
                                                                10
                                                              ) + " 0时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "代步车服务止期："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .dbcEndTime
                                                            ? _vm.policyInfo.dbcEndTime.slice(
                                                                0,
                                                                10
                                                              ) + " 24时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-col",
                                              { attrs: { span: 24 } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "el-divider",
                                                      {
                                                        attrs: {
                                                          "content-position":
                                                            "left",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#333",
                                                              "font-size":
                                                                "14px",
                                                              "font-weight":
                                                                "600",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "车险置换方案"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("补偿限额(万)：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .limitCompensation
                                                            ? _vm.policyInfo
                                                                .limitCompensation
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("置换年限(年)：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .targetScooterYearLimit ||
                                                            _vm.policyInfo
                                                              .targetScooterYearLimit ===
                                                              0
                                                            ? _vm.policyInfo
                                                                .targetScooterYearLimit
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("置换服务起期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .zhStartTime
                                                            ? _vm.policyInfo.zhStartTime.slice(
                                                                0,
                                                                10
                                                              ) + " 0时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-col",
                                              {
                                                attrs: {
                                                  xs: 12,
                                                  sm: 12,
                                                  md: 8,
                                                  lg: 8,
                                                  xl: 6,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [_vm._v("置换服务止期：")]
                                                    ),
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .zhEndTime
                                                            ? _vm.policyInfo.zhEndTime.slice(
                                                                0,
                                                                10
                                                              ) + " 24时"
                                                            : "-"
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTwo,
                              callback: function ($$v) {
                                _vm.activeTwo = $$v
                              },
                              expression: "activeTwo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "车辆信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车牌号：" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.claimDetail.plateNo
                                                        ? _vm.claimDetail
                                                            .plateNo
                                                        : "新车"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "车架号：" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.claimDetail.vin
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        attrs: {
                                          xs: 12,
                                          sm: 12,
                                          md: 8,
                                          lg: 8,
                                          xl: 6,
                                        },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "厂家品牌/车系：",
                                              prop: "carTypeVal",
                                            },
                                          },
                                          [
                                            _vm.claimDetail.classifyCode ==
                                            "BZHG"
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.policyInfo
                                                          .targetVhcBrandName ||
                                                          "-"
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        _vm.policyInfo
                                                          .targetVhcSeriesName ||
                                                          "-"
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        _vm.policyInfo
                                                          .targetVhcModelName ||
                                                          "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.claimDetail
                                                          .vhcBrandName || "-"
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        _vm.claimDetail
                                                          .vhcSeriesName
                                                      ) +
                                                      " "
                                                  ),
                                                  _vm.claimDetail
                                                    .vhcSeriesChineseName
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              _vm.claimDetail
                                                                .vhcSeriesChineseName ||
                                                                "-"
                                                            ) +
                                                            ")"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._l(_vm.attributeList, function (item) {
                                      return _c(
                                        "el-col",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.claimDetail.classifyCode !=
                                                  "DBC" &&
                                                item.attributeName ==
                                                  "购车发票日期",
                                              expression:
                                                "claimDetail.classifyCode != 'DBC' && item.attributeName == '购车发票日期'",
                                            },
                                          ],
                                          key: item.attributeName,
                                          attrs: {
                                            xs: 12,
                                            sm: 12,
                                            md: 8,
                                            lg: 8,
                                            xl: 6,
                                          },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  item.attributeName + "：",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  item.attributeName ==
                                                  "使用性质"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.policyInfo
                                                              .targetUseAttributeDisplay ||
                                                              "-"
                                                          )
                                                        ),
                                                      ])
                                                    : item.attributeName ==
                                                      "能源类型"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.policyInfo
                                                              .targetFuelTypeDisplay ||
                                                              "-"
                                                          )
                                                        ),
                                                      ])
                                                    : item.attributeName ==
                                                      "车辆类型"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.policyInfo
                                                              .targetVehicleTypeDisplay ||
                                                              "-"
                                                          )
                                                        ),
                                                      ])
                                                    : item.attributeName ==
                                                      "购车发票日期"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              (item.attributeValue &&
                                                                item.attributeValue.split(
                                                                  " "
                                                                )[0]) ||
                                                                "-"
                                                            )
                                                        ),
                                                      ])
                                                    : item.attributeName ==
                                                      "新车销售日期"
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              (item.attributeValue &&
                                                                item.attributeValue.split(
                                                                  " "
                                                                )[0]) ||
                                                                "-"
                                                            )
                                                        ),
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.attributeValue ||
                                                              "-"
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    _vm.claimDetail.classifyCode == "TCWY"
                                      ? _c(
                                          "el-col",
                                          {
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: { label: "使用性质：" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.policyInfo
                                                            .targetUseAttributeDisplay ||
                                                            "-"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeThree,
                              callback: function ($$v) {
                                _vm.activeThree = $$v
                              },
                              expression: "activeThree",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "理赔信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _vm._l(
                                      _vm.claimTargetattributeList,
                                      function (item) {
                                        return _c(
                                          "el-col",
                                          {
                                            key: item.attributeName,
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            item.attributeName != "理赔险种"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.attributeName
                                                          ) + "："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.attributeValue ||
                                                              item.attributeValue ===
                                                                0
                                                              ? item.attributeValue
                                                              : "-"
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.attributeName
                                                          ) + "："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.claimDetail
                                                              ? _vm.claimDetail
                                                                  .planName
                                                              : "-"
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._l(
                                      _vm.claimInsureAttributeList,
                                      function (item) {
                                        return _c(
                                          "el-col",
                                          {
                                            key: item.attributeName,
                                            attrs: {
                                              xs: 12,
                                              sm: 12,
                                              md: 8,
                                              lg: 8,
                                              xl: 6,
                                            },
                                          },
                                          [
                                            item.attributeName != "理赔险种"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.attributeName
                                                          ) + "："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.attributeValue ||
                                                              item.attributeValue ===
                                                                0
                                                              ? item.attributeValue
                                                              : "-"
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  { staticClass: "card_item" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card_item_label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.attributeName
                                                          ) + "："
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.claimDetail
                                                              ? _vm.claimDetail
                                                                  .planName
                                                              : "-"
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                                  ]
                                                ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTen,
                              callback: function ($$v) {
                                _vm.activeTen = $$v
                              },
                              expression: "activeTen",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "附件列表" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 0 } },
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "filesLists" },
                                        _vm._l(
                                          _vm.filestypelists,
                                          function (fitem, findex) {
                                            return _c(
                                              "div",
                                              {
                                                key: findex,
                                                staticClass: "fileBox",
                                                staticStyle: {
                                                  width: "100px",
                                                  "min-height": "140px",
                                                  display: "flex",
                                                  "flex-direction": "column",
                                                  "justify-content":
                                                    "flex-start",
                                                  "align-items": "center",
                                                },
                                              },
                                              [
                                                _vm.getFileSuffix(
                                                  fitem.fileExtension
                                                )
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("el-image", {
                                                          staticStyle: {
                                                            width: "100px",
                                                            height: "100px",
                                                            cursor: "pointer",
                                                          },
                                                          attrs: {
                                                            src: fitem.fileUrl,
                                                            "preview-src-list":
                                                              [fitem.fileUrl],
                                                          },
                                                        }),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              fitem.fileTypeDisplay
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    )
                                                  : _c("div", [
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(fitem.fileName)
                                                        ),
                                                      ]),
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            fitem.fileTypeDisplay
                                                          )
                                                        ),
                                                      ]),
                                                    ]),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { icon: "el-icon-close", size: "mini" },
                      on: { click: _vm.closePage },
                    },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "car-insurance-ui-dialog pageDialog",
          attrs: {
            visible: _vm.ClaimTerminateShow,
            "close-on-click-modal": false,
            width: "500px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.ClaimTerminateShow = $event
            },
            close: _vm.ClaimTerminateClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [
              _c("FormLabel", {
                staticStyle: { "font-size": "14px" },
                attrs: { "form-label": "撤销理赔" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-body" },
            [
              _c(
                "el-form",
                {
                  ref: "ClaimTerminateForm",
                  attrs: {
                    model: _vm.ClaimTerminateForm,
                    rules: _vm.ClaimTerminateRules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "撤销原因:", prop: "terminateReason" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "90%" },
                        attrs: {
                          "show-word-limit": "",
                          maxlength: "60",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.ClaimTerminateForm.terminateReason,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ClaimTerminateForm,
                              "terminateReason",
                              $$v
                            )
                          },
                          expression: "ClaimTerminateForm.terminateReason",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "撤销具体描述:",
                        prop: "terminateSpecificDesc",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "90%" },
                        attrs: {
                          type: "textarea",
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ClaimTerminateForm.terminateSpecificDesc,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ClaimTerminateForm,
                              "terminateSpecificDesc",
                              $$v
                            )
                          },
                          expression:
                            "ClaimTerminateForm.terminateSpecificDesc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-check",
                    plain: "",
                    type: "primary",
                  },
                  on: { click: _vm.claimTerminateClicked },
                },
                [_vm._v("确认")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: { click: _vm.ClaimTerminateClose },
                },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }