import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import drivingInsuranceClassify from '@/api/drivingInsuranceClassify.js';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import addDiadlog from './dialog/add';
import dictRequest from '@/api/dictionary';
export default {
  name: "drivingInsuranceClassify",
  mixins: [initHeight],
  components: {
    Pagination: Pagination,
    addDiadlog: addDiadlog
  },
  data: function data() {
    return {
      DialogType: null,
      riskProductId: null,
      DialogId: null,
      drawer: true,
      loading: true,
      showAddDialog: false,
      routerTypeList: [{
        routerName: 'GET'
      }, {
        routerName: 'POST'
      }, {
        routerName: 'PUT'
      }, {
        routerName: 'DELETE'
      }],
      searchForm: {},
      tableData: [],
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      multipleSelection: [],
      riskTypeList: [],
      viewData: {}
    };
  },
  created: function created() {
    this.getList(); //获取数据列表
  },
  mounted: function mounted() {
    this.getDictItemsByCode();
  },
  methods: {
    showAddDialogClose: function showAddDialogClose() {
      this.$refs.Diadlog.closePage();
      this.showAddDialog = false;
    },
    getDictItemsByCode: function getDictItemsByCode() {
      var _this = this;
      //获取险种类型
      dictRequest.getDictItemsByCode("RISK_TYPE").then(function (res) {
        if (res.data) {
          _this.riskTypeList = res.data;
        }
      });
    },
    getList: function getList() {
      var _this2 = this;
      //获取数据列表
      this.loading = true;
      var _this$listQuery = this.listQuery,
        pageNum = _this$listQuery.pageNum,
        pageSize = _this$listQuery.pageSize;
      drivingInsuranceClassify.getList(pageNum, pageSize, this.searchForm).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = res.data && res.data.list ? res.data.list : [];
          _this2.total = res.data.page.recordsTotal;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //表格选中操作
      this.multipleSelection = val;
    },
    batchDeletion: function batchDeletion() {
      var _this3 = this;
      //批量删除
      this.$confirm('是否确认删除, 确认删除后不能恢复?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this3.multipleSelection) {
          var ids = [];
          _this3.multipleSelection.map(function (item) {
            ids.push(item.accidentId);
          });
          _this3.deleteData(ids);
        }
        // 暂时缺接口
      }).catch(function () {});
    },
    resetSearch: function resetSearch() {
      // 重置
      this.searchForm = {
        insCorpCode: '',
        riskProductName: ''
      };
      this.getList();
    },
    searchData: function searchData() {
      // 查询
      this.getList();
    },
    showAddDialogClicked: function showAddDialogClicked(type) {
      //新增
      this.showAddDialog = true;
      this.DialogType = type;
    },
    // 查看
    toView: function toView(type, row) {
      this.DialogType = type;
      this.viewData = row;
      this.showAddDialog = true;
    },
    // 编辑
    editConfig: function editConfig(type, row) {
      this.DialogType = type;
      this.viewData = row;
      this.showAddDialog = true;
    },
    updateItem: function updateItem(row) {//是否启用
    },
    deleteRoute: function deleteRoute(row) {
      var _this4 = this;
      // 删除二次确认
      this.$confirm('是否删除当前数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.deleteData([row.accidentId]);
      }).catch(function () {});
    },
    deleteData: function deleteData(ids) {
      var _this5 = this;
      // 删除api
      drivingInsuranceClassify.deleteData(ids).then(function (res) {
        if (res.code == 200) {
          _this5.$message.success(res.msg);
        }
        _this5.getList();
      });
    },
    // 关闭弹窗
    loadData: function loadData() {
      this.showAddDialog = false;
      this.DialogType = null;
      this.getList();
    }
  }
};