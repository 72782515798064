/**
 * 机构业务配置接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var openApiHost = process.env.VUE_APP_FLAG == 'dev' ? 'http://openapi.test.bandins.cn' : process.env.VUE_APP_FLAG == 'uat' ? 'http://bxopenapitest.ftms.com.cn' : 'http://bxopenapi.ftms.com.cn';
var objApi = {
  /**
   * 保单附件批单下载
   * @param {*} data 内容
   */
  policyBatchDownloadFile: function policyBatchDownloadFile(policyId) {
    return request({
      method: 'get',
      url: getUrl("/nonauto/policy/batchDownloadFile?policyId=".concat(policyId), 16)
      // responseType: 'blob'
    });
  },

  /**
   * 保单合同附件批单下载
   * @param {*} data 内容
   */
  policyBatchDownloadContract: function policyBatchDownloadContract(policyId) {
    return request({
      method: 'get',
      url: getUrl("/nonauto/policy/batchDownloadContract?policyId=".concat(policyId), 16)
      // responseType: 'blob'
    });
  },

  /**
   * 订单附件批单下载
   * @param {*} data 内容
   */
  orderBatchDownloadFile: function orderBatchDownloadFile(orderId) {
    return request({
      method: 'get',
      url: getUrl("/order/batchDownloadFile?orderId=".concat(orderId), 16)
      // responseType: 'blob'
    });
  },

  /**
   * 产品字段管理列表
   * @param {*} data 内容
   */
  getProductInfotList: function getProductInfotList(data) {
    return request.get(getUrl("/product/attribute/search", 16), {
      params: data
    });
  },
  /**
   * 产品字段管理新增
   * @param {*} data 内容
   */
  getProducInfoAdd: function getProducInfoAdd(data) {
    return request.post(getUrl("/product/attribute/add", 16), data);
  },
  /**
   * 产品字段管理删除
   * @param {*} data 内容
   */
  getProducInfoDel: function getProducInfoDel(ids) {
    return request.delete(getUrl("/product/attribute/delete?attributeId=".concat(ids), 16));
  },
  /**
   * 产品字段管理编辑
   * @param {*} data 内容
   */
  getProducInfoEdit: function getProducInfoEdit(data) {
    return request.put(getUrl("/product/attribute/update", 16), data);
  },
  /**
   * 获取产品分类列表（分页）
   * @param {*} data 内容
   */
  getClassList: function getClassList(data) {
    return request.get(getUrl("/product/class/search", 16), {
      params: data
    });
  },
  /**
   * 获取产品分类新增
   * @param {*} data 内容
   */
  getClassAdd: function getClassAdd(data) {
    return request.post(getUrl("/product/class/add", 16), data);
  },
  /**
   * 获取产品分类详情
   * @param {*} data 内容
   */
  getClassDetail: function getClassDetail(data) {
    return request.get(getUrl("/product/class/detail", 16), {
      params: data
    });
  },
  /**
   * 获取产品分类编辑
   * @param {*} data 内容
   */
  getClassUpdate: function getClassUpdate(data) {
    return request.put(getUrl("/product/class/update", 16), data);
  },
  /**
   * 获取产品分类删除
   * @param {*} data 内容
   */
  getClassDel: function getClassDel(id) {
    return request.delete(getUrl("/product/class/delete?classId=".concat(id), 16));
  },
  /**
   * 获取产品分类列表用于产品管理页面的Tab
   * @param {*} data 内容
   */
  getClassTab: function getClassTab() {
    return request.get(getUrl("/product/class/list", 16));
  },
  /**
   * 查询产品分类列表，代步车替换为代步车子项数据
   * @param {*} data 内容
   */
  getProductClassListIncludeDBC: function getProductClassListIncludeDBC() {
    return request.get(getUrl("/product/class/getListIncludeDBC", 16));
  },
  /**
   * 根据产品分类编码获取产品所有属性
   * @param {*} data 内容
   */
  getPOAttributeList: function getPOAttributeList(data) {
    return request.get(getUrl("/product/class/getAttributeByClassCode", 16), {
      params: data
    });
  },
  /**
   * 根据产品分类编码获取订单所有属性
   * @param {*} data 内容
   */
  getFileTypeList: function getFileTypeList(data) {
    return request.get(getUrl("/product/class/getFileType", 16), {
      params: data
    });
  },
  /**
   * 根据产品分类编码获取产品类型的字典数据
   * @param {*} data 内容
   */
  getProductTypeDict: function getProductTypeDict(data) {
    return request.get(getUrl("/product/class/getProductTypeDict", 16), {
      params: data
    });
  },
  /**
   * 根据产品分类编码获取理赔所有属性
   * @param {*} data 内容
   */
  getClaimAttributeList: function getClaimAttributeList(data) {
    return request.get(getUrl("/product/class/getClaimAttributeByClassCode", 16), {
      params: data
    });
  },
  /**
   * 修改产品状态
   * @param {*} data 内容
   */
  getProductChangeStatus: function getProductChangeStatus(data) {
    return request.put(getUrl("/product/updateStatus", 16), data);
  },
  /**
   * 产品列表
   * @param {*} data 内容
   */
  getProductList: function getProductList(data) {
    return request.get(getUrl("/product/search", 16), {
      params: data
    });
  },
  /**
   * 新增产品
   * @param {*} data 内容
   */
  getProductAdd: function getProductAdd(data) {
    return request.post(getUrl("/product/add", 16), data);
  },
  /**
   * 编辑产品
   * @param {*} data 内容
   */
  getProductUpdate: function getProductUpdate(data) {
    return request.put(getUrl("/product/update", 16), data);
  },
  /**
   * 获取产品详情
   * @param {*} data 内容
   */
  getProductDetail: function getProductDetail(id) {
    return request.get(getUrl("/product/detail?productId=".concat(id), 16));
  },
  /**
   * 通过Code获取产品详情
   * @param {*} data 内容
   */
  getDetailByProductCode: function getDetailByProductCode(code) {
    return request.get(getUrl("/product/getByProductCode?productCode=".concat(code), 16));
  },
  /**
   * 删除产品
   * @param {*} data 内容
   */
  getProductDel: function getProductDel(id) {
    return request.delete(getUrl("/product/delete?productId=".concat(id), 16));
  },
  // 订单模块
  /**
   * 获取订单列表
   * @param {*} data 内容
   */
  getOrderList: function getOrderList(data) {
    return request.get(getUrl("/order/search", 16), {
      params: data
    });
  },
  /**
   * 获取修复订单列表
   * @param {*} data 内容
   */
  getOrderRepairList: function getOrderRepairList(data) {
    return request.get(getUrl("/repair/search", 16), {
      params: data
    });
  },
  /**
  * 批量注销
  * @param {*} data 内容
  */
  orderTerminate: function orderTerminate(data) {
    return request.post(getUrl("/repair/terminate", 16), data);
  },
  /**
  * 批量出单
  * @param {*} data 内容
  */
  orderInsure: function orderInsure(data) {
    return request.post(getUrl("/repair/insure", 16), data);
  },
  /**
  * 红色通知单号
  * @param {*} data 内容
  */
  cancelinputRedInvoice: function cancelinputRedInvoice(data) {
    return request.post(getUrl("/nonauto/cancel/inputRedInvoice", 16), data);
  },
  /**
   * 获取订单列表All,list页面使用的
   * @param {*} data 内容
   */
  getOrderAllList: function getOrderAllList(data) {
    return request.get(getUrl("/order/searchAll", 16), {
      params: data
    });
  },
  /**
   * 根据车牌号查询客户信息
   * @param {*} data 内容
   */
  getByPlateNoFromVin: function getByPlateNoFromVin(data) {
    return request.get(getUrl("/coreCustomer/getByPlateNoOrVin", 1), {
      params: data
    });
  },
  /**
   * 订单出单接口
   * @param {*} data 内容
   */
  getOrderAdd: function getOrderAdd(orderId) {
    return request.post(getUrl("/order/add?orderId=".concat(orderId), 16), {});
  },
  /**
   * 订单出单确认
   * @param {*} data 内容
   */
  orderConfirm: function orderConfirm(orderId) {
    return request.post(getUrl("/order/confirm?orderId=".concat(orderId), 16), {});
  },
  /**
   * 订单详情
   * @param {*} data 内容
   */
  getOrderDetail: function getOrderDetail(id) {
    return request.get(getUrl("/order/detail?orderId=".concat(id), 16));
  },
  /**
   * 订单详情（运维端）
   * @param {*} data 内容
   */
  getOrderDetailYW: function getOrderDetailYW(id) {
    return request.get(getUrl("/order/operationsOrderDetail?orderId=".concat(id), 16));
  },
  /**
   * 订单删除
   * @param {*} data 内容
   */
  getOrderDel: function getOrderDel(id) {
    return request.delete(getUrl("/order/delete?orderId=".concat(id), 16));
  },
  /**
   * 订单刷新
   * @param {*} data 内容
   */
  refreshOrderStatus: function refreshOrderStatus(id) {
    return request.get(getUrl("/order/refresh?orderId=".concat(id), 16));
  },
  /**
   * 获取协议模板
   * @param {*} data 内容
   */
  getProtocolTepm: function getProtocolTepm(code) {
    return request.get(getUrl("/product/getProtocolTepm?productCode=".concat(code), 16));
  },
  /**
   * 订单上传协议
   * @param {*} data 内容
   */
  uploadProtocol: function uploadProtocol(data) {
    return request.post(getUrl("/order/uploadProtocol", 16), data);
  },
  /**
   * 匹配产品
   * @param {*} data 内容
   */
  getMatchProduct: function getMatchProduct(data) {
    return request.get(getUrl("/product/match", 16), {
      params: data
    });
  },
  // **********************************代步车自动出单API**********************************

  /**
   * 代步车自动出单--文件监控
   * @param {*} data 内容
   */
  DBCFileSearch: function DBCFileSearch(data) {
    return request.get(getUrl("/ftms/nonautoAutoInsure/file/search", 11), {
      params: data
    });
  },
  /**
   * 代步车自动出单--文件下载
   * @param {*} data 内容
   */
  DBCDownloadFile: function DBCDownloadFile(id) {
    return request({
      method: 'get',
      url: getUrl("/ftms/nonautoAutoInsure/file/downloadFile?fileId=".concat(id), 11),
      responseType: 'blob'
    });
  },
  /**
   * 代步车自动出单--出单监控
   * @param {*} data 内容
   */
  DBCIssue: function DBCIssue(data) {
    return request.get(getUrl("/ftms/nonautoAutoInsure/search", 11), {
      params: data
    });
  },
  /**
   * 代步车自动出单--重新出单
   * @param {*} data 内容
   */
  DBCAfreshInsure: function DBCAfreshInsure(id) {
    return request.post(getUrl("/ftms/nonautoAutoInsure/afreshInsure?orderAutoInsureId=".concat(id), 11));
  },
  /**
   * 代步车自动出单--更改供应商出单 （保司重出）
   * @param {*} data 内容
   */
  DBCSupplierAfreshInsure: function DBCSupplierAfreshInsure(id) {
    return request.post(getUrl("/ftms/nonautoAutoInsure/changeSupplierInsure?orderAutoInsureId=".concat(id), 11));
  },
  /**
   * 代步车自动出单--更改组织机构出单（总店重出）
   * @param {*} data 内容
   */
  DBCOrgAfreshInsure: function DBCOrgAfreshInsure(id) {
    return request.post(getUrl("/ftms/nonautoAutoInsure/changeOrgInsure?orderAutoInsureId=".concat(id), 11));
  },
  // =================================非车业务统计（报表）==================================================
  /**
   * 查询非车险业务统计-出单总揽-汇总统计
   * @param {*} data 内容
   */
  getStatisticsTotal: function getStatisticsTotal(data) {
    return request.get(getUrl("/nonauto/policy/statistics/total", 16), {
      params: data
    });
  },
  /**
   * 查询非车险业务统计-出单总揽-每日统计
   * @param {*} data 内容
   */
  getStatisticsByDay: function getStatisticsByDay(data) {
    return request.get(getUrl("/nonauto/policy/statistics/day", 16), {
      params: data
    });
  },
  /**
   * 查询非车险业务统计-top排行榜-出单量统计（F收入贡献2023-01-11调整后）
   * @param {*} data 内容
   */
  getStatisticsByTopCount: function getStatisticsByTopCount(data) {
    return request.get(getUrl("/nonauto/policy/statistics/topCount", 16), {
      params: data
    });
  },
  /**
   * 查询非车险业务统计-top排行榜-出单保费统计（F收入贡献2023-01-11调整前）
   * @param {*} data 内容
   */
  getStatisticsByTopPremium: function getStatisticsByTopPremium(data) {
    return request.get(getUrl("/nonauto/policy/statistics/topPremium", 16), {
      params: data
    });
  },
  /**
   * 查询非车险业务统计-top排行榜-D收益统计
   * @param {*} data 内容
   */
  getStatisticsByTopProfitD: function getStatisticsByTopProfitD(data) {
    return request.get(getUrl("/nonauto/policy/statistics/topProfitD", 16), {
      params: data
    });
  },
  /**
   * 查询非车险业务统计-top排行榜-F收益贡献
   * @param {*} data 内容
   */
  getStatisticsByTopProfitF: function getStatisticsByTopProfitF(data) {
    return request.get(getUrl("/nonauto/policy/statistics/topProfitF", 16), {
      params: data
    });
  },
  /**
   * 查询非车险业务统计-赔付率统计接口
   * @param {*} data 内容
   */
  getClaimCompensateStatistics: function getClaimCompensateStatistics(data) {
    return request.get(getUrl("/nonauto/policy/statistics/claimCompensate", 16), {
      params: data
    });
  },
  /**
   * 查询非车险业务统计-服务类型业务统计
   * @param {*} data 内容
   */
  getStatisticsByProductClass: function getStatisticsByProductClass(data) {
    return request.get(getUrl("/nonauto/policy/statistics/productClass", 16), {
      params: data
    });
  },
  //  **********************************保单管理************************************

  /**
   * 分页列表查询
   * @param {*} data 内容
   */
  getNonAutoPolicyList: function getNonAutoPolicyList(data) {
    return request.get(getUrl("/nonauto/policy/search", 16), {
      params: data
    });
  },
  /**
   * 保单录入
   * @param {*} data 内容
   */
  addNonAutoPolicy: function addNonAutoPolicy(data) {
    return request.post(getUrl("/nonauto/policy/create", 16), data);
  },
  /**
   * 获取保单详情
   * @param {*} id
   */
  getNonAutoPolicyDetail: function getNonAutoPolicyDetail(id) {
    return request.get(getUrl("/nonauto/policy?policyId=".concat(id), 16));
  },
  /**
   * 保单删除
   * @param {*} id
   */
  deleteNonAutoPolicy: function deleteNonAutoPolicy(id) {
    return request.delete(getUrl("/nonauto/policy/delete?policyIds=".concat(id), 16));
  },
  //  **********************************退单管理************************************

  /**
   * 分页列表查询
   * @param {*} data 内容
   */
  getNonAutoCancelList: function getNonAutoCancelList(data) {
    return request.get(getUrl("/nonauto/cancel/search", 16), {
      params: data
    });
  },
  /**
   * 退单和退单审核时获取的保单详情
   * @param {*} data 内容
   */
  getNonAutoDetail: function getNonAutoDetail(id) {
    return request.get(getUrl("/nonauto/cancel?policyCancelId=".concat(id), 16));
  },
  /**
   * 根据车架号查询 可退单保单
   * @param {*} data 内容
   */
  queryNonAutoCancelList: function queryNonAutoCancelList(data) {
    return request.get(getUrl("/nonauto/policy/cancel/vin", 16), {
      params: data
    });
  },
  /**
   * 检查是否可以注销
   * @param {*} data 内容
   */
  verifyNonAutoCancel: function verifyNonAutoCancel(data) {
    return request.post(getUrl("/nonauto/cancel/verify", 16), data);
  },
  /**
   * 退单删除
   * @param {*} id
   */
  deleteNonAutoCancelPolicy: function deleteNonAutoCancelPolicy(id) {
    return request.delete(getUrl("/nonauto/cancel/delete?policyCancelIds=".concat(id), 16));
  },
  /**
   * 退单申请
   * @param {*} data
   */
  createNonAutoCancelPolicy: function createNonAutoCancelPolicy(data) {
    return request.post(getUrl("/nonauto/cancel/create", 16), data);
  },
  //============= 审核人员配置==========
  /**
   * 列表
   * @param {*} data 内容
   */
  getInscorpauditorconfigList: function getInscorpauditorconfigList(data) {
    return request.get(getUrl("/inscorpauditorconfig/search", 16), {
      params: data
    });
  },
  /**
   * 新建
   * @param {*} data 内容
   */
  getInscorpauditorconfigCreate: function getInscorpauditorconfigCreate(data) {
    return request.post(getUrl("/inscorpauditorconfig/create", 16), data);
  },
  /**
   * 编辑
   * @param {*} data 内容
   */
  getInscorpauditorconfigUpdate: function getInscorpauditorconfigUpdate(data) {
    return request.post(getUrl("/inscorpauditorconfig/update", 16), data);
  },
  /**
   * 删除
   * @param {*} data 内容
   */
  getInscorpauditorconfigDel: function getInscorpauditorconfigDel(ids) {
    return request.delete(getUrl("/inscorpauditorconfig/batchDelete/".concat(ids), 16));
  },
  /**
   * 查一个详情
   * @param {*} data 内容
   */
  getInscorpauditorconfigDetail: function getInscorpauditorconfigDetail(data) {
    return request.get(getUrl("/inscorpauditorconfig/get", 16), {
      params: data
    });
  },
  /**
   * 主机厂审批
   * @param {*} data 内容
   */
  getNonautoAudit: function getNonautoAudit(data) {
    return request.put(getUrl("/nonauto/cancel/audit", 16), data);
  },
  //---------------------------非车险供应商配置服务---------------------------////
  /**
  * 非车险供应商配置服务（分页）
  * @param {*} data 内容
  */
  getSupplierServiceConfig: function getSupplierServiceConfig(data) {
    return request.get(getUrl("/SupplierServiceConfig/search", 18), {
      params: data
    });
  },
  /**
  * 创建配置
  * @param {*} data 内容
  */
  SupplierServiceConfigAdd: function SupplierServiceConfigAdd(data) {
    return request.post(getUrl("/SupplierServiceConfig/create", 18), data);
  },
  /**
  * 删除配置
  * @param {*} data 内容
  */
  SupplierServiceConfigDel: function SupplierServiceConfigDel(id) {
    return request.delete(getUrl("/SupplierServiceConfig/delete?id=".concat(id), 18));
  },
  /**
  * 编辑配置
  * @param {*} data 内容
  */
  SupplierServiceConfigUpdate: function SupplierServiceConfigUpdate(id, data) {
    return request.put(getUrl("/SupplierServiceConfig/update?id=".concat(id), 18), data);
  },
  /**
  * 供应商服务配置列表
  * @param {*} data 内容
  */
  getSupplierServiceConfigList: function getSupplierServiceConfigList() {
    return request.get(getUrl("/SupplierServiceConfig/getList", 18));
  },
  /**
  * 获取供应商和产品
  * @param {*} data 内容
  */
  getSupplierProductList: function getSupplierProductList(id) {
    return request.get(getUrl("/configSchema/getSupplierProduct?id=".concat(id), 18));
  },
  /**
  * 获取供应商产品设置
  * @param {*} data 内容
  */
  getSupplierProductSchemaList: function getSupplierProductSchemaList(data) {
    return request.get(getUrl("/configSchema/getSupplierProductSchema", 18), {
      params: data
    });
  },
  /**
  * 获取供应商产品配置列表（分页）
  * @param {*} data 内容
  */
  getSupplierProductConfig: function getSupplierProductConfig(data) {
    return request.get(getUrl("/supplierProductConfig/search", 18), {
      params: data
    });
  },
  /**
  * 供应商产品新增
  * @param {*} data 内容
  */
  supplierProductConfigAdd: function supplierProductConfigAdd(data) {
    return request.post(getUrl("/supplierProductConfig/add", 18), data);
  },
  /**
  * 供应商产品编辑
  * @param {*} data 内容
  */
  supplierProductConfigUpdate: function supplierProductConfigUpdate(data) {
    return request.put(getUrl("/supplierProductConfig/update", 18), data);
  },
  /**
  *供应商产品删除
  * @param {*} data 内容
  */
  supplierProductConfigDel: function supplierProductConfigDel(id) {
    return request.delete(getUrl("/supplierProductConfig/delete?supplierProductConfigId=".concat(id), 18));
  },
  /**
  * 根据id查询配置详情
  * @param {*} data 内容
  */
  getSupplierProductDetail: function getSupplierProductDetail(id) {
    return request.get(getUrl("/supplierProductConfig/detail?supplierProductConfigId=".concat(id), 18));
  },
  /**
  * 启用禁用
  * @param {*} data 内容
  */
  supplierProductConfigEnable: function supplierProductConfigEnable(data) {
    return request.post(getUrl("/supplierProductConfig/enable", 18), data);
  },
  // =================================理赔接口==================================================

  /**
  * 获取理赔列表（分页）
  * @param {*} data 内容
  */
  getClaimsList: function getClaimsList(data) {
    return request.get(getUrl("/claim/search", 16), {
      params: data
    });
  },
  /**
  * 搜索理赔
  * @param {*} data 内容
  */
  getPolicySelectByVin: function getPolicySelectByVin(vin) {
    return request.get(getUrl("/nonauto/policy/getPolicySelectByVin?vin=".concat(vin), 16));
  },
  /**
  * 根据id查询理赔详情
  * @param {*} data 内容
  */
  getClaimDetail: function getClaimDetail(id) {
    return request.get(getUrl("/claim/detail?claimId=".concat(id), 16));
  },
  /**
  * 新增理赔申请
  * @param {*} data 内容
  */
  ClaimApplyAdd: function ClaimApplyAdd(claimId) {
    return request.post(getUrl("/claim/add?claimId=".concat(claimId), 16));
  },
  /**
  * 新增理赔暂存
  * @param {*} data 内容
  */
  ClaimApplySave: function ClaimApplySave(data) {
    return request.post(getUrl("/claim/save", 16), data);
  },
  /**
  * 新增理赔获取暂存
  * @param {*} data 内容
  */
  ClaimApplyGetExtra: function ClaimApplyGetExtra(data) {
    return request.get(getUrl("/claim/getExtra", 16), {
      params: data
    });
  },
  /**
  * 三保获取理赔附件
  * @param {*} data 内容
  */
  getClaimFileTypeList: function getClaimFileTypeList(code) {
    return request.get(getUrl("/claim/getClaimFileTypeList?planCode=".concat(code), 16));
  },
  /**
  * 理赔刷新
  * @param {*} data 内容
  */
  claimApplyRefresh: function claimApplyRefresh(data) {
    return request.get(getUrl("/claim/refresh", 16), {
      params: data
    });
  },
  /**
  * 获取理赔银行列表
  * @param {*} data 内容
  */
  getBankList: function getBankList(data) {
    return request.get(getUrl("/claim/getBankList", 16), {
      params: data
    });
  },
  /**
  * 理赔撤销
  * @param {*} data 内容
  */
  claimApplyTerminate: function claimApplyTerminate(data) {
    return request.post(getUrl("/claim/terminate", 16), data);
  },
  /**
  * 理赔删除
  * @param {*} data 内容
  */
  claimDelete: function claimDelete(claimId) {
    return request.delete(getUrl("/claim/delete?claimId=".concat(claimId), 16));
  },
  // 退单人员管理接口
  /**
  * 获取退单审核人员列表（分页）
  * @param {*} data 内容
  */
  getAuditorsList: function getAuditorsList(data) {
    return request.get(getUrl("/inscorpAuditorConfig/search", 19), {
      params: data
    });
  },
  /**
  * 退单审核人员新增
  * @param {*} data 内容
  */
  AuditorsAdd: function AuditorsAdd(data) {
    return request.post(getUrl("/inscorpAuditorConfig/add", 19), data);
  },
  /**
  * 退单审核人员编辑
  * @param {*} data 内容
  */
  AuditorsUpdate: function AuditorsUpdate(data) {
    return request.put(getUrl("/inscorpAuditorConfig/update", 19), data);
  },
  /**
  * 退单审核人员删除
  * @param {*} data 内容
  */
  AuditorsDel: function AuditorsDel(id) {
    return request.delete(getUrl("/inscorpAuditorConfig/delete?insCorpConfigId=".concat(id), 19));
  },
  /**
  * 退单审核人员启用禁用
  * @param {*} data 内容
  */
  AuditorsEnable: function AuditorsEnable(data) {
    return request.put(getUrl("/inscorpAuditorConfig/enable", 19), data);
  },
  /**
  * 退单审核人员详情
  * @param {*} data 内容
  */
  AuditorsDetail: function AuditorsDetail(id) {
    return request.get(getUrl("/inscorpAuditorConfig/detail?insCorpConfigId=".concat(id), 19));
  },
  /**
  * 获取供应商列表
  * @param {*} data 内容
  */
  getSuppliersList: function getSuppliersList(code) {
    return request.get(getUrl("/configSchema/getSupplierList?productClassifyCode=".concat(code), 19));
  },
  /**
  * 获取供应商列表
  * @param {*} data 内容
  */
  getAllSuppliersList: function getAllSuppliersList() {
    return request.get(getUrl("/configSchema/getSupplierList", 19));
  },
  // ===============================================退单审批记录=========================================

  /**
    * 审核记录列表
    * @param {*} data 内容
    */
  getInsCorpAuditList: function getInsCorpAuditList(data) {
    return request.get(getUrl("/nonauto/insCorpAudit/search", 19), {
      params: data
    });
  },
  /**
  * 再次推送通知(当通知状态为失败时调用)
  * @param {*} data 内容
  */
  againPushInform: function againPushInform(data) {
    return request.put(getUrl("/nonauto/insCorpAudit/againPushInform", 19), data);
  },
  // ===============================================合同预览=========================================
  /**
    * 合同预览
    * @param {*} data 内容
    */
  contractPreview: function contractPreview(data) {
    return request.post(getUrl("/contractPreview/preview", 16), data);
  },
  // ===============================================退单审批H5页面接口=========================================
  /**
  * 保司审批人员登录
  * @param {*} data 内容
  */
  insCorpAuditLogin: function insCorpAuditLogin(data) {
    return request.post(openApiHost + "/open/nonauto/insCorpAudit/token", data);
  },
  /**
  * 获取所有待审核列表
  * @param {*} data 内容
  */
  insCorpAuditCancelList: function insCorpAuditCancelList(data) {
    return request.get(openApiHost + "/open/nonauto/insCorpAudit/list", {
      params: data
    });
  },
  /**
  * 根据撤单号查询详情
  * @param {*} data 内容
  */
  // insCorpAuditCancelDetail: function (cancelNo) {
  //     return request.get(openApiHost + `/open/nonauto/insCorpAudit/cancelNo?cancelNo=${cancelNo}`)
  // },
  /**
  * 根据撤单号查询详情
  * @param {*} data 内容
  */
  insCorpAuditCancelDetail: function insCorpAuditCancelDetail(id) {
    return request.get(openApiHost + "/open/nonauto/insCorpAudit/auditDetail?auditRecordId=".concat(id));
  },
  /**
  * 退单审批
  * @param {*} data 内容
  */
  CancelDetailAudit: function CancelDetailAudit(data) {
    return request.put(openApiHost + "/open/nonauto/insCorpAudit/audit", data);
  },
  // ===============================================通过车架号获取最新保单信息=========================================
  /**
  * 通过车架号获取最新保单信息
  * @param {*} data 内容
  */
  getPolicyByVin: function getPolicyByVin(data) {
    return request.post(getUrl("/new/policy/newest/getByVin", 1), data);
  },
  /**
   * 订单暂存
   * @param {*} data 内容
   */
  orderSave: function orderSave(data) {
    return request.post(getUrl("/order/save", 16), data);
  },
  /**
   * 获取订单暂存数据
   * @param {*} data 内容
   */
  getOrderExtra: function getOrderExtra(orderId) {
    return request.get(getUrl("/order/getExtra?orderId=".concat(orderId), 16));
  },
  /**
  * 重新理赔申请
  * @param {*} data 内容
  */
  claimApplyAgain: function claimApplyAgain(data) {
    return request.post(getUrl("/claim/again?claimId=".concat(data.claimId), 16), data);
  },
  /**
  * 大修小修理赔附件
  * @param {*} data 内容
  */
  getNewProductClaimFile: function getNewProductClaimFile(data) {
    return request.get(getUrl("/claim/getClaimFile", 16), {
      params: data
    });
  },
  /**
  * 理赔合同预览
  * @param {*} data 内容
  */
  templatePreview: function templatePreview(code, data) {
    return request.post(getUrl("/claim/preview?templateCode=".concat(code), 16), data);
  }
};
export default objApi;