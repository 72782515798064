//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import requestApi from '@/api/interactPlatform';
import dictionary from '@/api/dictionary';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    dataConfigId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      form: {
        dataSource: '',
        dataConfigName: '',
        dataConfigCode: ''
      },
      rules: {
        dataSource: {
          required: true,
          message: '请选择数据来源',
          trigger: "change"
        },
        dataConfigName: {
          required: true,
          message: '请输入数据名称',
          trigger: "blur"
        },
        dataConfigCode: {
          required: true,
          message: '请输入数据编码',
          trigger: "blur"
        }
      },
      // SYSTEM_MICRO_SERVICE
      systemMmicroServiceList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    getSystemMmicroService: function getSystemMmicroService() {
      var _this = this;
      dictionary.getDictItemsByCode('SYSTEM_MICRO_SERVICE').then(function (res) {
        if (res.code === 200) {
          _this.systemMmicroServiceList = res.data ? res.data : [];
        }
      });
    },
    initData: function initData() {
      var _this2 = this;
      this.getSystemMmicroService();
      this.form = {
        dataSource: '',
        dataConfigName: '',
        dataConfigCode: ''
      };
      if (this.opType === "edit" || this.opType === 'copy') {
        requestApi.getData(this.dataConfigId).then(function (res) {
          if (res.code === 200) {
            _this2.form = res.data;
          }
          ;
        });
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this3 = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          var data = {};
          if (_this3.opType == 'edit') {
            data = {
              dataConfigId: _this3.dataConfigId,
              dataConfigCode: _this3.form.dataConfigCode,
              dataConfigName: _this3.form.dataConfigName,
              dataSource: _this3.form.dataSource
            };
          }
          ;
          var req = _this3.opType === 'add' || _this3.opType === 'copy' ? requestApi.addData(_this3.form) : requestApi.updateData(data);
          req.then(function (res) {
            if (res.code === 200) {
              _this3.$message({
                message: res.msg,
                type: 'success'
              });
              _this3.closePage();
            }
            ;
          });
        }
        ;
      });
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    }
  }
};