var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageContainer",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.fullscreenLoading,
              expression: "fullscreenLoading",
            },
          ],
        },
        [
          _c("div", { attrs: { slot: "main" }, slot: "main" }, [
            _c(
              "div",
              {
                staticClass: "app-m-cls",
                staticStyle: { "min-height": "calc(100vh - 130px)" },
              },
              [
                _c(
                  "div",
                  { ref: "header", staticClass: "header-cls" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.form,
                          rules: _vm.formRules,
                          "label-width": "140px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeOne,
                              callback: function ($$v) {
                                _vm.activeOne = $$v
                              },
                              expression: "activeOne",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "出单人员" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("IssuingPerson", {
                                  attrs: {
                                    form: _vm.form,
                                    className: "代步车服务",
                                    orgForm: _vm.orgForm,
                                    productInfo: _vm.productInfo,
                                  },
                                  on: {
                                    handleNodeClicked: _vm.handleNodeClicked,
                                  },
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTwo,
                              callback: function ($$v) {
                                _vm.activeTwo = $$v
                              },
                              expression: "activeTwo",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "车辆信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.form.isNewCar,
                                                expression: "form.isNewCar",
                                              },
                                            ],
                                            attrs: {
                                              placement: "bottom-end",
                                              effect: "light",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-size": "10px",
                                                },
                                                attrs: { slot: "content" },
                                                slot: "content",
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    "未上牌车辆默认车架号后五位"
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "车牌号：",
                                                  labelWidth: "170px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      ref: "plateNo",
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                      attrs: {
                                                        minlength: "7",
                                                        maxlength: "8",
                                                        placeholder:
                                                          "请输入车牌号",
                                                        clearable: "",
                                                        disabled: "",
                                                      },
                                                      model: {
                                                        value: _vm.form.plateNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "plateNo",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.plateNo",
                                                      },
                                                    }),
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        on: {
                                                          change:
                                                            _vm.noPlateNoChange,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.isNewCar,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "isNewCar",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.isNewCar",
                                                        },
                                                      },
                                                      [_vm._v("未上牌")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        !_vm.form.isNewCar
                                          ? _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "车牌号：",
                                                  prop: "plateNo",
                                                  labelWidth: "170px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      ref: "plateNo",
                                                      staticStyle: {
                                                        "margin-right": "10px",
                                                      },
                                                      attrs: {
                                                        minlength: "7",
                                                        maxlength: "8",
                                                        placeholder:
                                                          "请输入车牌号",
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        input: _vm.inputPlateNo,
                                                      },
                                                      model: {
                                                        value: _vm.form.plateNo,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "plateNo",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.plateNo",
                                                      },
                                                    }),
                                                    _c(
                                                      "el-checkbox",
                                                      {
                                                        on: {
                                                          change:
                                                            _vm.noPlateNoChange,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form.isNewCar,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "isNewCar",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.isNewCar",
                                                        },
                                                      },
                                                      [_vm._v("未上牌")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 16 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "车架号：",
                                              prop: "vin",
                                              labelWidth: "170px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    maxlength: "17",
                                                    placeholder: "请输入车架号",
                                                    clearable: "",
                                                  },
                                                  on: {
                                                    input: _vm.checkValue,
                                                    blur: _vm.vincheck,
                                                  },
                                                  model: {
                                                    value: _vm.form.vin,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "vin",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.vin",
                                                  },
                                                }),
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: _vm.searching,
                                                        expression: "searching",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      disabled: "",
                                                      size: "mini",
                                                      type: "primary",
                                                      plain: "",
                                                      icon: "el-icon-loading",
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("同步中···"),
                                                    ]),
                                                  ]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.searching,
                                                        expression:
                                                          "!searching",
                                                      },
                                                    ],
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      size: "mini",
                                                      type: "primary",
                                                      plain: "",
                                                      icon: "el-icon-search",
                                                    },
                                                    on: {
                                                      click: _vm.searchVehicle,
                                                    },
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("同步车辆信息"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "发动机号：",
                                              prop: "engineNo",
                                              labelWidth: "170px",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                maxlength: "15",
                                                placeholder: "请输入发动机号",
                                                clearable: "",
                                              },
                                              on: { input: _vm.inputEngineNo },
                                              model: {
                                                value: _vm.form.engineNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "engineNo",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "form.engineNo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "厂家品牌车系：",
                                              prop: "carTypeValue",
                                              "validate-on-rule-change": true,
                                              labelWidth: "170px",
                                            },
                                          },
                                          [
                                            _c("CarBrands", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                carTypeValue:
                                                  _vm.form.carTypeValue,
                                              },
                                              on: {
                                                carHandleChanged: function (
                                                  $event
                                                ) {
                                                  return _vm.CarHandleChange(
                                                    $event,
                                                    false
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      _vm.attributeList,
                                      function (item, index) {
                                        return _c(
                                          "el-col",
                                          { key: index, attrs: { span: 8 } },
                                          [
                                            item.inputType == "TEXT"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        "：",
                                                      labelWidth: "170px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请输入" +
                                                            item.attributeName,
                                                          trigger: "blur",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            item.dateType ==
                                                            "INTEGER",
                                                          expression:
                                                            "item.dateType=='INTEGER'",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请输入" +
                                                          item.attributeName,
                                                        disabled: item.readonly,
                                                        maxlength:
                                                          item.maxValue &&
                                                          item.maxValue.toString()
                                                            .length,
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.DECIMALS_maxValueInput(
                                                            $event,
                                                            item
                                                          )
                                                        },
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.limitInput4(
                                                            $event,
                                                            item.attributeCode
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[item.attributeCode]",
                                                      },
                                                    }),
                                                    _c("el-input", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            item.dateType ==
                                                            "DECIMALS",
                                                          expression:
                                                            "item.dateType=='DECIMALS'",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请输入" +
                                                          item.attributeName,
                                                        disabled: item.readonly,
                                                        maxlength:
                                                          item.maxValue &&
                                                          item.maxValue.toString()
                                                            .length,
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.DECIMALS_maxValueInput(
                                                            $event,
                                                            item
                                                          )
                                                        },
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.limitInput2(
                                                            $event,
                                                            item.attributeCode
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[item.attributeCode]",
                                                      },
                                                    }),
                                                    _c("el-input", {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            item.dateType ==
                                                            "CHARACTER",
                                                          expression:
                                                            "item.dateType=='CHARACTER'",
                                                        },
                                                      ],
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请输入" +
                                                          item.attributeName,
                                                        disabled: item.readonly,
                                                        maxlength:
                                                          item.maxValue &&
                                                          parseInt(
                                                            item.maxValue
                                                          ),
                                                        clearable: "",
                                                      },
                                                      on: {
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.inputAttributeCode(
                                                            $event,
                                                            item.attributeCode
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[item.attributeCode]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "TEXTAREA"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        "：",
                                                      labelWidth: "170px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请输入" +
                                                            item.attributeName,
                                                          trigger: "blur",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        type: "textarea",
                                                        maxlength:
                                                          item.maxValue &&
                                                          parseInt(
                                                            item.maxValue
                                                          ),
                                                        disabled: item.readonly,
                                                        placeholder:
                                                          "请输入" +
                                                          item.attributeName,
                                                        "show-word-limit": "",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[item.attributeCode]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "SELECT"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        "：",
                                                      labelWidth: "170px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    item.attributeCode ==
                                                    "vehicleType"
                                                      ? _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              placement:
                                                                "bottom",
                                                              effect: "light",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "10px",
                                                                },
                                                                attrs: {
                                                                  slot: "content",
                                                                },
                                                                slot: "content",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "新车: 车龄≤3个月,行驶里程≤5000公里的车辆;"
                                                                  ),
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "次新车: 3个月＜车龄≤33个月(部分车型车龄≤45个月),5000公里＜行驶里程≤95000公里的车辆"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%",
                                                                },
                                                                attrs: {
                                                                  disabled:
                                                                    item.readonly,
                                                                  size: "mini",
                                                                  clearable: "",
                                                                  placeholder:
                                                                    "请选择代步车" +
                                                                    item.attributeName,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeAttributeCode(
                                                                        $event,
                                                                        item.attributeCode
                                                                      )
                                                                    },
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form[
                                                                      item
                                                                        .attributeCode
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        item.attributeCode,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "form[item.attributeCode]",
                                                                },
                                                              },
                                                              _vm._l(
                                                                item.options,
                                                                function (
                                                                  citem
                                                                ) {
                                                                  return _c(
                                                                    "el-option",
                                                                    {
                                                                      key: citem.itemId,
                                                                      attrs: {
                                                                        label:
                                                                          citem.itemName,
                                                                        value:
                                                                          citem.itemCode,
                                                                      },
                                                                    }
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "el-select",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                item.readonly,
                                                              size: "mini",
                                                              clearable: "",
                                                              placeholder:
                                                                "请选择" +
                                                                item.attributeName,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.changeAttributeCode(
                                                                  $event,
                                                                  item.attributeCode
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  item
                                                                    .attributeCode
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    item.attributeCode,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[item.attributeCode]",
                                                            },
                                                          },
                                                          _vm._l(
                                                            item.options,
                                                            function (citem) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: citem.itemId,
                                                                  attrs: {
                                                                    label:
                                                                      citem.itemName,
                                                                    value:
                                                                      citem.itemCode,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "MULTI_SELECT"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        "：",
                                                      labelWidth: "170px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        attrs: {
                                                          multiple: "",
                                                          size: "mini",
                                                          clearable: "",
                                                          placeholder:
                                                            "请选择" +
                                                            item.attributeName,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[item.attributeCode]",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.options,
                                                        function (citem) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: citem.itemId,
                                                              attrs: {
                                                                label:
                                                                  citem.itemName,
                                                                value:
                                                                  citem.itemCode,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "DATE"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          item.attributeCode ==
                                                            "targetInvoiceDate" &&
                                                          item.readonly
                                                            ? true
                                                            : false,
                                                        expression:
                                                          "item.attributeCode=='targetInvoiceDate' && item.readonly?true:false",
                                                      },
                                                    ],
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        "：",
                                                      labelWidth: "170px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c("el-date-picker", {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        format: "yyyy-MM-dd",
                                                        disabled: item.readonly,
                                                        "value-format":
                                                          "yyyy-MM-dd",
                                                        type: "date",
                                                        placeholder:
                                                          "请选择" +
                                                          item.attributeName,
                                                        "picker-options":
                                                          item.attributeName ==
                                                          "购车发票日期"
                                                            ? _vm.disabledLessThan
                                                            : "",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[
                                                            item.attributeCode
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            item.attributeCode,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form[item.attributeCode]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.inputType == "SELECT_BUTTON"
                                              ? _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label:
                                                        item.attributeName +
                                                        "：",
                                                      labelWidth: "170px",
                                                      prop: item.attributeCode,
                                                      rules: [
                                                        {
                                                          required:
                                                            item.requiredState,
                                                          message:
                                                            "请选择" +
                                                            item.attributeName,
                                                          trigger: "change",
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-radio-group",
                                                      {
                                                        staticStyle: {
                                                          width: "100%",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[
                                                              item.attributeCode
                                                            ],
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              item.attributeCode,
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[item.attributeCode]",
                                                        },
                                                      },
                                                      _vm._l(
                                                        item.options,
                                                        function (
                                                          citem,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "el-radio",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                label:
                                                                  citem.itemCode,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  citem.itemName
                                                                )
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeThree,
                              callback: function ($$v) {
                                _vm.activeThree = $$v
                              },
                              expression: "activeThree",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "客户信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("customerInfo", {
                                  attrs: { form: _vm.form, classCode: "DBC" },
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeFour,
                              callback: function ($$v) {
                                _vm.activeFour = $$v
                              },
                              expression: "activeFour",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: { "line-height": "25px" },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "产品信息" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "保险公司：",
                                              prop: "supplierCode",
                                              "validate-on-rule-change": true,
                                              labelWidth: "170px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                staticStyle: { width: "100%" },
                                                on: {
                                                  change: _vm.changeSupplier,
                                                },
                                                model: {
                                                  value: _vm.form.supplierCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "supplierCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.supplierCode",
                                                },
                                              },
                                              _vm._l(
                                                _vm.suppliersLists,
                                                function (item, index) {
                                                  return _c(
                                                    "el-radio",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        label:
                                                          item.supplierCode,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.supplierName
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "选择产品：",
                                              prop: "productCode",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                directives: [
                                                  {
                                                    name: "loading",
                                                    rawName: "v-loading",
                                                    value: _vm.selsectLoading,
                                                    expression:
                                                      "selsectLoading",
                                                  },
                                                ],
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  filterable: "",
                                                  placeholder: "请选择产品",
                                                  disabled:
                                                    _vm.form.supplierCode &&
                                                    !_vm.selsectLoading
                                                      ? false
                                                      : true,
                                                  clearable: "",
                                                },
                                                on: {
                                                  focus: function ($event) {
                                                    return _vm.tipsClicked(
                                                      _vm.productList
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    return _vm.handleProduct(
                                                      $event,
                                                      false
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.form.productCode,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "productCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.productCode",
                                                },
                                              },
                                              _vm._l(
                                                _vm.productList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.productName,
                                                      value: item.productCode,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.form.productCode,
                                            expression: "form.productCode",
                                          },
                                        ],
                                        attrs: { span: 12 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "保障期限：",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.form.productCode,
                                                    expression:
                                                      "form.productCode",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.form.productCode &&
                                                        _vm.formProductServiceDay +
                                                          "月"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.form.productCode,
                                        expression: "form.productCode",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "服务起期：",
                                              prop: "nonautoStartTime",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                format: "yyyy-MM-dd",
                                                "value-format": "yyyy-MM-dd",
                                                type: "date",
                                                placeholder: "请选择服务起期",
                                                "picker-options":
                                                  _vm.disabledStartLessThan,
                                                clearable: "",
                                              },
                                              on: {
                                                change: _vm.startTimeChange,
                                              },
                                              model: {
                                                value:
                                                  _vm.form.nonautoStartTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "nonautoStartTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.nonautoStartTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "endTime",
                                            attrs: {
                                              label: "服务止期：",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                format: "yyyy-MM-dd",
                                                "value-format": "yyyy-MM-dd",
                                                type: "date",
                                                placeholder: "请选择服务止期",
                                                disabled: "",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.form.nonautoEndTime,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "nonautoEndTime",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.nonautoEndTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.form.productCode,
                                        expression: "form.productCode",
                                      },
                                    ],
                                    staticStyle: {
                                      color: "red",
                                      "padding-left": "90px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " *代步车险责任起期输入规则:代步车责任起期不能小于当前日期七天。"
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " 如代步车险责任起期和商业险起期同一天，则无等待期:如不是同一天，或单独购买代步车险，则有7天等待期，即审核通过7天后生效。 "
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-row",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.form.productCode,
                                        expression: "form.productCode",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "销售方式：",
                                              prop: "salesMethod",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                on: {
                                                  change: _vm.selectPaymentType,
                                                },
                                                model: {
                                                  value: _vm.form.salesMethod,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "salesMethod",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.salesMethod",
                                                },
                                              },
                                              _vm._l(
                                                _vm.salesMethodOptions,
                                                function (item, index) {
                                                  return _c(
                                                    "el-radio",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        label: item.code,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.description)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.form.salesMethod == "SM01",
                                            expression:
                                              "form.salesMethod == 'SM01'",
                                          },
                                        ],
                                        attrs: { span: 12 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "销售价：",
                                              prop: "salesPrice",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入销售价",
                                                clearable: "",
                                                oninput:
                                                  "value=value.replace(/[^1-9]{0,1}(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                                              },
                                              on: { blur: _vm.changeAmout },
                                              model: {
                                                value: _vm.form.salesPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "salesPrice",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.salesPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.form.salesMethod == "SM01",
                                            expression:
                                              "form.salesMethod == 'SM01'",
                                          },
                                        ],
                                        staticStyle: { color: "#999" },
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "formSalesPrice",
                                            attrs: {
                                              label: "建议零售价(元)：",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.formSalesPrice) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.form.salesMethod == "SM01",
                                            expression:
                                              "form.salesMethod == 'SM01'",
                                          },
                                        ],
                                        staticStyle: { color: "#999" },
                                        attrs: { span: 6 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            staticClass: "formSalesPrice",
                                            attrs: { label: "DLR采购价(元)：" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.formPurchasePrice) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.form.productCode,
                                        expression: "form.productCode",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "累计最多赔偿天数：",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .cumulateMaxCompensationDay,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "cumulateMaxCompensationDay",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.cumulateMaxCompensationDay",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "每次事故最多赔偿天数：",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .accidentMaxCompensationDay,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "accidentMaxCompensationDay",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.accidentMaxCompensationDay",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "最大赔付金额(元)：",
                                              "label-width": "170px",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: { disabled: "" },
                                              model: {
                                                value:
                                                  _vm.form
                                                    .maxCompensationAmount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "maxCompensationAmount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.maxCompensationAmount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-collapse",
                          {
                            staticStyle: { "margin-top": "10px" },
                            model: {
                              value: _vm.activeTens,
                              callback: function ($$v) {
                                _vm.activeTens = $$v
                              },
                              expression: "activeTens",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              {
                                staticClass: "collapse-item-style",
                                attrs: { name: "1" },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      staticStyle: {
                                        "line-height": "25px",
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c("FormLabel", {
                                        attrs: { "form-label": "附件列表" },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "red",
                                            "font-size": "14px",
                                            "font-weight": "none",
                                            display: "flex",
                                            "flex-direction": "row",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "(请按附件类型上传，最少上传一个附件且附件大小不超过2M)"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "el-row",
                                  { attrs: { gutter: 0 } },
                                  [
                                    _c("el-col", { attrs: { span: 24 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "dialog-content" },
                                        _vm._l(
                                          _vm.filestypelists,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "dialogContentHeader",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "filesLists" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.fileList
                                                                .length <
                                                              _vm.filestypelists
                                                                .length *
                                                                3,
                                                            expression:
                                                              "fileList.length < filestypelists.length*3",
                                                          },
                                                        ],
                                                        staticClass: "fileBox",
                                                      },
                                                      [
                                                        _c(
                                                          "el-upload",
                                                          {
                                                            staticClass:
                                                              "upload-demo",
                                                            attrs: {
                                                              limit:
                                                                _vm
                                                                  .filestypelists
                                                                  .length * 3,
                                                              "before-remove":
                                                                function () {
                                                                  return (_vm.formData =
                                                                    "")
                                                                },
                                                              action: "",
                                                              multiple: "",
                                                              "show-file-list": false,
                                                              "http-request":
                                                                function (val) {
                                                                  return _vm.HTuploadFile(
                                                                    val,
                                                                    item.code,
                                                                    item.description
                                                                  )
                                                                },
                                                              "before-upload":
                                                                function (val) {
                                                                  return _vm.HTbeforeUpload(
                                                                    val,
                                                                    item.code
                                                                  )
                                                                },
                                                              "file-list":
                                                                _vm.fileList,
                                                              accept:
                                                                _vm.uploadType,
                                                              "list-type":
                                                                "text",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  size: "mini",
                                                                  plain: "",
                                                                  type: "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "点击上传"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._l(
                                                      _vm.fileList,
                                                      function (fitem, findex) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  fitem.fileType ==
                                                                  item.code,
                                                                expression:
                                                                  "fitem.fileType == item.code",
                                                              },
                                                            ],
                                                            key: findex,
                                                            staticClass:
                                                              "fileBox",
                                                          },
                                                          [
                                                            _vm.getFileSuffix(
                                                              fitem.fileExtension
                                                            )
                                                              ? _c("el-image", {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100px",
                                                                    height:
                                                                      "100px",
                                                                    cursor:
                                                                      "pointer",
                                                                  },
                                                                  attrs: {
                                                                    src: fitem.fileUrl,
                                                                    "preview-src-list":
                                                                      [
                                                                        fitem.fileUrl,
                                                                      ],
                                                                  },
                                                                })
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      fitem.fileName
                                                                    )
                                                                  ),
                                                                ]),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "deleteIcon",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.delFileList(
                                                                        fitem
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-circle-close",
                                                                }),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "footer-btn" }, [
              _c(
                "div",
                { staticClass: "footer-btn-list" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-back" },
                      on: { click: _vm.closePage },
                    },
                    [_vm._v(" 返回 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.holdDataClicked },
                    },
                    [_vm._v("暂存")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.save },
                    },
                    [_vm._v(" 提交出单 ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "car-insurance-ui-dialog",
          attrs: {
            visible: _vm.showeffectiveDateDialog,
            "close-on-click-modal": false,
            width: "1100px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showeffectiveDateDialog = $event
            },
            close: _vm.cancelClicked,
          },
        },
        [
          _c(
            "div",
            { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
            [
              _c("FormLabel", {
                staticStyle: { "font-size": "14px" },
                attrs: { "form-label": "服务信息确认" },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "dialog-body" }, [
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "16px",
                  display: "flex",
                  "justify-content": "flex-start",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "background-color": "red",
                      color: "#fff",
                      "font-size": "18px",
                      padding: "5px",
                      "box-sizing": "border-box",
                      "margin-right": "5px",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v("重要")]
                ),
                _c("span", { staticStyle: { "font-weight": "800" } }, [
                  _vm._v(
                    "请确认以下信息是否正确，如果发现有误，请及时修改信息重新提交，未修改将影响贵经销商的理赔权益;"
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "table" }, [
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox2" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("车主信息")]),
                  _c("div"),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("客户姓名")]),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.confirmObj.ownerName) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "tdRightBox" }, [
                  _c("div", { staticClass: "tdheader" }, [
                    _vm._v("客户证件号码"),
                  ]),
                  _c("div", [
                    _vm._v(
                      " " + _vm._s(_vm.confirmObj.ownerCertificateNo) + " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox" }, [
                  _c("div", { staticClass: "tdheader" }, [
                    _vm._v("客户联系方式"),
                  ]),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.confirmObj.ownerMobile) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "tdRightBox" }, [
                  _c("div", { staticClass: "tdheader" }, [
                    _vm._v("客户联系地址"),
                  ]),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.confirmObj.ownerAddress) + " "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox2" }, [
                  _c("div", { staticClass: "tdheader" }, [
                    _vm._v("服务合同信息"),
                  ]),
                  _c("div"),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("产品名称")]),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.confirmObj.productName) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "tdRightBox" }, [
                  _c("div", { staticClass: "tdheader" }, [
                    _vm._v("服务合同价格(元)"),
                  ]),
                  _vm.confirmObj.salesMethod == "SM01"
                    ? _c("div", [
                        _vm._v(" " + _vm._s(_vm.confirmObj.salesPrice) + " "),
                      ])
                    : _c("div", [_vm._v(" 0 ")]),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("车辆品牌")]),
                  _c("div"),
                ]),
                _c("div", { staticClass: "tdRightBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("车辆型号")]),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.confirmObj.vhcSeriesNo) + " "),
                    _vm.confirmObj.vhcSeriesName &&
                    _vm.confirmObj.vhcSeriesChineseName
                      ? _c("span", [
                          _vm._v(
                            "(" +
                              _vm._s(_vm.confirmObj.vhcSeriesName) +
                              "/" +
                              _vm._s(_vm.confirmObj.vhcSeriesChineseName) +
                              ")"
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("车辆类型")]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.confirmObj && _vm.confirmObj.vehicleTypeDisplay
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "tdRightBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("使用性质")]),
                  _c("div", [
                    _vm._v(
                      " " + _vm._s(_vm.confirmObj.useAttributeDisplay) + " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("车牌号")]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.confirmObj.plateNo
                            ? _vm.confirmObj.plateNo
                            : "新车"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "tdRightBox" }, [
                  _c("div", { staticClass: "tdheader" }, [
                    _vm._v("车架号(vin码)"),
                  ]),
                  _c("div", [_vm._v(" " + _vm._s(_vm.confirmObj.vin) + " ")]),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("发动机号")]),
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.confirmObj.engineNo) + " "),
                  ]),
                ]),
                _c("div", { staticClass: "tdRightBox" }),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox" }, [
                  _c("div", { staticClass: "tdheader" }, [
                    _vm._v("累计最多赔偿天数(天)"),
                  ]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.confirmObj.cumulateMaxCompensationDay) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "tdRightBox" }, [
                  _c("div", { staticClass: "tdheader" }, [
                    _vm._v("每次事故最多赔偿天数(天)"),
                  ]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.confirmObj.accidentMaxCompensationDay) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox2" }, [
                  _c(
                    "div",
                    { staticClass: "tdheader", staticStyle: { color: "#666" } },
                    [_vm._v("最大赔付金额(元)")]
                  ),
                  _c("div", [
                    _vm._v(
                      " " + _vm._s(_vm.confirmObj.maxCompensationAmount) + " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "td" }, [
                _c("div", { staticClass: "tdLeftBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("服务起期")]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.confirmObj.effectiveStartTime &&
                            _vm.confirmObj.effectiveStartTime.split(" ")[0] +
                              " 0时"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "tdRightBox" }, [
                  _c("div", { staticClass: "tdheader" }, [_vm._v("服务止期")]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.confirmObj.effectiveEndTime &&
                            _vm.confirmObj.effectiveEndTime.split(" ")[0] +
                              " 24时"
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm.errorList && _vm.errorList.length != 0
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "line-height": "22px",
                      color: "#606266",
                      "margin-left": "5px",
                      "font-size": "13px",
                      "font-weight": "600",
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v("检测到 "),
                    _vm._l(_vm.errorList, function (item, index) {
                      return _c(
                        "span",
                        { key: index, staticStyle: { color: "red" } },
                        [_vm._v(" “" + _vm._s(item) + "”")]
                      )
                    }),
                    _vm._v(
                      "与TACT系统信息不一致，请确认车主信息与车辆信息是否正确，避免影响后期理赔 "
                    ),
                  ],
                  2
                )
              : _vm._e(),
            !_vm.confirmObj.salesDate
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "line-height": "22px",
                      color: "red",
                      "margin-left": "5px",
                      "font-size": "13px",
                      "font-weight": "600",
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v(
                      "未检测到该车辆的销售日期，请尽快完善，避免影响后期理赔。 "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticStyle: { "font-size": "13px" } },
              [
                _c(
                  "el-checkbox",
                  {
                    model: {
                      value: _vm.confirmChecked,
                      callback: function ($$v) {
                        _vm.confirmChecked = $$v
                      },
                      expression: "confirmChecked",
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("*"),
                    ]),
                    _vm._v("已阅读以上信息，确认出单"),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    icon: "el-icon-check",
                    plain: "",
                    type: "primary",
                    disabled: _vm.countDownTime > 0,
                  },
                  on: { click: _vm.confirmClicked },
                },
                [
                  _vm.countDownTime > 0
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.countDownTime) + "秒后确认"),
                      ])
                    : _c("span", [_vm._v("确认")]),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", icon: "el-icon-close" },
                  on: { click: _vm.cancelClicked },
                },
                [_vm._v(" 取消 ")]
              ),
            ],
            1
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.maxboxloading,
                expression: "maxboxloading",
              },
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.maxboxloading,
                expression: "maxboxloading",
              },
            ],
            staticClass: "maskBox",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }