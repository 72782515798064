import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgIcon from '@/components/SvgIcon/index.vue';
export default {
  props: {
    menuList: {
      type: Array,
      default: []
    },
    menuListData: {
      type: Array,
      default: []
    }
  },
  components: {
    SvgIcon: SvgIcon
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {
    toNext: function toNext(item) {
      console.log(item);
      if (item.path) {
        this.$router.push(item.path);
      }
    }
  }
};