import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import storageServiceRequest from "@/api/storageService";
import dict from '@/mixins/dict';
export default {
  props: {
    itemId: {
      type: Number,
      default: null
    },
    refresh: {
      type: Boolean,
      default: true
    }
  },
  mixins: [dict],
  data: function data() {
    return {
      form: {
        accessKey: '',
        category: '',
        remarks: '',
        secret: '',
        storageAddress: '',
        storageName: '',
        storagePublicAddress: '',
        storagePublicAddressSupportSsl: false,
        usedSelfPublicAddress: false
      },
      loading: false,
      formRules: {
        accessKey: [{
          required: true,
          message: '请输入存储访问Key',
          trigger: 'blur'
        }],
        secret: [{
          required: true,
          message: '请输入存储访问密钥',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.form = {
          accessKey: '',
          category: '',
          remarks: '',
          secret: '',
          storageAddress: '',
          storageName: '',
          storagePublicAddress: '',
          storagePublicAddressSupportSsl: false,
          usedSelfPublicAddress: false
        };
        this.$refs['detail'].resetFields();
      }
    }
  },
  created: function created() {
    this.loadData();
  },
  mounted: function mounted() {},
  methods: {
    // 获取详情
    loadData: function loadData() {
      var _this = this;
      if (!this.itemId) return;
      this.loading = true;
      storageServiceRequest.getData(this.itemId).then(function (res) {
        _this.loading = false;
        _this.form = res.data;
        _this.$set(_this.form, 'accessKey', null);
        _this.$set(_this.form, 'secret', null);
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 保存
    save: function save() {
      var _this2 = this;
      this.$refs["detail"].validate(function (valid, object) {
        if (valid) {
          storageServiceRequest.updateSecret(_this2.itemId, _this2.form).then(function (res) {
            if (res.code == 200) {
              _this2.$message({
                type: "success",
                message: res.msg
              });
              _this2.closePage();
            }
          });
        }
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$refs['detail'].resetFields();
      this.$emit('closePage');
    }
  }
};