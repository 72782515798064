var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls", staticStyle: {} }, [
    _c(
      "div",
      {
        ref: "header",
        staticClass: "header-cls",
        staticStyle: { "max-height": "500px", overflow: "auto" },
      },
      [
        _c(
          "el-card",
          { attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                ref: "createOrder",
                staticStyle: { "margin-bottom": "-20px" },
                attrs: {
                  model: _vm.form,
                  rules: _vm.createOrderRules,
                  "label-width": "110px",
                  size: "mini",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "0" },
                        attrs: { label: "短信内容：" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入短信内容",
                            clearable: "",
                            type: "textarea",
                            rows: 3,
                          },
                          model: {
                            value: _vm.form.smsContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "smsContent", $$v)
                            },
                            expression: "form.smsContent",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-bottom": "0" },
                        attrs: { label: "" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { margin: "10px 0" },
                            attrs: { type: "primary", plain: "", size: "mini" },
                          },
                          [_vm._v("解析短信")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "车主姓名", prop: "customerName" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入车主姓名",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.customerName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "customerName", $$v)
                                },
                                expression: "form.customerName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户电话：", prop: "phone" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入客户电话",
                                oninput:
                                  "value=value.replace(/[^0-9a-zA-Z\\-.]+/,'')",
                                maxlength: "11",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.phone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "phone", $$v)
                                },
                                expression: "form.phone",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车牌号：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入车牌号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.plateNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "plateNo", $$v)
                                },
                                expression: "form.plateNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "品牌型号：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入品牌型号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.modelName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "modelName", $$v)
                                },
                                expression: "form.modelName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车辆现状：" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请选择车辆现状",
                                },
                                model: {
                                  value: _vm.form.carStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "carStatus", $$v)
                                  },
                                  expression: "form.carStatus",
                                },
                              },
                              _vm._l(_vm.vehicleStatus, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.itemName,
                                    value: item.itemCode,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "留修类型：",
                              prop: "pushRepairType",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择留修类型" },
                                model: {
                                  value: _vm.form.pushRepairType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "pushRepairType", $$v)
                                  },
                                  expression: "form.pushRepairType",
                                },
                              },
                              _vm._l(_vm.StudyAbroadType, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.itemName,
                                    value: item.itemCode,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "保险公司：", prop: "insCorpCode" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择保险公司" },
                                model: {
                                  value: _vm.form.insCorpCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "insCorpCode", $$v)
                                  },
                                  expression: "form.insCorpCode",
                                },
                              },
                              _vm._l(_vm.supplyers, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.shortName,
                                    value: item.code,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "工单来源：", prop: "sourceType" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择工单来源" },
                                model: {
                                  value: _vm.form.sourceType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sourceType", $$v)
                                  },
                                  expression: "form.sourceType",
                                },
                              },
                              _vm._l(_vm.TicketSource, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "报案号：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入报案号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.reportNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "reportNo", $$v)
                                },
                                expression: "form.reportNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "报案时间：" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "date",
                                placeholder: "选择日期",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.reportTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "reportTime", $$v)
                                },
                                expression: "form.reportTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "出险地址：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入出险地址",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.reportAddress,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "reportAddress", $$v)
                                },
                                expression: "form.reportAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "碰撞视频1：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder:
                                  "请输入碰撞视频链接，例如：http(s)://www.baidu.com/碰撞视频.mp4",
                                clearable: "",
                              },
                              model: {
                                value: _vm.form.accidentVideoUrlOne,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "accidentVideoUrlOne", $$v)
                                },
                                expression: "form.accidentVideoUrlOne",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "接车员：",
                              prop: "conductorUserId",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择接车员" },
                                model: {
                                  value: _vm.form.conductorUserId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "conductorUserId", $$v)
                                  },
                                  expression: "form.conductorUserId",
                                },
                              },
                              _vm._l(_vm.conductorUserlist, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.userName,
                                    value: item.userId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "通知信息：" } },
                          [
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: _vm.form.sendMessage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sendMessage", $$v)
                                  },
                                  expression: "form.sendMessage",
                                },
                              },
                              [_vm._v("短信通知客户")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.form.sendMessage
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "通知内容：" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入通知内容",
                                    type: "textarea",
                                    maxlength: "200",
                                    "show-word-limit": "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.messageContent,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "messageContent", $$v)
                                    },
                                    expression: "form.messageContent",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "justify-content": "center",
          "margin-top": "8px",
        },
      },
      [
        _c(
          "el-button",
          {
            attrs: {
              icon: "el-icon-check",
              type: "primary",
              plain: "",
              size: "mini",
            },
            on: { click: _vm.save },
          },
          [_vm._v("保存")]
        ),
        _c(
          "el-button",
          {
            attrs: { icon: "el-icon-close", size: "mini" },
            on: { click: _vm.closePage },
          },
          [_vm._v("关闭")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }