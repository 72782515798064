/**
 * 报表管理接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 业绩报表
   */
  getStatisticAchievements: function getStatisticAchievements(data) {
    return request.get(getUrl("/statistics/statisticAchievements", 1), {
      params: data
    });
  },
  /**
   * 员工业务统计
   */
  getEmployeeStatistics: function getEmployeeStatistics(data) {
    return request.get(getUrl("/policy/statistics/employee", 1), {
      params: data
    });
  },
  /**
   * 机构业务统计
   */
  getOrgStatistics: function getOrgStatistics(data) {
    return request.get(getUrl("/policy/statistics/org", 1), {
      params: data
    });
  },
  /**
   * 经营业绩表
   */
  getOperatingPerformance: function getOperatingPerformance(data) {
    return request.get(getUrl("/statistics/operatingPerformance", 1), {
      params: data
    });
  }
  ///statistics/operatingPerformance
};

export default objApi;