import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
var _objApi;
import "core-js/modules/es.array.concat.js";
/**
 * 通用接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = (_objApi = {
  /**
   * 获取ALL主险
   */
  getAllParentDuty: function getAllParentDuty() {
    return request.get(getUrl("/visystem-riskkind/allMaster", 1));
  },
  /**
   * 获取ALL条款
   */
  getAllclause: function getAllclause(type) {
    return request.get(getUrl("/common/dropdown/clause?type=".concat(type), 1));
  },
  /**
   * 获取ALL条款
   */
  getAllDuty: function getAllDuty() {
    return request.get(getUrl("/visystem-riskkind/allRiskkind", 1));
  },
  /**
   * 获取所有保险方案下拉框this.form.salesOrgName
   */
  getALLDutyPackage: function getALLDutyPackage(code) {
    return request.get(getUrl("/riskkind/page/list/".concat(code), 1));
  },
  /**
   * 详情
   * @param {*} id
   */
  getDutyPackageItemDetail: function getDutyPackageItemDetail(id) {
    return request.get(getUrl("/riskkind/page/".concat(id), 1));
  },
  /**
   * 上传影像内容
   */
  storageMedia: function storageMedia(data) {
    return request({
      method: 'post',
      url: getUrl("/insure/media/storageMedia", 1),
      data: data,
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  },
  base64Storage: function base64Storage(quoteId, data) {
    return request({
      method: 'post',
      url: getUrl("/insure/media/base64Storage?quoteId=".concat(quoteId), 1),
      data: data
    });
  },
  /**
   * 获取上传图片
   */
  queryStorageFile: function queryStorageFile(quoteId) {
    return request.post(getUrl("/insure/media/queryStorageFile?quoteId=".concat(quoteId), 1));
  },
  /**
   * 获取上传图片
   */
  getImgUrl: function getImgUrl(objStorageId) {
    return request.get(getUrl("/insure/media/download/".concat(objStorageId), 1));
  },
  /**
   * 获取user信息
   */
  getUserInfo: function getUserInfo() {
    return request.get(getUrl("/user/get", 1));
  },
  /**
   * 获取增值服务
   */
  getServiceItems: function getServiceItems() {
    return request.get(getUrl("/diff/serviceItem", 1));
  },
  /**
   * 按条件获取增值服务
   */
  getServiceTerms: function getServiceTerms(data) {
    return request.get(getUrl("/diff/getServiceTerms", 1), {
      params: data
    });
  },
  /**
   * 获取支付类型
   */
  getPaymentTypes: function getPaymentTypes(proposalId) {
    return request.get(getUrl("/insure/getPaymentTypes?proposalId=".concat(proposalId), 1));
  },
  /**
   * 获取是否存在未支付
   */
  getUnFinishedPayment: function getUnFinishedPayment(proposalId) {
    return request.get(getUrl("/insure/getUnFinishedPayment?proposalId=".concat(proposalId), 1));
  },
  /**
   * 发起支付
   */
  payment: function payment(data) {
    return request.post(getUrl("/insure/payment", 1), data);
  },
  /**
   * 取消支付
   */
  cancelPayment: function cancelPayment(proposalId) {
    return request.get(getUrl("/insure/cancelPayment?proposalId=".concat(proposalId), 1));
  },
  /**
   * 刷新投保单状态
   */
  refresh: function refresh(proposalId) {
    return request.get(getUrl("/insure/refresh?proposalId=".concat(proposalId), 1));
  },
  /**
   * 发送短信
   */
  sendCode: function sendCode(proposalId) {
    return request.post(getUrl("/insure/sendVerifyCode?proposalId=".concat(proposalId), 1), {});
  },
  /**
   * 保存短信
   */
  saveCode: function saveCode(proposalId, data) {
    return request.post(getUrl("/insure/saveVerifyCode?proposalId=".concat(proposalId), 1), data);
  },
  /**
   * 获取API配置动态表单
   */
  getBizSchemaData: function getBizSchemaData(data) {
    return request.get(getUrl("/org-biz-config/getBizSchema", 1), {
      params: data
    });
  },
  /**
   * 上传投保单图片
   */
  storageMediaToProposal: function storageMediaToProposal(proposalId, data) {
    return request.post(getUrl("/insure/media/storageMediaToProposal?proposalId=".concat(proposalId), 1), data);
  },
  /**
   * 获取投保单图片
   */
  queryFromProposalDocument: function queryFromProposalDocument(proposalId, quoteId) {
    return request.get(getUrl("/insure/media/queryFromProposalDocument?proposalId=".concat(proposalId, "&quoteId=").concat(quoteId), 1));
  },
  /**
   * 上传投保单图片到保司
   */
  storageMediaTodInsuranceAgency: function storageMediaTodInsuranceAgency(proposalId, data) {
    return request.post(getUrl("/insure/media/storageMediaTodInsuranceAgency?proposalId=".concat(proposalId), 1), data);
  },
  /**
   * 删除影像上传图片
   */
  deleteDocument: function deleteDocument(ownerBy) {
    return request.get(getUrl("/insure/media/deleteDocument", 1), {
      params: ownerBy
    });
  },
  /**
   * 撤销投保
   */
  repealApply: function repealApply(data) {
    return request.post(getUrl("/insure/repealApply", 1), data);
  },
  /**
   * 根据token获取用户信息
   */
  getUserByToken: function getUserByToken() {
    return request.get(getUrl("/getUserByToken", 5));
  }
}, _defineProperty(_objApi, "getUserInfo", function getUserInfo() {
  return request.get(getUrl("/employee/me", 4));
}), _defineProperty(_objApi, "getUserRoleMenus", function getUserRoleMenus() {
  return request.get(getUrl("/roles/getUserRoleMenus/me", 5));
}), _defineProperty(_objApi, "getOemUserInfo", function getOemUserInfo() {
  return request.get(getUrl("/oem/info/me", 13));
}), _defineProperty(_objApi, "getDealerUserInfo", function getDealerUserInfo() {
  return request.get(getUrl("/dealer/info/me", 12));
}), _defineProperty(_objApi, "queryAccidenProducts", function queryAccidenProducts(data) {
  return request.post(getUrl("/insure/acciden/product", 1), data);
}), _defineProperty(_objApi, "getAccidentProductList", function getAccidentProductList(quoteId, data) {
  return request.post(getUrl("/insure/accident/getProductList?quoteId=".concat(quoteId), 1), data);
}), _defineProperty(_objApi, "queryAccidenProductDetail", function queryAccidenProductDetail(data) {
  return request.post(getUrl("/insure/accident/product/deatil", 1), data);
}), _defineProperty(_objApi, "getConsoleUrl", function getConsoleUrl(refreshUrl) {
  return request.get(getUrl("/cloudLog/getConsoleUrl?refreshUrl=".concat(refreshUrl), 5));
}), _defineProperty(_objApi, "getTactPushLog", function getTactPushLog(data) {
  return request.get(getUrl("/ftms/push/vehicleSales/log/search", 11), {
    params: data
  });
}), _defineProperty(_objApi, "batchGetVehicleSales", function batchGetVehicleSales(ids) {
  return request.post(getUrl("/ftms/push/batchGetVehicleSales?ids=".concat(ids), 11), {});
}), _defineProperty(_objApi, "queryRenewal", function queryRenewal(data) {
  return request.post(getUrl("/renewalInformation/query", 1), data);
}), _defineProperty(_objApi, "searchPolicyPush", function searchPolicyPush(data) {
  return request.get(getUrl("/ftms/policy/push/search", 11), {
    params: data
  });
}), _defineProperty(_objApi, "pushAgain", function pushAgain(data) {
  return request.post(getUrl("/ftms/policy/push/again", 11), data);
}), _defineProperty(_objApi, "searchVehicleModelFile", function searchVehicleModelFile(data) {
  return request.get(getUrl("/vehicleModel/file/search", 11), {
    params: data
  });
}), _defineProperty(_objApi, "fileDownload", function fileDownload(fileId) {
  return request({
    method: 'get',
    url: getUrl("/vehicleModel/file/download/".concat(fileId), 11),
    responseType: 'blob'
  });
}), _defineProperty(_objApi, "getTemplateByOrg", function getTemplateByOrg(data) {
  return request.get(getUrl("/import/templates/getTemplateByOrg", 9), {
    params: data
  });
}), _defineProperty(_objApi, "getDuringRepairFileList", function getDuringRepairFileList(data) {
  return request.get(getUrl("/duringRepair/file/search", 11), {
    params: data
  });
}), _defineProperty(_objApi, "duringRepairFileDownload", function duringRepairFileDownload(fileId) {
  return request({
    method: 'get',
    url: getUrl("/duringRepair/file/download/".concat(fileId), 11),
    responseType: 'blob'
  });
}), _defineProperty(_objApi, "getDuringRepairOrderList", function getDuringRepairOrderList(data) {
  return request.get(getUrl("/duringRepair/orderDetailSearch", 11), {
    params: data
  });
}), _defineProperty(_objApi, "refreshSolution", function refreshSolution(proposalId) {
  return request.get(getUrl("/insure/refreshSolution?proposalId=".concat(proposalId), 1));
}), _defineProperty(_objApi, "getSolutionConfig", function getSolutionConfig() {
  return request.post(getUrl("/insCorpInvokeMonitor/getEnabled", 14), {});
}), _defineProperty(_objApi, "searchByMonitor", function searchByMonitor(data) {
  return request.get(getUrl("/insCorpInvokeMonitor/refreshByMonitor", 14), {
    params: data
  });
}), _objApi);
export default objApi;