//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from "@/mixins/dict";
import initHeight from "@/mixins/initHeight";
import storageRequest from '@/api/storage';
import servicePackageApi from "@/api/servicePackage";
import { downFile } from '@/utils/common';
export default {
  props: {
    refresh: {
      type: Boolean
    }
  },
  mixins: [initHeight, dict],
  data: function data() {
    return {
      formData: '',
      uploadForm: {
        storageId: ''
      },
      fileList: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      this.uploadForm = {
        storageId: ''
      };
      this.fileList = [];
    },
    uploadFile: function uploadFile(param) {
      var _this = this;
      //上传文件
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this.$message.success(res.msg);
          _this.uploadForm.storageId = res.data.fileStorageId;
        }
      });
    },
    // 附件校验
    validate: function validate() {
      var flag = true;
      if (!this.uploadForm.storageId) {
        this.$message.warning('请上传文件');
        flag = false;
        return flag;
      }
      return flag;
    },
    selectFile: function selectFile() {
      var _this2 = this;
      //上传点击
      if (!this.validate()) return;
      servicePackageApi.importTempServicePacks(this.uploadForm).then(function (res) {
        if (res.code === 200) {
          _this2.$confirm("".concat(res.data.failures ? '失败数据' + res.data.failures + '个，点击打开文件' : '导入成功', " "), '提示', {
            showCancelButton: false,
            confirmButtonText: "".concat(res.data.failures ? '打开文件' : "确定"),
            type: "".concat(res.data.failures ? 'warning' : 'success')
          }).then(function () {
            if (res.data.failures) {
              downFile(res.data.fileUrl, res.data.fileName);
              _this2.initData();
            } else {
              _this2.initData();
              _this2.closePage(false);
            }
          }).catch(function () {});
        }
      });
    },
    closePage: function closePage() {
      var boolean = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      //关闭弹窗
      this.$emit("closePage", boolean);
    }
  }
};