import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dict from '@/mixins/dict';
import initHeight from '@/mixins/initHeight';
import Pagination from '@/components/Pagination';
import recognitionApi from '@/api/recognition';
import storageRequest from '@/api/storage';
import { getSupplyers } from '@/utils/common';
export default {
  mixins: [initHeight, dict],
  components: {
    Pagination: Pagination
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      showConfigDictDialog: false,
      listLoading: false,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      InsuranceList: [{
        code: "BZ",
        name: "交强险"
      }, {
        code: "BS",
        name: "商业险"
      }],
      fileList: [],
      PDFContent: "",
      templateContent: "",
      supplyers: [],
      cascaderAddr: [],
      cityOptions: JSON.parse(localStorage.getItem("VI_CityPcTree")) || [],
      form: {
        fileStorageId: "",
        insCityCode: "",
        insCorpCode: "",
        riskType: ""
      },
      rules: {},
      total: 0,
      timeout1: null
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
    this.supplyers = getSupplyers();
  },
  methods: {
    getInsCorpName: function getInsCorpName(val) {
      var item = _.find(this.supplyers, function (i) {
        return i.code == val;
      });
      // this.form.insCorpName = item.shortName;
    },
    handleChange: function handleChange(val) {
      var _this = this;
      //省/市/区选择
      this.$nextTick(function () {
        var cascaderAddr = _this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;
        console.log(cascaderAddr);
        console.log(val);
        // this.form.insCityName = cascaderAddr[1];
        _this.form.insCityCode = val[1];
      });
    },
    // 删除文件
    removeFile: function removeFile() {
      this.form.fileStorageId = '';
      this.PDFContent = '';
    },
    // 上传文件
    uploadFile: function uploadFile(param) {
      var _this2 = this;
      var formData = new FormData();
      formData.append("file", param.file);
      storageRequest.uploadFile(formData).then(function (res) {
        if (res.code === 200) {
          _this2.$message.success(res.msg);
          setTimeout(function () {
            _this2.fileList = [{
              fileName: param.file.name,
              //	文件名
              name: param.file.name,
              //	文件名
              fileId: res.data.fileStorageId
            }];
            _this2.form.fileStorageId = res.data.fileStorageId;
            _this2.form.fileUrl = res.data.fileUrl;
            recognitionApi.getText(res.data).then(function (resData) {
              _this2.PDFContent = resData.data;
            });
          }, 1000);
        }
      });
    },
    analysisJson: function analysisJson() {
      var _this3 = this;
      recognitionApi.analysisJson(this.form).then(function (res) {
        console.log(res);
        if (res.code == 200) {
          _this3.templateContent = res.data ? JSON.stringify(res.data) : '';
        }
      });
    },
    initData: function initData() {
      //初始化数据
      this.form = {
        fileStorageId: "",
        insCityCode: "",
        insCorpCode: "",
        riskType: ""
      };
      this.cascaderAddr = [];
      this.PDFContent = "";
      this.templateContent = '';
    },
    loadData: function loadData() {//加载数据
    },
    save: function save() {
      var _this4 = this;
      if (this.timeout1) {
        //防抖
        clearTimeout(this.timeout1);
      }
      this.timeout1 = setTimeout(function () {
        _this4.save1();
      }, 800);
    },
    save1: function save1() {
      var _this5 = this;
      //保存数据
      this.closePage();
      // let configList = this.tableData
      repairApi.addTemplate().then(function (res) {
        if (res.code == 200) {
          _this5.$message({
            message: res.msg,
            type: 'success'
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};