import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dealerRegional from '@/api/dealerRegional';
import AreaDialog from './dialog/areaDialog';
export default {
  name: 'RegionalMaintenance',
  mixins: [initHeight],
  components: {
    AreaDialog: AreaDialog
  },
  data: function data() {
    return {
      tableData: [],
      loading: false,
      highlightCurrent: true,
      showDialog: false,
      currentType: '',
      currentStatus: '',
      insCorpItem: {},
      dealerIdArr: [],
      dealerId: '',
      currentData: {},
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {}
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.loadData();
  },
  methods: {
    //删除省级、市级
    deleteArea: function deleteArea(type, item) {
      var _this = this;
      this.$confirm('您确定要删除该数据吗', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        type: 'warning'
      }).then(function () {
        var req = type === 'province' ? dealerRegional.deleteProvince(item.id) : dealerRegional.deleteCity(item.id);
        req.then(function (res) {
          if (res.code === 200) {
            _this.$message.success(res.msg);
            _this.loadData();
          }
        });
      }).catch(function () {});
    },
    //编辑
    editArea: function editArea(status, type, item) {
      this.currentStatus = status;
      this.currentType = type;
      this.currentData = item;
      this.showDialog = true;
    },
    //新增
    addArea: function addArea(status, type) {
      this.currentStatus = status;
      this.currentType = type;
      this.showDialog = true;
    },
    //获取列表数据
    loadData: function loadData() {
      var _this2 = this;
      this.loading = true;
      this.dealerIdArr = [];
      this.showDialog = false;
      var oemCode = this.userInfo.orgCode;
      dealerRegional.getList(oemCode).then(function (res) {
        if (res.code === 200) {
          _this2.tableData = res.data;
          _this2.loading = false;
        }
      }).catch(function (err) {
        _this2.loading = false;
      });
    },
    // 刷新
    breakData: function breakData() {
      this.loadData();
    }
  }
};