import _defineProperty from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
var _name$components$mixi;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import initHeight from '@/mixins/initHeight';
import dict from '@/mixins/dict';
import Pagination from '@/components/Pagination';
import exportApi from '@/api/export';
import exportLogApi from '@/api/exportLog';
import OemSelect from '@/components/OemSelect';
import { saveFile, commonExport } from '@/utils/common';
export default (_name$components$mixi = {
  name: 'MyExportLog',
  components: {
    Pagination: Pagination,
    OemSelect: OemSelect
  },
  mixins: [initHeight, dict],
  computed: {},
  data: function data() {
    return {
      activeName: "all",
      drawer: false,
      searchForm: {
        fileName: null,
        operatorName: null,
        exportStatus: null,
        orgCode: null
      },
      list: null,
      total: 0,
      detailTotal: 0,
      listLoading: true,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      detail: {
        pageNum: 1,
        pageSize: 20
      },
      tableData: [],
      detailTableData: [],
      loading: false,
      orgData: [],
      showDetail: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: ''
      }
    };
  }
}, _defineProperty(_name$components$mixi, "computed", {
  meExportFlag: function meExportFlag() {
    return this.$route.path === "/meExportLog";
  }
}), _defineProperty(_name$components$mixi, "watch", {
  meExportFlag: function meExportFlag(val) {
    this.loadData();
  }
}), _defineProperty(_name$components$mixi, "created", function created() {}), _defineProperty(_name$components$mixi, "mounted", function mounted() {
  this.loadData();
}), _defineProperty(_name$components$mixi, "activated", function activated() {
  this.loadData();
}), _defineProperty(_name$components$mixi, "methods", {
  // 导出
  exportFn: function exportFn() {
    var _this = this;
    this.$nextTick(function () {
      var exporParams = {
        total: _this.total,
        limitNum: 200000,
        fileName: _this.meExportFlag ? "我的导出列表" : "系统导出监控表",
        exportUrl: "/tp-data-process-service/export-log".concat(_this.meExportFlag ? '/me' : ''),
        searchForm: _objectSpread(_objectSpread({}, _this.searchForm), _this.listQuery)
      };
      commonExport(exporParams, _this.$refs['exportMultipleTable']);
    });
  },
  // tab切换事件
  handleTabClick: function handleTabClick(tab, event) {
    this.$forceUpdate();
  },
  // 重置搜索表单
  resetSearch: function resetSearch() {
    this.searchForm = {};
    this.orgForm = {
      salesOrgCode: '',
      salesOrgName: ''
    };
  },
  // 搜索
  searchData: function searchData() {
    var salesOrgCode = this.orgForm.salesOrgCode;
    this.searchForm.orgCode = salesOrgCode;
    this.listQuery.pageNum = 1;
    this.loadData();
  },
  // 刷新
  breakData: function breakData() {
    this.loadData();
  },
  // 获取列表数据
  loadData: function loadData() {
    var _this2 = this;
    this.loading = true;
    this.drawer = false;
    var logReq = this.meExportFlag ? exportLogApi.getMeList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm) : exportLogApi.getList(this.listQuery.pageNum, this.listQuery.pageSize, this.searchForm);
    logReq.then(function (res) {
      _this2.loading = false;
      if (!_.isEmpty(res.data)) {
        _this2.tableData = res.data.list;
        _this2.$nextTick(function () {
          _this2.$refs['exportMultipleTable'].doLayout();
        });
        //总条数
        _this2.total = res.data.page.recordsTotal;
      }
    }).catch(function (err) {
      _this2.loading = false;
    });
    return;
  },
  downFile: function downFile(item) {
    var _this3 = this;
    if (item.exportStatus != 'SUCCESS') {
      this.$message({
        message: "\u5BFC\u51FA\u5DF2".concat(item.exportStatusDisplay, "\uFF0C\u4E0D\u80FD\u8FDB\u884C\u4E0B\u8F7D"),
        type: 'warning'
      });
      return;
    }
    exportApi.exportExcel(item.exportId).then(function (res) {
      _this3.loading = false;
      var fileName = item.fileName;
      var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      saveFile(res, contentType, fileName);
      _this3.loadData();
    }).catch(function (err) {
      _this3.loading = false;
    });
  },
  // 获取明细数据
  getData: function getData(row) {
    var _this4 = this;
    this.listLoading = true;
    this.showDetail = true;
    if (row) {
      this.exportId = row.exportId;
    }
    exportLogApi.getDetailList(this.detail.pageNum, this.detail.pageSize, this.exportId).then(function (res) {
      _this4.listLoading = false;
      if (res.code == 200 && res.data) {
        _this4.detailTableData = res.data.list || [];
        _this4.detailTotal = res.data.page && res.data.page.recordsTotal;
      }
    }).catch(function (err) {
      _this4.listLoading = false;
    });
  }
}), _name$components$mixi);