var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.formLoading,
          expression: "formLoading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: {},
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "500px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "addForm",
                  staticStyle: { "margin-bottom": "-20px" },
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _vm.bizType === "OEM"
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "组织机构:",
                                    prop: "ownerOrgCode",
                                  },
                                },
                                [
                                  _c("OemSelect", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      searchForm: _vm.orgForm,
                                      isContainOem: false,
                                    },
                                    on: { nodeClick: _vm.nodeClick },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bizType === "DEALER"
                        ? _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "组织机构:",
                                    prop: "ownerOrgCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: _vm.form.ownerOrgName,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "ownerOrgName", $$v)
                                      },
                                      expression: "form.ownerOrgName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "font-size": "14px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("车主信息")]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车主名:", prop: "ownerName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入车主名",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerName", $$v)
                                  },
                                  expression: "form.ownerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系电话:",
                                prop: "ownerMobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入联系电话",
                                  maxlength: "11",
                                  oninput: "value=value.replace(/[^\\d]/g,'')",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerMobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerMobile", $$v)
                                  },
                                  expression: "form.ownerMobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "其他电话:",
                                prop: "ownerOtherMobile",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入其他电话",
                                  maxlength: "11",
                                  oninput: "value=value.replace(/[^\\d]/g,'')",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerOtherMobile,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerOtherMobile", $$v)
                                  },
                                  expression: "form.ownerOtherMobile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件类型:",
                                prop: "ownerCertificateType",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择证件类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.ownerCertificateType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "ownerCertificateType",
                                        $$v
                                      )
                                    },
                                    expression: "form.ownerCertificateType",
                                  },
                                },
                                _vm._l(
                                  _vm.VI_CERTIFICATE_TYPE,
                                  function (group) {
                                    return _c(
                                      "el-option-group",
                                      {
                                        key: group.label,
                                        attrs: { label: group.label },
                                      },
                                      _vm._l(
                                        group.options,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.dictName,
                                              value: item.dictCode,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "证件号:",
                                prop: "ownerCertificateNo",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  placeholder: "请输入证件号",
                                  clearable: "",
                                },
                                on: {
                                  input: _vm.inputCardNo,
                                  blur: _vm.certificateNoChange,
                                },
                                model: {
                                  value: _vm.form.ownerCertificateNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "ownerCertificateNo",
                                      $$v
                                    )
                                  },
                                  expression: "form.ownerCertificateNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "座机号:",
                                prop: "ownerLandline",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入座机号",
                                  maxlength: "15",
                                  oninput: "value=value.replace(/[^\\d-]/g,'')",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerLandline,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerLandline", $$v)
                                  },
                                  expression: "form.ownerLandline",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "电子邮箱:", prop: "email" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入电子邮箱",
                                  maxlength: "40",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所在地区:" } },
                            [
                              _c("el-cascader", {
                                ref: "cascaderAddr",
                                staticStyle: { width: "100%" },
                                attrs: { options: _vm.options },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.cascaderAddr,
                                  callback: function ($$v) {
                                    _vm.cascaderAddr = $$v
                                  },
                                  expression: "cascaderAddr",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系地址:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入联系地址",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.ownerAddress,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "ownerAddress", $$v)
                                  },
                                  expression: "form.ownerAddress",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "font-size": "14px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("车辆信息")]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车牌号:", prop: "plateNo" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "10",
                                      disabled: _vm.checked,
                                      placeholder: "请输入车牌号",
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.plateNo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "plateNo", $$v)
                                      },
                                      expression: "form.plateNo",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "padding-left": "10px" } },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          on: { change: _vm.noPlateNoChange },
                                          model: {
                                            value: _vm.checked,
                                            callback: function ($$v) {
                                              _vm.checked = $$v
                                            },
                                            expression: "checked",
                                          },
                                        },
                                        [_vm._v("未上牌")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车架号:", prop: "vin" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "17",
                                  placeholder: "请输入车架号",
                                  clearable: "",
                                },
                                on: { input: _vm.inputvin },
                                model: {
                                  value: _vm.form.vin,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "vin", $$v)
                                  },
                                  expression: "form.vin",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "发动机号:", prop: "engineNo" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "20",
                                  placeholder: "请输入发动机号",
                                  clearable: "",
                                },
                                on: { input: _vm.inputEngineNo },
                                model: {
                                  value: _vm.form.engineNo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "engineNo", $$v)
                                  },
                                  expression: "form.engineNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "注册日期:" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择注册日期",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  "picker-options": _vm.disabledLessThan,
                                },
                                model: {
                                  value: _vm.form.registerDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "registerDate", $$v)
                                  },
                                  expression: "form.registerDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "使用性质:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择使用性质",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.useAttributeName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "useAttributeName",
                                        $$v
                                      )
                                    },
                                    expression: "form.useAttributeName",
                                  },
                                },
                                _vm._l(
                                  _vm.USE_ATTRIBUTE,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "品牌型号:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "40",
                                  placeholder: "请输入品牌型号",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.modelName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "modelName", $$v)
                                  },
                                  expression: "form.modelName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "车辆类型:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择车辆类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.vehicleTypeName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "vehicleTypeName", $$v)
                                    },
                                    expression: "form.vehicleTypeName",
                                  },
                                },
                                _vm._l(
                                  _vm.VEHICLE_KINDS,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "号牌种类:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择号牌种类",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.plateType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "plateType", $$v)
                                    },
                                    expression: "form.plateType",
                                  },
                                },
                                _vm._l(_vm.PLATE_TYPE, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.dictName,
                                      value: item.dictCode,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "能源类型:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择能源类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.form.fuelTypeName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "fuelTypeName", $$v)
                                    },
                                    expression: "form.fuelTypeName",
                                  },
                                },
                                _vm._l(
                                  _vm.ENGRGY_TYPES,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.dictName,
                                        value: item.dictCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "整备质量(吨):" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入整备质量",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.wholeWeight = _vm.onInput(
                                      _vm.form.wholeWeight,
                                      0,
                                      1000,
                                      3
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.wholeWeight,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "wholeWeight", $$v)
                                  },
                                  expression: "form.wholeWeight",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "功率(KW):" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入功率(KW)",
                                  clearable: "",
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.form.power = _vm.onInput(
                                      _vm.form.power,
                                      0,
                                      1000,
                                      3
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.form.power,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "power", $$v)
                                  },
                                  expression: "form.power",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "厂牌车型:" } },
                            [
                              _c("CarBrands", {
                                attrs: { carTypeValue: _vm.carTypeVal },
                                on: { carHandleChanged: _vm.CarHandleChange },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "text-align": "left",
                        "font-size": "14px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("上年投保信息")]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "客户类别:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择客户类别",
                                  },
                                  model: {
                                    value: _vm.form.renewalType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "renewalType", $$v)
                                    },
                                    expression: "form.renewalType",
                                  },
                                },
                                _vm._l(
                                  _vm.renewalTypeList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.itemName,
                                        value: item.itemCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商业险保司名称:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择保司类型",
                                  },
                                  model: {
                                    value: _vm.form.bsLastYearInsCorpName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "bsLastYearInsCorpName",
                                        $$v
                                      )
                                    },
                                    expression: "form.bsLastYearInsCorpName",
                                  },
                                },
                                _vm._l(
                                  _vm.insuranceList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.shortName,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "商业险到期日:" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  placeholder: "请输入商业险到期日",
                                },
                                model: {
                                  value: _vm.form.bsLastYearEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bsLastYearEndDate", $$v)
                                  },
                                  expression: "form.bsLastYearEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "交强险保司名称:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    clearable: "",
                                    placeholder: "请选择保司类型",
                                  },
                                  model: {
                                    value: _vm.form.bzLastYearInsCorpName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "bzLastYearInsCorpName",
                                        $$v
                                      )
                                    },
                                    expression: "form.bzLastYearInsCorpName",
                                  },
                                },
                                _vm._l(
                                  _vm.insuranceList,
                                  function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.shortName,
                                        value: item.shortName,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "交强险到期日:" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  type: "date",
                                  placeholder: "请输入交强险到期日",
                                },
                                model: {
                                  value: _vm.form.bzLastYearEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "bzLastYearEndDate", $$v)
                                  },
                                  expression: "form.bzLastYearEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-check",
                type: "primary",
                plain: "",
                size: "mini",
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.closePage(true)
                },
              },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }