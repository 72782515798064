var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "app-m-cls vehicle-sales-maintain" }, [
      _c(
        "div",
        { staticClass: "table-cls normal-btn-cls" },
        [
          _c(
            "el-card",
            { staticClass: "box-card", attrs: { shadow: "never" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _c("div", { staticClass: "search-box" }, [
                  _vm.bizType === "BROKER"
                    ? _c(
                        "div",
                        [
                          _c("span", [_vm._v("车销门店：")]),
                          _vm.bizType === "BROKER"
                            ? _c("OrgSelect", {
                                staticStyle: {
                                  "max-width": "150px",
                                  display: "inline-block",
                                },
                                attrs: { searchForm: _vm.orgForm },
                                on: { nodeClick: _vm.nodeClick },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm.bizType === "OEM"
                    ? _c(
                        "div",
                        [
                          _c("span", [_vm._v("车销门店：")]),
                          _c("OemSelect", {
                            staticStyle: {
                              "max-width": "150px",
                              display: "inline-block",
                            },
                            attrs: {
                              searchForm: _vm.orgForm,
                              canClickArea: true,
                            },
                            on: { nodeClick: _vm.nodeClick },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c("span", [_vm._v("车销门店：")]),
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.searchForm.ownerOrgName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "ownerOrgName", $$v)
                              },
                              expression: "searchForm.ownerOrgName",
                            },
                          }),
                        ],
                        1
                      ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("车架号：")]),
                      _c("el-input", {
                        attrs: {
                          maxlength: "17",
                          placeholder: "请输入车架号",
                          clearable: "",
                        },
                        on: { input: _vm.checkValue },
                        model: {
                          value: _vm.searchForm.frameNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "frameNo", $$v)
                          },
                          expression: "searchForm.frameNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("span", [_vm._v("车销日期：")]),
                      _c("DatePicker", {
                        attrs: {
                          pickerDateTime: _vm.salesStartEndTime,
                          startDate: "salesStartDate",
                          endDate: "salesEndDate",
                          width: "230px",
                        },
                        on: {
                          pickerDateChange: function ($event) {
                            return _vm.getPickersDate(
                              "salesStartEndTime",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.resetSearch },
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.searchData },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.breakData },
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini" },
                          on: { click: _vm.exportExcels },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "empty-text": "默认不查询数据，请点击查询按钮进行查询",
                    size: "mini",
                    border: "",
                    data: _vm.tableData,
                    height: _vm.tableHeightERR,
                    "header-cell-style": { background: "#F7F7F7" },
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "frameNo",
                      label: "车架号",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vehicleName",
                      label: "厂牌车型",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "销售日期",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.salesDate &&
                                    scope.row.salesDate.split(" ")[0]
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "交车日期",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.deliveryEndDate &&
                                    scope.row.deliveryEndDate.split(" ")[0]
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "buyerName",
                      label: "车主名",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "buyerTel1",
                      label: "联系电话",
                      width: "110",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.buyerTel1
                                    ? scope.row.buyerTel1.replace(
                                        /^(.{3})(?:\d+)(.{2})$/,
                                        "$1******$2"
                                      )
                                    : ""
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contractNo",
                      label: "合同编号",
                      width: "130",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "退车日期",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.salesCancelDate &&
                                    scope.row.salesCancelDate.split(" ")[0]
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sales",
                      label: "销售员",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "dealerCode",
                      label: "门店编码",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vhcBodyPrice",
                      label: "车辆本体价格",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "vhcBodyPay",
                      label: "销售价格",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "insurancePolicyNo",
                      label: "保单号",
                      width: "180",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "保单起期",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.effectiveDateStart &&
                                    scope.row.effectiveDateStart.split(" ")[0]
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "保单止期",
                      width: "100",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  scope.row.effectiveDateEnd &&
                                    scope.row.effectiveDateEnd.split(" ")[0]
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      width: "100",
                      align: "center",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.viewDetail(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("pagination", {
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.pageNum,
                  limit: _vm.listQuery.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "pageSize", $event)
                  },
                  pagination: function ($event) {
                    return _vm.loadData()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }