import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import slipRequest from "@/api/insuranceslip";
import OrgSelect from '@/components/OrgSelect';
import commonRequest from "@/api/common";
import { character, getCityItem } from "@/utils/common";
export default {
  components: {
    OrgSelect: OrgSelect
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      form: {
        bizType: 'BROKER',
        orgCode: '',
        insCityCode: '',
        plateNo: '',
        vin: '',
        engineNo: '',
        ownerCertificateNo: '',
        ownerName: '',
        insuredName: '',
        lastPolicyNo: ''
      },
      formRules: {
        orgCode: {
          required: true,
          message: '请选择业务机构',
          trigger: ['change', 'blur']
        },
        insCityCode: {
          required: true,
          message: '请选择当前续保渠道',
          trigger: 'change'
        },
        vin: [{
          required: false,
          message: "请输入车架号",
          trigger: "blur"
        }, {
          min: 17,
          max: 17,
          message: "输入的车架号不等于17位",
          trigger: ["blur", "change"]
        }]
      },
      orgData: {
        salesOrgName: "",
        salesOrgCode: ""
      },
      policyCityItems: [],
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      var _this = this;
      this.form = {
        bizType: 'BROKER',
        orgCode: '',
        insCityCode: '',
        plateNo: '',
        vin: '',
        engineNo: '',
        ownerCertificateNo: '',
        ownerName: '',
        insuredName: '',
        lastPolicyNo: ''
      };
      this.orgData = {
        salesOrgName: "",
        salesOrgCode: ""
      };
      this.policyCityItems = [];
      this.$nextTick(function () {
        _this.$refs.form.resetFields();
      });
    },
    getChannels: function getChannels(orgCode) {
      var _this2 = this;
      //获取渠道信息
      slipRequest.getChannels(orgCode).then(function (res) {
        if (res.code == 200) {
          console.log(res.data, 'dat');
          var items = res.data;
          _this2.policyCityItems = [];
          items = _.uniqWith(items, _.isEqual);
          items.forEach(function (item) {
            var cityItem = {};
            cityItem = getCityItem(item.insCityCode);
            _this2.policyCityItems.push(cityItem);
          });
          if (!_.isEmpty(_this2.policyCityItems)) {
            _this2.policyCityItems = _.uniqWith(_this2.policyCityItems, _.isEqual);
          }
          ;
        }
      }).catch(function (error) {});
    },
    nodeClick: function nodeClick(val) {
      this.form.orgCode = val.salesOrgCode;
      this.getChannels(this.form.orgCode);
    },
    inputvin: function inputvin(val) {
      //监听输入事件
      if (val) {
        val = val.replace(/i/g, '').replace(/I/g, '').replace(/o/g, '').replace(/O/g, '').replace(/q/g, '').replace(/Q/g, '');
        var quoteVehicle = _.cloneDeep(this.form);
        quoteVehicle.vin = character(_.toUpper(val));
        this.form = quoteVehicle;
        this.$forceUpdate();
      }
    },
    pastevin: function pastevin(val) {
      //监听粘贴事件
      var _clipboardData = val.clipboardData || window.clipboardData;
      var text = _clipboardData.getData('text');
      if (text) {
        text = text.replace(/\r\n/g, '').trim();
        this.inputvin(text);
      }
    },
    //关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    // 校验
    validate: function validate() {
      var flag = false;
      if (!this.form.plateNo && !this.form.vin) {
        this.$message.warning("\u8F66\u724C\u53F7\u548C\u8F66\u67B6\u53F7\u4E0D\u80FD\u540C\u65F6\u4E3A\u7A7A");
        flag = true;
        return flag;
      }
      ;
      return flag;
    },
    save: function save() {
      var _this3 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          if (_this3.validate()) return;
          _this3.formLoading = true;
          commonRequest.queryRenewal(_this3.form).then(function (res) {
            _this3.formLoading = false;
            if (res.code === 200) {
              _this3.$message.success(res.msg);
              _this3.closePage();
            }
            ;
          }).catch(function (err) {
            _this3.formLoading = false;
          });
        }
      });
    }
  }
};