import "core-js/modules/es.array.concat.js";
/**
 * 人员管理
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 列表
   * @param {*} data 数据
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示数量
   */
  getList: function getList(pageNum, pageSize, data) {
    return request.get(getUrl("/employee/train/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 4), {
      params: data
    });
  },
  /**
  * 列表
  * @param {*} data 更新明细数据
  */
  getData: function getData(data) {
    return request.get(getUrl("/employee/train/change/search", 4), {
      params: data
    });
  },
  /**
   * 查询
   * @param {*} id 
   */
  query: function query(id) {
    return request.get(getUrl("/employee/".concat(id), 4));
  },
  /**
   * 新增薪酬数据
   * @param {*} data 内容
   */
  add: function add(data) {
    return request.post(getUrl("/employee/train/create", 4), data);
  },
  /**
   * 更新薪酬数据
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  update: function update(data) {
    return request.post(getUrl("/employee/train/update", 4), data);
  },
  /**
   * 删除
   * @param {*} id
   */
  delete: function _delete(id) {
    return request.delete(getUrl("/employee/".concat(id), 4));
  },
  /**
   * 重置
   * @param {*} id
   */
  resetPwd: function resetPwd(id) {
    return request.delete(getUrl("/employee/".concat(id, "/password"), 4));
  },
  /**
   * 查询当前机构下的人员
   * @param {*} orgCode
   */
  salesmanByOrgCode: function salesmanByOrgCode(orgCode) {
    return request.get(getUrl("/employee/".concat(orgCode, "/org"), 4));
  },
  /**
   * 修改密码
   * @param {*} userId
   */
  modifyPwd: function modifyPwd(userId, data) {
    return request.put(getUrl("/users/".concat(userId, "/password"), 5), data);
  }
};
export default objApi;