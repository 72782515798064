import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Pagination from '@/components/Pagination';
import ImportDetailDialog from './importDetailDialog';
import OemSelect from '@/components/OemSelect';
import UploadBillDialog from './uploadBillDialog';
import request from '@/api/finance';
import { saveFile, getUserOrgInfoDealerOem, getSupplyers, downFile } from "@/utils/common";
export default {
  components: {
    Pagination: Pagination,
    ImportDetailDialog: ImportDetailDialog,
    OemSelect: OemSelect,
    UploadBillDialog: UploadBillDialog
  },
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    bizType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20
      },
      searchForm: {},
      tableData: [],
      loading: false,
      orgForm: {
        salesOrgCode: '',
        salesOrgName: '',
        salesOrgType: ''
      },
      showDialog: false,
      showImportDialog: false,
      currentId: '',
      drawer: false,
      createdDateTime: [],
      supplyers: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.resetSearch();
        this.loadData();
      }
      ;
    }
  },
  created: function created() {
    if (this.bizType === "DEALER") {
      this.initData();
    }
    ;
    this.supplyers = getSupplyers();
    this.loadData();
  },
  methods: {
    dateChange: function dateChange(start, end, val, type) {
      //日期选择事件
      if (val && val.length > 1) {
        this.searchForm[start] = val[0];
        this.searchForm[end] = val[1];
      } else {
        this.searchForm[start] = null;
        this.searchForm[end] = null;
      }
      ;
    },
    // 初始化数据
    initData: function initData() {
      var orgObj = getUserOrgInfoDealerOem(this.bizType);
      this.searchForm.orgCode = orgObj.salesOrgCode;
      this.searchForm.orgName = orgObj.salesOrgName;
    },
    handleNodeClick: function handleNodeClick(data) {
      // 机构树点击事件
      this.searchForm.orgCode = data.salesOrgCode;
      this.searchForm.orgName = data.salesOrgName;
      this.searchForm.areaType = data.salesOrgType;
    },
    resetSearch: function resetSearch() {
      //重置搜索表单
      this.searchForm = {
        orgName: this.bizType === 'OEM' ? '' : this.searchForm.orgName,
        orgCode: this.bizType === 'OEM' ? '' : this.searchForm.orgCode
      };
      this.orgForm = {
        salesOrgName: this.bizType === 'OEM' ? '全部' : '',
        salesOrgType: '',
        salesOrgCode: ''
      };
      this.createdDateTime = [];
    },
    searchData: function searchData() {
      //搜索
      this.listQuery.pageNum = 1;
      this.loadData();
    },
    breakData: function breakData() {
      // 刷新
      // this.resetSearch();
      this.loadData();
    },
    // 获取列表数据
    loadData: function loadData() {
      var _this = this;
      this.showDialog = false;
      this.showImportDialog = false;
      this.loading = true;
      this.drawer = false;
      if (this.searchForm && this.searchForm.createdTimeEnd) {
        this.searchForm.createdTimeEnd = this.searchForm.createdTimeEnd.split(' ')[0] + ' 23:59:59';
      }
      var data = _objectSpread(_objectSpread({}, this.searchForm), this.listQuery);
      request.invoiceImportSearch(data).then(function (res) {
        _this.loading = false;
        if (res.code === 200) {
          _this.tableData = res.data.list;
          _this.total = res.data.page.recordsTotal;
        }
        ;
      }).catch(function (err) {
        _this.loading = false;
      });
    },
    // 关闭弹窗
    closePage: function closePage() {
      this.$emit('closePage');
    },
    // 详情
    toDetails: function toDetails(item) {
      this.currentId = item.repertoireId;
      this.showDialog = true;
    },
    // 下载模板
    downloadTemplate: function downloadTemplate() {
      request.download().then(function (res) {
        var fileName = "保司台账模板.xlsx";
        var contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        saveFile(res, contentType, fileName);
      });
    },
    // 打开文件
    openFile: function openFile(item) {
      downFile(item.fileUrl, item.fileName);
    },
    // 上传文件
    uploadFile: function uploadFile() {
      this.showImportDialog = true;
    }
  }
};