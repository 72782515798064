import "core-js/modules/es.array.concat.js";
/**
 * 报表接口
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /**
   * 导出
   */
  exportExcel: function exportExcel(data) {
    return request({
      method: 'POST',
      url: getUrl("/product/exportExcel/table", 1),
      data: data,
      responseType: 'blob'
    });
  },
  /**
   * 投保率(新保.续保)
   */
  getReportList: function getReportList(data) {
    return request.get(getUrl("/statistics/report/insured/rate", 1), {
      params: data
    });
  },
  /**
   * 统计报表累计
   * @param {*} data
   */
  getReportTotalList: function getReportTotalList(data) {
    return request.get(getUrl("/statistics/report/cumulative", 1), {
      params: data
    });
  },
  /**
   * 统计报表详情
   * @param {*} data
   */
  getReportDetals: function getReportDetals(data) {
    return request.get(getUrl("/statistics/report/detals", 1), {
      params: data
    });
  },
  /**
   * 获取保险公司列表
   */

  getInsuranceList: function getInsuranceList(type) {
    return request.get(getUrl("/suppliers/getByType?type=".concat(type)), 3);
  },
  /**
   * 获取省级区域列表
   */
  getAreaList: function getAreaList(id) {
    return request.get(getUrl("/area/province/district/list?oemCode=".concat(id), 13));
  },
  /**
   * 分页查询监管报表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getSuperviseList: function getSuperviseList(pageNum, pageSize, data) {
    return request.get(getUrl("/supervises?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 批量删除监管报表
   * @param {*} data
   */
  deleteSuperviseList: function deleteSuperviseList(data) {
    return request.post(getUrl("/supervises/delete", 1), data);
  },
  /**
   * 下载监管报表模板
   * @param {*} type 报表类型
   */
  downloadSupervise: function downloadSupervise(type) {
    return request({
      method: 'get',
      url: getUrl("/supervises/downloadTemplate?statisticsType=".concat(type), 1),
      responseType: 'blob'
    });
  },
  /**
   * 监管报表导入
   * @param {*} data
   */
  importSupervise: function importSupervise(data) {
    return request.post(getUrl("/supervises/importSupervise", 1), data);
  },
  /**
   * 监管报表利润明细
   * @param {*} id
   */
  incomeStatementDetails: function incomeStatementDetails(id) {
    return request.get(getUrl("/supervises/".concat(id, "/profit"), 1));
  },
  /**
   * 监管报表资产负债明细
   * @param {*} id
   */
  assetsLiabilitiesDetails: function assetsLiabilitiesDetails(id) {
    return request.get(getUrl("/supervises/".concat(id, "/balance"), 1));
  },
  /**
   * 监管报表机构情况明细
   * @param {*} id
   */
  institutionalSituationDetails: function institutionalSituationDetails(id) {
    return request.get(getUrl("/supervises/".concat(id, "/org"), 1));
  },
  /**
   * 监管报表产险明细
   * @param {*} id
   */
  productInsuranceDetails: function productInsuranceDetails(id) {
    return request.get(getUrl("/supervises/".concat(id, "/insurance"), 1));
  },
  /**
   * 保单详情
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getPolicyDetalsList: function getPolicyDetalsList(pageNum, pageSize, data) {
    return request.get(getUrl("/statistics/report/policy/detals?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 车辆销售详情
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getVehicleSalesDetalsList: function getVehicleSalesDetalsList(pageNum, pageSize, data) {
    return request.get(getUrl("/statistics/report/vehicleSales/detals?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 更新数据
   * @param {*} type
   */
  updateData: function updateData(type) {
    return request.get(getUrl("/statistics/report/update?type=".concat(type), 1));
  },
  /**
   * 获取保单统计最新修改时间
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getPolicyModifiedTime: function getPolicyModifiedTime(pageNum, pageSize, data) {
    return request.get(getUrl("/statistics/report/policy/updateDate?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  },
  /**
   * 车辆销售最新修改时间
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getVehicleSalesModifiedTime: function getVehicleSalesModifiedTime(pageNum, pageSize, data) {
    return request.get(getUrl("/statistics/report/vehicleSales/updateDate?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 1), {
      params: data
    });
  }
};
export default objApi;