var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-m-cls",
      staticStyle: { "max-height": "600px" },
    },
    [
      _c(
        "div",
        {
          ref: "header",
          staticClass: "header-cls",
          staticStyle: { "max-height": "550px", overflow: "auto" },
        },
        [
          _c(
            "el-card",
            { attrs: { shadow: "never" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.formRules,
                    "label-width": "110px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "原始值：",
                                prop: "primitiveValue",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入原始值",
                                  maxlength: "40",
                                  disabled: _vm.dialogType == "view",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.primitiveValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "primitiveValue", $$v)
                                  },
                                  expression: "form.primitiveValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 22 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "替换值：",
                                prop: "replaceValue",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入替换值",
                                  maxlength: "40",
                                  disabled: _vm.dialogType == "view",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.replaceValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "replaceValue", $$v)
                                  },
                                  expression: "form.replaceValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "center",
            "margin-top": "8px",
          },
        },
        [
          _vm.dialogType != "view"
            ? _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-check",
                    type: "primary",
                    plain: "",
                    size: "mini",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-close", size: "mini" },
              on: { click: _vm.closePage },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }