var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-m-cls history-policy-manage" }, [
    _c(
      "div",
      { staticClass: "table-cls normal-btn-cls" },
      [
        _c(
          "el-drawer",
          {
            attrs: {
              title: "",
              visible: _vm.drawer,
              modal: false,
              "append-to-body": true,
              size: "370px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.drawer = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "form",
                attrs: {
                  model: _vm.searchForm,
                  "label-width": "110px",
                  size: "mini",
                },
              },
              [
                _vm.bizType === "OEM"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "经销商编码" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入经销商编码",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.dealercode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "dealercode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.dealercode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.bizType === "DEALER"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "经销商编码" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入经销商编码",
                                    disabled: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.dealercode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "dealercode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.dealercode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车牌号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "10",
                                placeholder: "请输入车牌号",
                                clearable: "",
                              },
                              on: { input: _vm.inputPlateNo },
                              model: {
                                value: _vm.searchForm.registerno,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "registerno", $$v)
                                },
                                expression: "searchForm.registerno",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "车架号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "17",
                                placeholder: "请输入车架号",
                                clearable: "",
                              },
                              on: { input: _vm.checkValue },
                              model: {
                                value: _vm.searchForm.vinno,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "vinno", $$v)
                                },
                                expression: "searchForm.vinno",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "系统注册日期" } },
                          [
                            _c("DatePicker", {
                              attrs: {
                                pickerDateTime: _vm.sysregStartEndTime,
                                startDate: "sysregtimeStart",
                                endDate: "sysregtimeEnd",
                                width: "100%",
                              },
                              on: {
                                pickerDateChange: function ($event) {
                                  return _vm.getPickersDate(
                                    "sysregStartEndTime",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商业险签单日期" } },
                          [
                            _c("DatePicker", {
                              attrs: {
                                pickerDateTime: _vm.cibillStartEndTime,
                                startDate: "cibilltimeStart",
                                endDate: "cibilltimeEnd",
                                width: "100%",
                              },
                              on: {
                                pickerDateChange: function ($event) {
                                  return _vm.getPickersDate(
                                    "cibillStartEndTime",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "交强险签单日期" } },
                          [
                            _c("DatePicker", {
                              attrs: {
                                pickerDateTime: _vm.salibillStartEndTime,
                                startDate: "salibilltimeStart",
                                endDate: "cibilltsalibilltimeEndimeEnd",
                                width: "100%",
                              },
                              on: {
                                pickerDateChange: function ($event) {
                                  return _vm.getPickersDate(
                                    "salibillStartEndTime",
                                    $event
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "交强险保单号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入交强险保单号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.searchForm.saliid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "saliid", $$v)
                                },
                                expression: "searchForm.saliid",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 22 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "商业险保单号" } },
                          [
                            _c("el-input", {
                              attrs: {
                                maxlength: "40",
                                placeholder: "请输入商业险保单号",
                                clearable: "",
                              },
                              model: {
                                value: _vm.searchForm.ciid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "ciid", $$v)
                                },
                                expression: "searchForm.ciid",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "div",
                        { staticClass: "searchBtnStyle" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                icon: "el-icon-refresh-left",
                              },
                              on: { click: _vm.resetSearch },
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "primary",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.searchData },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-cls normal-btn-cls" },
      [
        _c(
          "el-card",
          { staticClass: "box-card", attrs: { shadow: "never" } },
          [
            _c("div", { attrs: { slot: "header" }, slot: "header" }, [
              _c("div", { staticClass: "search-box" }, [
                _vm.bizType === "OEM"
                  ? _c(
                      "div",
                      [
                        _c("span", [_vm._v("经销商编码：")]),
                        _c("el-input", {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            placeholder: "请输入经销商编码",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.dealercode,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "dealercode", $$v)
                            },
                            expression: "searchForm.dealercode",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.bizType === "DEALER"
                  ? _c(
                      "div",
                      [
                        _c("span", [_vm._v("经销商编码：")]),
                        _c("el-input", {
                          staticStyle: { display: "inline-block" },
                          attrs: {
                            placeholder: "请输入经销商编码",
                            disabled: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.searchForm.dealercode,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "dealercode", $$v)
                            },
                            expression: "searchForm.dealercode",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c("span", [_vm._v("车牌号：")]),
                    _c("el-input", {
                      attrs: {
                        maxlength: "10",
                        placeholder: "请输入车牌号",
                        clearable: "",
                      },
                      on: { input: _vm.inputPlateNo },
                      model: {
                        value: _vm.searchForm.registerno,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "registerno", $$v)
                        },
                        expression: "searchForm.registerno",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "65px", "font-size": "13px" } },
                      [_vm._v("车架号：")]
                    ),
                    _c("el-input", {
                      staticStyle: { "max-width": "200px" },
                      attrs: {
                        maxlength: "17",
                        placeholder: "请输入车架号",
                        clearable: "",
                      },
                      on: { input: _vm.checkValue },
                      model: {
                        value: _vm.searchForm.vinno,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "vinno", $$v)
                        },
                        expression: "searchForm.vinno",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", icon: "el-icon-refresh-left" },
                        on: { click: _vm.resetSearch },
                      },
                      [_vm._v("重置")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-search",
                        },
                        on: { click: _vm.searchData },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "search-btn" },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.breakData } },
                      [_vm._v("刷新")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.drawer = true
                          },
                        },
                      },
                      [_vm._v("搜索")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "multipleTable",
                staticStyle: { width: "100%" },
                attrs: {
                  border: "",
                  size: "mini",
                  data: _vm.tableData,
                  height: _vm.tableHeightJD,
                  "header-cell-style": { background: "#F7F7F7" },
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "dealer_abbr",
                    label: "经销商简称",
                    width: "150",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "dealercode",
                    label: "经销店编码",
                    width: "150",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "applicant",
                    label: "投保人",
                    width: "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "applicantphone",
                    label: "投保人电话",
                    width: "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.applicantphone
                                  ? _vm._.trim(
                                      scope.row.applicantphone
                                    ).replace(
                                      /^(.{3})(?:\d+)(.{2})$/,
                                      "$1******$2"
                                    )
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "assured",
                    label: "被保险人",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "assuredphone",
                    label: "被保险人电话",
                    width: "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.assuredphone
                                  ? scope.row.assuredphone.replace(
                                      /^(.{3})(?:\d+)(.{2})$/,
                                      "$1******$2"
                                    )
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "engine",
                    label: "发动机号",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "carusetypeDescription",
                    label: "车辆使用性质",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "vinno",
                    label: "车架号",
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "registerno",
                    label: "车牌号",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salicompanyname",
                    label: "交强险保险公司",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "saliid",
                    label: "交强险保单号",
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salibill",
                    label: "交强险保费",
                    "min-width": "100",
                    align: "right",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salibilltime",
                    label: "交强险签单日期",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salistarttime",
                    label: "交强险有效起期",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "saliendtime",
                    label: "交强险有效止期",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cicompanyname",
                    label: "商业险保险公司",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "ciid",
                    label: "商业险保单号",
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cibill",
                    label: "商业险保费",
                    "min-width": "100",
                    align: "right",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cibilltime",
                    label: "商业险签单日期",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cistarttime",
                    label: "商业险有效起期",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "ciendtime",
                    label: "商业险有效止期",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "dlwcoverage",
                    label: "车损险保额(元)",
                    "min-width": "120",
                    align: "right",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "crcoverageDescription",
                    label: "三者险保额(万元)",
                    align: "right",
                    "min-width": "130",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "potcidiverbillDescription",
                    label: "车上人员责任险司机保额",
                    align: "right",
                    "min-width": "170",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "potcipassengerbillDescription",
                    label: "车上人员责任险乘客(1人)保额",
                    align: "right",
                    "min-width": "200",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "assuredphone5",
                    label: "被保人电话后5位",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "mortgageornotDescription",
                    label: "是否按揭",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "vhccodeDescription",
                    label: "车型",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "othervhccode",
                    label: "其他车型",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "billprice",
                    label: "发票价格",
                    "min-width": "100",
                    align: "right",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "billtime",
                    label: "发票时间",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "vinnod7",
                    label: "vin码后7位",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "registernod4",
                    label: "车牌号后4位",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "insurancestateDescription",
                    label: "保险状态",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "insurancetypeDescription",
                    label: "投保类型",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "saliornotDescription",
                    label: "购买交强险",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salimethodsDescription",
                    label: "交强险投保方式",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "saliplancodeDescription",
                    label: "交强险认证险种",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.saliplancodeDescription === "C01"
                                  ? "商业险"
                                  : scope.row.saliplancodeDescription === "C51"
                                  ? "交强险"
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salichannel",
                    label: "交强险渠道名称",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salisalecode",
                    label: "交强险车商代码",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salisalename",
                    label: "交强险车商名称",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "ciornotDescription",
                    label: "购买商业险",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cimethodsDescription",
                    label: "商业险投保方式",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "ciplancodeDescription",
                    label: "商业险认证险种",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.ciplancodeDescription === "C01"
                                  ? "商业险"
                                  : scope.row.ciplancodeDescription === "C51"
                                  ? "交强险"
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cichannel",
                    label: "商业险渠道名称",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cisalecode",
                    label: "商业险车商代码",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "cisalename",
                    label: "商业险车商名称",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "dlwiopornotDescription",
                    label: "车损险不计免赔",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "criopornotDescription",
                    label: "三者险不计免赔",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "ssibillDescription",
                    label: "车身划痕险保额",
                    "min-width": "120",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "dlrsiornotDescription",
                    label: "4s店专修险",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.dlrsiornotDescription == "0"
                                  ? "未购买"
                                  : scope.row.dlrsiornotDescription == "1"
                                  ? "购买"
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "others1",
                    label: "其他1",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "others2",
                    label: "其他2",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "others3",
                    label: "其他3",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "salesagency",
                    label: "销售经办",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "entryclerk",
                    label: "录入员",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "sysregtime",
                    label: "系统注册日期",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "updatecount",
                    label: "更新记数器",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "potcidiverbillother",
                    label: "司机保额其他",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "potcipassengerbillother",
                    label: "乘客保额其他",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "applicantcode",
                    label: "投保人代码",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "assuredcode",
                    label: "被投保人代码",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "headdealercode",
                    label: "总店经销店",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "branchcompanyno",
                    label: "分店编号",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.branchcompanyno
                                  ? scope.row.branchcompanyno + "号店"
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "vhcsalesdate",
                    label: "新车销售时间",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "is_del",
                    label: "删除标记",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "create_time",
                    label: "创建日期",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "update_time",
                    label: "更新时间",
                    "min-width": "100",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                scope.row.update_time
                                  ? scope.row.update_time.slice(0, 10)
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "操作", "min-width": "80", fixed: "right" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.viewDetail(scope.row)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c("pagination", {
              attrs: {
                total: _vm.total,
                page: _vm.listQuery.pageNum,
                limit: _vm.listQuery.pageSize,
              },
              on: {
                "update:page": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageNum", $event)
                },
                "update:limit": function ($event) {
                  return _vm.$set(_vm.listQuery, "pageSize", $event)
                },
                pagination: function ($event) {
                  return _vm.loadData()
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }