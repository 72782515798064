import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
/**
 * 车险对接配置服务
 */
import request from '@/utils/request';
import getUrl from '@/utils/get-url';
var objApi = {
  /************************************对接服务配置*******************************************************/
  /**
   * 创建配置
   * @param {*} data 内容
   */
  createServiceConfig: function createServiceConfig(data) {
    return request.post(getUrl("/serviceConfigs/create", 14), data);
  },
  /**
   * 删除配置
   * @param {*} ids 
   */
  deleteServiceConfig: function deleteServiceConfig(ids) {
    return request.delete(getUrl("/serviceConfigs/delete?serviceConfigIdList=".concat(ids.toString()), 14));
  },
  /**
   * 修改配置
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  updateServiceConfig: function updateServiceConfig(serviceConfigId, data) {
    return request.put(getUrl("/serviceConfigs/update?serviceConfigId=".concat(serviceConfigId), 14), data);
  },
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getServiceConfigList: function getServiceConfigList(pageNum, pageSize, data) {
    return request.get(getUrl("/serviceConfigs/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 14), {
      params: data
    });
  },
  /**
   * 获取配置（保司编码）
   * @param {*} insCorpCode 保险公司编码
   */
  getServiceConfigByInsCorpCode: function getServiceConfigByInsCorpCode(insCorpCode) {
    return request.get(getUrl("/serviceConfigs/getByInsCorpCode?insCorpCode=".concat(insCorpCode), 14));
  },
  /**
   * 获取配置（服务编码）
   * @param {*} serviceCode 服务编码
   */
  getServiceConfigByServiceCode: function getServiceConfigByServiceCode(serviceCode) {
    return request.get(getUrl("/serviceConfigs/getByCode?serviceCode=".concat(serviceCode), 14));
  },
  /**
   * 获取配置（主键ID）
   * @param {*} serviceConfigId 主键ID
   */
  getServiceConfigById: function getServiceConfigById(serviceConfigId) {
    return request.get(getUrl("/serviceConfigs/get?serviceConfigId=".concat(serviceConfigId), 14));
  },
  /**
   * 启用或禁用
   * @param {*} serviceConfigId 主键ID
   */
  changeServiceConfigStatus: function changeServiceConfigStatus(serviceConfigId, enable) {
    return request.put(getUrl("/serviceConfigs/changeStatus?serviceConfigId=".concat(serviceConfigId, "&&enable=").concat(enable), 14));
  },
  /************************************保司接口配置*******************************************************/
  /**
   * API配置列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getApiConfigList: function getApiConfigList(pageNum, pageSize, data) {
    return request.get(getUrl("/insCorpApiConfig/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 14), {
      params: data
    });
  },
  /**
   * API配置详情
   * @param {*} id 查询参数
   */
  getApiConfigById: function getApiConfigById(apiConfigId) {
    return request.get(getUrl("/insCorpApiConfig/get?apiConfigId=".concat(apiConfigId), 14));
  },
  /**
   * 获取配置（配置编码）
   * @param {*} apiConfigCode 配置编码
   */
  getApiConfigByCode: function getApiConfigByCode(apiConfigCode) {
    return request.get(getUrl("/insCorpApiConfig/getByCode?apiConfigCode=".concat(apiConfigCode), 14));
  },
  /**
   * 获取配置（保司编码，接口类型）
   * @param {*} insCorpApiType 接口类型
   * @param {*} insCorpCode 保司编码
   */
  getApiConfigByInsCorpCode: function getApiConfigByInsCorpCode(apiConfigType, insCorpCode) {
    return request.get(getUrl("/insCorpApiConfig/getByInsCorpCode", 14), {
      params: {
        apiConfigType: apiConfigType,
        insCorpCode: insCorpCode
      }
    });
  },
  /**
   * 新增API配置
   * @param {*} data 内容
   */
  createApiConfig: function createApiConfig(data) {
    return request.post(getUrl("/insCorpApiConfig/create", 14), data);
  },
  /**
   * 修改API配置
   * @param {*} apiConfigId 查询参数
   * @param {*} data 内容
   */
  updateApiConfig: function updateApiConfig(apiConfigId, data) {
    return request.put(getUrl("/insCorpApiConfig/update?apiConfigId=".concat(apiConfigId), 14), data);
  },
  /**
   * 删除API配置
   * @param {*} ids 
   */
  deleteApiConfig: function deleteApiConfig(ids) {
    return request.delete(getUrl("/insCorpApiConfig/delete?apiConfigIdList=".concat(ids.toString()), 14));
  },
  /**
   * 获取API配置回调地址
   * @param {*} apiConfigId 
   */
  getApiConfigCallbackUrl: function getApiConfigCallbackUrl(apiConfigId) {
    return request.get(getUrl("/insCorpApiConfig/getApiNotifyUrlList?apiConfigId=".concat(apiConfigId), 14));
  },
  /**
   * 启用或禁用
   * @param {*} apiConfigId 主键ID
   */
  changeApiConfigStatus: function changeApiConfigStatus(apiConfigId, enable) {
    return request.put(getUrl("/insCorpApiConfig/changeStatus?apiConfigId=".concat(apiConfigId, "&&enable=").concat(enable), 14));
  },
  /************************************保司工号配置*******************************************************/
  /**
   * 列表
   * @param {*} pageNum 当前页
   * @param {*} pageSize 每页显示条数
   * @param {*} data 内容
   */
  getChannelConfigList: function getChannelConfigList(pageNum, pageSize, data) {
    return request.get(getUrl("/insCorpChannel/search?pageNum=".concat(pageNum, "&pageSize=").concat(pageSize), 14), {
      params: data
    });
  },
  /**
   * 详情
   * @param {*} id 查询参数
   */
  getChannelConfigById: function getChannelConfigById(channelConfigId) {
    return request.get(getUrl("/insCorpChannel/get?channelConfigId=".concat(channelConfigId), 14));
  },
  /**
   * 获取配置（渠道信息）
   * @param {*} insCityCode 投保城市编码
   * @param {*} insCorpCode 投保保司编码
   * @param {*} insCorpSysId 保司系统标识
   * @param {*} insChannelName 渠道名称
   */
  getApiConfigByChannel: function getApiConfigByChannel(insCityCode, insCorpCode, insCorpSysId, insChannelName) {
    return request.get(getUrl("/insCorpChannel/getApiConfigByChannel", 14), {
      params: {
        insCityCode: insCityCode,
        insCorpCode: insCorpCode,
        insCorpSysId: insCorpSysId,
        insChannelName: insChannelName
      }
    });
  },
  /**
   * 获取配置（渠道信息）
   * @param {*} insCityCode 投保城市编码
   * @param {*} insCorpCode 投保保司编码
   * @param {*} insCorpSysId 保司系统标识
   * @param {*} insChannelName 渠道名称
   */
  getBizConfigByChannel: function getBizConfigByChannel(insCityCode, insCorpCode, insCorpSysId, insChannelName) {
    return request.get(getUrl("/insCorpChannel/getBizConfigByChannel", 14), {
      params: {
        insCityCode: insCityCode,
        insCorpCode: insCorpCode,
        insCorpSysId: insCorpSysId,
        insChannelName: insChannelName
      }
    });
  },
  /**
   * 新增
   * @param {*} data 内容
   */
  createChannelConfig: function createChannelConfig(data) {
    return request.post(getUrl("/insCorpChannel/create", 14), data);
  },
  /**
   * 修改
   * @param {*} id 查询参数
   * @param {*} data 内容
   */
  updateChannelConfig: function updateChannelConfig(channelConfigId, data) {
    return request.put(getUrl("/insCorpChannel/update?channelConfigId=".concat(channelConfigId), 14), data);
  },
  /**
   * 删除
   * @param {*} ids 
   */
  deleteChannelConfig: function deleteChannelConfig(ids) {
    return request.delete(getUrl("/insCorpChannel/delete?channelConfigIdList=".concat(ids.toString()), 14));
  },
  /************************************保司配置结构接口*******************************************************/
  /**
   * 获取保司接口配置
   * @param {*} insCorpCode 投保保司编码
   * @param {*} serviceConfigCode 对接服务配置编码
   */
  getApiSchemaConfig: function getApiSchemaConfig(insCorpCode, serviceConfigCode, apiConfigType) {
    return request.get(getUrl("/settingSchema/getApiSchema", 14), {
      params: {
        insCorpCode: insCorpCode,
        serviceConfigCode: serviceConfigCode,
        apiConfigType: apiConfigType
      }
    });
  },
  /**
   * 获取保司业务配置
   * @param {*} apiConfigCode API配置编码
   */
  getBizSchemaConfig: function getBizSchemaConfig(apiConfigCode) {
    return request.get(getUrl("/settingSchema/getBizSchema", 14), {
      params: {
        apiConfigCode: apiConfigCode
      }
    });
  },
  /**
   * 启用或禁用
   * @param {*} channelConfigId 主键ID
   */
  changeChannelStatus: function changeChannelStatus(channelConfigId, enable) {
    return request.put(getUrl("/insCorpChannel/changeStatus?channelConfigId=".concat(channelConfigId, "&&enable=").concat(enable), 14));
  },
  /**
   * 通过工号ID获取工号特约关系维护列表
   * @param {*} id 
   */
  getByChannelId: function getByChannelId(id) {
    return request.get(getUrl("/insCorpChannelSpecial/getByChannelId?insCorpChannelId=".concat(id), 14));
  },
  /**
   * 添加特约关系维护列表
   * @param {*} data 
   */
  addChannelSpecial: function addChannelSpecial(data) {
    return request.post(getUrl("/insCorpChannelSpecial/create", 14), data);
  },
  /**
   * 删除特约关系维护列表
   * @param {*} data 
   */
  delChannelSpecial: function delChannelSpecial(ids) {
    return request.delete(getUrl("/insCorpChannelSpecial/delete?relationIdList=".concat(ids.toString()), 14));
  },
  /**
   * 获取配置（功能类型）
   * @param {*} insChannelName 
   * @param {*} insCityCode 
   * @param {*} insCorpCode 
   * @returns 
   */
  getFunctionFeature: function getFunctionFeature(insChannelName, insCityCode, insCorpCode) {
    return request.get(getUrl("/insCorpApiConfig/getFunctionFeature", 14), {
      params: {
        insChannelName: insChannelName,
        insCityCode: insCityCode,
        insCorpCode: insCorpCode
      }
    });
  }
};
export default objApi;