import _objectSpread from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "/var/jenkins_home/workspace/test_vi-web-uat-ft/node_modules/_@babel_runtime@7.22.15@@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.push.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import servicePlanRequest from '@/api/InsuranceServicePlan';
import dictRequest from '@/api/dictionary';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    DialogId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      carCheckList: [],
      JYXcheckList: [],
      ZZFWcheckList: [],
      resDatalist: [],
      form: {
        insuranceSchemeRiskParamList: [],
        schemeCode: "",
        schemeName: "",
        schemeResume: "",
        serviceAnnotation: "",
        sort: ""
      },
      createPlanRules: {
        schemeName: [{
          required: true,
          message: '请输入方案名称',
          trigger: 'blur'
        }],
        schemeCode: [{
          required: true,
          message: '请输入方案编码',
          trigger: 'blur'
        }]
      },
      conductorUserlist: [],
      listQuery: {
        pageNum: 1,
        pageSize: 1000
      },
      InsuranceTypes: []
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.getInsuranceTypes();
        if (this.dialogType == 'add') {
          this.form = {
            insuranceSchemeRiskParamList: [],
            schemeCode: "",
            schemeName: "",
            schemeResume: "",
            serviceAnnotation: "",
            sort: ""
          }, this.$refs['createPlan'].resetFields();
          this.carCheckList = [];
        } else {
          this.initData();
        }
      }
    }
  },
  mounted: function mounted() {
    this.getInsuranceTypes();
    if (this.dialogType == 'add') {
      this.form = {
        insuranceSchemeRiskParamList: [],
        schemeCode: "",
        schemeName: "",
        schemeResume: "",
        serviceAnnotation: "",
        sort: ""
      }, this.$refs['createPlan'].resetFields();
      this.carCheckList = [];
    } else {
      this.initData();
    }
  },
  methods: {
    deleteItem: function deleteItem(item) {
      var _this = this;
      //删除险别
      console.log('item=>:', item);
      this.$confirm('您确定要删除么？', {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        type: 'warning'
      }).then(function () {
        servicePlanRequest.deleteRisk(item).then(function (res) {
          _this.initData();
        });
      });
    },
    getInsuranceTypes: function getInsuranceTypes() {
      var _this2 = this;
      //获取险别
      dictRequest.getDictItemsByCode("TYPES_OF_INSURANCE").then(function (res) {
        if (res.data) {
          var _iterator = _createForOfIteratorHelper(res.data),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var i = _step.value;
              i.modelNo = '';
              i.riskPremium = '';
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          _this2.InsuranceTypes = res.data ? res.data : [];
        }
      });
    },
    initData: function initData() {
      var _this3 = this;
      //初始化数据
      this.carCheckList = [];
      servicePlanRequest.getDetail(this.DialogId).then(function (res) {
        if (res.code == 200) {
          if (res.data.insuranceSchemeRiskList && res.data.insuranceSchemeRiskList.length != 0) {
            var _iterator2 = _createForOfIteratorHelper(res.data.insuranceSchemeRiskList),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var i = _step2.value;
                var _iterator3 = _createForOfIteratorHelper(_this3.InsuranceTypes),
                  _step3;
                try {
                  for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                    var j = _step3.value;
                    if (i.riskCode == j.itemCode) {
                      j.insuranceSchemeId = i.insuranceSchemeId;
                      j.insuranceSchemeRiskId = i.insuranceSchemeRiskId;
                      j.serviceProjectId = i.serviceProjectId;
                      _this3.carCheckList.push(i.riskCode);
                    }
                  }
                } catch (err) {
                  _iterator3.e(err);
                } finally {
                  _iterator3.f();
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
            ;
          } else {
            _this3.carCheckList = [];
          }
          _this3.resDatalist = res.data.insuranceSchemeRiskList ? res.data.insuranceSchemeRiskList : [];
          _this3.form = res.data;
        }
      });
    },
    save: function save() {
      var _this4 = this;
      //保存数据
      this.$refs['createPlan'].validate(function (valid) {
        if (valid) {
          if (_this4.dialogType == 'add') {
            var obj = [];
            var _iterator4 = _createForOfIteratorHelper(_this4.InsuranceTypes),
              _step4;
            try {
              for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                var i = _step4.value;
                var _iterator5 = _createForOfIteratorHelper(_this4.carCheckList),
                  _step5;
                try {
                  for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
                    var j = _step5.value;
                    if (i.itemCode == j) {
                      obj.push({
                        riskCode: i.itemCode,
                        riskName: i.itemName,
                        sort: i.sortBy
                      });
                    }
                  }
                } catch (err) {
                  _iterator5.e(err);
                } finally {
                  _iterator5.f();
                }
              }
            } catch (err) {
              _iterator4.e(err);
            } finally {
              _iterator4.f();
            }
            _this4.form.insuranceSchemeRiskParamList = obj;
            console.log(_this4.form);
            // return
            servicePlanRequest.createInsurancescheme(_this4.form).then(function (res) {
              if (res.code == 200) {
                _this4.$message({
                  message: res.msg,
                  type: 'success'
                });
                _this4.closePage();
              }
            });
          } else {
            var _obj = [];
            var _iterator6 = _createForOfIteratorHelper(_this4.InsuranceTypes),
              _step6;
            try {
              for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
                var _i = _step6.value;
                var _iterator7 = _createForOfIteratorHelper(_this4.carCheckList),
                  _step7;
                try {
                  for (_iterator7.s(); !(_step7 = _iterator7.n()).done;) {
                    var _j = _step7.value;
                    if (_i.itemCode == _j) {
                      _obj.push({
                        insuranceSchemeId: _i.insuranceSchemeId,
                        insuranceSchemeRiskId: _i.insuranceSchemeRiskId,
                        serviceProjectId: _i.serviceProjectId,
                        riskCode: _i.itemCode,
                        riskName: _i.itemName,
                        sort: _i.sortBy
                      });
                    }
                  }
                } catch (err) {
                  _iterator7.e(err);
                } finally {
                  _iterator7.f();
                }
              }
            } catch (err) {
              _iterator6.e(err);
            } finally {
              _iterator6.f();
            }
            var data = _objectSpread(_objectSpread({
              insuranceSchemeId: _this4.DialogId
            }, _this4.form), {}, {
              insuranceSchemeRiskParamList: _obj
            });
            delete data.insuranceSchemeRiskList;
            console.log(data);
            // return
            servicePlanRequest.updateDetail(data).then(function (res) {
              if (res.code == 200) {
                _this4.$message({
                  message: res.msg,
                  type: 'success'
                });
                _this4.closePage();
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$refs['createPlan'].resetFields();
      this.carCheckList = [];
      this.$emit('closePage');
    }
  }
};