//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customersRequest from '@/api/customers';
import Rules from '@/utils/rules';
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    currentObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      formLoading: false,
      form: {},
      formRules: {
        caller: [{
          required: true,
          message: '请输入呼出主叫号码',
          trigger: 'blur'
        }, {
          pattern: Rules["MOBILE_RULE"].value,
          message: "呼出主叫号码格式有误",
          trigger: ["blur", "change"]
        }]
      }
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
        this.$refs.addForm.resetFields();
      }
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      //初始化数据
      this.form = _.cloneDeep(this.currentObj);
      this.$set(this.form, 'noTips', !this.form.caller);
    },
    save: function save(boolean) {
      var _this = this;
      //保存数据
      this.$refs['addForm'].validate(function (valid, obj) {
        if (valid) {
          _this.form.tips = boolean;
          _this.formLoading = true;
          customersRequest.webapi(_this.form).then(function (res) {
            _this.formLoading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
              _this.closePage();
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    }
  }
};