import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import operateManageApi from '@/api/operateManage';
import dictRequest from '@/api/dictionary';
import { limitInput } from "@/utils/common";
export default {
  props: {
    refresh: {
      type: Boolean,
      default: true
    },
    opType: {
      type: String,
      default: ''
    },
    currentObj: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      form: {
        modelName: '',
        makerModelCode: '',
        industryModelCode: '',
        purchasePrice: '',
        yearPattern: ''
      },
      rules: {
        modelName: {
          required: true,
          message: '请输入品牌型号/车型名称',
          trigger: "blur"
        },
        makerModelCode: {
          required: true,
          message: '请输入精友车型编码',
          trigger: "blur"
        },
        industryModelCode: {
          required: true,
          message: '请输入行业车型编码',
          trigger: "blur"
        },
        purchasePrice: {
          required: true,
          message: '请输入新车购置价',
          trigger: "blur"
        },
        yearPattern: {
          required: true,
          message: '请输入年款',
          trigger: "blur"
        }
      },
      formLoading: false
    };
  },
  watch: {
    refresh: function refresh(newValue) {
      if (newValue) {
        this.initData();
      }
      ;
    }
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    // 字母或数字
    inputMakerCode: function inputMakerCode(val) {
      val = val.replace(/[^A-Za-z0-9]/g, '');
      this.form.makerModelCode = val;
      this.$forceUpdate();
    },
    // 字母或数字
    inputModelCode: function inputModelCode(val) {
      val = val.replace(/[^A-Za-z0-9]/g, '');
      this.form.industryModelCode = val;
      this.$forceUpdate();
    },
    // 初始化数据
    initData: function initData() {
      this.form = {
        modelName: '',
        makerModelCode: '',
        industryModelCode: '',
        purchasePrice: '',
        yearPattern: ''
      };
      if (this.opType === "edit") {
        this.form = _.cloneDeep(this.currentObj);
      }
      ;
      this.$refs.detail.resetFields();
    },
    // 保存数据
    save: function save() {
      var _this = this;
      this.$refs.detail.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          var req = _this.opType === 'add' ? operateManageApi.addPiccVehicle(_this.form) : operateManageApi.editPiccVehicle(_this.currentObj.piccVehicleModelId, _this.form);
          req.then(function (res) {
            _this.formLoading = false;
            if (res.code === 200) {
              _this.$message.success(res.msg);
              _this.closePage();
            }
            ;
          }).catch(function (err) {
            _this.formLoading = false;
          });
        }
        ;
      });
    },
    closePage: function closePage() {
      //关闭弹窗
      this.$emit('closePage');
    },
    onInput: function onInput(num, min, max, limit) {
      //对输入内容做精度校验
      this.$forceUpdate();
      if (limit > 0) {
        if (min && parseFloat(num) < parseFloat(min)) {
          num = min;
        }
        ;
        if (max && parseFloat(num) > parseFloat(max)) {
          num = max;
        }
        ;
      } else {
        if (min && num < min) {
          num = min;
        }
        ;
        if (max && num > max) {
          num = max;
        }
        ;
      }
      ;
      return limitInput(num, limit);
    }
  }
};